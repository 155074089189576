import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, UrlSegment, ActivatedRouteSnapshot } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthenticationService } from "../services/authentication.service";
import { RepositoryService } from "../services/repository.service";
import { AllowedAdminRoles, User, UserRoles } from "../models/user";
import { environment } from "../../environments/environment";
import { UpdateService } from "../services/update.service";

@Component({
  selector: "paper-login",
  templateUrl: "login.component.web.html",
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loginModel: User;
  loginProcessing: boolean = false;
  loadingProfileFailed: boolean = false;
  loginProcessingMessage: string;
  returnUrl: string;
  env = environment;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private repository: RepositoryService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private updateService: UpdateService,
  ) {
    this.loginModel = new User({
      username: "",
      password: ""
    });
  }

  /** initialize the component */
  ngOnInit() {
    // set up login form
    this.loginForm = new FormGroup({
      username: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required)
    });
  }

  /** user has pressed login button, get values, validate and attempt login */
  login() {
    const username = this.loginForm.get("username").value.trim();
    const password = this.loginForm.get("password").value.trim();

    // check if form valid
    if (!this.loginForm.valid) {
      this.snackBar.open("Form is invalid", "Error", { duration: 6000 });
      return false;
    }

    // attempt to run auth, offline, online
    this.processLogin(username, password)
      .then(({ key, user, message }) => {
        // let user know we have succeeded
        console.log('ProcessLogin success', message);
        this.snackBar.open(message, "Success", { duration: 6000 });

        // login has succeeded, perform post auth steps
        // note: any errors thrown in the postLoginProcess will be caught here and result in error snackbar
        this.processPostLogin(key, user);
      }).catch((loginError) => {
        // login has failed, do not process further
        this.loginProcessing = false;
        console.log('ProcessLogin fail', loginError);
        this.snackBar.open(loginError, "Error", { duration: 10000 });
      });

  }

    /** check that the user has admin rights if this is the admin app */
    checkAdminAppAccess(user: User) {
      console.log("component name: ",this.route.root.component.name );
      if (
        (this.route.root.component.name == "AppAdminComponent" ||  this.route.root.component.name == "Me") &&
        !user.userRoles.some(role => AllowedAdminRoles.includes(role.toLowerCase()))
      ) {
        // admin app, user not authorized to access
        console.log("401: Not authorized used to access the Admin app");
        //throw "User not authorized used to access the Admin app.";
        return false;
      }
      console.log("Authorized used to access the Admin app");
      return true;
    }

    /** navigate to default or return URL */
    navigateToUserApp(user: User) {
      this.returnUrl = "/user/new_record";
      // Extract the current location details
      const currentLocation = window.location;
      // Check if the current domain is localhost
      const newLocation = `${currentLocation.protocol}//${currentLocation.hostname}:${this.returnUrl}`;
      console.log("Authentication OK, Attempt navigate to: ", newLocation);
      //window.location.href = newLocation;
      //console.log("Not on localhost, navigating within the current application.");
      const newWindow = window.open(newLocation, '_self', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }

  /** navigate to default or return URL */
  navigateToApp(user: User) {
    // get return url from route parameters or default to '/new_record'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/new_record";

    // if logged in user is a FA Admin redirect to /new-users view
    this.returnUrl = user.userRoles?.includes(UserRoles.fa_admin)
      ? "/new-users"
      : this.returnUrl;

    console.log("Authentication OK, Attempt navigate to: ", this.returnUrl);
    this.router.navigateByUrl(this.returnUrl);
  }

  /** accept username and password and try to do the login */
  processLogin(username: string, password: string): Promise<{ key: string, user: User, message: string }> {
    return new Promise(async (resolve, reject) => {

      // update login UI show user we are processing
      this.loginProcessing = true;
      this.loginProcessingMessage = "Checking for local user database";

      // check if local user db exists
      await this.authService
        .checkLocalUserDb(username)
        .then(async (checkLocalUserDbsSuccess) => {

          // offline user db found, login offline
          console.log('Local db exists, attempting offline login', checkLocalUserDbsSuccess);
          this.loginProcessingMessage = "Processing offline login";
          this.authService.loadFacilityKeyLocal(username, password)
            .then(({ localDBUserKey, localDBUserObj }) => {
              // successfully logged in offline, allow user to work
              // console.log(result.user, result.encryptionKey);

              // let user know what is happening
              let message = "Successful Offline login";
              this.snackBar.open("Successful Offline login", "Success", { duration: 6000 });

              // set user details from data returned from local db
              this.authService.setUser(new User(localDBUserObj));
              const user = this.authService.getUser();

              // offline login success, return key and user
              resolve({ key: localDBUserKey, user, message });

              // offline login has succeeded, if online also attempt online login
              // allow user to work in background while we attempt online login
              if (navigator.onLine) {
                console.log("Offline login successful, Attempting online");
                this.processOnlineLogin(username, password, localDBUserKey).then(success => {
                  console.log('Offline login succeeded, online login succeeded');
                }).catch(error => {
                  console.log('Offline succeeded but online failed', error);
                });
              }
            }).catch((error) => {
              // unable to load local facility key, username & password must be wrong or have changed
              console.log(error, JSON.stringify(error));
              reject("Error with offline login: Please ensure you are using the same password you used " +
                "on your last successful login for this user on this device.");
            });

        }).catch(async (checkLocalUserDbError) => {
          // no offline user db, login online
          console.log("Local db does not exist, attempting online login.", checkLocalUserDbError);
          this.loginProcessing = true;
          this.loginProcessingMessage = "Requesting authorization";
          await this.processOnlineLogin(username, password).then(({ key, user }) => {
            // online authentication has succeeded, perform post auth steps
            console.log('First time online auth succeeded, perform post auth steps');
            resolve({ key, user, message: 'Online login successful' });
          }).catch(onlineLoginError => {
            // offline then online have both failed
            // reset login password
            this.loginModel.password = "";
            reject(onlineLoginError);
          });
        });

    });
  }

  /** offline login has worked, if we are online also attempt online login */
  processOnlineLogin(username: string, password: string, key: string = null): Promise<{ key: string, user: User }> {
    return new Promise(async (resolve, reject) => {

      // attempt online auth
      await this.authService
        .userLogin(username, password)
        .then(async (response) => {
          // online auth successful, load profile
          await this.loadUserProfile(username)
            .then(async (result) => {
              const user = this.authService.getUser();
              console.log('Profile loaded');

              // if we don't have key, get facility key from remote 
              if (!key) {
                console.log('Online login, need to get encryption key from remote facility')
                await this.authService
                  .loadFacilityKeyRemote(user.facility)
                  .then((encryptionKey) => {
                    key = encryptionKey; // facility encryption key from remote 
                  }).catch((err) => {
                    // error loading remote encryption key
                    console.log("Error loading remote encryption key", err);
                    reject("Unable to load facility encryption key (remote), please try again");
                  });
              } else {
                console.log('Offline login, we have encryption key from local user DB', key);
              }

              // check if we have key, stop processing if we do not have key
              if (!key) { console.log('Key still missing, abort processOnlineLogin'); return; }

              // encryption key loaded, create localUserDb
              console.log('Creating local user DB');
              this.authService
                .createUserDb(user, username, password, key)
                .catch((err) => { console.log('Error creating local user DB', err) });

              // check for un-synced objects
              this.repository.checkUnSyncedInCouch()
                .then((res) => console.log(res))
                .catch((error) => console.log(error));

              // online authentication has succeeded, return key and user
              resolve({ key, user });
            }).catch((error) => {
              // error loading remote user profile
              this.loadingProfileFailed = true;
              reject("Error loading user profile" + error);
            });
        }).catch((error) => {
          // error with online auth, maybe wrong username password or connection issue
          let errorMessage =
            error["message"] === undefined ?
              `An error occurred, if you are logging in with this username on this device for the first time
          please ensure that you are connected to the internet. If you have previously logged in with this username
          on this device please ensure that you have the correct username and password.`
              : error["message"];

          reject(errorMessage);
        });

    });
  }

  /** authentication has succeeded, now give user access to the app */
  processPostLogin(key: string, user: User) {
    // load main database based on user location and begin sync
    this.repository.loadMainDb(user.facility, key);

    // check for updates and show notifications
    this.updateService.init(this.authService.firstTimeLogin);


    // check the app and logged in user
    const adminAllowed = this.checkAdminAppAccess(user);

    // navigate to URL that user was trying to access (return url)
    adminAllowed === true ? this.navigateToApp(user) : this.navigateToUserApp(user);

    // get user ip & update local user with it if we get it
    this.getUserIp(user);
  }

  /** get user ip and update user object with it if we get it */
  getUserIp(user: User) {
    // get user ip
    this.authService
      .getClientIp()
      .then((ip) => {
        // success loaded user ip
        user.ipAddress = ip;
        this.authService.setUser(user);
        console.log("user IP: " + user.ipAddress);
      }).catch((err) => {
        // error loading user ip, create user db anyway
        console.log("Unable to load user ip", err);
        // this.snackBar.open("Unable to load user IP", "Error", { duration: 6000 });
      })
  }

  /** allow user to retry loading their profile */
  retryLoadProfile() {
    this.loadingProfileFailed = false;
    this.loadUserProfile(this.loginModel.username);
  }

  /** load user information/user object from remote server  */
  loadUserProfile(name: string): Promise<User> {
    console.log("Attempting to load user profile for: " + name);
    return new Promise(async (resolve, reject) => {
      await this.authService
        .loadRemoteUser(name)
        .then((result) => {
          this.snackBar.open("Successful Online login", "Success", { duration: 6000 });
          resolve(result);
        })
        .catch((error) => {
          this.loadingProfileFailed = true;
          this.snackBar.open("Sorry, error getting profile" + error, "Error", { duration: 6000 });
          reject(error);
        });
    });
  }
}


