<!-- Results Edit Component Navigation -->
<mat-toolbar class="blackBar">
  <button mat-raised-button (click)="back()" class="mat-button-small align-left">
    <mat-icon>navigate_before</mat-icon>
  </button>
  <button mat-raised-button (click)="update(true)" [disabled]="isProcessingOMR || isProcessingHWR || processingSheetUpdate"
  [style.color]="isProcessingOMR || isProcessingHWR || processingSheetUpdate ? '#999999': '#40ff40'"
    class="mat-button-small update-button">
    <mat-icon>done</mat-icon>
  </button>
  <button mat-raised-button (click)="manualEdit()" class="mat-button-small manual-edit-button">
    <mat-icon svgIcon="manual-edit" aria-hidden="false" aria-label="Manually select the corners of the stamp" id="manual-edit-icon"></mat-icon>
  </button>
  <button mat-raised-button (click)="cancel();" class="mat-button-small align-right" style="color: red">
    <mat-icon>clear</mat-icon>
  </button>
</mat-toolbar>


<!-- Editable Scanned Image -->
<div style="overflow: hidden; position: relative">
  <canvas id="canvasCircles"></canvas>

  <!-- Handwriting Field Results -->
  <div style="position: absolute; top:0; left:0">
    <ng-container *ngFor="let handwritingField of template?.handwritingFields; let item_index=index">
      <div *ngIf="!(handwritingField | hideHwFieldInView: template)">
        <a class="handwritingDigitDisplay" (click)="showGroupEdit(handwritingField.groupName)"
          *ngIf="sheet?.handwritingResults?.[handwritingField.groupName]?.[handwritingField.fieldName]?.displayVal; else spinner"
          [ngStyle]="{ 
          'top': handwritingField.y * dimensions.templateScale + 'px', 
          'left': handwritingField.x * dimensions.templateScale + 'px', 
          'width': template.handwritingFieldWidth * dimensions.templateScale + 'px',
          'height': template.handwritingFieldHeight * dimensions.templateScale + 'px' 
        }">
          <span
            *ngIf="sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal?.toLowerCase() === 'blank'; else othersResults"></span>

          <ng-template #othersResults>
            <span
              *ngIf="sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal?.toLowerCase() === 'unknown'; else HWResults">?</span>
          </ng-template>
          <ng-template #HWResults>
            {{sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal}}
          </ng-template>
        </a>
        <ng-template #spinner>
          <i class="fa fa-circle-o-notch fa-spin" [ngStyle]="{ 'position': 'absolute',
          'top': handwritingField.y * dimensions.templateScale + 'px', 
          'left': handwritingField.x * dimensions.templateScale + 'px' }"></i>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <!-- Handwriting Field Editor -->
  <ng-container *ngFor="let handwritingGroup of template?.handwritingGroups">
    <div id="hwResultsDiv_{{ handwritingGroup.groupTitle }}" class="hwResultsDiv"
      [hidden]="!showEditGroup[handwritingGroup.groupTitle]"
      [ngStyle]="{'max-Width': dimensions.maxWidth + 'px', 'width': '97%'}">
      <div class="hwHeader">
        <span>{{ handwritingGroup.groupLabel ? handwritingGroup.groupLabel : handwritingGroup.groupTitle }}</span>
        <a (click)="hideGroupEdit(handwritingGroup.groupTitle)">Close ✖</a>
      </div>
      <div class="hwContentDiv">
        <ng-container *ngFor="let handwritingField of template?.handwritingFields">
          <div *ngIf="handwritingField.groupName === handwritingGroup.groupTitle" class="hwResultDiv">
            <div id="selectDiv_{{ handwritingField.serialNumber }}"></div>

            <ng-container *ngIf="sheet && sheet.handwritingResults &&
              handwritingField.groupName === handwritingGroup.groupTitle &&
              sheet.handwritingResults[handwritingField.groupName] &&
              sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName]">

              <!-- numeric select options -->
              <select *ngIf="handwritingGroup.handwritingFieldOptionGroup === 'numeric'; else nonNumeric"
                [ngStyle]="{ 'width': dimensions.digitWidth + 'px'}"
                [(ngModel)]="sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal"
                (change)="onChangeUserValidatedResult($event, handwritingField)">
                <option
                  *ngFor="let fieldOption of getHandwritingFieldOptions(handwritingGroup.handwritingFieldOptionGroup)"
                  [value]="fieldOption">{{fieldOption}}</option>
              </select>

              <!-- non-numeric select options -->
              <ng-template #nonNumeric>
                <ng-select
                  [(ngModel)]="sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal"
                  (change)="onChangeUserValidatedResult($event, handwritingField)"
                  [items]="getHandwritingFieldOptions(handwritingGroup.handwritingFieldOptionGroup)">
                </ng-select>
              </ng-template>

            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

</div>

<!-- HW & OMR processing progress bar -->
<div class="form-group" style="overflow: hidden; position: relative;" 
  [ngStyle]="{ 'width': template?.templateWidth * dimensions?.templateScale + 'px'}" >
  <mat-progress-bar mode="indeterminate" *ngIf="isProcessingHWR || isProcessingOMR"></mat-progress-bar>
</div>

<!-- Toggle template and Scanned Image -->
<div class="form-group" style="overflow: hidden; position: relative; padding: 10px">
  <mat-slide-toggle [(ngModel)]="showTemplateOverlay" (change)="toggleTemplateOverlay()">
    Show Template
  </mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="showScannedImageOverlay" (click)="toggleScannedImageOverlay()" class="uk-margin-left">
    Show Scanned Image
  </mat-slide-toggle>
</div>

<!-- If error -->
<div *ngIf="sheet.sheetErrors?.length > 0 && appSettings.uiViewSettings.showSheetErrors">
  <hr />
  <strong>Validation Errors</strong>
  <ul class="uk-list uk-list-line">
    <li class="uk-list uk-list-line" *ngFor="let sheetError of sheet.sheetErrors">

      <div [ngSwitch]="sheetError.code">

        <!-- message to be shown when there is any other error code -->
        <ng-container *ngSwitchDefault>
          <strong class="uk-text-danger">Error:</strong> {{sheetError.message}}
        </ng-container>

        <!-- message to be shown when there is invalid report date -->
        <ng-container *ngSwitchCase="SheetErrorCodes.invalidReportDate">
          <strong class="uk-text-danger">Error:</strong> {{sheetError.message}}
        </ng-container>

        <!-- message to be shown when there is a commodity count mismatch -->
        <ng-container *ngSwitchCase="SheetErrorCodes.commodityCountMismatch">
          <strong class="uk-text-danger">Error:</strong> {{sheetError.message}}
        </ng-container>

      </div>

    </li>
  </ul>
</div>

<div>

  <!-- <strong>sheet errors ({{ sheet?.sheetErrors?.length }}):</strong> {{ sheet?.sheetErrors | json }} -->
  <!-- <strong>showEditGroup:</strong> {{ showEditGroup | json }}<br /> -->
  <!-- <strong>template?.handwritingFields:</strong> {{ template?.handwritingFields | json }}<br /> -->
  <!-- <strong>this.newRecord:</strong> {{ this.newRecord | json }}<br /> -->
  <!-- <strong>currentAttachment:</strong> {{ currentAttachment | json }}<br /> -->
  <!-- <strong>sheet:</strong> {{ sheet | json }}<br /> -->
  <!-- <strong>sheet.handwritingResults:</strong> {{ sheet.handwritingResults | json }}<br /> -->
  <!-- <strong>AppSettings.minDate:</strong> {{ this.appSettings.recordSettings.minDate| json }}<br /> -->
  <!-- <strong>DefaultMinDate:</strong> {{ DefaultMinDate }}<br /> -->
  <!-- <strong>Supported Precision:</strong> {{supportedPrecision}}<br /> -->


</div>
