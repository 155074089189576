import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

import { Patient } from '../../../models/patient';
import { Facility } from '../../../models/facility';
import { User, UserRoles } from '../../../models/user';
import { TemplateSeries } from '../../../models/series';
import { Attachment } from '../../../models/attachment';
import { ReportSeries } from '../../../models/report-series';
import { AppSettings, DEFAULT_DATE_MASK, DefaultMinDate } from '../../../models/settings';
import { HandwritingField, HandwritingFieldOptionGroups, HandwritingGroup, StampTemplate } from '../../../models/stamp-template';
import { AnswerSheet, HandwritingResult, HandwritingResultData, HandwritingResultGroup, PsocDeviceInfo, SheetError, SheetErrorCodes, SheetErrorStatusCodes } from '../../../models/answer-sheet';

import { FormatPhone } from '../../../pipes/formatPhone';
import { GetMaxValue } from '../../../pipes/getMaxValuePipe';
import { ValidDatePipe } from '../../../pipes/validDate.pipe';
import { GetCountValue } from '../../../pipes/getCountValuePipe';
import { GetExclusiveValuePipe } from '../../../pipes/getExclusiveValuePipe';

import { SheetService } from '../../../services/sheet.service';
import { PatientService } from '../../../services/patient.service';
import { TemplateService } from '../../../services/template.service';
import { AttachmentService } from '../../../services/attachment.service';
import { RepositoryService } from '../../../services/repository.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ExtractResultsService } from '../../../services/extract-results.service';
import { DetectBorderServiceQR } from '../../../services/detect-border-qr.service';
import { TemplateSeriesService } from '../../../services/template-series.service';

import { environment } from '../../../../environments/environment';
import { fabric } from 'fabric';
import * as moment from 'moment';
import * as DeviceDetector from 'device-detector-js';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  template: `
    <h2 mat-dialog-title class="uk-text-danger uk-margin-top-remove">Error(s)</h2>
    <mat-dialog-content>
    <div style="max-height: 70vh; overflow: auto">
      <div *ngFor="let sheetError of data.sheetErrors">
        <div [ngSwitch]="sheetError.code">
          <!-- message to be shown when there no match of error code types -->
          <ng-container *ngSwitchDefault>
            <p class="uk-margin-top-remove">{{ sheetError.message }}</p>
          </ng-container>

          <!-- message to be shown when there is invalid report date -->
          <ng-container *ngSwitchCase="SheetErrorCodes.invalidReportDate">
            <p class="uk-margin-top-remove">{{ sheetError.message }}</p>
          </ng-container>

          <!-- message to be shown when there is a commodity count mismatch -->
          <ng-container *ngSwitchCase="SheetErrorCodes.commodityCountMismatch">
            <p class="uk-margin-top-remove">{{ sheetError.message }}</p>
          </ng-container>

          <!-- message to be shown when there is a calculation error -->
          <ng-container
            *ngSwitchCase="SheetErrorCodes.commodityCalculationFailed"
          >
            <p class="uk-margin-top-remove">{{ sheetError.message }}</p>
            <table
              class="uk-margin-top-remove uk-table uk-table-striped uk-table-condensed"
              style="border: 2px solid rgba(34, 34, 34, 0.1);"
            >
              <thead>
                <tr>
                  <th colspan="3">{{ sheetError.data.groupName }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Beginning balance</td>
                  <td>+</td>
                  <td>
                    {{ sheetError.data.groupResults["Beginning Balance"] }}
                  </td>
                </tr>
                <tr>
                  <td>Qty received:</td>
                  <td>+</td>
                  <td>
                    {{ sheetError.data.groupResults["Quantity Received"] }}
                  </td>
                </tr>
                <tr>
                  <td>Qty dispensed:</td>
                  <td>-</td>
                  <td>
                    {{ sheetError.data.groupResults["Quantity Dispensed"] }}
                  </td>
                </tr>
                <tr>
                  <td>Added:</td>
                  <td>+</td>
                  <td>{{ sheetError.data.groupResults["Stock Added"] }}</td>
                </tr>
                <tr>
                  <td>Removed:</td>
                  <td>-</td>
                  <td>{{ sheetError.data.groupResults["Stock Removed"] }}</td>
                </tr>
                <tr>
                  <td>Ending balance:</td>
                  <td>=</td>
                  <td style="color: red">
                    {{ sheetError.data.groupResults["Ending Balance"] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
    </div>

    <br />
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close('correctError')"
    >
      Go Back to Correct the Error
    </button>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close('continueWithError')"
    >
      Continue With Error
    </button>
    </mat-dialog-actions>
  `,
})
export class DataValidationDialogComponent {
  public SheetErrorCodes = SheetErrorCodes;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) { }
}

@Component({
  selector: "paper-sheet-results-edit",
  styleUrls: ["sheet-results-edit.component.css"],
  templateUrl: "sheet-results-edit.component.html",
  providers: [GetCountValue, GetMaxValue, GetExclusiveValuePipe],
})
export class SheetResultsEditComponent implements OnInit, OnDestroy {
  env = environment;
  @Input() loggedInUser: User;
  @Input() facility: Facility;
  @Input() series: TemplateSeries[];
  @Input() newRecord: boolean;
  @Input() currentPatient: Patient;
  @Input() set appSettings(appSettings: AppSettings) {
    this._appSettings = appSettings;
    if (this.appSettings.uiViewSettings.cicWorkflow) {
      this.isCHAUser = this.loggedInUser.userRoles
        ? this.loggedInUser.userRoles.includes(
          this.repositoryService.enumSelector(UserRoles)[3].title
        )
        : false;
    }
  }
  get appSettings(): AppSettings {
    return this._appSettings;
  }
  _appSettings: AppSettings;
  DefaultMinDate = DefaultMinDate;

  isProcessingOMR: boolean = false; // tracks if OMR algorithm still running
  loadingReportAnswerSheets: boolean;

  @Input() processingSheetUpdate: boolean;
  @Output() processingSheetUpdateChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  isProcessingHWR: boolean = false; // tracks if HWR algorithm is still running

  supportedPrecision: string; // track precision capabilities of this device
  locationInfo; // track location info for this device

  @Output() processingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() manualEditNavigate: EventEmitter<any> = new EventEmitter<any>(); // cancel button pressed
  @Output() resultsEditCancel: EventEmitter<any> = new EventEmitter<any>(); // cancel button pressed
  @Output() resultsEditNavigateBack: EventEmitter<any> =
    new EventEmitter<any>(); // navigate back button pressed
  @Output() resultsEditUpdate: EventEmitter<any> = new EventEmitter<any>(); // update button pressed

  @Input() set currentAttachment(currentAttachment: Attachment) {
    this._currentAttachment = currentAttachment;
    console.log('SheetResultsEdit: done setting sheet, attempting to load template dimensions');
    this.loadTemplateDimensions();
  }
  get currentAttachment(): Attachment {
    return this._currentAttachment;
  }
  _currentAttachment: Attachment;

  @Input() set template(template: StampTemplate) {
    this._template = template;
    console.log('SheetResultsEdit: done setting template, attempting to load template dimensions');
    this.loadTemplateDimensions();
  }
  get template(): StampTemplate {
    return this._template;
  }
  _template: StampTemplate;

  @Input() set sheet(sheet: AnswerSheet) {
    this._sheet = sheet;
    if (this.processingSheetUpdate) { console.log('SheetResultsEdit: processing, do not update results edit view'); return; }
    console.log('SheetResultsEdit: done setting sheet, attempting to load template dimensions');
    this.loadTemplateDimensions();
  }
  get sheet(): AnswerSheet {
    return this._sheet;
  }
  _sheet: AnswerSheet;

  @Input() currentReportSeries: ReportSeries;

  isCHAUser: boolean;
  dialogRef: MatDialogRef<any>;

  public SheetErrorCodes = SheetErrorCodes;

  canvasCircles: any = null; // fabric canvas

  showEditGroup: any = {};

  /** track whether layer images loaded/displayed */
  templateImageLayer: any;
  templateImageLoaded: boolean = false;
  scannedImageLayer: any;
  scannedImageLayerLoaded: boolean = false;
  showTemplateOverlay: boolean = false;
  showScannedImageOverlay: boolean = true;

  COLOR_CHECKED = "lightgreen";
  COLOR_UNCHECKED = "orange";

  HandwritingFieldOptionGroups = HandwritingFieldOptionGroups;

  // dimensions
  dimensions: {
    maxWidth: number;
    maxHeight: number;
    originalTemplateHeight: number;
    originalTemplateWidth: number;
    templateScale: number;
    digitWidth: number;
  } = {
      maxWidth: Math.min(500, window.innerWidth), // allow for padding
      maxHeight: Math.min(600),
      digitWidth: (Math.min(500, window.innerWidth) - 10) / 10,
      originalTemplateHeight: null,
      originalTemplateWidth: null,
      templateScale: null, // scale factor for fitting canvases to window
    };

  // keep track of whether any circles have been modified
  _hasChanges: boolean = false;
  get dirty() {
    return this._hasChanges;
  }
  // keep track of update to encounter date/scan date
  _isNewEncounterDate: boolean = false;
  get newEncounterDate() {
    return this._isNewEncounterDate;
  }

  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private snackbar: MatSnackBar,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private getCountValue: GetCountValue,
    private cd: ChangeDetectorRef,
    private getMaxValue: GetMaxValue,
    private getExclusiveValuePipe: GetExclusiveValuePipe,
    matIconRegistry: MatIconRegistry,
    private sheetService: SheetService,
    private patientService: PatientService,
    private repositoryService: RepositoryService,
    private templateService: TemplateService,
    private attachmentService: AttachmentService,
    private templateSeriesService: TemplateSeriesService,
    private deviceService: DeviceDetectorService,
    private detectBorderServiceQR: DetectBorderServiceQR,
    private auth: AuthenticationService,
    private extractResultsService: ExtractResultsService,
  ) {
    matIconRegistry.addSvgIcon(
      "manual-edit",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/manual-edit.svg"
      )
    );
  }

  /** Initialize canvas and register mouse click listener */
  ngOnInit() {
    // init canvas circles if not initialized
    this.initCanvasCircles();

    // if circle clicked change value
    this.canvasCircles.on("mouse:dblclick", (event) => {
      if (event.target) {
        if (event.target.id !== undefined) {
          console.log(event.target.id);
          // const serialNumber = event.target.id;
          const fieldGroupName = event.target.groupName;
          const fieldName = event.target.fieldName;
          this.sheet.answers[fieldGroupName][fieldName] =
            !this.sheet.answers[fieldGroupName][fieldName];
          const fieldValue: boolean =
            this.sheet.answers[fieldGroupName][fieldName];
          event.target.set(
            "stroke",
            fieldValue ? this.COLOR_CHECKED : this.COLOR_UNCHECKED
          );
          this._hasChanges = true;
          this.canvasCircles.renderAll();

          // value has changed, revalidate
          this.processResults(false);
        }
      }
    });

    // run routines
    this.processResults(false);

    this.supportedPrecision = this.detectBorderServiceQR.precisionSupported(); // get precision of this device

    this.getPosition().then(pos => { // get location info for this device
      console.log(`Geo location: ${pos.lng} ${pos.lat}`);
      this.locationInfo = pos;
    });

    if (!this.currentAttachment || !this.currentAttachment._attachments) {
      this.getAttachment();
    }
  }

  /** function to request user location (geolocation longitude & latitude) */
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation
        .getCurrentPosition(resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        }, err => { reject(err); });
    });
  }

  /** initialize circles canvas if not initialized */
  initCanvasCircles() {
    if (!this.canvasCircles) {
      fabric.Object.prototype.objectCaching = false;
      this.canvasCircles = new fabric.Canvas("canvasCircles", {
        allowTouchScrolling: true,
        enablePointerEvents: true,
      });
    }
  }

  /** Destroy unused elements to recover memory */
  ngOnDestroy(): void {
    this.canvasCircles.clear();
    this.canvasCircles.dispose();
    this.canvasCircles = null;
    if (this.templateImageLayer) {
      this.templateImageLayer.dispose();
    }
    this.templateImageLayer = null;
    if (this.scannedImageLayer) {
      this.scannedImageLayer.dispose();
    }
    this.scannedImageLayer = null;
  }

  // return values for a given option group
  getHandwritingFieldOptions(optionGroupName): string[] {
    return HandwritingFieldOptionGroups.find((o) => o.name === optionGroupName)
      .values;
  }

  /** function to load */
  loadTemplateDimensions() {
    console.log('SheetResultsEdit: attempting to load template dimensions for sheet results edit');

    if (!this.sheet) {
      console.log('SheetResultsEdit: unable to load template dimensions, missing sheet');
      return;
    }

    if (!this.template) {
      console.log('SheetResultsEdit: unable to load template dimensions, missing template');
      return;
    }

    if (!this.currentAttachment) {
      console.log('SheetResultsEdit: unable to load template dimensions, missing current attachment');
      return;
    }

    console.log('SheetResultsEdit: all prerequisites in order, loading template dimensions');

    // initialize canvas circles if not initialized
    this.initCanvasCircles();

    // set dimensions original template width/height to template image width/height
    this.dimensions.originalTemplateHeight = this.template.templateHeight;
    this.dimensions.originalTemplateWidth = this.template.templateWidth;

    // calculate scale to fit template image inside video
    this.dimensions.templateScale = Math.min(
      this.dimensions.maxWidth / this.dimensions.originalTemplateWidth,
      this.dimensions.maxHeight / this.dimensions.originalTemplateHeight
    );

    // reset the canvas, clear all the circles
    this.resetCanvas();

    // set width/height of canvas to the template image width/height
    this.canvasCircles.setHeight(
      this.template.templateHeight * this.dimensions.templateScale
    );
    this.canvasCircles.setWidth(
      this.template.templateWidth * this.dimensions.templateScale
    );

    // get image attachment (scan image)
    this.getAttachment();

    // at this point, if this is a new record, start processing
    if (this.newRecord && this.currentAttachment?._attachments['file'].data) {
      console.log('processing OMR & HWR');
      this.extract(this.currentAttachment._attachments['file'].data);
    } else {
      this.drawTemplateCirclesToCanvas();
    }

    console.log('SheetResultsEdit: Template image file loaded');

  }

  /** Function used to load template _attachment */
  getTemplateAttachment(templateId): Promise<string> {
    return new Promise((resolve, reject) => {
      this.templateService
        .getTemplateAttachment(templateId)
        .then((blob) => {
          // create a url from the blob
          const url = URL.createObjectURL(blob);
          // create instance of an image
          const templateImage = new Image();
          // onload of template image
          templateImage.onload = async () => {
            this.templateImageLoaded = true;

            console.log('SheetResultsEdit: loaded template image: height, width', templateImage.height, templateImage.width);
            console.log('SheetResultsEdit: loaded template: height, width', this.template.templateHeight, this.template.templateWidth);

            // set dimensions original template width/height to template image width/height
            this.dimensions.originalTemplateHeight = templateImage.height;
            this.dimensions.originalTemplateWidth = templateImage.width;

            // calculate scale to fit template image inside video
            this.dimensions.templateScale = Math.min(
              this.dimensions.maxWidth / this.dimensions.originalTemplateWidth,
              this.dimensions.maxHeight / this.dimensions.originalTemplateHeight
            );

            // reset the canvas
            this.resetCanvas();

            // set width/height of canvas to the template image width/height
            this.canvasCircles.setHeight(
              templateImage.height * this.dimensions.templateScale
            );
            this.canvasCircles.setWidth(
              templateImage.width * this.dimensions.templateScale
            );

            // add template image layer
            await this.addTemplateImageLayer(templateImage);

            // Add function to get scanned image( sheet attachment file )
            // this.getAttachment();
            this.drawTemplateCirclesToCanvas();
            console.log('SheetResultsEdit: Template image file loaded');
            resolve('template image loaded')
          };
          templateImage.src = url;

        }).catch((error) => {
          console.log('SheetResultsEdit: Unable to load template image', error);
          reject('template image layer load failed; ' + error)
        });
    });
  }

  /** setup circle controls for each template answer field */
  drawTemplateCirclesToCanvas(): void {

    console.log('SheetResultsEdit: Drawing template circles to main canvas');
    this.template.answerTemplates.forEach((answerTemplate) => {
      if (!this.sheet.answers[answerTemplate.groupName]) {
        // if group does not exist add it
        this.sheet.answers[answerTemplate.groupName] = {
          [answerTemplate.fieldName]: false,
        };
      } else if (
        !this.sheet.answers[answerTemplate.groupName][answerTemplate.fieldName]
      ) {
        // if property doesn't exist on model, add it
        this.sheet.answers[answerTemplate.groupName][answerTemplate.fieldName] =
          false;
      }

      if (
        !this.template.groups.find(
          (group) => group.groupTitle === answerTemplate.groupName
        ).hideInView
      ) {
        // add circle on canvas
        this.addCircle(
          answerTemplate.x * this.dimensions.templateScale,
          answerTemplate.y * this.dimensions.templateScale,
          answerTemplate.serialNumber,
          this.sheet.answers[answerTemplate.groupName][answerTemplate.fieldName],
          answerTemplate.fieldName,
          answerTemplate.groupName
        );
      }
    });
  }

  /**
   * Function to add circle to template image
   * @param x x coordinate (distance from left)
   * @param y y coordinate (distance from top)
   */
  addCircle(
    x: number,
    y: number,
    fieldIndex: number,
    selected: boolean,
    fieldName: string,
    groupName: string
  ): void {
    const circleColor: string = selected
      ? this.COLOR_CHECKED
      : this.COLOR_UNCHECKED;
    const circle = new fabric.Circle({
      id: fieldIndex,
      groupName: groupName,
      fieldName: fieldName,
      stroke: circleColor,
      fill: "rgba(0,0,0,0)",
      radius: this.template.circle_radius * this.dimensions.templateScale,
      strokeWidth: 4 * this.dimensions.templateScale,
      opacity: 1,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      hasBorders: false,
      left: x,
      top: y,
      originX: "center",
      originY: "center",
    });
    this.canvasCircles.add(circle);
  }

  /** function to get attachment file from the database */
  async getAttachment() {
    console.log('SheetResultsEdit: attempt to get attachment')
    // fetch all attachment files from the sheet object
    if (!this.currentAttachment || !this.currentAttachment._attachments) {
      this.showTemplateOverlay = true;
      this.showScannedImageOverlay = false;
      this.toggleScannedImageOverlay();
      this.toggleTemplateOverlay();
      console.log('SheetResultsEdit: No attachments: unable to list attachments');
      return;
    }

    if (!this._sheet._id || this.currentAttachment._attachments['file'].data) {
      console.log('SheetResultsEdit: this is a new record, load attachments directly')
      // this is a new record, load attachments directly
      await this.addScannedImageLayer(this.currentAttachment._attachments['file'].data);
    } else {
      console.log('SheetResultsEdit: this is an existing record, load attachments from pouch')
      // this is an existing record, load attachments from pouch
      this.attachmentService.getSheetAttachment(this.currentAttachment._id, 'file').then((blob) => {
        this.addScannedImageLayer(blob);
      }).catch((err) => {
        console.log('SheetResultsEdit: Unable to load scanned image file', err);
        this.snackbar.open('ResultsComponent: Unable to load scanned image file', 'Error', { duration: 6000 });
      });

    }
  }

  /** function to add template image layer */
  addTemplateImageLayer(templateImage: any) {
    // copy template image to fabric
    this.templateImageLayer = new fabric.Image(templateImage, {
      left: 0,
      top: 0,
      scaleX: this.dimensions.templateScale,
      scaleY: this.dimensions.templateScale,
      opacity: 0.85,
      selectable: false,
      evented: false,
    });
    // set boolean to check if template image loaded to true
    console.log('SheetResultsEdit: template image layer added');
  }

  /** function to turn loading image into a promise so we can wait for it if necessary */
  loadImage = (url) => new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', (err) => {
      console.log('loadImage err', err);
      reject(err);
    });
    img.src = url;
  });

  /**
  * function to add scanned image layer
  * @param imageBlob scanned image blob
  */
  async addScannedImageLayer(imageBlob) {
    console.log('SheetResultsEdit: adding scanned image layer');
    const url = URL.createObjectURL(imageBlob);

    await this.loadImage(url).then(scannedImage => {
      // copy scanned image to fabric
      this.scannedImageLayer = new fabric.Image(scannedImage, {
        left: 0,
        top: 0,
        scaleX:
          (this.dimensions.originalTemplateWidth *
            this.dimensions.templateScale) /
          scannedImage.width,
        scaleY:
          (this.dimensions.originalTemplateHeight *
            this.dimensions.templateScale) /
          scannedImage.height,
        opacity: 0.85,
        selectable: false,
        evented: false,
      });
      console.log('SheetResultsEdit: scanned image layer loaded');
      this.scannedImageLayerLoaded = true;

      // add scanned image to the back of the canvas
      this.canvasCircles.add(this.scannedImageLayer);
      this.canvasCircles.sendToBack(this.scannedImageLayer);

      if (this.template.handwritingFields) {
        // show handwriting field images
        let circlesContext = this.canvasCircles.toCanvasElement().getContext('2d', { willReadFrequently: true });
        for (const handwritingField of this.template.handwritingFields) {
          const left = handwritingField.x * this.dimensions.templateScale;
          const top = handwritingField.y * this.dimensions.templateScale;
          const width =
            this.template.handwritingFieldWidth * this.dimensions.templateScale;
          const height =
            this.template.handwritingFieldHeight *
            this.dimensions.templateScale;

          const fieldData = circlesContext.getImageData(
            left,
            top,
            width,
            height
          );

          // draw to debug
          const newCanvas = document.createElement("canvas");
          newCanvas.width = width;
          newCanvas.height = height;

          newCanvas.style.width = this.dimensions.digitWidth + "px";
          const newContext = newCanvas.getContext("2d", {
            willReadFrequently: true,
          });
          newContext.putImageData(fieldData, 0, 0);

          document.getElementById("selectDiv_" + handwritingField.serialNumber).appendChild(newCanvas);
        };
      }
      this.zone.run(() => {
        this.canvasCircles.renderAll(); // force ui to update
        this.cd.detectChanges();
      });

    }).catch(err => { console.log('error loading scanned image', err) });

  }

  /** show editing popup for given group */
  showGroupEdit(groupName: string) {
    this.showEditGroup[groupName] = true;
    this.cd.detectChanges();
  }

  /** hide editing popup for given group */
  hideGroupEdit(groupName: string) {
    this.showEditGroup[groupName] = false;
  }

  /** reset the canvas */
  resetCanvas() {
    this.canvasCircles.clear();
  }

  /**
   * Toggle display of template overlay
   */
  async toggleTemplateOverlay() {
    console.log('SheetResultsEdit: toggle display of template overlay');
    if (!this.templateImageLoaded) {
      console.log('SheetResultsEdit: Template image not yet loaded');

      await this.getTemplateAttachment(this.sheet.stampTemplateId).then(success => {
        console.log('SheetResultsEdit: Template image loaded', success);
        this.showTemplateOverlay = true;
      }).catch(err => {
        console.log('SheetResultsEdit: Template image not yet loaded', err);
        this.snackbar.open('Template image not yet loaded', 'Error', { duration: 6000 });
        this.showTemplateOverlay = false;
        return;
      });

    }

    if (!this.showTemplateOverlay) {
      // remove template image from canvas
      this.canvasCircles.remove(this.templateImageLayer);
    } else {
      // add template image to canvas
      this.canvasCircles.add(this.templateImageLayer);
      this.canvasCircles.sendToBack(this.templateImageLayer);
    }
  }

  /** Toggle display of scanned image */
  toggleScannedImageOverlay() {
    console.log('SheetResultsEdit: toggle display of scanned image overlay');
    // check if we have the scanned image layer
    if (!this.scannedImageLayerLoaded) {
      console.log('SheetResultsEdit: Scanned image not yet loaded');
      this.snackbar.open('Scanned image not yet loaded or does not exist', 'Error', { duration: 6000 });
      return;
    }

    if (!this.showScannedImageOverlay) {
      // add sheet image to canvas
      this.canvasCircles.add(this.scannedImageLayer);
      this.canvasCircles.sendToBack(this.scannedImageLayer);
    } else {
      // remove sheet image from canvas
      this.canvasCircles.remove(this.scannedImageLayer);
    }
  }

  /** User has pressed manual update button */
  manualEdit() {
    console.log('SheetResultsEdit: manual edit has been pressed');
    this.manualEditNavigate.emit();
  }

  /** User has pressed cancel button */
  cancel() {
    console.log('SheetResultsEdit: cancel has been pressed');
    this.resultsEditCancel.emit();
  }

  /** User has pressed update button */
  update(applyChanges: boolean) { // applyChanges controls whether to run the shareToDefaultFacility routine
    if (this.isProcessingOMR || this.isProcessingHWR) {
      console.log('SheetResultsEdit: this.isProcessingOMR', this.isProcessingOMR, 'this.isProcessingHWR', this.isProcessingHWR);
      console.log('SheetResultsEdit: Still extracting, do not update');
      return;
    }

    this.processingSheetUpdate = true; this.processingSheetUpdateChange.emit(this.processingSheetUpdate);
    console.log('SheetResultsEdit: update has been pressed');

    // correct month year formats
    const templateInSeries = this.templateSeriesService.isTemplateInSeries(this.template);
    const isFirstTemplateInSeries = templateInSeries && this.series !== undefined
      ? this.series[0].templateIds.indexOf(this.sheet.stampTemplateId) === 0
      : false;

    if (!this.newRecord && this.appSettings.uiViewSettings.cicWorkflow && this.isCHAUser && isFirstTemplateInSeries && this._hasChanges) {
      let monthFromScan: any = this.sheetService.getAnswers(this.template.groups[0].groupTitle, this.sheet);
      let yearFromScan: any = this.sheetService.getAnswers(this.template.groups[1].groupTitle, this.sheet);

      console.log('SheetResultsEdit: Month:', monthFromScan, 'year:', yearFromScan);

      // get month & year in form of a numeric OR set to unknown if not filled
      yearFromScan =
        yearFromScan && yearFromScan.length !== 1 ? "Unknown" : yearFromScan[0];
      monthFromScan =
        monthFromScan && monthFromScan.length !== 1
          ? "Unknown"
          : this.sheetService.getMonthNumber(monthFromScan);

      this.sheet.encounterDate = monthFromScan + "/28/" + yearFromScan;
      this._isNewEncounterDate = true;
    }

    // process routines (returns result true if we want to go ahead and save, false if we want to stay and fix errors)
    // applyChanges controls whether to run the shareToDefaultFacility routine
    this.processResults(applyChanges).then(result => {
      if (result) {
        // processing succeeded, update the record
        this.resultsEditUpdate.emit();
      } else {
        // stop processing, user has decided to correct errors.
        this.processingSheetUpdate = false; this.processingSheetUpdateChange.emit(this.processingSheetUpdate);
      }
    }).catch(() => {
      // error when processing routines
      this.processingSheetUpdate = false; this.processingSheetUpdateChange.emit(this.processingSheetUpdate);
    });

  }

  /**
   * Function handle handwriting value correction
   * @param event event with value
   * @param handwritingField handwriting field group
   */
  onChangeUserValidatedResult(
    event: Event,
    handwritingField: HandwritingField
  ) {
    // the Value is either an Event object or a single value eg V
    const value =
      typeof event === "object"
        ? (event.target as HTMLInputElement).value
        : event; // TODO: the Event object value is not a single value eg 7: 7
    const fieldValues = this.sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName];
    this.sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].userValidatedResult = value;

    // value has changed, revalidate
    this.processResults(false);
  }

  /** User has pressed 'next' button */
  back() {
    console.log('SheetResultsEdit: back has been pressed');
    this.resultsEditNavigateBack.emit();
  }


  /** function to extract the data from scanned image that has already been cut to the detected qr corners */
  extract(correctedImageBlob: Blob) {
    console.log('SheetResultsEdit: Start extraction, process OMR & HWR');
    this.isProcessingHWR = true;
    this.isProcessingOMR = true;

    // clear sheet handwriting Result before new extraction
    this.sheet.handwritingResults = {};

    let extractStartTime = performance.now(); // record start time

    if (!this.sheet) {
      console.log('SheetResultsEdit: Sheet not loaded, please wait');
      return;
    }

    if (!this.template) {
      console.log('SheetResultsEdit: Template not loaded, please wait');
      return;
    }

    // perform extraction
    const transformedImg = new Image();
    transformedImg.onload = () => {
      // subscribe to results of handwriting processing

      this.extractResultsService.getHandwritingResultUpdateObservable()
        .subscribe({
          next: (resultData: { handwritingGroupName: string, handwritingFieldName: string, fieldUpdateData: HandwritingResultData }) => {
            this.updateHWField(resultData.handwritingGroupName, resultData.handwritingFieldName, resultData.fieldUpdateData);
          },
          error: (error) => {
            console.log('HW observable error processing HW updates from extract results service to sheets component', error)
          },
          complete: () => {
            // when results is updated update the handwriting results
            console.log('HW observable processing and updating has completed')
            if (this.sheet.handwritingResults) {
              const groupWithUserInput = this.template.handwritingGroups.filter(handwritingGroup => handwritingGroup.userInput);
              this.sheet.handwritingResults = this.HWRFieldCopyUserInput(this.sheet.handwritingResults, groupWithUserInput);
              this.cd.detectChanges();
              // done processing HW
              this.isProcessingHWR = false;
              this.processResults(false);
            }
          },
        });


      // start processing
      this.extractResultsService.extractProcess(this.template, transformedImg)
        .then(([extractedAnswers, omrExtractionInfo]) => {
          // save extracted answers to sheet
          this.sheet.answers = extractedAnswers;

          if (this.env.debugMode) { console.log('this.sheet.extractedAnswers', this.sheet.answers); }

          if (this.env.debugMode) { console.log('this.sheet.handwritingResults', this.sheet.handwritingResults); }

          // check if answers are all true
          const answerGroups = Object.keys(this.sheet.answers);
          let checked = 0, unchecked = 0;
          answerGroups.forEach(answerGroup => {
            Object.keys(this.sheet.answers[answerGroup]).forEach(answerField => {
              console.log(answerGroup, answerField, 'checked', this.sheet.answers[answerGroup][answerField]);
              this.sheet.answers[answerGroup][answerField] ? ++checked : ++unchecked;
            });
          });

          const percentage = (100 * checked / (unchecked + checked));
          console.log('SheetsComponent: Checked: ' + checked + ' Unchecked: ' + unchecked +
            ' Percentage: ' + percentage);

          // if (5 < percentage && percentage > 95) {
          //     this.snackBar.open(
          //         'Please ensure that you are using the correct template and that you have filled in the appropriate circles.',
          //         'Warning', { duration: 6000 });
          // }

          if (percentage === 0) {
            this.snackbar.open(
              'Please ensure that you are using the correct template and that you have filled in the appropriate circles.',
              'Warning', { duration: 6000 });
          }

          // update performance debug
          let extractEndTime = performance.now();
          console.log('SheetsComponent: Scan and Extract done in: ' + (extractEndTime - extractStartTime) + ' Milliseconds');
          // done processing OMR
          this.isProcessingOMR = false;

          console.log('Starting device detection');
          try {
            this.sheet.deviceInfo = this.doDeviceDetection(omrExtractionInfo);
          } catch (e) {
            console.log('Error in device detection', e);
          }
          console.log('Device detection completed', this.sheet.deviceInfo);

          // extraction successful
          this.drawTemplateCirclesToCanvas();

          this.processResults(false); // process routines and validation

        })
        // .then(() => this.extractResultsService.destroy())
        .catch(err => {
          if (this.env.debugMode) { console.log('SheetsComponent: extraction error:', err); }
        });
    };
    transformedImg.src = URL.createObjectURL(correctedImageBlob);
  }

  updateHWField(handwritingGroupName: string, handwritingFieldName: string, fieldUpdateData: HandwritingResultData) {
    if (!this.sheet.handwritingResults) { this.sheet.handwritingResults = {}; }
    if (!this.sheet.handwritingResults[handwritingGroupName]) {
      // if group does not exist add it
      this.sheet.handwritingResults[handwritingGroupName] = {
        [handwritingFieldName]: {
          mlResult: null, userInputVal: null, userValidatedResult: null, displayVal: null, adminValidatedVal: null,
          adminValidationDate: null, confidence: null, modelVersion: null
        }
      };
    }
    // update the field with the new data
    this.zone.run(() => {
      this.sheet.handwritingResults[handwritingGroupName][handwritingFieldName] = fieldUpdateData;
      this.cd.detectChanges();
    })
    console.log('HW observable field updated', handwritingGroupName, handwritingFieldName)
  }

  /**
 * Function copy user input to each HWR fields for groups that have user input value
 * @param handwritingResults extracted handwriting result
 * @param groupWithUserInput HWR groups with user input
 * @returns updated handwriting result
 */
  HWRFieldCopyUserInput(handwritingResults: HandwritingResultGroup, groupWithUserInput: HandwritingGroup[]) {
    groupWithUserInput.forEach((handwritingGroup: HandwritingGroup) => {
      if (!handwritingResults[handwritingGroup.groupTitle]) { return }
      const fieldType = handwritingGroup.userInputFormat.split(':')[0]
      const fieldFormat = handwritingGroup.userInputFormat.split(':')[1]

      // split the source of user input eg sheet:encounterDate or patient:phoneNumber
      const sourceObject = handwritingGroup.userInputField.split(':');
      const userInputObject = sourceObject[0] === 'sheet' ? this.sheet : sourceObject[0] === 'patient' ? this.currentPatient : null;

      if (!userInputObject) { return handwritingResults }

      // get the user input value
      let userInputValue: any;
      const userInputLocationSplit = sourceObject[1].split('.'); // 'otherAnswers.genexpert.specimenId' => ['otherAnswers','genexpert'.'specimenId']
      if (userInputLocationSplit.length > 1) {
        // cases where we need to get the user value from multiple levels of an object
        // e.g sheet['otherAnswers']['genexpert']['specimenId']
        let userValObjTemp = userInputObject;
        userInputLocationSplit.forEach(level => {
          // loop through and every time update temp object with the inner object
          userValObjTemp = userValObjTemp[level];
          userInputValue = userValObjTemp;
        })
      } else {
        userInputValue = userInputObject[sourceObject[1]];
      }

      const groupFields = this.template.handwritingFields.filter(field => field.groupName === handwritingGroup.groupTitle)
        .sort((a, b) => a.serialNumber - b.serialNumber);
      let fieldNames = groupFields.map(field => field.fieldName);

      switch (fieldType) {
        case 'date':
          userInputValue = moment(new Date(userInputValue)).format(fieldFormat);
          break;
        case 'time':
          if (userInputValue) {
            userInputValue = userInputValue.split(' ')[0];
            const splitHrMin = userInputValue.split(':');
            userInputValue = splitHrMin[0] + splitHrMin[1];
          } else {
            userInputValue = '';
          }
          break;
        case 'number':
          fieldNames = fieldNames.reverse();
          userInputValue = userInputValue.toString().split('').reverse().join('');
          break;
        case 'phone':
          userInputValue = FormatPhone.removeCountryCode(userInputValue);
          break;

        default:
          console.log('Field type is not defined in code');
          break;
      }
      fieldNames.forEach((fieldName: string, index: number) => {
        if (!handwritingResults[handwritingGroup.groupTitle][fieldName]) { return }
        const digitVal = userInputValue.split('')[index];
        handwritingResults[handwritingGroup.groupTitle][fieldName].userInputVal = digitVal ? digitVal : null;
      });
    });
    return handwritingResults;
  }

  /** function to do device detection and return the results */
  doDeviceDetection(omrExtractionInfo: any): PsocDeviceInfo {
    const userDevice = this.deviceService.getDeviceInfo();
    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(userDevice.userAgent);

    // update client device information
    const deviceInfo: PsocDeviceInfo = {
      isDesktop: this.deviceService.isDesktop(),
      isMobile: this.deviceService.isMobile(),
      isTablet: this.deviceService.isTablet(),
      appVersion: this.env.appVersion,
      templateInfo: {
        name: this.template.name,
        version: this.template.version,
      },
      supportedPrecision: this.supportedPrecision,
      ipAddress: this.auth.getUser().ipAddress,
      locationInfo: this.locationInfo,
      userAgent: userDevice.userAgent,
      client: device.client,
      device: device.device,
      os: device.os,
      omrExtractionInfo: omrExtractionInfo
    };

    return deviceInfo;

  }

  /**
   * returns Get the latest sheet A record in the report series
   * reportSeriesId string used to find answersheet records by report series ID
  */
  async getReportSheetARecord(reportSeriesId: string) {
    return new Promise<AnswerSheet>((resolve, reject) => {      
      this.loadingReportAnswerSheets = true;
      this.sheetService.getReportAnswerSheets(reportSeriesId).then((reportSeriesRecords)=> {
        this.loadingReportAnswerSheets = false;
        const sheetA: AnswerSheet = reportSeriesRecords
        .filter((record) => record.stampTemplateId === this.appSettings.recordSettings.defaultTemplate)
        .reduce((a, b) => { return (a && a._id > b._id) ? a : b });
        console.log('sheetA ?', sheetA);
        resolve(sheetA);
      }).catch((error) => {
        this.loadingReportAnswerSheets = false;
        console.log(error);
        const snackBarRef = this.snackbar.open(
          "Error loading sheet section A report series record, please try again.",
          "Retry"
        );
        snackBarRef.onAction().subscribe(() => {
          this.getReportSheetARecord(reportSeriesId);
        });
      });
    });
  }

  /** 
   * Process routines, get triggers from the set routine  
   * returns true if we want to go ahead and save, false if we want to stay and fix errors
   * applyChanges controls whether to run the shareToDefaultFacility routine
  */
  processResults(applyChanges: boolean): Promise<boolean> {
    // if still processing HW or OMR return and wait
    if (this.isProcessingOMR || this.isProcessingHWR) {
      console.log('SheetResultsEdit: this.isProcessingOMR:', this.isProcessingOMR, 'this.isProcessingHWR:', this.isProcessingHWR);
      console.log('SheetResultsEdit: Not done processing OMR, HW, don\'t process routines yet');
      return;
    }

    return new Promise((resolve, reject) => {
      // process routines
      console.log('SheetResultsEdit: Start processing routines');
      this.processRoutines(applyChanges).then(results => {
        console.log(results)
        // process any errors
        this.processErrors().then((result) => {
          resolve(result);
        });
      }).catch(error => {
        console.error('SheetResultsEdit: Error processing routines', error);
        this.snackbar.open(error, 'Error', { duration: 6000 });
        reject(false);
      });
    });
  }

  /**
   * function to alert user if there are errors after processing
   * returns true if we want to go ahead and save, false if we want to stay and fix errors
   */
  processErrors(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // check if sheet has errors
      if (!this.sheet?.sheetErrors || this.sheet?.sheetErrors?.length < 1) {
        console.log('sheet has no errors');
        resolve(true); return;
      }

      // alert for errors (only alert if warnSheetErrors setting is on)
      console.log('sheet has errors', this.sheet.sheetErrors);
      if (this.appSettings.uiViewSettings.warnSheetErrors && this.sheet.sheetErrors) {

        console.log('sheet has errors and show error warning modal is enabled');

        // show errors dialog
        this.dialogRef = this.dialog.open(DataValidationDialogComponent, {
          data: { sheetErrors: this.sheet.sheetErrors },
          disableClose: true,
        });
        this.dialogRef.afterClosed().subscribe(result => {
          if (result === 'correctError') {
            console.log('Correct the data and save again', result);
            // user wants to correct errors, stop processing
            resolve(false)
          } else if (result === 'continueWithError') {
            console.log('Continue with error and save', result);
            // update sheet with errors                        
            resolve(true);
          }
        });
      } else {
        // sheet has errors but user is not cha
        console.log('Warn sheet errors disabled. Proceed with errors');
        resolve(true);
      }
    });
  }

  /** function process routines for the available routines */
  processRoutines(applyChanges: boolean): Promise<string> { // applyChanges controls whether to run the shareToDefaultFacility routine
    return new Promise(async (resolve, reject) => {
      // save fixed errors and mark them as processed
      if (
        !this.newRecord &&
        this.sheet.sheetErrors &&
        this.sheet.sheetErrors.length > 0
      ) {
        // copy errors to fixed errors
        this.sheet.fixedErrors = this.sheet.sheetErrors;

        // mark all errors as processed
        this.sheet.fixedErrors = this.sheet.fixedErrors.map(
          (sheetError: SheetError) => {
            sheetError.status = SheetErrorStatusCodes.fixed;
            return sheetError;
          }
        );

        // remove duplicates
        this.sheet.sheetErrors.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
      }

      // store any routines we need to process
      const routinesToProcess = [];

      // reset errors array
      this.sheet.sheetErrors = [];

      // validate Multiple options chosen for exclusive field (OMR)
      routinesToProcess.push(this.validateExclusiveOMRGroups());

      // validate that least one option chosen for required field (OMR)
      routinesToProcess.push(this.validateRequiredOMRGroups());

      // validate that HW required field has value (HW)
      routinesToProcess.push(this.validateRequiredHWGroups());

      // check if template has any HW fields with format set, then validate
      if (this.template.handwritingGroups?.length > 0) {
        routinesToProcess.push(this.validateHWGroupFormat());
      }

      // check if validate report dates routine exists and perform report dates
      if (this.template.routines.find((r) => r._id === "validateReportDates")) {
        routinesToProcess.push(this.validateReportDates());
      }

      // check if validate group calculation routine exists and validate group calculation
      if (
        this.template.routines.find(
          (r) => r._id === "validateGroupCalculations"
        )
      ) {
        routinesToProcess.push(this.validateGroupCalculations());
      }

      // check if group counts by comparing with max for each commodity group
      if (this.template.routines.find((r) => r._id === "validateGroupCounts")) {
        routinesToProcess.push(this.validateGroupCounts());
      }

      // check validateCountEqMax
      if (this.template.routines.find((r) => r._id === "validateCountEqMax")) {
        routinesToProcess.push(this.validateCountEqMax());
      }

      // check validateTotalHWeqOMR
      if (
        this.template.routines.find((r) => r._id === "validateTotalHWeqOMR")
      ) {
        routinesToProcess.push(this.validateTotalHWeqOMR());
      }
      // check validateZeroDoseChildrenTotalHWeqOMR (SCI: ZD STS)
      if (
        this.template.routines.find((r) => r._id === "validateZeroDoseChildrenTotalHWeqOMR")
      ) {
        // get latest sheet A in report compare with current sheet B
        let sheetA = await this.getReportSheetARecord(this.sheet.reportSeriesId).then((sheetA: AnswerSheet) => {
          return sheetA;
        });
        routinesToProcess.push(this.validateZeroDoseChildrenTotalHWeqOMR(sheetA));
      }
      
      // check validateOtherChildrenTotalHWeqCountOMR (SCI: ZD STS)
      if (
        this.template.routines.find((r) => r._id === "validateOtherChildrenTotalHWeqCountOMR")
      ) {
        // get latest sheet A in report compare with current sheet F
          let sheetA = await this.getReportSheetARecord(this.sheet.reportSeriesId).then((sheetA: AnswerSheet) => {
          return sheetA;
        });
        routinesToProcess.push(this.validateOtherChildrenTotalHWeqCountOMR(sheetA));
      }


      // check # doses received ({opvDosesReceived}) not equal to # doses used+returned ({numDosesUsed}+{numDosesReturned})
      if (
        this.template.routines.find(
          (r) => r._id === "validatePolioDosesCalculations"
        )
      ) {
        routinesToProcess.push(this.validatePolioDosesCalculations());
      }

      // check immunization date in formio vs HW
      if (
        this.template.routines.find(
          (r) => r._id === "validateImmunizationDateMatch"
        )
      ) {
        routinesToProcess.push(this.validateImmunizationDateMatch());
      }

      // check screening date vs HW
      if (
        this.template.routines.find(
          (r) => r._id === "validateScreeningDateMatch"
        )
      ) {
        routinesToProcess.push(this.validateScreeningDateMatch());
      }

      // check if team # matches with the app and HW
      if (
        this.template.routines.find((r) => r._id === "validateTeamNumberMatch")
      ) {
        routinesToProcess.push(this.validateTeamNumberMatch());
      }

      // check if the DFA code matches with the logged in user DFA code
      if (
        this.template.routines.find((r) => r._id === "validateDFACodeMatch")
      ) {
        routinesToProcess.push(this.validateDFACodeMatch());
      }

      // check if the validation of HW is equal to max of OMR (unvaccinated)
      if (
        this.template.routines.find(
          (r) => r._id === "validateTotalZeroDoseVaccinatedHWeqOMR"
        )
      ) {
        routinesToProcess.push(this.validateTotalZeroDoseVaccinatedHWeqOMR());
      }

      if (
        this.template.routines.find(
          (r) => r._id === "validateTotalRevisitedAndVaccinatedHWeqHW"
        )
      ) {
        routinesToProcess.push(
          this.validateTotalRevisitedAndVaccinatedHWeqHW()
        );
      }

      // Validate any new AFP cases have age provided.
      if (
        this.template.routines.find(
          (r) => r._id === "validateNewAFPMustHaveAge"
        )
      ) {
        routinesToProcess.push(this.validateNewAFPMustHaveAge());
      }

      // validate if screened before = yes, screened times (#) provided
      if (this.template.routines.find((r) => r._id === 'validateScreenedBeforeMustHaveScreenedTimes')) {
        routinesToProcess.push(this.validateScreenedBeforeMustHaveScreenedTimes());
      }

      // validate if referred = yes, referred to provided
      if (this.template.routines.find((r) => r._id === 'validateReferredMustHaveReferredTo')) {
        routinesToProcess.push(this.validateReferredMustHaveReferredTo());
      }

      // validate if any mhGAP Q1-Q6 = yes, referred provided
      if (this.template.routines.find((r) => r._id === 'validateReferredRequired')) {
        routinesToProcess.push(this.validateReferredRequired());
      }

      // append county name value from OMR result
      if (
        this.template.routines.find(
          (r) => r._id === "shareCountyOMRToReportSeries"
        )
      ) {
        routinesToProcess.push(this.shareCountyOMRToReportSeries());
      }

      // copy team #, campaign day (OMR) values - polio workflow
      if (
        this.template.routines.find(
          (r) => r._id === "shareDFATeamCampaignDayOMRToReportSeries"
        )
      ) {
        routinesToProcess.push(this.shareDFATeamCampaignDayOMRToReportSeries());
      }

      // check if shareToSelectedFacility routine exist and share patient
      const shareToSelectedFacility = this.template.routines.find(
        (r) => r._id === "shareToSelectedFacility"
      );
      if (shareToSelectedFacility) {
        if (!shareToSelectedFacility.trigger) {
          return;
        } // do nothing if no trigger set
        const groupName = shareToSelectedFacility.trigger.rules[0].groupName;
        const groupResults = this.sheet.answers[groupName]; // get answers results object
        const keys = Object.keys(groupResults).filter(
          (k) => groupResults[k] === true
        );

        // get default referral facility if more than one marked
        if ((keys.length >= 1 || keys[0] === "Other") && applyChanges) {
          routinesToProcess.push(this.shareToDefaultFacility());
        } else {
          // if referral facility is not filled
          routinesToProcess.push(
            Promise.resolve("No referral facility was selected.")
          );
        }
      }

      // check if shareToDefaultFacility routine exist and share patient to default facility
      if (
        this.template.routines.find(
          (r) => r._id === "shareToDefaultFacility"
        ) &&
        applyChanges
      ) {
        routinesToProcess.push(this.shareToDefaultFacility());
      }

      // else if both routines don't exist resolve to continue
      if (routinesToProcess.length === 0) {
        resolve("No routine set for processing");
      }

      // process all routines that have been set
      Promise.all(routinesToProcess).then(responses => {
        console.log('SheetResultsEdit: routines successfully processed', responses);
        resolve('routines successfully processed');
      }).catch(err => reject(err));
    });
  }

  /**
   * function that shares the teamNumber and Campaign day (OMR) to the report series object
   * @param targetOMRGroupName target OMR Group Name
   */
  shareDFATeamCampaignDayOMRToReportSeries(
    targetOMRGroupName: string = "Campaign day"
  ) {
    return new Promise<any>((resolve, reject) => {
      // extract exclusive value from OMR answer group
      const answer = this.sheet.answers[targetOMRGroupName];
      const exclusiveValue = this.getExclusiveValuePipe.transform(
        answer,
        targetOMRGroupName,
        this.template
      );
      this.currentReportSeries.campaignDay = exclusiveValue;
      this.currentReportSeries.teamNumber = this.sheet.customBeforeFieldsData?.teamNumber;

      resolve('Polio (SIA-OPV) Workflow: "SIA-OPV A metadata copied to report series')
    });
  }

  /**
   * function that shares the county (OMR) to the report series object
   * @param targetOMRGroupName target OMR Group Name
   */
  shareCountyOMRToReportSeries(targetOMRGroupName: string = "County") {
    return new Promise<any>((resolve, reject) => {
      // extract exclusive value from OMR answer group
      const answer = this.sheet.answers[targetOMRGroupName];
      const exclusiveValue = this.getExclusiveValuePipe.transform(
        answer,
        targetOMRGroupName,
        this.template
      );
      this.currentReportSeries.county = exclusiveValue;
      this.currentReportSeries.screeningOrg = this.sheet.customBeforeFieldsData?.screeningOrg;

      resolve('MhGap Workflow: "County" and "Scanning Org" value appended to report series');
    });
  }

  /** Share patient to set default facility */
  shareToDefaultFacility(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (
        (!this.facility.facilityOptions ||
          !this.facility.facilityOptions.defaultShareTo ||
          this.facility.facilityOptions.defaultShareTo.length < 1) &&
        (!this.currentPatient.sharedFacilities ||
          this.currentPatient.sharedFacilities.length < 1)
      ) {
        resolve("This facility does not have any defaultShareTo set.");
        return;
      }

      const defaultShareTo = this.facility.facilityOptions.defaultShareTo;
      console.log(`routine: template shared to default facility`);
      this.sharePatient(defaultShareTo)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  /**
   * Add shared to facility to patients objects
   * @param shareToFacility facility patient is being shared to
   */
  sharePatient(shareToFacility: string[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (!this.currentPatient) {
        reject("Error in sharePatient: current patient not set");
        return;
      }

      // make sure current patients facility is in shared facilities so that filtered sync still works
      if (!Array.isArray(shareToFacility)) {
        shareToFacility = [shareToFacility];
      }
      if (!this.currentPatient.sharedFacilities) {
        this.currentPatient.sharedFacilities = [];
        this.currentPatient.sharedFacilities.push(
          this.currentPatient.createFacility
        );
      }

      // init sheet.sharedFacilities array
      if (!this.sheet.sharedFacilities) {
        this.sheet.sharedFacilities = [];
      }

      if (
        !shareToFacility.every(
          (f) => this.currentPatient.sharedFacilities.indexOf(f) > -1
        )
      ) {
        // if shareToFacility(s) are not already in the list of shared facilities
        let cleanShareToFacilities = [];
        cleanShareToFacilities =
          this.currentPatient.sharedFacilities.concat(shareToFacility);
        cleanShareToFacilities = Array.from(new Set(cleanShareToFacilities)); // remove duplicates

        // update patient and its object
        this.sheet.sharedFacilities = cleanShareToFacilities;
        this.patientService
          .sharePatient(this.currentPatient, cleanShareToFacilities)
          .then((res) => {
            // successful
            resolve("Successfully shared Patient.");
          })
          .catch(error => {
            console.log('SheetResultsEdit: Error occurred sharing:', error);
            reject('Error occurred while sharing Patient.');
          });
      } else {
        // shareToFacility(s) are already in the list of shared facilities
        // only update the current sheet with shared to facilities
        if (
          !shareToFacility.every(
            (f) => this.sheet.sharedFacilities.indexOf(f) > -1
          )
        ) {
          this.sheet.sharedFacilities = this.currentPatient.sharedFacilities;
          resolve("Successfully shared Record.");
        } else {
          resolve(
            "ShareToFacilities skipped: This patient has already been shared with these facilities"
          );
        }
      }
    });
  }

  // routine to validate if screened before = yes, screened times (#) provided
  validateScreenedBeforeMustHaveScreenedTimes() {
    return new Promise<any>((resolve, reject) => {
      // name of the group where Screened Before info is captured (OMR)
      const screenedBeforeGroupName = "Screened before";

      // prepare data
      const numValues = Object.keys(this.sheet.answers[screenedBeforeGroupName]).filter(
        (k) => this.sheet.answers[screenedBeforeGroupName][k] === true
      ).length;

      // check if answer sheet has hasBeenScreenedBefore (OMR) has been filled
      const hasBeenScreenedBefore = this.sheet.answers[screenedBeforeGroupName]["No"];

      // get values for screened times (#)
      const screenedTimes = this.getNumericHWGroupVal("Screened times", [
        "screened-times_units",
      ]);

      if (numValues < 1) {
        // don't process if check if Screened Before group has not been filled (will be validated by required func)
        resolve("Screened Before must have screened times (#) NOT validated");
      } else if (hasBeenScreenedBefore) {
        // don't process if screened before = no
        resolve("Not screened before, screened times (#) not required");
      } else if (!(screenedTimes)) {
        // screened before = Yes and screened times (#) not filled add error
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.screenedBeforeMustHaveScreenedTimes,
          message: `Screened before = yes, then screened times (#) is required.`,
        };
        this.sheet.sheetErrors.push(err);
      }

      console.log('SheetResultsEdit: routine: Screened before must have screened times (#) provided validated')
      resolve('Screened before must have screened times (#) provided validated');
    });
  }

  // routine validates that if referred = yes, referred to should be provided
  validateReferredMustHaveReferredTo() {
    return new Promise<any>((resolve, reject) => {
      // name of the group where Referred info is captured (OMR)
      const referredGroupName = "Referred";

      // name of the group where Referred to info is captured (OMR)
      const referredToGroupName = "Referred to";

      // prepare data
      const referredValues = Object.keys(this.sheet.answers[referredGroupName]).filter(
        (k) => this.sheet.answers[referredGroupName][k] === true
      ).length;

      const referredToValues = Object.keys(this.sheet.answers[referredToGroupName]).filter(
        (k) => this.sheet.answers[referredToGroupName][k] === true
      ).length;

      // check if answer sheet has hasBeenReferred (OMR) has been filled
      const hasBeenReferred = this.sheet.answers[referredGroupName]["No"];

      if (referredValues < 1) {
        // don't process if referred is not filled, has its own validation
        resolve("Referred field must be filled");
      } else if (hasBeenReferred) {
        // don't process if referred = no
        resolve("Not referred, referred to not required");
      } else if (referredToValues < 1) {
        // referred = Yes and referred to not filled add error
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.referredMustHaveReferredTo,
          message: `Referred = yes, then referred to is required.`,
        };
        this.sheet.sheetErrors.push(err);
      }

      console.log('SheetResultsEdit: routine: Referred must have referred to provided validated')
      resolve('Referred must have referred to provided validated');
    });
  }

  // routine validates that if any mhGap Q1-Q6 = yes, referred should be provided
  validateReferredRequired() {
    return new Promise<any>((resolve, reject) => {
      // name of the group where Referred info is captured (OMR)
      const referredGroupName = "Referred";

      // prepare data
      const referredValues = Object.keys(this.sheet.answers[referredGroupName]).filter(
        (k) => this.sheet.answers[referredGroupName][k] === true
      ).length;

      var mhGAPValues = 0;

      for (let i = 1; i <= 6; i++) {
        const groupName = 'mhGAP-Q' + i;
        mhGAPValues += Object.keys(this.sheet.answers[groupName]).filter(
          (k) => this.sheet.answers[groupName][k] === true
        ).length;
      }

      if (referredValues < 1 && mhGAPValues > 0) {
        // if any mhGap Q1-Q6 = yes and referred not filled add error
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.referredRequired,
          message: `Referred: Not filled (Required).`,
        };
        this.sheet.sheetErrors.push(err);
      }

      console.log('SheetResultsEdit: routine: MhGAP Q1-Q6 = yes, must have referred provided validated')
      resolve('MhGAP Q1-Q6 = yes, must have referred provided validated');
    });
  }

  /** run default validations for any given hw group with a data format (integer, date, decimal, text) */
  validateHWGroupFormat() {
    return new Promise<any>((resolve, reject) => {
      this.template.handwritingGroups?.forEach((hwGroup: HandwritingGroup) => {
        console.log('SheetResultsEdit: validate group ', hwGroup.groupTitle, 'data format', hwGroup.dataFormat);

        let groupTitle = hwGroup.groupTitle;
        let sheetHWResult: HandwritingResult =
          this.sheet.handwritingResults[groupTitle];

        switch (hwGroup.dataFormat) {
          case "date":
            // get sorted list of date fields
            let fieldLabels = this.template.handwritingFields
              .filter((hwField) => hwField.groupName === hwGroup.groupTitle)
              .sort((a, b) => a.serialNumber - b.serialNumber)
              .map((hwField) => hwField.fieldName);

            // let dateValue: Date;
            let d1 = sheetHWResult[fieldLabels[0]].displayVal,
              d0 = sheetHWResult[fieldLabels[1]].displayVal,
              m1 = sheetHWResult[fieldLabels[2]].displayVal,
              m0 = sheetHWResult[fieldLabels[3]].displayVal,
              y1 = sheetHWResult[fieldLabels[4]].displayVal,
              y0 = sheetHWResult[fieldLabels[5]].displayVal;

            // clean the date variable, remove the word blank
            const clean_day = (`${d1}${d0}`).replace(/null|blank/g, '');
            const clean_month = (`${m1}${m0}`).replace(/null|blank/g, '');
            const clean_year = (`${y1}${y0}`).replace(/null|blank/g, '');

            const day: number = clean_day === '' ? NaN : +clean_day
            const month: number = clean_month === '' ? NaN : +clean_month
            const year: number = clean_year === '' ? NaN : +`20${clean_year}`

            let dateValue = new Date(year, month - 1, day);

            // Date before (defaultMinDate) not allowed
            let minDate = this.appSettings.recordSettings.minDate
              ? new Date(this.appSettings.recordSettings.minDate)
              : DefaultMinDate;
            let minDateString = new ValidDatePipe().transform(
              minDate,
              DEFAULT_DATE_MASK
            );
            if (dateValue < minDate) {
              console.log(`invalid date (${day}/${month}/${year}) - dates before (${minDateString}) not allowed`);
              this.sheet.sheetErrors.push(
                {
                  status: SheetErrorStatusCodes.pending,
                  code: SheetErrorCodes.hwDateInvalid,
                  message: `${groupTitle}: Invalid date (${day}/${month}/${year}) - dates before (${minDateString}) not allowed`,
                  data: { groupName: groupTitle }
                }
              );
            }

            // Date after () not allowed

            // Day range 1-31
            if (day < 1 || day > 31) {
              console.log(
                `invalid date (${day}/${month}/${year}) - day out of range`
              );
              this.sheet.sheetErrors.push({
                status: SheetErrorStatusCodes.pending,
                code: SheetErrorCodes.hwDateInvalid,
                message: `${groupTitle}: Invalid date (${day}/${month}/${year}) - day out of range`,
                data: { groupName: groupTitle },
              });
            }

            // Month range 1-12
            if (month < 1 || month > 12) {
              console.log(
                `invalid date (${day}/${month}/${year}) - month out of range`
              );
              this.sheet.sheetErrors.push({
                status: SheetErrorStatusCodes.pending,
                code: SheetErrorCodes.hwDateInvalid,
                message: `${groupTitle}: Invalid date (${day}/${month}/${year}) - month out of range`,
                data: { groupName: groupTitle },
              });
            }

            // Year range 23-27
            if (year < 2020 || year > 2030) {
              console.log(`invalid date (${day}/${month}/${year}) - year out of range`);
              this.sheet.sheetErrors.push(
                {
                  status: SheetErrorStatusCodes.pending,
                  code: SheetErrorCodes.hwDateInvalid,
                  message: `${groupTitle}: Invalid date (${day}/${month}/${year}) - year out of range`,
                  data: { groupName: groupTitle }
                }
              );
            }

            break;

          case "integer":
            break;

          case "phone":
            break;

          case "decimal":
            break;

          case "text":
          default:
            break;
        }
      });

      console.log("routine: validated HW groups by data format");
      resolve("validated HW groups by data format");
    });
  }

  /** routine to validate that any required OMR groups have a value  */
  validateRequiredHWGroups() {
    return new Promise<any>((resolve, reject) => {
      // don't validate if no HW groups
      if (this.template?.handwritingGroups?.length < 1) {
        console.log('SheetResultsEdit: No HW groups, do not validate required HW groups');
        resolve('no required HW groups to validate');;
      }

      Object.keys(this.sheet.handwritingResults).forEach((hwGroupName) => {
        // load data about group
        const templateGroup = this.template.handwritingGroups.find(g => g.groupTitle === hwGroupName);
        console.log('SheetResultsEdit: templateGroup validateRequiredHWGroups', templateGroup);

        // don't validate if not required
        if (!templateGroup.required) {
          return;
        }

        // count non blank values
        const nonBlankGroupValues = Object.keys(this.sheet.handwritingResults[hwGroupName]).filter(k => this.sheet.handwritingResults[hwGroupName][k].displayVal?.toLowerCase() !== 'blank');

        // validate field, should be at least one non blank value
        if (nonBlankGroupValues.length < 1) {
          const err: SheetError = {
            status: SheetErrorStatusCodes.pending,
            code: SheetErrorCodes.requiredHWGroupNoValues,
            message: `${hwGroupName}: Not filled (Required)`,
            data: {
              groupName: hwGroupName,
            },
          };
          this.sheet.sheetErrors.push(err);
        }
      });

      console.log("routine: required HW groups validated");
      resolve("required HW groups validated");
    });
  }

  /** routine to validate that any required OMR groups have at least one one value  */
  validateRequiredOMRGroups() {
    return new Promise<any>((resolve, reject) => {
      Object.keys(this.sheet.answers).forEach((groupName) => {
        // load data about group
        const templateGroup = this.template.groups.find(
          (g) => g.groupTitle === groupName
        );

        // don't validate if not required
        if (!templateGroup.required) {
          return;
        }

        // find how many values for this group
        const numValues = Object.keys(this.sheet.answers[groupName]).filter(
          (k) => this.sheet.answers[groupName][k] === true
        ).length;

        // validate field, should at least one
        if (numValues < 1) {
          const err: SheetError = {
            status: SheetErrorStatusCodes.pending,
            code: SheetErrorCodes.requiredOMRGroupNotFilled,
            message: `${groupName}: Not filled (Required)`,
            data: {
              groupName: groupName,
            },
          };
          this.sheet.sheetErrors.push(err);
        }
      });

      console.log("routine: required OMR groups validated");
      resolve("required OMR groups validated");
    });
  }

  /** routine to validate that any exclusive OMR groups only have max one value  */
  validateExclusiveOMRGroups() {
    return new Promise<any>((resolve, reject) => {
      Object.keys(this.sheet.answers).forEach((groupName) => {
        // load data about group
        const templateGroup = this.template.groups.find(
          (g) => g.groupTitle === groupName
        );

        // don't validate if not exclusive
        if (!templateGroup.exclusive) {
          return;
        }

        // find how many values for this group
        const numValues = Object.keys(this.sheet.answers[groupName]).filter(
          (k) => this.sheet.answers[groupName][k] === true
        ).length;

        // validate field, should only have one or less value
        if (numValues > 1) {
          const err: SheetError = {
            status: SheetErrorStatusCodes.pending,
            code: SheetErrorCodes.exclusiveOMRGroupHasMultiples,
            message: `${groupName}: More than one value selected (Exclusive)`,
            data: {
              groupName: groupName,
            },
          };
          this.sheet.sheetErrors.push(err);
        }
      });

      console.log('SheetResultsEdit: routine: exclusive OMR groups validated')
      resolve('exclusive OMR groups validated');
    });
  }

  /** function to validate template a dates scanned match encounter dates */
  validateReportDates() {
    return new Promise<any>((resolve, reject) => {
      // current template is: CIC A, validate date and month
      const monthsGroupResults =
        this.sheet.answers[this.template.groups[0].groupTitle];
      const yearsGroupResults =
        this.sheet.answers[this.template.groups[1].groupTitle];
      const monthFromForm = moment(this.sheet.encounterDate).format("MMMM");
      const yearFromForm = moment(this.sheet.encounterDate).format("YYYY");
      const monthsFromScan = Object.keys(monthsGroupResults).filter(
        (k) => monthsGroupResults[k] === true
      );
      const yearsFromScan = Object.keys(yearsGroupResults).filter(
        (k) => yearsGroupResults[k] === true
      );

      const monthNow = moment().month();
      const yearNow = moment().year();
      const futureMonths = monthsFromScan.filter(
        (monthString) => moment("1 " + monthString + " 1999").month() > monthNow
      );
      const futureYears = yearsFromScan.filter(
        (year) => Number(year) > yearNow
      );

      // validate future date selected
      if (futureYears.length || (futureMonths.length && yearsFromScan.includes(yearNow.toString()))) {
        this.sheet.sheetErrors.push({
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.invalidReportDate,
          message: "Future date selected.",
        });
      }

      // validate date is not before project started
      if (yearsFromScan.includes('2020')) {
        this.sheet.sheetErrors.push({
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.invalidReportDate,
          message: "Date selected is before project started.",
        });
      }

      // validate multiple years selected
      if (yearsFromScan.length > 1) {
        this.sheet.sheetErrors.push({
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.invalidReportDate,
          message: "Multiple years selected. " + yearsFromScan.join(", ") + ".",
        });
      }

      // validate multiple months selected
      if (monthsFromScan.length > 1) {
        this.sheet.sheetErrors.push({
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.invalidReportDate,
          message:
            "Multiple months selected: " + monthsFromScan.join(", ") + ".",
        });
      }

      // validate no years selected
      if (yearsFromScan.length < 1) {
        this.sheet.sheetErrors.push({
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.invalidReportDate,
          message: "No year selected.",
        });
      }

      // validate no months selected
      if (monthsFromScan.length < 1) {
        this.sheet.sheetErrors.push({
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.invalidReportDate,
          message: "No month selected.",
        });
      }

      // validate selected date matches scanned date
      if (
        monthFromForm !== monthsFromScan[0] ||
        yearFromForm !== yearsFromScan[0]
      ) {
        this.sheet.sheetErrors.push({
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.invalidReportDate,
          message: `Report date mismatch: 
            Date on scan (${monthsFromScan}  ${yearsFromScan}) 
            does not match date on form (${monthFromForm} ${yearFromForm}).`,
        });
      }

      console.log('SheetResultsEdit: routine: template dates validated')
      resolve('template dates validated');
    });
  }

  /** routine to validate user select screening date matches HW date */
  validateScreeningDateMatch() {
    return new Promise<any>((resolve, reject) => {
      // prepare needed data for validation (ignore time)
      const encounterDate = new Date(this.sheet.encounterDate);
      if (this.env.debugMode) {
        console.log("Form value encounterDate", encounterDate);
      }

      // pass values to function and get date
      const hwImmDate = this.getDateHWGroupVal("Date", [
        "day_tens",
        "day_units",
        "month_tens",
        "month_units",
        "year_tens",
        "year_units",
      ]);
      if (this.env.debugMode) {
        console.log("HW value Date", hwImmDate);
      }

      // validate number of polio doses received is equal to the number of doses used plus the number of doses returned
      if (encounterDate.getTime() !== hwImmDate.getTime()) {
        const displayDate = Number.isNaN(hwImmDate.getTime())
          ? hwImmDate
          : this.datePipe.transform(hwImmDate, "d MMM, y");
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.mismatchImmunizationDateHWvsForm,
          message: `Screening date entered in the app (${this.datePipe.transform(
            encounterDate,
            "d MMM, y"
          )}) does not match the date entered on paper (${displayDate}).`,
          data: {
            encounterDate: encounterDate,
            hwImmDate: hwImmDate,
          },
        };
        this.sheet.sheetErrors.push(err);
      }

      if (this.env.debugMode) {
        console.log(
          "routine: Validate if the screening date entered in the app does matches the date entered in paper"
        );
      }
      resolve(
        "Validate if the screening date entered in the app does matches the date entered in paper"
      );
    });
  }

  /** routine to validate formio immunization date matches HW date */
  validateImmunizationDateMatch() {
    return new Promise<any>((resolve, reject) => {
      // prepare needed data for validation (ignore time)
      const formioImmDate = new Date(new Date(this.sheet.customBeforeFieldsData["immunizationDate"]).toDateString());
      if (this.env.debugMode) { console.log('SheetResultsEdit: Form value formioImmDate', formioImmDate) }

      // pass values to function and get date
      const hwImmDate = this.getDateHWGroupVal('Immunisation date', ['day_tens', 'day_units', 'month_tens', 'month_units', 'year_tens', 'year_units']);
      if (this.env.debugMode) { console.log('SheetResultsEdit: HW value Immunisation date', hwImmDate) }

      // validate number of polio doses received is equal to the number of doses used plus the number of doses returned
      if (formioImmDate.getTime() !== hwImmDate.getTime()) {
        const displayDate = Number.isNaN(hwImmDate.getTime())
          ? hwImmDate
          : this.datePipe.transform(hwImmDate, "d MMM, y");
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.mismatchImmunizationDateHWvsForm,
          message: `“Immunization date entered in the app (${this.datePipe.transform(
            formioImmDate,
            "d MMM, y"
          )}) does not match the date entered on paper (${displayDate}).`,
          data: {
            formioImmDate: formioImmDate,
            hwImmDate: hwImmDate,
          },
        };
        this.sheet.sheetErrors.push(err);
      }

      if (this.env.debugMode) {
        console.log(
          "routine: Validate if the immunization date entered in the app does matches the date entered in paper"
        );
      }
      resolve(
        "Validate if the immunization date entered in the app does matches the date entered in paper"
      );
    });
  }

  /** routine to validate team # matches the HW team number */
  validateTeamNumberMatch() {
    return new Promise<any>((resolve, reject) => {
      const formTeamNumber = this.sheet.customBeforeFieldsData["teamNumber"];
      const hwTeamNumber = this.getNumericHWGroupVal("Team number", [
        "teamNumber_units",
      ]);

      if (formTeamNumber !== hwTeamNumber) {
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.mismatchTeamNumberHWvsForm,
          message: `“Team # entered in the app (${formTeamNumber}) does not match the team # written on paper (${hwTeamNumber}).`,
          data: {
            formTeamNumber: formTeamNumber,
            hwTeamNumber: hwTeamNumber,
          },
        };
        this.sheet.sheetErrors.push(err);
      }

      if (this.env.debugMode) {
        console.log(
          "routine: Validate if the immunization date entered in the app does matches the date entered in paper"
        );
      }
      resolve(
        "Validate if the team number selected in the app does match the team number entered on paper"
      );
    });
  }

  /** routine to validate logged in user DFA Code matches the HW DFA Code */
  validateDFACodeMatch() {
    return new Promise<any>((resolve, reject) => {
      const splitUserName = this.loggedInUser.username.split("-");
      const userDFACode = splitUserName[splitUserName.length - 1];
      const hwDFACode = this.getAlphaHWGroupVal("DFA area code", [
        "dfaArea_tens",
        "dfaArea_units",
      ]);

      if (userDFACode !== hwDFACode) {
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.mismatchDFACodeHWvsUser,
          message: `“DFA Code of the logged in user (${userDFACode}) does not match the DFA Code entered on paper (${hwDFACode}).`,
          data: {
            userDFACode: userDFACode,
            hwDFACode: hwDFACode,
          },
        };
        this.sheet.sheetErrors.push(err);
      }

      if (this.env.debugMode) {
        console.log(
          "routine: validates if the DFA Code entered on paper does not match the DFA Code of the logged in user"
        );
      }
      resolve(
        "Validate if the DFA Code entered on paper matches the DFA code of the logged in user"
      );
    });
  }

  /**
   * function to return the value of a date handwriting group
   * @param targetHWGroupName name of the target HW group
   * @param targetHWfields array of field names from highest to lowest e.g. year tens, year ones, month tens, month ones, day tens, day ones
   * @returns value as number
   */
  getDateHWGroupVal(
    targetHWGroupName: string,
    targetHWfields: Array<string>
  ): Date {
    // ['day_tens', 'day_units','month_tens','month_units','year_tens','year_units']
    let d1 = this.sheet.handwritingResults[targetHWGroupName][targetHWfields[0]].displayVal,
      d0 = this.sheet.handwritingResults[targetHWGroupName][targetHWfields[1]].displayVal,
      m1 = this.sheet.handwritingResults[targetHWGroupName][targetHWfields[2]].displayVal,
      m0 = this.sheet.handwritingResults[targetHWGroupName][targetHWfields[3]].displayVal,
      y1 = this.sheet.handwritingResults[targetHWGroupName][targetHWfields[4]].displayVal,
      y0 = this.sheet.handwritingResults[targetHWGroupName][targetHWfields[5]].displayVal

    // clean the date variable, remove the word blank
    const clean_day = (`${d1}${d0}`).replace(/null|blank/g, '');
    const clean_month = (`${m1}${m0}`).replace(/null|blank/g, '');
    const clean_year = (`${y1}${y0}`).replace(/null|blank/g, '');

    const day: number = clean_day === '' ? NaN : +clean_day
    const month: number = clean_month === '' ? NaN : +clean_month
    const year: number = clean_year === '' ? NaN : +`20${clean_year}`

    // mm - 1 because months are numbered from 0 i.e. jan is 0
    // new date (year, month, day);
    let valDate = new Date(+`${year}`, +`${month}` - 1, +`${day}`);
    return valDate;
  }

  /** routine to validate if the number of polio doses received is not equal to the number of doses used plus the number of doses returned */
  validatePolioDosesCalculations() {
    return new Promise<any>((resolve, reject) => {
      // prepare needed data for validation
      const opvDosesReceived = this.getNumericHWGroupVal('OPV vials received', ['opv-vials-received_units']);
      if (this.env.debugMode) { console.log('SheetResultsEdit: HW value opvDosesReceived', opvDosesReceived); }

      const numDosesUsed = this.getNumericHWGroupVal('OPV vials used', ['opv-vials-used_units']);
      if (this.env.debugMode) { console.log('SheetResultsEdit: HW value numDosesUsed', numDosesUsed); }

      const numDosesReturned = this.getNumericHWGroupVal('OPV vials unused', ['opv-vials-unused_units']);
      if (this.env.debugMode) { console.log('SheetResultsEdit: HW value numDosesReturned', numDosesReturned); }

      // validate number of polio doses received is equal to the number of doses used plus the number of doses returned
      if (opvDosesReceived !== numDosesUsed + numDosesReturned) {
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.mismatchPolioDosesCalculation,
          message: `OPV Vials: # vials received (${opvDosesReceived}) not equal to # vials Used + Unused (${numDosesUsed}+${numDosesReturned}) `,
          data: {
            opvDosesReceived: opvDosesReceived,
            numDosesUsed: numDosesUsed,
            numDosesReturned: numDosesReturned,
          },
        };
        this.sheet.sheetErrors.push(err);
      }

      if (this.env.debugMode) { console.log('SheetResultsEdit: routine: number of polio doses received is not equal to the number of doses used plus the number of doses returned validated') }
      resolve('number of polio doses received is not equal to the number of doses used plus the number of doses returned validated');
    });
  }

  /** routine to validate Other "vaccinated" Children total from HW in section A equals COUNT from OMR in section F */
  validateOtherChildrenTotalHWeqCountOMR(answersheetA: AnswerSheet) {
    return new Promise<any>((resolve, reject) => {
      const sheetA = answersheetA;
      console.log('SheetResultsEdit: sheet A ?', sheetA);

      // validate Total Other vaccinated Boys-0-11m
      this.validateHWEqCount(
        "Total Other vaccinated Boys-0-11m", 
        "Total Other vaccinated Boys-0-11m", 
        [
          "total-other-vaccinated-boys-0-11m_tens",
          "total-other-vaccinated-boys-0-11m_units"
        ],
        "OV-Children-0-11m-boys",
        // optional
        "Section A", 
        "Section F", 
        sheetA 
      );

      // validate "Total Other vaccinated Boys-12-23m"
      this.validateHWEqCount(
        "Total Other vaccinated Boys-12-23m", 
        "Total Other vaccinated Boys-12-23m", 
        [
          "total-other-vaccinated-boys-12-23m_tens",
          "total-other-vaccinated-boys-12-23m_units"
        ],
        "OV-Children-12-23m-boys", 
        // optional
        "Section A", 
        "Section F", 
        sheetA 
      );

      // validate "Total Other vaccinated Boys-24-59m"
      this.validateHWEqCount(
        "Total Other vaccinated Boys-24-59m", 
        "Total Other vaccinated Boys-24-59m", 
        [
          "total-other-vaccinated-boys-24-59m_tens",
          "total-other-vaccinated-boys-24-59m_units"
        ],
        "OV-Children-24-59m-boys", 
        // optional
        "Section A", 
        "Section F", 
        sheetA 
      );

      // validate Total vaccinations (12-59 months)
      this.validateHWEqCount(
        "Total Other vaccinated Girls-0-11m", 
        "Total Other vaccinated Girls-0-11m", 
        [
          "total-other-vaccinated-girls-0-11m_tens",
          "total-other-vaccinated-girls-0-11m_units"
        ], 
        "OV-Children-0-11m-girls",
        // optional
        "Section A", 
        "Section F", 
        sheetA
      );

      // validate "Total Other vaccinated Girls-12-23m"
      this.validateHWEqCount(
        "Total Other vaccinated Girls-12-23m", 
        "Total Other vaccinated Girls-12-23m", 
        [
          "total-other-vaccinated-girls-12-23m_tens",
          "total-other-vaccinated-girls-12-23m_units"
        ],
        "OV-Children-12-23m-girls", 
        // optional
        "Section A", 
        "Section F", 
        sheetA 
      );

      // validate "Total Other vaccinated Girls-24-59m"
      this.validateHWEqCount(
        "Total Other vaccinated Girls-24-59m", 
        "Total Other vaccinated Girls-24-59m", 
        [
          "total-other-vaccinated-girls-24-59m_tens",
          "total-other-vaccinated-girls-24-59m_units"
        ],
        "OV-Children-24-59m-girls", 
        // optional
        "Section A", 
        "Section F", 
        sheetA 
      );

      console.log('SheetResultsEdit: routine: total HW vs COUNT OMR validated')
      resolve('total HW vs COUNT OMR validated');
    });
  }

  /** routine to validate Zero Dose total from HW equals MAX of the total from OMR set of groups */
  validateZeroDoseChildrenTotalHWeqOMR(answersheetA: AnswerSheet) {
    return new Promise<any>((resolve, reject) => {      
      const sheetA = answersheetA;
      // define the OMR groups
      const BCG_06_11m_boys = ["ZD-BCG-6-11m-boys-resident", "ZD-BCG-6-11m-boys-non-resident"];
      const PENTA_06_11m_boys = ["ZD-Penta-6-11m-boys-resident", "ZD-Penta-6-11m-boys-non-resident"];
      const bOPV_06_11m_boys = ["ZD-bOPV-6-11m-boys-resident", "ZD-bOPV-6-11m-boys-non-resident"];
      const IPV_06_11m_boys = ["ZD-IPV-6-11m-boys-resident", "ZD-IPV-6-11m-boys-non-resident"];
      const MCV1_06_11m_boys = ["ZD-MCV1-6-11m-boys-resident", "ZD-MCV1-6-11m-boys-non-resident"];

      const BCG_06_11m_girls = ["ZD-BCG-6-11m-girls-resident", "ZD-BCG-6-11m-girls-non-resident"];
      const PENTA_06_11m_girls = ["ZD-Penta-6-11m-girls-resident", "ZD-Penta-6-11m-girls-non-resident"];
      const bOPV_06_11m_girls = ["ZD-bOPV-6-11m-girls-resident", "ZD-bOPV-6-11m-girls-non-resident"];
      const IPV_06_11m_girls = ["ZD-IPV-6-11m-girls-resident", "ZD-IPV-6-11m-girls-non-resident"];
      const MVC1_06_11m_girls = ["ZD-MCV1-6-11m-girls-resident", "ZD-MCV1-6-11m-girls-non-resident"];

      const PENTA_12_23m_boys = ["ZD-Penta-12-23m-boys-resident", "ZD-Penta-12-23m-boys-non-resident"];
      const bOPV_12_23m_boys = ["ZD-bOPV-12-23m-boys-resident", "ZD-bOPV-12-23m-boys-non-resident"];
      const IPV_12_23m_boys = ["ZD-IPV-12-23m-boys-resident", "ZD-IPV-12-23m-boys-non-resident"];
      const MCV1_12_23m_boys = ["ZD-MCV1-12-23m-boys-resident", "ZD-MCV1-12-23m-boys-non-resident"];

      const PENTA_12_23m_girls = ["ZD-Penta-12-23m-girls-resident", "ZD-Penta-12-23m-girls-non-resident"];
      const bOPV_12_23m_girls = ["ZD-bOPV-12-23m-girls-resident", "ZD-bOPV-12-23m-girls-non-resident"];
      const IPV_12_23m_girls = ["ZD-IPV-12-23m-girls-resident", "ZD-IPV-12-23m-girls-non-resident"];
      const MVC1_12_23m_girls = ["ZD-MCV1-12-23m-girls-resident", "ZD-MCV1-12-23m-girls-non-resident"];

      const bOPV_24_59m_boys = ["ZD-bOPV-24-59m-boys-resident", "ZD-bOPV-24-59m-boys-non-resident"];
      const IPV_24_59m_boys = ["ZD-IPV-24-59m-boys-resident", "ZD-IPV-24-59m-boys-non-resident"];
      const MCV1_24_59m_boys = ["ZD-MCV1-24-59m-boys-resident", "ZD-MCV1-24-59m-boys-non-resident"];

      const bOPV_24_59m_girls = ["ZD-bOPV-24-59m-girls-resident", "ZD-bOPV-24-59m-girls-non-resident"];
      const IPV_24_59m_girls = ["ZD-IPV-24-59m-girls-resident", "ZD-IPV-24-59m-girls-non-resident"];
      const MVC1_24_59m_girls = ["ZD-MCV1-24-59m-girls-resident", "ZD-MCV1-24-59m-girls-non-resident"];

      // define the HW and corresponding groups of OMR groups (comparison)
      const targetGroups = {
        "Total Zero-dose Boys-6-11m": {
          "targetHWfields":  ["total-zero-dose-boys-6-11m_tens", "total-zero-dose-boys-6-11m_units"],
          "targetOMRGroups": [BCG_06_11m_boys, PENTA_06_11m_boys, bOPV_06_11m_boys, IPV_06_11m_boys, MCV1_06_11m_boys]
        },
        "Total Zero-dose Girls-6-11m": {
          "targetHWfields": ["total-zero-dose-girls-6-11m_tens", "total-zero-dose-girls-6-11m_units"],
          "targetOMRGroups": [BCG_06_11m_girls, PENTA_06_11m_girls, bOPV_06_11m_girls, IPV_06_11m_girls, MVC1_06_11m_girls]
        },
        "Total Zero-dose Boys-12-23m": {
          "targetHWfields": ["total-zero-dose-boys-12-23m_tens", "total-zero-dose-boys-12-23m_units"],
          "targetOMRGroups": [PENTA_12_23m_boys, bOPV_12_23m_boys, IPV_12_23m_boys, MCV1_12_23m_boys],
        },
        "Total Zero-dose Girls-12-23m": {
          "targetHWfields": ["total-zero-dose-girls-12-23m_tens", "total-zero-dose-girls-12-23m_units"],
          "targetOMRGroups": [PENTA_12_23m_girls, bOPV_12_23m_girls, IPV_12_23m_girls, MVC1_12_23m_girls]
        },
        "Total Zero-dose Boys-24-59m": {
          "targetHWfields": ["total-zero-dose-boys-24-59m_tens", "total-zero-dose-boys-24-59m_units"],
          "targetOMRGroups": [bOPV_24_59m_boys, IPV_24_59m_boys, MCV1_24_59m_boys]
        },
        "Total Zero-dose Girls-24-59m": {
          "targetHWfields": ["total-zero-dose-girls-24-59m_tens", "total-zero-dose-girls-24-59m_units"],
          "targetOMRGroups": [bOPV_24_59m_girls, IPV_24_59m_girls, MVC1_24_59m_girls]
        }
      };

      // validate each group (section A)
      Object.keys(targetGroups).forEach((targetGroup) => {
        const targetDisplayName: string = targetGroup;
        const targetHWGroupName: string = targetGroup;
        const targetHWfields: Array<string> = targetGroups[targetGroup]["targetHWfields"];
        const targetOMRGroups: string[][] = targetGroups[targetGroup]["targetOMRGroups"];

        // load total from HW fields
        const hwVal = this.getNumericHWGroupVal(targetHWGroupName, targetHWfields, sheetA);
        // load total from OMR max
        let groupSum: number[] = []; // list of the total sum of each group
        targetOMRGroups.forEach(targetOMRSubGroup=> {
          let subGroupCounts = []; // list of the count of each group
          let totalSum: number; // total sum of the count of each group
          
          targetOMRSubGroup.forEach(omrGroup => {
            const groupValues = this.sheet.answers[omrGroup];
            const groupCount = this.getCountValue.transform(groupValues, omrGroup, this.template);
            subGroupCounts.push(groupCount);
            totalSum = subGroupCounts.map(v => parseInt(v, 10)).reduce((partialSum, a) => partialSum + a, 0);
          });
          groupSum.push(totalSum);
        });
        const groupMax = groupSum.reduce((a, b) => Math.max(a, b), 0);
        console.log('SheetResultsEdit: ', targetGroup, '(HW value): ', hwVal, '(OMR MAX Value):', groupMax, '(MAX of SUM of COUNT (OMR sub-group) in): ', targetOMRGroups );

        // validate value from hw group = max value from omr
        if (hwVal !== groupMax) {
          const err: SheetError = {
            status: SheetErrorStatusCodes.pending,
            code: SheetErrorCodes.mismatchTotalHWvsMaxOMR,
            message: `${targetDisplayName}: Total written in section A (${hwVal}) does not match circles filled in section B (${groupMax}) `,
            data: {
              targetDisplayName: targetDisplayName,
              targetHWGroupName: targetHWGroupName,
              targetOMRGroupName: targetOMRGroups,
            },
          };
          this.sheet.sheetErrors.push(err);
        }
      });

      console.log('SheetResultsEdit: routine: total HW vs MAX of SUM of COUNT of OMR groups validated')
      resolve('total HW vs MAX of SUM of COUNT of OMR groups validated');
    });
  }

  /** routine to validate total from HW equals total from OMR */
  validateTotalHWeqOMR() {
    return new Promise<any>((resolve, reject) => {
      // validate Total vaccinations (0-11 months)
      this.validateHWEqMax(
        "Total vaccinations (0-11 months)",
        "Vaccinated-0-11m",
        "Total Vaccinated-0-11m",
        ["total-vaccinated-0-11m_tens", "total-vaccinated-0-11m_units"]
      );

      // validate Total vaccinations (12-59 months)
      this.validateHWEqMax(
        "Total vaccinations (12-59 months)",
        "Vaccinated-12-59m",
        "Total Vaccinated-12-59m",
        [
          "total-vaccinated-12-59m_hundreds",
          "total-vaccinated-12-59m_tens",
          "total-vaccinated-12-59m_units",
        ]
      );

      console.log('SheetResultsEdit: routine: total HW vs total OMR validated')
      resolve('total HW vs total OMR validated');
    });
  }

  /** routine to validate any new AFP cases have age provided. */
  validateNewAFPMustHaveAge() {
    return new Promise<any>((resolve, reject) => {
      // name of the group where AFP case info is captured (OMR)
      const newAfpGroupName = "Newly paralysed child";

      // prepare data
      const numValues = Object.keys(this.sheet.answers[newAfpGroupName]).filter(
        (k) => this.sheet.answers[newAfpGroupName][k] === true
      ).length;

      // check if answer sheet has no new AFP cases (OMR) has been filled
      const hasNewAfpCases = this.sheet.answers[newAfpGroupName]["None"];

      // get values for new AFP case ages
      const AFPcase1Age = this.getNumericHWGroupVal("AFPcase1-Age", [
        "afp1-age_hundreds",
        "afp1-age_tens",
        "afp1-age_units",
      ]);
      const AFPcase2Age = this.getNumericHWGroupVal("AFPcase2-Age", [
        "afp2-age_hundreds",
        "afp2-age_tens",
        "afp2-age_units",
      ]);
      const AFPcase3Age = this.getNumericHWGroupVal("AFPcase3-Age", [
        "afp3-age_hundreds",
        "afp3-age_tens",
        "afp3-age_units",
      ]);

      if (numValues < 1) {
        // don't process if check if new AFP cases group has not been filled (will be validated by required func)
        resolve("new AFP cases have age provided NOT validated");
      } else if (hasNewAfpCases) {
        // don't process if AFP cases = none
        resolve("No new AFP cases, age fields not required");
      } else if (!(AFPcase1Age || AFPcase2Age || AFPcase3Age)) {
        // at least one new AFP case has been selected and no afp ages filled add error
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.newlyAFPMustHavePhone,
          message: `If there are new suspected AFP cases then child age (in months) is required.`,
        };
        this.sheet.sheetErrors.push(err);
      }

      console.log('SheetResultsEdit: routine: new AFP cases have age provided validated')
      resolve('new AFP cases have age provided validated');
    });
  }

  /** routine to validate total from HW equals total from OMR */
  validateTotalZeroDoseVaccinatedHWeqOMR() {
    return new Promise<any>((resolve, reject) => {
      // validate Total zero-dose vaccinations (0-11 months)
      this.validateHWEqMax(
        "Total zero dose-vaccinated (0-11 months)",
        "Zero dose-Vaccinated-0-11m",
        "Zero dose-Total vaccinated-0-11m",
        [
          "zero-dose-total-vaccinated-0-11m_tens",
          "zero-dose-total-vaccinated-0-11m_units",
        ]
      );

      // validate Total zero-dose vaccinations (12-59 months)
      this.validateHWEqMax(
        "Total zero dose-vaccinated (12-59 months)",
        "Zero dose-Vaccinated-12-59m",
        "Zero dose-Total vaccinated-12-59m",
        [
          "zero-dose-total-vaccinated-12-59m_hundreds",
          "zero-dose-total-vaccinated-12-59m_tens",
          "zero-dose-total-vaccinated-12-59m_units",
        ]
      );

      console.log('SheetResultsEdit: routine: total zero dose-vaccinated HW vs total OMR validated')
      resolve('total zero dose-vaccinated HW vs total OMR validated');
    });
  }

  /** routine to validate total from HW equals SUM from HW[] */
  validateTotalRevisitedAndVaccinatedHWeqHW() {
    return new Promise<any>((resolve, reject) => {
      // revisited and vaccinated HW Groups - SIA-OPV D

      const revisitedAndVaccinateHWGroups = [
        {
          groupName: "House1-Revisited-absent",
          hwFields: ["House1-revisited-absent_units"],
        },
        {
          groupName: "House1-Revisited-refused",
          hwFields: ["House1-revisited-refused_units"],
        },
        {
          groupName: "House2-Revisited-absent",
          hwFields: ["House2-revisited-absent_units"],
        },
        {
          groupName: "House2-Revisited-refused",
          hwFields: ["House2-revisited-refused_units"],
        },
        {
          groupName: "House3-Revisited-absent",
          hwFields: ["House3-revisited-absent_units"],
        },
        {
          groupName: "House3-Revisited-refused",
          hwFields: ["House3-revisited-refused_units"],
        },
        {
          groupName: "House4-Revisited-absent",
          hwFields: ["House4-revisited-absent_units"],
        },
        {
          groupName: "House4-Revisited-refused",
          hwFields: ["House4-revisited-refused_units"],
        },
        {
          groupName: "House5-Revisited-absent",
          hwFields: ["House5-revisited-absent_units"],
        },
        {
          groupName: "House5-Revisited-refused",
          hwFields: ["House5-revisited-refused_units"],
        },
        {
          groupName: "House6-Revisited-absent",
          hwFields: ["House6-revisited-absent_units"],
        },
        {
          groupName: "House6-Revisited-refused",
          hwFields: ["House6-revisited-refused_units"],
        },
        {
          groupName: "House7-Revisited-absent",
          hwFields: ["House7-revisited-absent_units"],
        },
        {
          groupName: "House7-Revisited-refused",
          hwFields: ["House7-revisited-refused_units"],
        },
        {
          groupName: "House8-Revisited-absent",
          hwFields: ["House8-revisited-absent_units"],
        },
        {
          groupName: "House8-Revisited-refused",
          hwFields: ["House8-revisited-refused_units"],
        },
        {
          groupName: "House9-Revisited-absent",
          hwFields: ["House9-revisited-absent_units"],
        },
        {
          groupName: "House9-Revisited-refused",
          hwFields: ["House9-revisited-refused_units"],
        },
        {
          groupName: "House10-Revisited-absent",
          hwFields: ["House10-Revisited-absent_units"],
        },
        {
          groupName: "House10-Revisited-refused",
          hwFields: ["House10-Revisited-refused_units"],
        },
      ];
      // unvaccinated HW Groups - SIA-OPV D
      const unvaccinated0To11MHWGroups: any[] = [
        {
          groupName: "House1-Unvaccinated-0-11m-absent",
          hwFields: ["House1-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House1-Unvaccinated-0-11m-refused",
          hwFields: ["House1-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House2-Unvaccinated-0-11m-absent",
          hwFields: ["House2-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House2-Unvaccinated-0-11m-refused",
          hwFields: ["House2-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House3-Unvaccinated-0-11m-absent",
          hwFields: ["House3-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House3-Unvaccinated-0-11m-refused",
          hwFields: ["House3-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House4-Unvaccinated-0-11m-absent",
          hwFields: ["House4-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House4-Unvaccinated-0-11m-refused",
          hwFields: ["House4-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House5-Unvaccinated-0-11m-absent",
          hwFields: ["House5-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House5-Unvaccinated-0-11m-refused",
          hwFields: ["House5-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House6-Unvaccinated-0-11m-absent",
          hwFields: ["House6-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House6-Unvaccinated-0-11m-refused",
          hwFields: ["House6-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House7-Unvaccinated-0-11m-absent",
          hwFields: ["House7-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House7-Unvaccinated-0-11m-refused",
          hwFields: ["House7-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House8-Unvaccinated-0-11m-absent",
          hwFields: ["House8-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House8-Unvaccinated-0-11m-refused",
          hwFields: ["House8-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House9-Unvaccinated-0-11m-absent",
          hwFields: ["House9-unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House9-Unvaccinated-0-11m-refused",
          hwFields: ["House9-unvaccinated-0-11m-refused_units"],
        },
        {
          groupName: "House10-Unvaccinated-0-11m-absent",
          hwFields: ["House10-Unvaccinated-0-11m-absent_units"],
        },
        {
          groupName: "House10-Unvaccinated-0-11m-refused",
          hwFields: ["House10-Unvaccinated-0-11m-refused_units"],
        },
      ];
      const unvaccinated12To59MHWGroups = [
        {
          groupName: "House1-Unvaccinated-12-59m-absent",
          hwFields: ["House1-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House1-Unvaccinated-12-59m-refused",
          hwFields: ["House1-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House2-Unvaccinated-12-59m-absent",
          hwFields: ["House2-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House2-Unvaccinated-12-59m-refused",
          hwFields: ["House2-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House3-Unvaccinated-12-59m-absent",
          hwFields: ["House3-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House3-Unvaccinated-12-59m-refused",
          hwFields: ["House3-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House4-Unvaccinated-12-59m-absent",
          hwFields: ["House4-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House4-Unvaccinated-12-59m-refused",
          hwFields: ["House4-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House5-Unvaccinated-12-59m-absent",
          hwFields: ["House5-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House5-Unvaccinated-12-59m-refused",
          hwFields: ["House5-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House6-Unvaccinated-12-59m-absent",
          hwFields: ["House6-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House6-Unvaccinated-12-59m-refused",
          hwFields: ["House6-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House7-Unvaccinated-12-59m-absent",
          hwFields: ["House7-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House7-Unvaccinated-12-59m-refused",
          hwFields: ["House7-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House8-Unvaccinated-12-59m-absent",
          hwFields: ["House8-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House8-Unvaccinated-12-59m-refused",
          hwFields: ["House8-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House9-Unvaccinated-12-59m-absent",
          hwFields: ["House9-unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House9-Unvaccinated-12-59m-refused",
          hwFields: ["House9-unvaccinated-12-59m-refused_units"],
        },
        {
          groupName: "House10-Unvaccinated-12-59m-absent",
          hwFields: ["House10-Unvaccinated-12-59m-absent_units"],
        },
        {
          groupName: "House10-Unvaccinated-12-59m-refused",
          hwFields: ["House10-Unvaccinated-12-59m-refused_units"],
        },
      ];
      const unvaccinatedHWGroups = unvaccinated0To11MHWGroups.concat(
        unvaccinated12To59MHWGroups
      );

      const revisitedHWSumVal = this.calculateSumOfSetOfHWGroups(
        revisitedAndVaccinateHWGroups
      );

      const unvaccinated0To11HWSumVal = this.calculateSumOfSetOfHWGroups(
        unvaccinated0To11MHWGroups
      );

      const unvaccinated12To59HWSumVal = this.calculateSumOfSetOfHWGroups(
        unvaccinated12To59MHWGroups
      );

      // validate revisited and vaccinated cases
      this.validateHWEqHW(
        "Total revisited and vaccinated",
        [`Revisited & vaccinated (${revisitedHWSumVal})`],
        revisitedAndVaccinateHWGroups,
        "Total revisited and vaccinated",
        [
          "Total-revisited-and-vaccinated_tens",
          "Total-revisited-and-vaccinated_units",
        ]
      );

      this.validateHWEqHW(
        "Total unvaccinated",
        [
          `Never vaccinated (0-11m) (${unvaccinated0To11HWSumVal})`,
          `Never vaccinated (12-59m) (${unvaccinated12To59HWSumVal})`,
        ],
        unvaccinatedHWGroups,
        "Total unvaccinated",
        ["Total-unvaccinated_tens", "Total-unvaccinated_units"]
      );

      console.log(
        "routine: Total revisited and vaccinated HW vs SUM revisited-and-vaccinated HW validated"
      );
      resolve(
        "Total revisited and vaccinated HW vs SUM revisited-and-vaccinated HW validated"
      );
    });
  }

    /**
   * validate that a given value from a HW field is equal to a count OMR count field
   * @param targetDisplayName display name for showing the error
   * @param targetHWGroupName name of the target HW group
   * @param targetHWfields array of field names from highest to lowest e.g. hundreds, tens, ones
   * @param targetOMRGroupName name of the target OMR group
   * @param targetHWDisplayText optional name of the target HW template
   * @param targetOMRDisplayText optional name of the target OMR template
   * @param targetHWSheetRecord optional answersheet record to extract the numeric HW Group value 
   */
    validateHWEqCount(
      targetDisplayName: string,
      targetHWGroupName: string,
      targetHWfields: Array<string>,
      targetOMRGroupName: string,
      targetHWDisplayText: string = '',
      targetOMRDisplayText: string = '',
      targetHWSheetRecord?: AnswerSheet
    ) {
      // load total from HW fields
      const hwVal = this.getNumericHWGroupVal(targetHWGroupName, targetHWfields, targetHWSheetRecord);
      console.log('SheetResultsEdit: HW value', hwVal);
  
      // load total from OMR max
      const groupValues = this.sheet.answers[targetOMRGroupName];
      const groupCount = this.getCountValue.transform(groupValues, targetOMRGroupName, this.template);
      console.log('SheetResultsEdit: OMR count', groupCount);
  
      // validate value from hw group = max value from omr
      if (hwVal !== groupCount) {
        const err: SheetError = {
          status: SheetErrorStatusCodes.pending,
          code: SheetErrorCodes.mismatchTotalHWvsCountOMR,
          message: `${targetDisplayName}: Total written in ${targetHWDisplayText} (${hwVal}) does not match circles filled in ${targetOMRDisplayText} (${groupCount}) `,
          data: {
            targetDisplayName: targetDisplayName,
            targetHWGroupName: targetHWGroupName,
            targetOMRGroupName: targetOMRGroupName,
          },
        };
        this.sheet.sheetErrors.push(err);
      }
    }

  /**
   * validate that a given value from a HW field is equal to a max of an OMR count field
   * @param targetDisplayName display name for showing the error
   * @param targetOMRGroupName name of the target OMR group
   * @param targetHWGroupName name of the target HW group
   * @param targetHWfields array of field names from highest to lowest e.g. hundreds, tens, ones
   */
  validateHWEqMax(
    targetDisplayName: string,
    targetOMRGroupName: string,
    targetHWGroupName: string,
    targetHWfields: Array<string>
  ) {
    // load total from HW fields
    const hwVal = this.getNumericHWGroupVal(targetHWGroupName, targetHWfields);
    console.log('SheetResultsEdit: HW value', hwVal);

    // load total from OMR max
    const groupValues = this.sheet.answers[targetOMRGroupName];
    const groupMax = this.getMaxValue.transform(groupValues, targetOMRGroupName, this.template);
    console.log('SheetResultsEdit: OMR max', groupMax);

    // validate value from hw group = max value from omr
    if (hwVal !== groupMax) {
      const err: SheetError = {
        status: SheetErrorStatusCodes.pending,
        code: SheetErrorCodes.mismatchTotalHWvsMaxOMR,
        message: `${targetDisplayName}: Total written (${hwVal}) does not match circles filled (${groupMax}) `,
        data: {
          targetDisplayName: targetDisplayName,
          targetHWGroupName: targetHWGroupName,
          targetOMRGroupName: targetOMRGroupName,
        },
      };
      this.sheet.sheetErrors.push(err);
    }
  }

  /** calculate and returns the sum of values of a set of HW groups */
  calculateSumOfSetOfHWGroups(targetHWGroups: any[]) {
    const hwGroupValues = targetHWGroups.map((g) => ({
      ...g,
      hwVal: this.getNumericHWGroupVal(String(g.groupName), g.hwFields),
    }));

    const hwSumVal = hwGroupValues.map(g => g.hwVal).reduce((accumulator, currentValue) => {
      return accumulator + (isNaN(currentValue) ? 0 : currentValue);
    }, 0);

    return hwSumVal;
  }

  /**
   * validate that a given value from a HW field is equal to the sum of values of set of HW groups
   * @param targetDisplayName display name for showing the error
   * @param targetDisplayGroups array of display names for set of HW groups in comparison
   * @param targetHWGroups array of HW groups and their fields[] in comparison (sum)
   * @param targetHWGroupName name of the target HW group
   * @param targetHWfields array of field names from highest to lowest e.g. hundreds, tens, ones
   */
  validateHWEqHW(
    targetDisplayName: string,
    targetDisplayGroups: string[],
    targetHWGroups: any[],
    targetHWGroupName: string,
    targetHWfields: Array<string>
  ) {
    // load total from HW fields
    const hwVal = this.getNumericHWGroupVal(targetHWGroupName, targetHWfields);
    console.log('SheetResultsEdit: HW value', hwVal);

    // load sum of set of HW groups
    const hwSumVal = this.calculateSumOfSetOfHWGroups(targetHWGroups);

    const targetDisplayGroupNames = targetDisplayGroups.join(" + ");

    // validate value from hw group = sum value of set of HW groups
    if (hwVal !== hwSumVal) {
      const err: SheetError = {
        status: SheetErrorStatusCodes.pending,
        code: SheetErrorCodes.mismatchTotalHWvsSumHW,
        message: `${targetDisplayName}: Total written (${hwVal}) not equal to ${targetDisplayGroupNames}.`,
        data: {
          targetDisplayName: targetDisplayName,
          targetHWGroupName: targetHWGroupName,
          targetHWGroups: targetHWGroups,
        },
      };
      this.sheet.sheetErrors.push(err);
    }
  }

  /**
   * function to return the value of a numeric handwriting group
   * @param targetHWGroupName name of the target HW group
   * @param targetHWfields array of field names from highest to lowest e.g. hundreds, tens, ones
   * @returns value as number
   */
  getNumericHWGroupVal(
    targetHWGroupName: string,
    targetHWfields: Array<string>,
    targetHWSheet?: AnswerSheet
  ): number {
    const sheet: AnswerSheet = targetHWSheet && targetHWSheet._id ? targetHWSheet : this.sheet;
    return +targetHWfields
      .map((hwFieldName) => sheet.handwritingResults[targetHWGroupName][hwFieldName].displayVal)
      .map((hwFieldValue) => hwFieldValue.toLowerCase() === 'blank' || hwFieldValue.toLowerCase() === 'unknown' ? '' : hwFieldValue)
      .join('');
  }

  /**
   * function to return the value of a numeric handwriting group
   * @param targetHWGroupName name of the target HW group
   * @param targetHWfields array of field names from highest to lowest e.g. hundreds, tens, ones
   * @returns value as a string
   */
  getAlphaHWGroupVal(
    targetHWGroupName: string,
    targetHWfields: Array<string>
  ): string {
    return targetHWfields
      .map((hwFieldName) => this.sheet.handwritingResults[targetHWGroupName][hwFieldName].displayVal)
      .map((hwFieldValue) => hwFieldValue.toLowerCase() === 'blank' ? '' : hwFieldValue)
      .join('');
  }

  /** routine to validate polio dosage count of circles is equal to the max value filled. Specific to SIA-B  */
  validateCountEqMax() {
    return new Promise<any>((resolve, reject) => {
      Object.keys(this.sheet.answers).forEach((groupName) => {
        // get display name or use group name if not available
        const groupDisplayName = (() => {
          switch (groupName) {
            case "Vaccinated-0-11m":
              return "# Vaccinations (0-11 months)";
            case "Vaccinated-12-59m":
              return "# Vaccinations (12-59 months)";
            case "Zero dose-Vaccinated-0-11m":
              return "# Zero dose Vaccinations (0-11 months)";
            case "Zero dose-Vaccinated-12-59m":
              return "# Zero dose Vaccinations (12-59 months)";
            // case "Missed vaccination-0-11m-absent": return '# Vaccinations Missed - Absent (0-11 months)';
            // case "Missed vaccination-0-11m-refused": return '# Vaccinations Missed - Refused (0-11 months)';
            // case "Missed vaccination-12-59m-absent": return '# Vaccinations Missed - Absent (12-59 months)';
            // case "Missed vaccination-12-59m-refused": return '# Vaccinations Missed - Refused (12-59 months)';
            default:
              return groupName;
          }
        })();

        // prepare data for validation
        const groupValues = this.sheet.answers[groupName];
        const groupCount = this.getCountValue.transform(
          groupValues,
          groupName,
          this.template
        );
        const groupMax = this.getMaxValue.transform(
          groupValues,
          groupName,
          this.template
        );

        // validate, if validation fails add an error
        if (groupCount !== groupMax) {
          const err: SheetError = {
            status: SheetErrorStatusCodes.pending,
            code: SheetErrorCodes.countVsMaxMismatch,
            message: `${groupDisplayName}: Number of circles filled (${groupCount}) does not match max value (${groupMax})`,
            data: {
              groupName: groupName,
              groupDisplayName: groupDisplayName,
              groupCount: groupCount,
              groupMax: groupMax,
            },
          };
          this.sheet.sheetErrors.push(err);
        }
      });

      console.log("routine: template group counts vs max validated");
      resolve("template group counts vs max validated");
    });
  }

  /** routine to validate group counts, counts should equal max */
  validateGroupCounts() {
    return new Promise<any>((resolve, reject) => {
      Object.keys(this.sheet.answers).forEach((groupName) => {
        const val = this.sheet.answers[groupName];
        const count = this.getCountValue.transform(
          val,
          groupName,
          this.template
        );
        const max = this.getMaxValue.transform(val, groupName, this.template);
        if (max !== count) {
          const err: SheetError = {
            status: SheetErrorStatusCodes.pending,
            code: SheetErrorCodes.commodityCountMismatch,
            message: `${groupName} (Possible missing data)`,
            data: {
              groupName: groupName,
            },
          };
          this.sheet.sheetErrors.push(err);
        }
      });

      console.log("routine: template group counts validated");
      resolve("template group counts validated");
    });
  }

  /** routine to validate group calculations Ending balance = (Beginning balance)+(Qty received)-(Qty dispensed)+(Added)-(Removed)) */
  validateGroupCalculations() {
    return new Promise<any>((resolve, reject) => {
      // reduce the answers to an object of group name and selected value as: [{ groupName: value }]
      const resultsArray = Object.keys(this.sheet.answers).map((key) => {
        const results = {},
          val = this.sheet.answers[key];
        results[key] = this.getCountValue.transform(val, key, this.template);
        return results;
      });

      // arrange results in a meaningful way
      const groupedResults = {};
      const uniqueGroupNames = [];
      this.template.groups.sort().forEach((g, i) => {
        const firstLevelGroupName = g.groupTitle
          .split("-")
          .slice(0, -1)
          .join("-")
          .trim();

        resultsArray.forEach((r) => {
          Object.keys(r).map((n) => {
            if (
              n.split("-").slice(0, -1).join("-").trim() === firstLevelGroupName
            ) {
              // add key if it doesn't exist
              if (!groupedResults[firstLevelGroupName]) {
                groupedResults[firstLevelGroupName] = {};
                uniqueGroupNames.push(firstLevelGroupName);
              }
              const secondLevelGroupName = n.split("-").pop().trim();
              groupedResults[firstLevelGroupName][secondLevelGroupName] = r[n];
            }
          });
        });
      });

      // call function to validate data
      uniqueGroupNames.forEach((groupName) => {
        if (!this.validateGroupCalculation(groupedResults, groupName)) {
          const err: SheetError = {
            status: SheetErrorStatusCodes.pending,
            code: SheetErrorCodes.commodityCalculationFailed,
            message: `${groupName} calculation`,
            data: {
              groupResults: groupedResults[groupName],
              groupName: groupName,
            },
          };
          this.sheet.sheetErrors.push(err);
        }
      });

      console.log("routine: template group calculations validated");
      resolve("template group calculations validated");
    });
  }

  /** function to validate calculation for a given commodity and return if valid or not */
  validateGroupCalculation(results: any, firstLevelGroupName: string): boolean {
    let BB: number, EB: number, QR: number, QD: number, SA: number, SR: number;
    const resultsGroup = results[firstLevelGroupName];

    BB = resultsGroup["Beginning Balance"];
    EB = resultsGroup["Ending Balance"];
    QR = resultsGroup["Quantity Received"];
    QD = resultsGroup["Quantity Dispensed"];
    SA = resultsGroup["Stock Added"];
    SR = resultsGroup["Stock Removed"];

    return EB === BB + (QR - QD) + (SA - SR);
  }
}
