<form [formGroup]="form">
  <!-- transfusion date -->
  <div class="form-group" *ngIf="template.showTransfusionEndDate">
    <mat-form-field class="width100">
      <input matInput [matDatepicker]="pickerEndDate" formControlName="transfusionEndDate" placeholder="Transfusion End Date"
        [(ngModel)]="answerSheet.transfusionEndDate" [required]="template.requireTransfusionEndDate">
      <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #pickerEndDate touchUi></mat-datepicker>
    <validation-messages [control]="form.controls['transfusionEndDate']"></validation-messages>
  </div>

  <!-- transfusion time field -->
  <div class="form-group" *ngIf="template.showTransfusionEndTime">
    <mat-form-field class="width100">
      <input matInput [ngxMatTimepicker]="pickerEndTime" [required]="template.requireTransfusionEndTime"
        formControlName="transfusionEndTime" [(ngModel)]="answerSheet.transfusionEndTime"
        placeholder="Transfusion End Time">
      <ngx-mat-timepicker-toggle matSuffix [for]="pickerEndTime"></ngx-mat-timepicker-toggle>
    </mat-form-field>
    <ngx-mat-timepicker #pickerEndTime></ngx-mat-timepicker>
    <validation-messages [control]="form.controls['transfusionEndTime']"></validation-messages>
  </div>
</form>