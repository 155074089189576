import { PouchObject } from './pouch-object';

export class ReportSeries implements PouchObject {
  public static type: string = 'reportSeries';
  public static fields = ['_id', '_rev', 'type', '_deleted', 'patientId', 'seriesStartDate', 'serialNumber', 'facilityWard', 'county',
    'screeningOrg', 'teamNumber', 'campaignDay', 'sharedFacilities', 'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy',
    'seriesStartTime', 'createFacility', 'updateFacility'];
  type: string;
  _id: string;
  _rev: string;
  _deleted: boolean;

  patientId?: string; // blood-safe, TB & Migori
  serialNumber?: number; // blood-safe & mhGAP
  facilityWard?: string; // blood-safe
  county?: string; // mhGAP
  screeningOrg?: string; //mhGAP

  teamNumber?: any; // polio (sia-opv)
  campaignDay?: any; // polio (sia-opv)

  seriesStartDate: Date | string;
  seriesStartTime?: string;
  sharedFacilities: string[];

  dateAdded: Date | string;
  createdBy: string;
  dateUpdated: Date| string;
  updatedBy: string;
  createFacility: string;
  updateFacility: string;
  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}
