import { PouchObject } from './pouch-object';

export const DEFAULT_DATE_MASK = 'dd MMM YYYY';

export class AppSettings implements PouchObject {
  public static type: string = 'settings';
  public static fields = ['_id', '_rev', '_deleted', 'type', 'dateAdded', 'dateUpdated', 'createdBy', 'updatedBy', 'modelKey', 'createFacility',
    'updateFacility', 'patientSettings', 'recordSettings', 'uiViewSettings', 'farmSettings', 'appUpdateSettings'];

  _id: string;
  _rev: string;
  _deleted: boolean;
  type: string;
  dateAdded: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  createFacility: string;
  updateFacility: string;
  modelKey: string;
  patientSettings = new PatientsSettings();
  uiViewSettings = new UiViewSettings();
  recordSettings = new RecordSettings();
  farmSettings = new FarmSettings();
  appUpdateSettings = new AppUpdateSettings();

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class FieldSetting {
  show = true;
  required = true;
}

export class UiViewSettings {
  cicWorkflow = false;
  tbWorkflow = false;
  safeBloodWorkflow = false;
  migoriWorkflow = false;
  farmAlertWorkflow = false;
  polioWorkflow = false;
  mhGapWorkFlow = false;
  showFacilityNameOnEditForm = false;
  showSheetErrors = false; // show errors in view, list view, edit page and errors popup
  warnSheetErrors = false; // warn user about errors with errors popup
  recordLabel = 'Medical Record';
  userLabel: string;
  templateFieldLabel = 'Select Stamp template *';
  encounterTime: FieldSetting = new FieldSetting();
  facilityLabel: string = 'Facility';
  skipEditScreenOnNextRecord: boolean = false;
  disableTemplateField: boolean = false;
  serialNumberLabel: string = 'Serial Number';
  serialNumberLabelShort: string = 'Serial #';
  navigationMenu = new NavigationMenu();
}

export const DefaultMinDate = new Date('01/01/2010');

export class NavigationMenu {
  reportsListLabel: string = 'Reports';
}
export class RecordSettings {
  setMinDate: boolean = false;
  dateMaskFormat = DEFAULT_DATE_MASK;
  minDate: Date = DefaultMinDate;
  dateFieldLabel = 'Date of Encounter';
  timeFieldLabel = 'Time of Encounter';
  defaultTemplate: string;
  enableReportSeries: boolean = false;
  showSerialNumberField: boolean = false;
  templatesInSeries: boolean = false;
  serialNumberMin: number = 1;
  serialNumberMax: number = 2;
  enableEditRecordInAdmin: boolean = false;
  enableEditRecordInUser: boolean = false;
  enableEditRecordForUserRole: string[] = [];
}

export class AppUpdateSettings {
  latestVersion: string;
  minCompatibleVersion: string;
}

export class PatientsSettings {
  enableBarcodeScanning = false;
  enablePatientAdding = true;
  patientIdRequired = false;
  enablePatientObjects = true;
  patientIdLabel = 'Patient Registration Number';
  newPatientBtnLabel = 'New Patient';
}

export class FarmSettings {
  showFarmNextRecordButton: boolean = false;
  incomeExpenseTemplate: string;
  smartRegistrationTemplate: string;
}
