import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from '../models/patient';
import { Facility } from '../models/facility';

@Pipe({ name: 'getPatientId' })
export class GetPatientId implements PipeTransform {
  /** get user readable patient id  */
  transform(patientId: string, patients: Patient[]) {
    let currentPatient: Patient, currentPatientId = patientId + '_missing';
    // update patient ID if we find it
    if (patients) {
      currentPatient = patients.find(p => p._id === patientId);
      if (currentPatient) { currentPatientId = currentPatient.patientId; }
    }
    return currentPatientId;
  }
}

@Pipe({ name: 'getPatientName' })
export class GetPatientName implements PipeTransform {
  transform(patientId: string, patients: Patient[]) {
    let currentPatient: Patient, currentPatientName = '';
    if (patients) {
      currentPatient = patients.find(p => p._id === patientId);
      if (currentPatient) { currentPatientName = currentPatient.firstName; }
    }
    return currentPatientName;
  }
}

@Pipe({ name: 'getPatientFullName' })
export class GetPatientFullName implements PipeTransform {
  transform(patientId: string, patients: Patient[]) {
    let currentPatient: Patient, currentPatientFullName = '';
    if (patients) {
      currentPatient = patients.find(p => p._id === patientId);
      if (currentPatient) { currentPatientFullName = currentPatient.firstName + ' ' + currentPatient.lastName; }
    }
    return currentPatientFullName;
  }
}

/** returns the patient's facility name */
@Pipe({ name: 'getPatientFacility' })
export class GetPatientFacility implements PipeTransform {
  transform(patientId: string, patients: Patient[], facilities: Facility[]) {
    let currentPatient: Patient, currentPatientFacility = '';
    if (patients) {
      currentPatient = patients.find(p => p._id === patientId);
      if (currentPatient) {
        const facility = facilities.find(f => f.code === currentPatient.createFacility)
        if (facility) { currentPatientFacility = facility.name } else { currentPatientFacility = '[Missing]'; }
      }
    }
    return currentPatientFacility;
  }
}

@Pipe({ name: 'getPatientPhone' })
export class GetPatientPhone implements PipeTransform {
  transform(patientId: string, patients: Patient[]) {
    let currentPatient: Patient, currentPatientPhone = '';
    if (patients) {
      currentPatient = patients.find(p => p._id === patientId);
      if (currentPatient) { currentPatientPhone = currentPatient.phoneNumber; }
    }
    return currentPatientPhone;
  }
}
