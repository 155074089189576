import { Component, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { UserService } from "../../services/user.service";
import { RepositoryService } from "../../services/repository.service";
import { User } from "../../models/user";
import { MatTable, MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "paper-delete-user-dialog-component",
  template: ` <h2 mat-dialog-title > Confirm User Delete</h2>
  <mat-dialog-content>
    <p>
      This user will be deleted <br />Please note that this data cannot be
      recovered.
    </p>
  </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-raised-button (click)="dialogRef.close(true)">
        Delete
      </button>
      <button type="button" mat-raised-button (click)="dialogRef.close()">
        Cancel
      </button>
    </mat-dialog-actions>`,
})
export class DeleteUserDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: "paper-users-list",
  templateUrl: "users-list.component.html",
})
export class UsersListComponent {
  @Output() userDelete: EventEmitter<User> = new EventEmitter<User>();
  @Output() userEdit: EventEmitter<User> = new EventEmitter<User>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild('usersTable') usersTable: MatTable<User>;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ["_id", "emailAddress", "userRole", "facility", "dateAdded", "Actions"];
  dataSource = new MatTableDataSource<User>();
  dialogRef: MatDialogRef<any>;

  _users: User[];
  @Input() set users(users: User[]) {
    this._users = users;
    this.dataSource.data = this._users;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // this.usersTable?.renderRows();
    this.dataSource._updateChangeSubscription();
  };
  get users(): User[] { return this._users; }

  constructor(
    public dialog: MatDialog,
    public usersService: UserService,
    public repositoryService: RepositoryService,
  ) { }

  // let parent component know we want to edit this user
  editUser(user: User) {
    this.userEdit.emit(user);
  }

  // let parent component know we want to delete this user
  deleteUser(user: User) {
    this.userDelete.emit(user);
  }

  // confirm deleting user with dialog
  confirmDelete(user: User) {
    this.dialogRef = this.dialog.open(DeleteUserDialogComponent);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.deleteUser(user);
      }
      this.dialogRef = null;
    });
  }

  // listen to user filter
  applyUserFilter(event: Event) {
    this.dataSource.filter = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

