import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppSettings } from '../models/settings';
import { AnswerSheet } from '../models/answer-sheet';

@Pipe({ name: 'validDate' })
export class ValidDatePipe implements PipeTransform {
  datePipe: DatePipe = new DatePipe('en-US');
  transform(d, format: string) {
    const date = new Date(d);
    return !date || date.toString() === 'Invalid Date' ? 'Not filled' : this.datePipe.transform(date, format);
  }
}

@Pipe({ name: 'workFlowDate' })
export class WorkFlowDate implements PipeTransform {
  transform(sheet: AnswerSheet, appSettings: AppSettings, format: string = 'dd MMM, y') {
    let returnDate = null;

    if (appSettings.uiViewSettings.cicWorkflow) {
      return sheet.encounterDate;
    } else if (appSettings.uiViewSettings.polioWorkflow) {
      returnDate = sheet.customBeforeFieldsData?.immunizationDate;
    } else {
      returnDate = sheet.encounterDate;
    }

    return new ValidDatePipe().transform(returnDate, format);
  }
}
