<h2>Add User</h2>

<form [formGroup]="userForm" (ngSubmit)="addUser()">

  <validation-messages [control]="userForm.controls['firstName']"></validation-messages>
  <validation-messages [control]="userForm.controls['lastName']"></validation-messages>
  <validation-messages [control]="userForm.controls['phone']"></validation-messages>
  <validation-messages [control]="userForm.controls['emailAddress']"></validation-messages>
  <validation-messages [control]="userForm.controls['username']"></validation-messages>
  <validation-messages [control]="userForm.controls['confirmPassword']"></validation-messages>

  <div class="form-group">
    <label for="username" class="psoc-label">Username*:</label>
    <mat-form-field>
      <input matInput type="text" formControlName="username" [(ngModel)]="user.username" placeholder="Username"
        autocomplete="off">
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="firstName" class="psoc-label">First name*:</label>
    <mat-form-field>
      <input matInput type="text" formControlName="firstName" [(ngModel)]="user.firstName" placeholder="First name"
        autocomplete="off">
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="lastName" class="psoc-label">Last name*:</label>
    <mat-form-field>
      <input matInput type="text" formControlName="lastName" [(ngModel)]="user.lastName" placeholder="Last name"
        autocomplete="off">
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="emailAddress" class="psoc-label">Email*:</label>
    <mat-form-field>
      <input matInput type="email" formControlName="emailAddress" [(ngModel)]="user.emailAddress" placeholder="Email"
        autocomplete="off">
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="phone" class="psoc-label">Telephone*:</label>
    <mat-form-field>
      <input matInput type="tel" formControlName="phone" [(ngModel)]="user.phone" placeholder="Telephone"
        autocomplete="off" (blur)="formatNumber()">
    </mat-form-field>
  </div>

  <div class="form-group uk-margin-bottom uk-margin-top">
    <label for="facility" class="psoc-label">Facility*:</label>
    <mat-form-field>
      <mat-select formControlName="facility" [(ngModel)]="user.facility" placeholder="Select Facility">
        <mat-option *ngFor="let facility of facilities" [value]="facility.code">{{ facility.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="form-group uk-margin-top">
    <label for="userRole" class="psoc-label">Role*:</label>
    <mat-radio-group formControlName="userRole" [(ngModel)]="user.userRole">
      <mat-radio-button *ngFor="let role of userRoleOptions" name="user_role_options" [value]="role">
        {{role}}
        &nbsp;
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="form-group uk-margin-top">
    <label class="psoc-label">User Roles*:</label>
    <mat-form-field>
      <mat-select formControlName="userRoles" multiple [(ngModel)]="user.userRoles" placeholder="Select User Roles">
        <mat-option *ngFor="let role of userRolesOptions" [value]="role.title">{{role.value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <validation-messages [control]="userForm.controls['userRoles']"></validation-messages>
  </div>

  <div class="form-group">
    <label for="password" class="psoc-label">Password*:</label>
    <mat-form-field>
      <input matInput type="password" formControlName="password" [(ngModel)]="user.password" placeholder="Password"
        autocomplete="off">
    </mat-form-field>
  </div>

  <div class="form-group">
    <label for="confirmPassword" class="psoc-label">Confirm Password*:</label>
    <mat-form-field>
      <input matInput type="password" formControlName="confirmPassword" placeholder="Confirm Password"
        autocomplete="off">
    </mat-form-field>
  </div>

  <div class="form-group">
    <button mat-raised-button type="submit" [disabled]="!userForm.valid">Register User</button>
    <button mat-raised-button (click)="resetForm(); false;">Reset</button>
  </div>

</form>