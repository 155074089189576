import { PouchObject } from './pouch-object';

/** save details about patient */
export class Patient implements PouchObject {
  public static fields = [
    '_id', '_rev', '_deleted', 'type',
    'patientId', 'firstName', 'lastName', 'otherNames',
    'phoneNumber', 'countryCode', 'emailAddress', 'dateOfBirth', 'sex', 'postalAddress', 'city', 'patientType',
    'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility', 'sharedFacilities'];
  public static type = 'patient';
  _id: string;
  _rev: string;
  _deleted = false;
  type: string;

  patientId: string; // registration # or patient ID
  firstName: string; // firstName, required, 2-50 characters long
  lastName: string; // lastName, required, 2-50 characters long
  otherNames: string; // otherNames, 2-50 characters long
  phoneNumber: string; // phoneNumber
  countryCode: string = '254'; // default code region KE
  emailAddress: string;
  dateOfBirth: Date; // required, must be in past
  sex: string; // required, "male", "female"
  postalAddress: string;
  city: string; // required
  patientType: string; // default:non-paying, required, "paying", "non-paying"
  sharedFacilities: string[];

  dateAdded: Date | string;
  dateUpdated: Date | string;
  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}

export let CountryCodes = [
  { 'code': '211', 'country': 'S.Sudan' },
  { 'code': '254', 'country': 'Kenya' },
  { 'code': '250', 'country': 'Rwanda' },
  { 'code': '255', 'country': 'Uganda' },
  { 'code': '256', 'country': 'Tanzania' },
  { 'code': '234', 'country': 'Nigeria' }
];
