import { PouchObject } from './pouch-object';

export class User implements PouchObject {
  public static type: string = 'user';
  public static fields = ['id', '_id', '_rev', '_deleted', 'firstName', 'lastName', 'phone', 'username', 'emailAddress',
    'password', 'facility', 'status', 'userRole', 'userRoles', 'chvUsersList', 'dateAdded', 'dateUpdated', 'type', 'ipAddress'];
  id: number;
  _id: string;
  _rev: string;
  _deleted: boolean = false;

  firstName: string;
  lastName: string;
  phone: string;

  username: string;
  emailAddress?: string;
  password?: string;
  facility?: string;
  userStatus?: UserStatuses;
  userRole?: string;
  userRoles?: string[];
  chvUsersList?: CHVUsersList[];
  dateAdded: Date | string;
  dateUpdated: Date | string;
  type: string;
  ipAddress: string;

  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}
export class CHVUsersList {
  firstName: string;
  patientId: string;
}
export enum UserRoles {
  mediator = 'mediator',
  admin = 'admin',
  chv = 'CHV',
  cha = 'CHA',
  fa_admin = 'FA-Admin',
  manager = 'manager',
  staff = 'staff',
  editor = 'editor',
  viewer = 'viewer'
}

export const AllowedAdminRoles: string[] = [
  UserRoles.admin.toLowerCase(),
  UserRoles.viewer.toLowerCase(),
  UserRoles.editor.toLowerCase()
];


export enum UserStatuses {
  rejected = 'rejected',
  approved = 'approved',
  pending = 'pending'
}