import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AuthenticationService } from "../services/authentication.service";
import { FacilityService } from "../services/facility.service";
import { PatientService } from "../services/patient.service";
import { ReportSeriesService } from "../services/report-series.service";
import { RepositoryObserver } from "../services/repository-observer";
import { RepositoryService } from "../services/repository.service";
import { AppSettingsService } from "../services/settings.service";
import { SheetService } from "../services/sheet.service";
import { TemplateSeriesService } from "../services/template-series.service";
import { TemplateService } from "../services/template.service";

import { AnswerSheet } from "../models/answer-sheet";
import { Facility } from "../models/facility";
import { Patient } from "../models/patient";
import { TemplateSeries } from "../models/series";
import { AppSettings } from "../models/settings";
import { StampTemplate } from "../models/stamp-template";
import { SharedComponent } from "../shared";

@Component({
  selector: "paper-patients",
  templateUrl: "./patients.component.html",
})
export class PatientsComponent extends SharedComponent implements OnInit, RepositoryObserver {
  constructor(
    public snackBar: MatSnackBar,
    public sheetService: SheetService,
    public auth: AuthenticationService,
    public repository: RepositoryService,
    public patientService: PatientService,
    public facilityService: FacilityService,
    public templateService: TemplateService,
    public appSettingsService: AppSettingsService,
    public templateSeriesService: TemplateSeriesService,
    public reportSeriesService: ReportSeriesService
  ) {
    super(
      snackBar,
      sheetService,
      auth,
      repository,
      patientService,
      facilityService,
      templateService,
      appSettingsService,
      templateSeriesService,
      reportSeriesService
    );
  }

  ngOnInit(): void {
    this.repository.registerObserver(this); // register to be updated if new data comes in
    this.loggedInUser = this.auth.getUser();

    this.getAppSettings();
    this.getFacilities();
    this.getPatients();
    this.getTemplates();
    this.getTemplateSeries();
    this.getSheets();
  }

  notify(objectType: string): void {
    const useCache = false;

    if (objectType === AppSettings.type) {
      if (this.debugMode) {
        console.log("get app settings");
      }
      this.getAppSettings();
    }

    if (objectType === Facility.type) {
      if (this.debugMode) {
        console.log("get updated facilities");
      }
      this.getFacilities();
    }

    if (objectType === Patient.type) {
      if (this.debugMode) {
        console.log("get updated patients");
      }
      this.getPatients(useCache);
    }

    if (objectType === StampTemplate.type) {
      if (this.debugMode) {
        console.log("get updated templates");
      }
      this.getTemplates();
    }

    if (objectType === TemplateSeries.type) {
      if (this.debugMode) {
        console.log("get updated series");
      }
      this.getTemplateSeries();
    }

    if (objectType === AnswerSheet.type) {
      if (this.debugMode) {
        console.log("get updated answer sheets");
      }
      this.getSheets(useCache);
    }
  }
}
