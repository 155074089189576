import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AppSettings } from '../../../models/settings';
import { AnswerSheet } from '../../../models/answer-sheet';
import { StampTemplate } from '../../../models/stamp-template';

@Component({
  selector: 'paper-transfusion-date',
  templateUrl: './transfusion-date-time.component.html'
})
export class TransfusionDateTimeComponent implements OnInit {

  _form: FormGroup;
  minDate: Date;
  @Input() set form(form: FormGroup) {
    this._form = form;
    this._form.addControl('transfusionDate', new FormControl('', []));
    this._form.addControl('transfusionTime', new FormControl('', []));
  } get form(): FormGroup { return this._form; }
  @Input() appSettings: AppSettings;
  @Input() answerSheet: AnswerSheet;
  @Input() template: StampTemplate;
  @Output() dateTimeChanged: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.minDate = new Date();
    this.form.get('transfusionDate').disable();
    this.form.get('transfusionTime').disable();
    this.form.valueChanges.subscribe((formValues: any) => {
      const transfusionDateControl = this.form.get('transfusionDate');
      const transfusionTimeControl = this.form.get('transfusionTime');
      if (formValues.encounterDate) {
        this.minDate = formValues.encounterDate;
        if (transfusionDateControl.disabled) {
          transfusionDateControl.enable();
        }
        if (transfusionTimeControl.disabled) {
          transfusionTimeControl.enable();
        }
      } else {
        if (transfusionDateControl.enabled) {
          transfusionDateControl.disable();
        }
        if (transfusionTimeControl.enabled) {
          transfusionTimeControl.disable();
        }
      }
    });
  }
}