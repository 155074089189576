import { NgModule } from '@angular/core';

import { OrderBy } from './orderByPipe';
import { FormatPhone } from './formatPhone';
import { OrderByDate } from './orderByDatePipe';
import { GetMaxValue } from './getMaxValuePipe';
import { GetAttachmentImage } from './getAttachmentImagePipe';
import { HideHwFieldInView } from './hideFieldsInViewPipe';
import { RemoveUserPrefix } from './removeUserPrefixPipe';
import { ValidDatePipe, WorkFlowDate } from './validDate.pipe';
import { GetExclusiveValuePipe } from './getExclusiveValuePipe';
import { GetCountValue, GetHWValues } from './getCountValuePipe';
import { GetFacilityName, GetFacilityWard } from './getFacilityNamePipe';
import { GetTemplateName, IsTemplateOfType } from './getTemplateDetailsPipe';
import { CheckReportHasErrors } from './checkReportHasErrorsPipe'
import { GetPatientId, GetPatientName, GetPatientPhone, GetPatientFacility, GetPatientFullName } from './getPatientDetailsPipe';
import { GetScannedCount, FindScannedRecords, GetTemplateNameAlphabet, GetLastEncounterDate } from './getSeriesScannedReportsPipe';
import { ToLocalTimePipe } from './ToLocalTimePipe';

@NgModule({
  declarations: [OrderBy, OrderByDate, RemoveUserPrefix, GetPatientId, GetPatientName, GetPatientFullName, GetPatientFacility, CheckReportHasErrors,
    GetTemplateName, GetFacilityName, GetCountValue, GetMaxValue, GetAttachmentImage, GetExclusiveValuePipe, FormatPhone, ValidDatePipe, GetPatientPhone, GetFacilityWard,
    GetScannedCount, FindScannedRecords, GetTemplateNameAlphabet, GetLastEncounterDate, IsTemplateOfType, GetHWValues, WorkFlowDate, HideHwFieldInView,ToLocalTimePipe],
  exports: [OrderBy, OrderByDate, RemoveUserPrefix, GetPatientId, GetPatientName, GetPatientFullName, GetPatientFacility, CheckReportHasErrors,
    GetTemplateName, GetFacilityName, GetCountValue, GetMaxValue, GetAttachmentImage, GetExclusiveValuePipe, FormatPhone, ValidDatePipe, GetPatientPhone, GetFacilityWard,
    GetScannedCount, FindScannedRecords, GetTemplateNameAlphabet, GetLastEncounterDate, IsTemplateOfType, GetHWValues, WorkFlowDate, HideHwFieldInView,ToLocalTimePipe]
})
export class PipesModule { }
