import { PouchObject } from './pouch-object';

export class Facility implements PouchObject {
    public static fields = [
        '_id', '_rev', '_deleted', 'type',
        'code', 'name', 'level', 'facilityType', 'ownership', 'state', 'localGovernment',
        'regulatoryBody', 'county', 'sub_county', 'latitude', 'longitude', 'contact', 'facilityfields', 'facilityOptions',
        'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility', 'encryptionKey', 'facilityWards'];
    public static type = 'facility';
    _id: string;
    _rev: string;
    _deleted = false;
    type: string;
    code: string;
    name: string;
    level: string;
    facilityType: string;
    ownership: string;
    regulatoryBody: string;
    state?: string;
    county: string;
    sub_county: string;
    localGovernment?: string;
    latitude?: Date;
    longitude?: boolean;
    contact: string;

    // set facility fields
    facilityfields = new FacilityFields();
    facilityOptions = new FacilityOptions();

    facilityWards?: FacilityWard[];

    dateAdded: Date | string;
    dateUpdated: Date | string;
    updatedBy: string;
    createdBy: string;
    createFacility: string;
    updateFacility: string;

    encryptionKey: string; // used to encrypt offline database for each location

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

export class FacilityFields {
    randomRegNumber = false;
    firstName: RequireShow = new RequireShow();
    lastName: RequireShow = new RequireShow();
    otherNames: RequireShow = new RequireShow();
    sex: RequireShow = new RequireShow();
    dateOfBirth: RequireShow = new RequireShow();
    phonenumber: RequireShow = new RequireShow();
    emailAddress = new RequireShow();
    city: RequireShow = new RequireShow();
    postalAddress: RequireShow = new RequireShow();
    patientType: RequireShow = new RequireShow();
}

export class FacilityOptions {
    hasSputumCollection = false;
    referringFacility = false;
    hasLab = false;
    hasDispatch = false;
    defaultShareTo: string[] = [];
    isMainFacility = false;
    isResearchAssistantFacility = false;
    trainingDates: TrainingDate[] = [];
}

export class TrainingDate {
    start: string;
    end: string;
}

export class RequireShow {
    show = false;
    required = false;
}

export class FacilityWard {
    _id: string;
    name: string;
}
