export class BitMatrix {
  public static createEmpty(width: number, height: number) {
    return new BitMatrix(new Uint8ClampedArray(width * height), width);
  }

  public width: number;
  public height: number;
  private data: Uint8ClampedArray;

  constructor(data: Uint8ClampedArray, width: number) {
    this.width = width;
    this.height = data.length / width;
    this.data = data;
  }

  public length() {
    return this.data.length;
  }

  public get(x: number, y: number): boolean {
    if (x < 0 || x >= this.width || y < 0 || y >= this.height) {
      return false;
    }
    return !!this.data[y * this.width + x];
  }

  public set(x: number, y: number, v: boolean) {
    this.data[y * this.width + x] = v ? 1 : 0;
  }

  public setRegion(left: number, top: number, width: number, height: number, v: boolean) {
    for (let y = top; y < top + height; y++) {
      for (let x = left; x < left + width; x++) {
        this.set(x, y, !!v);
      }
    }
  }

  public toString(): string {
    let result = '';
    // console.log('bitmatrix data', this.data);
    for (let y = 0, height = this.height; y < height; ++y) {
      for (let x = 0, width = this.width; x < width; ++x) {
        result += this.get(x, y) ? ' 1' : ' 0';
      }
      result += '\n';
    }
    return result;
  }

  public toImage(canvas) {
    const context: CanvasRenderingContext2D = canvas.getContext('2d', { willReadFrequently: true });
    canvas.height = this.height;
    canvas.width = this.width;

    const imagedata = context.createImageData(this.height, this.width);
    // Loop over all of the pixels
    for (let y = 0, height = this.height; y < height; ++y) {
      for (let x = 0, width = this.width; x < width; ++x) {
        // Get the pixel index
        const pixelindex = (y * width + x) * 4;

        if (this.get(x, y)) {
          // Set the pixel data
          imagedata.data[pixelindex] = 0;     // Red
          imagedata.data[pixelindex + 1] = 0; // Green
          imagedata.data[pixelindex + 2] = 0;  // Blue
        } else {
          imagedata.data[pixelindex] = 255;     // Red
          imagedata.data[pixelindex + 1] = 255; // Green
          imagedata.data[pixelindex + 2] = 255;  // Blue
        }
        imagedata.data[pixelindex + 3] = 255;   // Alpha
      }
    }
    context.putImageData(imagedata, 0, 0);
  }
}
