import { NgModule } from '@angular/core';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { BarcodeScannerLivestreamModule, BarcodeScannerLivestreamOverlayModule } from "ngx-barcode-scanner";

import { PipesModule } from '../pipes/pipes.module';
import { ValidationModule } from '../validation/validation.module';
import { NavigationModule } from '../navigation/navigation.module';
import { AppMaterialModule } from '../material/app-material.module';

import { UserHasAuthorityDirective } from '../directives/authority.directive';

import {
  CameraComponent, SheetEditComponent, ManualEditComponent, SheetResultsEditComponent, DataValidationDialogComponent, SheetViewComponent,
  SelectReferringFacilityComponent, SuccessDialogComponent, TransfusionDateTimeComponent, TransfusionEndDateTimeComponent,
  RecordExistInSeries, SerialNumberChanged, PatientChangedDialog, PaperDialogComponent, BarcodeDialogComponent,
  ConfirmAddDuplicateScanDialogComponent, ExitScanDialogComponent, ConfirmDiscardDialogComponent
} from './components';
import { SheetsComponent } from './sheets.component';
import { YearMonthFormatDirective } from '../directives/year-month.directive';
import { FormioModule } from '@formio/angular';

@NgModule({
  imports: [
    PipesModule,
    FormsModule,
    CommonModule,
    MomentModule,
    RouterModule,
    FormioModule,
    NgSelectModule,
    HttpClientModule,
    FlexLayoutModule,
    NavigationModule,
    ValidationModule,
    AppMaterialModule,
    ReactiveFormsModule,
    NgxMatTimepickerModule,
    UserHasAuthorityDirective,
    NgxMatIntlTelInputComponent,
    BarcodeScannerLivestreamModule,
    BarcodeScannerLivestreamOverlayModule
  ],
  declarations: [
    SheetsComponent,
    CameraComponent,
    SheetEditComponent,
    SheetViewComponent,
    RecordExistInSeries,
    SerialNumberChanged,
    ManualEditComponent,
    PaperDialogComponent,
    PatientChangedDialog,
    BarcodeDialogComponent,
    SuccessDialogComponent,
    ExitScanDialogComponent,
    YearMonthFormatDirective,
    SheetResultsEditComponent,
    TransfusionDateTimeComponent,
    ConfirmDiscardDialogComponent,
    DataValidationDialogComponent,
    TransfusionEndDateTimeComponent,
    SelectReferringFacilityComponent,
    ConfirmAddDuplicateScanDialogComponent
  ],
  exports: [
    YearMonthFormatDirective
  ]
})
export class SheetsModule { }

