import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Globals } from './globals';

import PouchDB from 'pouchdb';
import PouchDBAuthentication from 'pouchdb-authentication';
import * as CryptoPouch from './crypto-pouch';

//const PouchDB: PouchDB.Static = (rawPouchDB as any).default;
//const PouchDBAuthentication: PouchDB.Plugin = (rawPouchDBAuthentication as any).default;
PouchDB.plugin(PouchDBAuthentication);
//const CryptoPouch: PouchDB.Plugin = (rawCryptoPouch as any);

PouchDB.plugin(CryptoPouch);

import { HttpClient } from '@angular/common/http';
import { Facility } from '../models/facility';
import { User } from '../models/user';

// declare let emit: Function;
import { BehaviorSubject } from 'rxjs';
import { formatDateWithFields, formatSystemGeneratedDate } from '../helpers/formatDate';
// import 'rxjs/add/operator/toPromise';

@Injectable()
export class AuthenticationService {
  public isRoot: BehaviorSubject<boolean> = new BehaviorSubject(false);

  userRoles = {
    adminUser: 'Admin',
    mediatorUser: 'Mediator',
  };

  userRoleOptions = [
    this.userRoles.adminUser,
    this.userRoles.mediatorUser,
  ];

  private remoteAuthDb: any;
  private remoteFacilitiesDb: any;
  private localUserDb: any; // encrypted with username & password, contains location encryption key
  private userModel: User;
  public firstTimeLogin: boolean = false;
  public firstTimeLoginStart;

  constructor(private globals: Globals, private http: HttpClient) {
    this.remoteAuthDb = new PouchDB(environment.couchURL + environment.pouchDBName, { skip_setup: true });
    this.remoteFacilitiesDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-facilities', { skip_setup: true });
    //PouchDB.plugin(PouchDBAuthentication);
    // PouchDB.plugin(CryptoPouch);
  }

  setIsRoot(isRoot: boolean = true) {
    this.isRoot.next(isRoot);
  }

  /** Function check if user is authorized, to perform some actions */
  isAuthorized(requiredRoles: string[], userRoles: string[] = this.userModel.userRoles): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    if (!userRoles) { return false }
    return userRoles.some(role => requiredRoles.includes(role));
  }

  isLoggedIn(): boolean {
    return this.getUser() !== undefined && this.getUser().username !== '';
  }
  getUser(): User {
    return this.userModel;
  }
  setUser(newUser: User) {
    this.userModel = newUser;
    this.globals.currentUser.next(newUser);
  }
  destroyUser() {
    this.userModel = this.getNullUser();
    this.globals.currentUser.next(this.userModel);
  }
  getNullUser(): User {
    return new User({
      _id: '',
      id: '',
      _rev: '',
      username: '',
      emailAddress: '',
      password: '',
      facility: '',
      userStatus: '',
      userRole: '',
      dateAdded: ''
    });
  }

  // return true if user is logged in and is an admin
  isAdmin(): boolean {
    if (!this.isLoggedIn) {
      return false;
    }
    return (this.userModel.userRole === this.userRoles.adminUser);
  }

  /** return true if logged in user is of passed role */
  authorize(role: string): boolean {
    if (!this.isLoggedIn) {
      return false;
    }
    return this.userModel.userRoles.includes(role);
  }

  // login a user
  userLogin(username: string, password: string): Promise<any> {
    // set auth headers for login
    const ajaxOpts = {
      ajax: {
        headers: {
          Authorization: 'Basic ' + window.btoa(username + ':' + password)
        }
      }
    };

    return new Promise((resolve, reject) => {
      this.remoteAuthDb.login(username, password, ajaxOpts).then(response => {
        console.log('Auth attempt response', response);

        if (response['ok'] || response['ok'] === true) {
          // check if root couch user
          this.setIsRoot(response['roles'].includes('_admin'));
          // login is successful, resolve
          resolve(response);
        } else {
          // try and give a useful error on failed login
          let errMsg: string;
          if (response['message'] === 'ETIMEDOUT') {
            errMsg =
              'Online login unsuccessful, you may proceed offline, your data will be synchronized when internet connectivity is restored';
          } else if (response['message']) {
            errMsg = response['message'];
          } else if (response['status'] === 0) {
            errMsg =
              'Online login unsuccessful, you may proceed offline, your data will be synchronized when internet connectivity is restored';
          }
          reject('Unable to log in to remote server: ' + errMsg);
        }

      }).catch(error => {
        if (error.code === 'ETIMEDOUT') {
          const errMsg =
            'Online login unsuccessful, you may proceed offline, your data will be synchronized when internet connectivity is restored';
          reject(errMsg);
        } else {
          reject(error);
        }
      });
    });
  }

  // change users password for a user
  userChangePassword(username: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      console.log('change password for ' + username + ' to ' + password);
      this.remoteAuthDb.changePassword(username, password)
        .then(res => {
          resolve(res);
        }).catch(err => {
          console.log('An error occurred', err);
          reject(err);
        });
    });
  }

  /**
   * define role based user's userRole
   */
  getRole(user: User) {
    return user.userRole === 'Admin' ? 'admin' : 'mediator';
  }

  /**
   * change the profile of a user
   */
  userChangeProfile(userModel: User): Promise<User> {
    userModel = formatDateWithFields(userModel);
    return new Promise((resolve, reject) => {

      this.remoteAuthDb.putUser(userModel.username, {
        metadata: {
          id: userModel.id,
          emailAddress: userModel.emailAddress,
          facility: userModel.facility,
          status: userModel.userStatus,
          userRole: userModel.userRole,
          userRoles: userModel.userRoles,
          chvUsersList: userModel.chvUsersList,
          dateAdded: userModel.dateAdded,
          firstName: userModel.firstName,
          lastName: userModel.lastName,
          phone: userModel.phone
        },
        roles: [this.getRole(userModel)]
      }).then(response => {
        if (response['ok'] || response['ok'] === true) {
          userModel._rev = response.rev;
          resolve(userModel);
        } else {
          console.log('Error updating user: ', response['message']);
          reject(response['message']);
        }
      }).catch(err => {
        console.log('Error updating user: ', err);
        reject(err);
      });

    });
  }

  /**
   * Function register a user
   */
  addUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.remoteAuthDb.signup(user.username, user.password, {
        metadata: {
          id: ((new Date().getTime())),
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          emailAddress: user.emailAddress,
          facility: user.facility,
          userStatus: user.userStatus,
          userRole: user.userRole,
          userRoles: user.userRoles,
          chvUsersList: user.chvUsersList,
          dateAdded: formatSystemGeneratedDate(new Date())
        },
        roles: [this.getRole(user)]
      }).then(response => {
        if (response['ok'] || response['ok'] === true) {
          user._rev = response.rev;
          user._id = response.id;
          resolve(user);
        } else if (response['status'] === 409) {
          reject('This username already exists');
        }
      }).catch((error) => {
        reject(error);
      });

    });
  }

  // check the session of the current user with couch, tells us who is logged in to couch
  // response.userCtx.name is the current user
  checkUserSession(): Promise<any> {
    return this.remoteAuthDb.getSession().then((res) => res).catch((err) => err);
  }

  // if logged in get profile else return error
  // loads user document of current user, stores in local storage as curent user
  loadRemoteUser(username: string): Promise<User> {

    return new Promise((resolve, reject) => {

      this.remoteAuthDb.getUser(username).then(result => {

        if (result['status'] === 404 || result['error'] === 'not found') {
          this.makeGlobalsFalse();
          reject('Sorry, your profile cannot be found');
        } else if (result['error'] || result['error'] === true || result['status'] === 400) {
          this.makeGlobalsFalse();
          reject('Error: Please ensure that your account is valid');
        } else if (result['code'] === 'ETIMEDOUT') {
          reject('Profile loading timed out');
        } else {
          console.log('Loaded remote user: ' + result['name']); // + JSON.stringify(result));
          // once the user is logged in,lets store the details in local storage
          const newUser = new User({
            _id: result['_id'],
            id: result['id'],
            _rev: result['_rev'],
            username: result['name'],
            firstName: result['firstName'],
            lastName: result['lastName'],
            emailAddress: result['emailAddress'],
            phone: result['phone'],
            facility: result['facility'],
            userStatus: result['status'],
            userRole: result['userRole'],
            userRoles: result['userRoles'],
            chvUsersList: result['chvUsersList'],
            dateAdded: result['dateAdded']
          });
          this.setUser(newUser);
          resolve(newUser);
        }

      }).catch(err => {
        resolve(err);
      });

    });
  }


  /** get client ipAddress function */
  getClientIp(): Promise<string> {
    return new Promise((resolve, reject) => {
      // grab public ipAddress
      this.http.get('https://ipinfo.io?token=671746af92baa4')
        .toPromise()
        .then((data: any) => {
          resolve(data.ip);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  // log the user out
  userSignOut(): Promise<User> {
    return this.remoteAuthDb.logout().then((res) => res).catch((err) => err);
  }

  // create database for user and save encryption key to it
  // encrypt new database with username and password
  createUserDb(user: User, username: string, password: string, key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.localUserDb = new PouchDB('local_' + username, { skip_setup: true });
      this.localUserDb.crypto(username + password);

      const userData: any = {
        _id: 'key',
        encryptionKey: key,
        user: user
      };

      this.localUserDb.get('key')
        .then(doc => {
          if (doc._rev) {
            userData._rev = doc._rev;
          }
          // information exists in local db, update it
          this.localUserDb.put(userData)
            .then((response: any) => {
              resolve('success');
            }).catch(error => {
              console.log('Error occurred writing to userdata to localUserDb: ', error);
              reject(error);
            });
        }).catch((error) => {
          // information does not exist in local db, add it
          console.log('Getting key from localUserDb failed, attempting to write user data: ', error);
          console.log('The above error is expected, should be a first time login and the local user db does not exist, adding it now')

          this.localUserDb.put(userData)
            .then((response2: any) => {
              resolve('success');
            })
            .catch(error2 => {
              console.log('Error occurred writing user data to localUserDb: ', error2);
              reject(error2);
            });
        });
    });
  }

  // check if local user db exists
  checkLocalUserDb(username: string): Promise<any> {
    this.localUserDb = new PouchDB('local_' + username, { skip_setup: true });
    return new Promise((resolve, reject) => {
      this.localUserDb.info()
        .then((details) => {

          if (details.doc_count === 0 && details.update_seq === 0) {
            // local user database not created
            this.firstTimeLogin = true;
            this.firstTimeLoginStart = performance.now();
            reject('user db does not exist');
            this.localUserDb.destroy();
          } else {
            // local user database exists
            this.firstTimeLogin = false;
            resolve('user db exists');
          }
        })
        .catch(e => {
          // Error
          console.log(e);
        });
    });
  }

  // load encryption key for facility from local user db
  loadFacilityKeyLocal(username: string, password: string): Promise<{ localDBUserKey: string, localDBUserObj: User }> {
    this.localUserDb = new PouchDB('local_' + username, { skip_setup: true });
    this.localUserDb.crypto(username + password);
    return new Promise((resolve, reject) => {
      this.localUserDb.get('key')
        .then((result: any) => {
          if (!result.encryptionKey) { reject("localUserDb.get('key') returns null"); return; }
          const localDBUserKey = result.encryptionKey.toString()
          const localDBUserObj = result.user;
          // console.log('loadFacilityKeyLocal: ', JSON.stringify(result), 'encryptionKey', localDBUserKey, 'user', localDBUserObj);
          console.log('loadFacilityKeyLocal: success');
          resolve({ localDBUserKey, localDBUserObj });
        }).catch(err => reject(err));
    });
  }

  // load encryption key for facility from remote db
  loadFacilityKeyRemote(facilityCode: string): Promise<string> {
    return new Promise((resolve, reject) => {

      // make sure that a facility has been specified
      console.log('Loading remote encryption key for facility', facilityCode)
      if (!facilityCode) {
        reject('No facility specified in request for remote facility key');
        return;
      }

      // directly load 
      this.remoteFacilitiesDb.get(facilityCode)
        .then((result: any) => {
          console.log('Loaded remote facility to get key from:', result.code);
          if (!result.encryptionKey) {
            reject('Error: Unable to load encryption key from remote facility:' + facilityCode );
            return;
          }
          resolve(result.encryptionKey);
        }).catch(err => {
          console.log('Error loading remote facility to get key from', err);
          reject(err)
        });

    });
  }
  // this is due to getting a user error or internet connection
  // note, this logs out the user
  makeGlobalsFalse() {
    this.destroyUser();
  }

  // this function currently runs once on initial load of app
  // succeeds if the user still has valid session with server and valid profile
  refreshLoggedInUserDetails(): Promise<any> {
    return new Promise((resolve, reject) => {

      // check who is logged in
      this.checkUserSession()
        .then(response => {
          // if logged in response will be ok and response['userCtx'].name will be valid name
          if (response['ok'] || response['ok'] === true) {
            this.loadRemoteUser(response['userCtx'].name)
              .then(result => {

                console.log('refreshLoggedInUserDetails: loadRemoteUser result', result);
                if (result['status'] === 404 || result['error'] === 'not found') {
                  this.makeGlobalsFalse();
                  reject('Sorry, Your profile cannot be found');
                } else if (result['error'] || result['error'] === true || result['status'] === 400) {
                  this.makeGlobalsFalse(); // do we need to disable access?
                  reject('Your session with the server has expired, please log in to enable sync');
                } else if (result['code'] === 'ETIMEDOUT') {
                  // timed out, do nothing?
                  resolve('Attempt to check user session timed out.');
                } else {
                  // logged in
                  const currentUser = new User({
                    _id: result['_id'],
                    id: result['id'],
                    _rev: result['_rev'],
                    username: result['name'],
                    emailAddress: result['emailAddress'],
                    facility: result['facility'],
                    userStatus: result['status'],
                    userRole: result['userRole'],
                    userRoles: result['userRoles'],
                    dateAdded: result['dateAdded']
                  });
                  this.setUser(currentUser);

                  console.log('logged in and emitted');
                  resolve('Successfully logged in');
                }

              })
              .catch(error => {
                console.log('refreshLoggedInUserDetails error: ', error);
                this.makeGlobalsFalse();
                reject('Sorry, error getting profile');
              });

          } else {
            this.makeGlobalsFalse();
            reject('Sorry, error getting profile (response not OK)');
          }
        })
        .catch(error => {
          this.makeGlobalsFalse();
          reject('Sorry, Error getting user session. Please log in' + error);
        });

    });
  }

}
