import { Injectable } from '@angular/core';
import { AnswerSheet } from '../models/answer-sheet';
import { ReportSeries } from './../models/report-series';

import { RepositoryService } from './repository.service';


@Injectable()
export class ReportSeriesService {

  public reportSeries: ReportSeries[] = []; // store all series to be returned
  seriesLoaded: Boolean = false;  // track whether cached version of series available

  constructor(private repository: RepositoryService) { }

  /**
   * Function update a report series to the database
   * @param { ReportSeries } series a series object to update or save
   * @returns success or fail
   */
  updateReportSeries(series: ReportSeries): Promise<ReportSeries> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(series, ReportSeries.type)
        .then((pouchObject) => {
          const updatedSheet: ReportSeries = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedSheet);
        }).catch(error => {
          console.error('An error occurred updating this answer sheet', error);
          reject(error);
        });
    });
  }

  /**
   * Function get all report series from the database
   * @param {Boolean} useCache supplied to either query from database or use list in memory
   * @returns 
   */
  getAllReportSeries(useCache: boolean = true): Promise<ReportSeries[]>  {
    return new Promise((resolve, reject) => {
      if (this.seriesLoaded && useCache) {
        console.log('already loaded series objects');
        resolve(this.reportSeries);
      } else {
        this.loadReportSeries().then((allReportSeries) => {
          this.reportSeries = allReportSeries;
          this.seriesLoaded = true;
          resolve(this.reportSeries);
        }).catch(error => {
          console.error('An error occurred getting report series', error);
          reject(error);
        });
      }
    });
  }

  /**
   * Returns all report series from DB
   */
  loadReportSeries(): Promise<ReportSeries[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(ReportSeries.type)
        .then((result) => {
          const reportSeries: ReportSeries[] = result.docs.map((doc: any) => this.mapObjectToSeries(doc));
          resolve(reportSeries as ReportSeries[]);
        })
        .catch(error => {
          console.error('An error occurred loading report series', error);
          reject(error);
        });
    });
  }

  /**
   * function fetch a series object from the database
   * @param seriesId series id to fetch from database
   * @returns a series object
   */
  getSeriesById(seriesId: string): Promise<ReportSeries> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObject(ReportSeries.type, seriesId, ReportSeries.fields).then(result => {
        const series: ReportSeries = result.docs.map((doc: any) => this.mapObjectToSeries(doc))[0];
        resolve(series as ReportSeries);
      }).catch((error: any) => reject(error));
    });
  }

  /**
   * function fetch all patient series from the database
   * @param patientId patient id to fetch
   * @returns an array of series objects
   */
  getSeriesByPatientId(patientId: string): Promise<ReportSeries[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(ReportSeries.type, 'patientId', patientId).then(result => {
        const series: ReportSeries[] = result.docs.map((doc: any) => this.mapObjectToSeries(doc));
        resolve(series);
      }).catch((error: any) => reject(error));
    });
  }

  /**
   * function fetch all report series from db, by supplied parameters
   * @param filterBy field name
   * @param filterValue field value
   * @returns an array of report series objects
   */
  getReportSeriesBy(filterBy: string, filterValue: any): Promise<ReportSeries[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(ReportSeries.type, filterBy, filterValue).then(result => {
        const series: ReportSeries[] = result.docs.map((doc: any) => this.mapObjectToSeries(doc));
        resolve(series);
      }).catch((error: any) => reject(error));
    });
  }

  /** takes an object and maps it to a TemplateSeries Object */
  private mapObjectToSeries(object: any): ReportSeries {
    let series: ReportSeries = new ReportSeries();
    return series = { ...object };
  }

  /**
   * Function check if a series is complete or not
   * @param series current series to check if complete
   * @param fullSeries expected series list
   * @returns true or false
   */
  isSeriesComplete(series: ReportSeries, fullSeries: string[]): boolean {
    // TODO: ADD 
    return false;
  }

  /**
   * Function find the best fit report series of the current scan
   * @param sheet current template being scanned
   * @param reportSeries an array of report series for the patient
   * @returns best fix for the current scan
   */
  findBestFitReportSeries(sheet: AnswerSheet, reportSeries: ReportSeries[]) {
    const encounterTime = sheet.encounterTime ? sheet.encounterTime : '00:00:00';
    const currentEncounterDateTime = new Date(`${sheet.encounterDate} ${encounterTime}`).getTime();
    const dateDiff = reportSeries.map(reportSeries => Math.abs(currentEncounterDateTime - new Date(reportSeries.seriesStartDate).getTime()));

    const index = dateDiff.indexOf(Math.min(...dateDiff)); // find smallest value index
    return reportSeries[index]; // return the closest series object by encounter date
  }

}