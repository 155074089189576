import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { SignupUser } from '../../../models/signup';
import { Facility } from '../../../models/facility';
import { AppSettings } from '../../../models/settings';

import { AuthenticationService } from '../../../services/authentication.service';

import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class SignupService {
  env = environment;
  API_PATH: string = environment.paper_API;
  API_SECRET_KEY: string = environment.paper_API_SECRET_KEY;

  facilities: Facility[];
  appSettings: AppSettings;

  // request headers
  headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.API_SECRET_KEY
  });

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }

  /**
   * Function fetch the app settings from the database
   * @returns {AppSettings} settings config
   */
  getAppSettings = (): Promise<AppSettings> => {

    return new Promise((resolve, reject) => {
      try {
        const result = this.http.get(`${this.API_PATH}/settings?id=${this.env.settingsId}`, { headers: this.headers });
        result.subscribe({
          next(result: any) {
            this.appSettings = result.data;
          },
          error(error) {
            let errorMessage = error.error.message ? error.error.message :
              `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
            if (errorMessage.constructor === Array) {
              errorMessage = error.error.message[0];
            }
            reject(`App settings: ${errorMessage}`);
          },
          complete() {
            resolve(this.appSettings);
          },
        });
      } catch (error) {
        reject('Error happened connecting to the server, try again or contact Health-e-net team');
      }
    });
  };

  /**
   * Function register user
   * @param {signupUser: SignupUser} current user to save to db 
   * @returns 
   */
  registerUser = (signupUser: SignupUser): Promise<string> => {
    return new Promise((resolve, reject) => {
      try {
        const result = this.http.post(`${this.API_PATH}/signup`, signupUser, { headers: this.headers });
        result.subscribe({
          next(result: any) { console.log(result) },
          error(error) {
            let errorMessage = error.error.message ? error.error.message :
              `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
            if (errorMessage.constructor === Array) {
              errorMessage = error.error.message[0];
            }
            reject(`${error.error.error}: ${errorMessage}`);
          },
          complete() {
            resolve(`User ${signupUser.userName} added successfully.`);
          },
        });
      } catch (error) {
        reject('Error happened connecting to the server, try again or contact Health-e-net team');
      }
    });
  }

  /**
   * Function get available username
   * @param {String} firstName user firstName
   * @param {String} lastName user lastName
   * @returns {String} username
   */
  checkUserNameIfExist = (firstName: string, lastName: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      try {
        const result = this.http.get(`${this.API_PATH}/signup/username?firstName=${firstName}&lastName=${lastName}`, { headers: this.headers });
        result.subscribe({
          next(result: any) {
            resolve(result.username);
          },
          error(error) {
            let errorMessage = error.error.message ? error.error.message :
              `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
            if (errorMessage.constructor === Array) {
              errorMessage = error.error.message[0];
            }
            reject(`${error.error.error}: ${errorMessage}`);
          },
          complete() {
            console.log('Username request completed.');
          },
        });
      } catch (error) {
        reject('Error happened connecting to the server, try again or contact Health-e-net team');
      }
    });
  }

  /**
   * Function fetch all the facilities from the database
   * @returns list of facilities from db
   */
  getFacilities = () => {
    return new Promise((resolve, reject) => {
      try {
        const result = this.http.get(`${this.API_PATH}/facilities`, { headers: this.headers });
        result.subscribe({
          next(result: any) {
            resolve(result.data);
          },
          error(error) {
            let errorMessage = error.error.message ? error.error.message :
              `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
            if (errorMessage.constructor === Array) {
              errorMessage = error.error.message[0];
            }
            reject(`${error.error.error}: ${errorMessage}`);
          },
          complete() {
            console.log('Get facilities completed.')
          },
        });
      } catch (error) {
        reject('Error happened connecting to the server, try again or contact Health-e-net team');
      }
    });
  };

  /**
   * Search for state from the API
   * @param search state to search
   * @returns {Observable}
   */
  getState = (search: string): Observable<any> => {
    try {
      return this.http.get(`${this.API_PATH}/facilities/states?state=${search}`, { headers: this.headers });
    } catch (error) {
      console.log('getState ERROR:', error);
    }
  }

  /**
   * Function check if email is managed by google or not
   * @param email email to check
   * @returns return boolean
   */
  isEmailManagedByGoogle(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const result = this.http.get(`${this.API_PATH}/signup/isGoogleManagedEmail?emailAddress=${email}`, { headers: this.headers });
      result.subscribe({
        next(result: boolean) {
          resolve(result);
        },
        error(error) {
          let errorMessage = error.error.message ? error.error.message :
            `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
          if (errorMessage.constructor === Array) {
            errorMessage = error.error.message[0];
          }
          const err = error.error.error ? error.error.error : 'Error'
          console.log(`${err}: ${errorMessage}`);
          resolve(false); // if error return false
        }
      });
    });
  }

  /**
   * Function fetch all signups facilities and users
   * @param userRole Current logged in user
   * @returns list of new users and facilities
   */
  getAllSignUps(): Promise<SignupUser[] | Facility[]> {
    return new Promise((resolve, reject) => {
      const currentUser = this.authService.getUser();
      const userFacility: string = currentUser.facility;
      const userRoles: string[] = currentUser.userRoles;

      const result = this.http.get(`${this.API_PATH}/signup/all-signups`, { headers: this.headers, params: { userRoles, userFacility } });
      result.subscribe({
        next(result: any) {
          resolve(result.data);
        },
        error(error) {
          let errorMessage = error.error.message ? error.error.message :
            `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
          if (errorMessage.constructor === Array) {
            errorMessage = error.error.message[0];
          }
          console.log(`${error.error.error}: ${errorMessage}`);
          reject(errorMessage);
        }
      });
    });
  }

  /**
   * Function approve a user
   * @param id user id to approve
   * @returns true | false
   */
  approveUser(id: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const currentUser = this.authService.getUser();
      const body = { userId: id, userRoles: currentUser.userRoles };

      const result = this.http.post(`${this.API_PATH}/signup/approve-user`, body, { headers: this.headers });
      result.subscribe({
        next(result: any) {
          if (!result.data) {
            reject(`User not approved: (${result.message}), try again or contact Health-e-net team.`);
          } else {
            if (result.data.userSaved.ok) {
              resolve(true);
            } else {
              reject(`User not approved, try again or contact Health-e-net team.`);
            }
          }
        },
        error(error) {
          let errorMessage = error.error.message ? error.error.message :
            `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
          if (errorMessage.constructor === Array) {
            errorMessage = error.error.message[0];
          }
          console.log(`${error.error.error}: ${errorMessage}`);
          reject(errorMessage);
        }
      });
    });
  }

  /**
   * Function reject the selected user
   * @param id user id to reject
   * @returns true | false
   */
  rejectUser(id: string, facility: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const currentUser = this.authService.getUser();
      const body = { userId: id, userRoles: currentUser.userRoles, facility: facility };

      const result = this.http.post(`${this.API_PATH}/signup/reject-user`, body, { headers: this.headers });
      result.subscribe({
        next(result: any) {
          if (!result.data) {
            reject(`User not rejected: (${result.message}), try again or contact Health-e-net team.`);
          } else {
            if (result.data.updatedUser.ok) {
              resolve(true);
            } else {
              reject(`User not rejected, try again or contact Health-e-net team.`);
            }
          }
        },
        error(error) {
          let errorMessage = error.error.message ? error.error.message :
            `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
          if (errorMessage.constructor === Array) {
            errorMessage = error.error.message[0];
          }
          console.log(`${error.error.error}: ${errorMessage}`);
          reject(errorMessage);
        }
      });
    });
  }

  /**
   * Function check if a user exist in db using the phone number
   * @param phoneNumber phone number to check with
   * @returns
   */
  accountRecovery = async (phoneNumber: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const result = this.http.post(`${this.API_PATH}/signup/account_recovery`, { phoneNumber }, { headers: this.headers });
        result.subscribe({
          next(result: any) {
            resolve(result.data);
          },
          error(error) {
            let errorMessage = error.error.message ? error.error.message :
              `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
            if (errorMessage.constructor === Array) {
              errorMessage = error.error.message[0];
            }
            reject(`${error.error.error}: ${errorMessage}`);
          },
          complete() {
            console.log('Request Completed successfully.');
          },
        });
      } catch (error) {
        reject('Error happened connecting to the server, try again or contact Health-e-net team');
      }
    });
  }

  /**
   * Call the API to validate the password reset token
   */
  validateToken = async (userName: string, token: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const result = this.http.post(`${this.API_PATH}/signup/validate_token`, { userName, token }, { headers: this.headers });
        result.subscribe({
          next(result: any) {
            resolve(result.data);
          },
          error(error) {
            let errorMessage = error.error.message ? error.error.message :
              `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
            if (errorMessage.constructor === Array) {
              errorMessage = error.error.message[0];
            }
            reject(`${error.error.error}: ${errorMessage}`);
          },
          complete() {
            console.log('Request Completed successfully.');
          },
        });
      } catch (error) {
        reject('Error happened connecting to the server, try again or contact Health-e-net team');
      }
    });
  }

  /**
   * Function make call to the API to reset user password
   * @param userName current user user name to change the password for
   * @param password new password to be updated
   * @returns 
   */
  resetPassword = async (userName: string, password: string, resetToken: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const result = this.http.post(`${this.API_PATH}/signup/reset-password`, { userName, password, resetToken }, { headers: this.headers });
        result.subscribe({
          next(result: any) {
            resolve(result.data);
          },
          error(error) {
            let errorMessage = error.error.message ? error.error.message :
              `${error.name}: ${error.statusText} - Error happened connecting to the server, try again or contact Health-e-net team.`;
            if (errorMessage.constructor === Array) {
              errorMessage = error.error.message[0];
            }
            reject(`${error.error.error}: ${errorMessage}`);
          },
          complete() {
            console.log('User password changed successfully.')
          },
        });
      } catch (error) {
        reject('Error happened connecting to the server, try again or contact Health-e-net team');
      }
    });
  }
}
