import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AppSettingsService } from '../services/settings.service';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ScannedRecordsListResolver implements Resolve<boolean> {
  env = environment;

  constructor(private appSettingsService: AppSettingsService, private router: Router) { }

  async resolve(): Promise<boolean> {
    try {
      const appSettings = await this.appSettingsService.loadAppSettings(this.env.settingsId);
      return appSettings.recordSettings.enableReportSeries ? this.router.navigate(['/patients/report_series/list']) : true;
    } catch (error) {
      return false;
    }
  }
}