import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  standalone: true,
  selector: '[isAuthorized]'
})
export class UserHasAuthorityDirective {
  private authorities: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthenticationService,
  ) { }

  @Input()
  set authorizedRoles(value: string | string[]) {
    this.authorities = typeof value === 'string' ? [value] : value;
    this.updateView();
  }

  private updateView(): void {
    this.viewContainerRef.clear();
    const user = this.authService.getUser();
    const userRoles = user ? user.userRoles : null;

    if (this.authService.isAuthorized(this.authorities, userRoles)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  };
}
