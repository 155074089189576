<h2 mat-dialog-title >Conflict Manager</h2>
<mat-dialog-content>
   
    <h3>Choose action to proceed.</h3>
    <table>
        <thead>
            <tr>
                <th>Data Fields</th>
                <th>Current Data</th>
                <th>Incoming Data</th>
            </tr>
        </thead>
        <tbody>
            <!-- <tr *ngFor="let item of data.current | keyvalue">
                <td>{{item.key}}</td>
                <td>{{item.value ? item.value : '-'}}</td>
                <td>
                    <p [style.background-color]="item.value !== data.incoming[item.key] ? 'yellow': 'white'">
                        {{data.incoming[item.key] ? data.incoming[item.key] : '-'}}</p>
                </td>
            </tr> -->
        </tbody>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="warn" (click)="dialogRef.close(data.incoming)" class="uk-float-right"
    style="margin-right: 3px; margin-top: 3px;">Save Incoming Document</button>
<button mat-raised-button color="basic" (click)="dialogRef.close()" class="uk-float-right"
    style=" margin-right: 3px; margin-top: 3px;">Don't Make Changes</button>
</mat-dialog-actions>

