import { Pipe, PipeTransform } from '@angular/core';
import { StampTemplate } from '../models/stamp-template';

@Pipe({ name: 'getMaxValue' })
export class GetMaxValue implements PipeTransform {
  transform(values: any, groupName: string, template: StampTemplate) {
    // is this a group of field type count
    const groupCount = template.groups.find(g => g.groupTitle === groupName).count;

    if (groupCount) {
      if (typeof values === 'string') {
        values = values.split(' ').filter(e => e);
      } else if (typeof values === 'object') {
        values = Object.keys(values).filter(k => values[k] === true);
      }

      values = groupCount ?
        values.map(v => parseInt(v, 10)).reduce((a, b) => Math.max(a, b), 0) :
        values.map(v => parseInt(v, 10));
    }

    return values;
  }
}
