import { Injectable } from '@angular/core';
import { RepositoryObserver } from './repository-observer';
import { RepositoryService, Databases } from './repository.service';
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from '../models/settings';

@Injectable()
export class AppSettingsService implements RepositoryObserver {
  public appSettings: BehaviorSubject<AppSettings> = new BehaviorSubject(new AppSettings());
  appSettingsLoaded: boolean = false; // track whether we have cached version for app settings
  appSetting: AppSettings;

  constructor(private repository: RepositoryService) {
    this.repository.registerObserver(this);
  }

  notify(objectType: string): void {
    if (objectType === AppSettings.type) {
      this.appSettingsLoaded = false;
    }
  }

  /**
   * update app Settings
   */
  updateAppSettings(settings: AppSettings): Promise<AppSettings> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(settings, AppSettings.type, Databases.templatesDb)
        .then((pouchObject) => {
          const updatedAppSettings: AppSettings = JSON.parse(JSON.stringify(pouchObject));
          this.appSettingsLoaded = false;
          resolve(updatedAppSettings);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  /**
   * get app settings
   */
  loadAppSettings(settingsId: string, useSettingsCache: boolean = true): Promise<AppSettings> {
    return new Promise((resolve, reject) => {
      if (this.appSettingsLoaded && useSettingsCache) {
        console.log('already loaded app settings');
        resolve(this.appSetting);
      } else {
        this.repository.fetchObject(AppSettings.type, settingsId, AppSettings.fields, Databases.templatesDb).then((result) => {
          const appSettings: AppSettings = result.docs.map((doc: any) => this.mapObjectToAppSettings(doc))[0];
          this.appSettingsLoaded = true;
          this.appSetting = appSettings;
          this.appSettings.next(appSettings);
          resolve(appSettings);
        }).catch((err) => {
          console.log('An error occurred', err);
          reject(err);
        });
      }

    });
  }

  private mapObjectToAppSettings(object: any): AppSettings {
    let appSettings: AppSettings = new AppSettings();
    return appSettings = { ...object };
  }
}
