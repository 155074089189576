import { AfterViewInit, ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { DatePipe } from "@angular/common";
import { MatDialog, MatDialogRef, } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

import { AnswerSheet } from '../models/answer-sheet';
import { Attachment } from '../models/attachment';
import { Facility } from '../models/facility';
import { Patient } from '../models/patient';
import { ReportSeries } from '../models/report-series';
import { TemplateSeries } from '../models/series';
import { AppSettings } from '../models/settings';
import { StampTemplate, TemplateTypes } from '../models/stamp-template';
import { User, UserRoles } from '../models/user';

import { AttachmentService } from "../services/attachment.service";
import { AuthenticationService } from "../services/authentication.service";
import { DetectionStatus, QRCode } from "../services/detect-border-qr.service";
import { ExtractResultsService } from "../services/extract-results.service";
import { FacilityService } from "../services/facility.service";
import { PatientService } from "../services/patient.service";
import { ReportSeriesService } from "../services/report-series.service";
import { RepositoryObserver } from "../services/repository-observer";
import { AppTypes, RepositoryService } from "../services/repository.service";
import { AppSettingsService } from "../services/settings.service";
import { SheetService } from "../services/sheet.service";
import { TemplateSeriesService } from "../services/template-series.service";
import { TemplateService } from "../services/template.service";
import { ComponentCanDeactivate } from "../services/unsaved-changes.guard";
import { DetectBorderServiceQR } from "./../services/detect-border-qr.service";

import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';
import { FormatPhone } from "../pipes/formatPhone";
import { FindScannedRecords } from '../pipes/getSeriesScannedReportsPipe';

import {
  CameraComponent, ConfirmAddDuplicateScanDialogComponent, ConfirmDiscardDialogComponent, ExitScanDialogComponent, PatientChangedDialog,
  SheetEditComponent, SheetResultsEditComponent
} from "./components";

export enum ViewModes { view, edit, camera, results, manual } // view modes for edit view

@Component({
  selector: "paper-sheets-component",
  templateUrl: "sheets.component.html",
  providers: [FindScannedRecords, DatePipe, FormatPhone],
})

export class SheetsComponent implements OnInit, RepositoryObserver, ComponentCanDeactivate, OnDestroy, AfterViewInit {
  env = environment;
  debugMode: boolean = this.env.debugMode; // keep track of whether we are in debugMode
  appTypeEnum = AppTypes;
  loadingAppSettings: boolean = true;
  loadingFacility: boolean = true;
  loadingTemplates: boolean = true; // show templates loading spinner
  loadingSeries: boolean = false; // show series loading spinner
  loadingPatients: boolean = false;
  loadingPatientRecords: boolean = false;
  loadingReportAnswerSheets: boolean;

  loggedInUser: User;
  currentSheet: AnswerSheet = null; // track if we are dealing with a specific answer sheet
  _currentSheetTemplate: StampTemplate = null; // keep track of the template loaded for the current answer sheet
  set currentSheetTemplate(template: StampTemplate) {
    this.zone.runOutsideAngular(() => {
      if (template) {
        this.extractResultsService.loadAndWarmModel(template.handwritingFieldHeight, template.handwritingFieldWidth);
      }
    });
    this._currentSheetTemplate = template;
  }
  get currentSheetTemplate(): StampTemplate { return this._currentSheetTemplate; }
  selectedFacility: Facility = null; // keep track of the facility selected for the current answer sheet
  currentAttachment: Attachment = null; // keep track of the images for this record
  patientRecords: AnswerSheet[] = []; // filtered patient records
  reportSeriesRecords: AnswerSheet[] = []; // filtered report series records
  farmRecords: AnswerSheet[] = []; // filtered farm records by penNumber
  filteredReportSeriesList: ReportSeries[] = []; // filtered patient report series
  serialNumberRecords: AnswerSheet[] = [];
  teamNumberRecords: AnswerSheet[] = [];

  templates: StampTemplate[] = new Array<StampTemplate>(); // store loaded templates
  templatesEditForm: StampTemplate[] = new Array<StampTemplate>(); // store loaded templates for edit form
  patients: Patient[]; // store loaded patients
  facilities: Facility[]; // facilities
  currentPatient: Patient;
  templateSeries: TemplateSeries[]; // store loaded template series
  currentTemplateSeries: TemplateSeries;
  currentReportSeries: ReportSeries;
  facility: Facility;
  appSettings: AppSettings = new AppSettings();
  isCHAUser: boolean;
  currentSheetPenNumber: number = null;

  processingSheetUpdate: boolean = false; // track process of updating the sheet

  hasChanges: boolean = false; // track whether our current sheet has changes

  viewMode: ViewModes = null; // track current view of UI
  public ViewModes = ViewModes; // make enum available in template

  newScan: boolean = false;
  newRecord: boolean = true; // track whether we are dealing with a new record
  backupSheet: AnswerSheet; // keep a backup while editing records

  qrCode: QRCode; // keep information on detected QRcode

  detectBorderSubscription: Subscription; // subscription to detect border service results

  // child components for us to keep track of
  @ViewChild(SheetEditComponent, { static: false })
  sheetEditComponent: SheetEditComponent;
  @ViewChild(SheetResultsEditComponent, { static: false })
  sheetResultsEditComponent: SheetResultsEditComponent;
  @ViewChild(CameraComponent, { static: false })
  cameraComponent: CameraComponent;

  // dialog reference
  dialogRef: MatDialogRef<any>; // dialog for confirm save and exit

  // booleans to control UI state
  detectQRInProgress: boolean = false;
  navigatedUsingNextButton: boolean = false;
  firstTimeLogin: boolean = this.auth.firstTimeLogin;
  firstTimeLoginEndTime: string;
  disablePatientField: boolean = false;

  // debug
  @ViewChild("fileImage", { static: false }) fileImageElement: any;
  fileImage;
  imageSrc;
  inputWidth;
  inputHeight;
  detectedRectArea;
  totalContoursDetected;

  // timer handles for notify
  sheetHandle;
  templateHandle;
  facilityHandle;
  patientHandle;
  seriesHandle;
  appSettingsHandle;
  reportSeriesHandle;

  // dimensions
  dimensions: {
    maxWidth: number;
    maxHeight: number;
    templateScale: number;
  } = {
      maxWidth: Math.min(500, window.innerWidth),
      maxHeight: Math.min(600, window.innerHeight - 27), // allow for size of buttons bar
      templateScale: 0.9, // scale factor for fitting canvases to video, out of max 3
    };
  groupedSheetsInSeries: AnswerSheet[];
  hideCameraBack: boolean = false;
  loadingReportSeries: boolean;
  userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // get user timezone

  // private detectBorderService: DetectBorderService,
  constructor(
    private zone: NgZone,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private sheetService: SheetService,
    private auth: AuthenticationService,
    public repository: RepositoryService,
    private patientService: PatientService,
    private templateService: TemplateService,
    private facilityService: FacilityService,
    private attachmentService: AttachmentService,
    private appSettingsService: AppSettingsService,
    private findScannedRecords: FindScannedRecords,
    private reportSeriesService: ReportSeriesService,
    private detectBorderServiceQR: DetectBorderServiceQR,
    private extractResultsService: ExtractResultsService,
    private templateSeriesService: TemplateSeriesService
  ) {
    // check the view mode from url
    this.route.paramMap.subscribe(async (params) => {
      // console.log('navigation', params, params.get('viewMode'));

      switch (params.get("viewMode")) {
        case "view":
          console.log("navigation ViewModes.view");
          // for now redirect to add if we load this view from login
          if (
            !this.currentSheet &&
            (!params.get("sheetId") || params.get("sheetId") === "")
          ) {
            console.log(
              "Navigating to sheet view but no sheet selected, redirecting to add sheet"
            );
            this.showAddNewPatient();
          } else {
            this.showViewAnswerSheet(params.get("sheetId")); // get answerSheet to view
          }
          break;
        case "camera":
          console.log("navigation ViewModes.camera");
          if (!this.currentSheet) {
            console.log(
              "Navigating to camera view but no sheet selected, redirecting to add sheet"
            );
            this.showAddNewPatient();
          } else {
            this.showCamera(); // navigate to camera view
          }
          break;
        case "edit":
          console.log("navigation ViewModes.edit");
          // for now redirect to add if we load this view from login
          if (!this.currentSheet) {
            this.setDefaultTemplate();
            console.log(
              "Navigating to sheet edit but no sheet selected, redirecting to add sheet"
            );
            this.showAddNewPatient();
          }
          break;
        case "add":
          console.log("navigation ViewModes.add");
          this.showAddNewPatient();
          break;
        case "nextRecord":
          console.log("navigation ViewModes.nextRecord");
          this.navigatedUsingNextButton = true;
          // check if app settings loaded
          if (!this.appSettings || !this.appSettings._id) {
            await this.getAppSettings();
          }
          // check if patients loaded
          if (!this.patients) {
            await this.getPatients();
          }
          // check if templates loaded
          if (!this.templates || this.templates.length === 0) {
            await this.getTemplates();
          }

          if (!this.appSettings.patientSettings.enablePatientObjects) {
            this.currentPatient = null;
          }

          // set current patient using passed id
          if (params.get("currentPatientId")) {
            this.currentPatient = this.findPatientById(
              params.get("currentPatientId")
            );
            // get records for current patient
            await this.getPatientRecords(params.get("currentPatientId"));
          }
          // set current template to scan using passed id
          if (params.get("templateToScanId")) {
            this.currentSheetTemplate = this.findTemplateById(
              params.get("templateToScanId")
            );
          }
          // set current report series using passed id
          if (params.get("reportSeriesToScanId")) {
            if (
              !this.filteredReportSeriesList ||
              this.filteredReportSeriesList.length < 1
            ) {
              // get a single report-series one
              await this.getReportSeriesById(
                params.get("reportSeriesToScanId")
              );
            } else {
              this.currentReportSeries = this.findReportSeriesById(
                params.get("reportSeriesToScanId")
              );
            }
          } else {
            this.navigatedUsingNextButton = false;
          }
          // get current serialNumber
          let serialNumber = null;
          // set serial number for current scan using passed id
          if (params.get('serialNumber')) {
            serialNumber = +params.get('serialNumber');
            // get list of report series with this serial number (useful for deploys where skip edit screen set to true)
            await this.getReportSeriesBy('serialNumber', serialNumber);
          }
          this.scanNextRecord(this.currentSheetTemplate, serialNumber, true);
          break;
        default:
          console.log("navigation default");
          this.showAddNewPatient();
          break;
      }
    });

    // for first time login subscribe to sync down count
    if (this.firstTimeLogin) {
      this.repository.pendingDownSumChange.subscribe((change) => {
        // if count is 0 consider sync down as completed
        if (change === 0) {
          const completed = performance.now();
          this.firstTimeLoginEndTime =
            ((completed - this.auth.firstTimeLoginStart) / 1000 / 60).toFixed(2) + ' Minutes';
        }
      });
    }

  }

  /** functions to run once view is initialized */
  ngAfterViewInit(): void {
    // warmup the model if it hasn't already been loaded
    let hwHeight = 0, hwWidth = 0;
    if (this.currentSheetTemplate) { hwHeight = this.currentSheetTemplate.handwritingFieldHeight; hwWidth = this.currentSheetTemplate.handwritingFieldWidth; }
    this.zone.runOutsideAngular(() => {
      this.extractResultsService.loadAndWarmModel(hwHeight, hwWidth);
    });
  }

  /** functions to run once when component is initialized */
  ngOnInit() {
    this.repository.registerObserver(this); // register to be updated if new data comes in
    this.loggedInUser = this.auth.getUser();

    this.getAppSettings(); // load app settings from db
    this.getTemplates(); // load templates from db
    this.getFacilities(); // load facilities from db
  }

  /**
   * Load app required data, depending on settings
   */
  loadData() {
    if (this.appSettings.recordSettings.templatesInSeries) {
      this.loadingSeries = true;
      this.getTemplateSeries(); // load template series from db
    }
    if (this.appSettings.patientSettings.enablePatientObjects) {
      this.loadingPatients = true;
      this.getPatients(); // load patients from db
    }
  }

  /** Listen for notifications from pouch */
  notify(objectType: string): void {
    if (this.debugMode) { console.log('Sheets notify:', objectType); }

    if (objectType === AnswerSheet.type) {
      clearTimeout(this.sheetHandle);
      this.sheetHandle = setTimeout(() => {
        if (this.debugMode) {
          console.log("get updated sheets");
        }
        if (this.currentPatient) {
          this.filterPatientRecords(this.currentPatient._id);
        }
      }, 3000);
    } else if (objectType === StampTemplate.type) {
      clearTimeout(this.templateHandle);
      this.templateHandle = setTimeout(() => {
        if (this.debugMode) {
          console.log("get updated templates");
        }
        this.getTemplates(false);
      }, 3000);
    } else if (objectType === Facility.type) {
      clearTimeout(this.facilityHandle);
      this.facilityHandle = setTimeout(() => {
        if (this.debugMode) {
          console.log("get updated facilities");
        }
        this.getFacilities();
      }, 3000);
    } else if (objectType === Patient.type) {
      clearTimeout(this.patientHandle);
      this.patientHandle = setTimeout(() => {
        if (this.debugMode) {
          console.log("get updated patients");
        }
        this.getPatients(false);
      }, 3000);
    } else if (objectType === TemplateSeries.type) {
      clearTimeout(this.seriesHandle);
      this.seriesHandle = setTimeout(() => {
        if (this.debugMode) {
          console.log("get updated series");
        }
        this.getTemplateSeries();
      }, 3000);
    } else if (objectType === AppSettings.type) {
      clearTimeout(this.appSettingsHandle);
      this.appSettingsHandle = setTimeout(() => {
        if (this.debugMode) {
          console.log("get app settings");
        }
        this.getAppSettings();
      }, 3000);
    } else if (objectType === ReportSeries.type) {
      clearTimeout(this.reportSeriesHandle);
      this.reportSeriesHandle = setTimeout(() => {
        if (this.debugMode) {
          console.log("get updated report-series");
        }
        if (this.currentPatient) {
          this.filterPatientRecords(this.currentPatient._id);
        }
      }, 3000);
    }
  }

  /** guard against browser refresh, close, etc. */
  @HostListener("window:beforeunload")
  canDeactivate(): Observable<boolean> | boolean {
    if (this.dirty) {
      return false;
    }
    return true;
  }

  /** Find out whether any of our current child components have unsaved changes */
  get dirty() {
    // form => form dirty
    // camera => newRecord
    // results => hasChanges
    return (
      this.hasChanges ||
      (this.viewMode === ViewModes.edit &&
        this.sheetEditComponent &&
        this.sheetEditComponent.dirty) ||
      (this.viewMode === ViewModes.results &&
        this.sheetResultsEditComponent &&
        this.sheetResultsEditComponent.dirty)
    );
  }

  /** Restore backup answersheet */
  restoreBackup() {
    if (this.debugMode) {
      console.log("SheetsComponent: restoring backup");
    }
    if (!this.backupSheet) {
      console.error(
        "SheetsComponent: unable to restore backup answer sheet, backup does not exist"
      );
      return;
    }
    this.patientRecords = this.patientRecords.filter(
      (h: AnswerSheet) => h._id !== this.currentSheet._id
    );
    this.patientRecords.push(this.backupSheet);
  }

  /** Function get answersheet given its id */
  showViewAnswerSheet(sheetId: string): void {
    if (!sheetId) { return; }
    if (this.currentSheet && this.currentSheet._id === sheetId && this.currentSheetTemplate && this.currentPatient) { return };
    this.sheetService.getSheet(sheetId).then((sheet: AnswerSheet) => {
      try {
        if (this.appSettings.recordSettings.enableReportSeries) {
          // set report-series if its not set
          if (!this.currentReportSeries) {
            this.getReportSeriesById(sheet.reportSeriesId);
          }
        }

        this.currentSheetTemplate = this.findTemplateById(sheet.stampTemplateId);  // set current template  
        this.currentSheetPenNumber = this.appSettings && this.appSettings.uiViewSettings.farmAlertWorkflow ? sheet.penNumber : null;

        if (this.appSettings.patientSettings.enablePatientObjects) {
          this.currentPatient = this.findPatientById(sheet.patientId); // set current patient
          this.getPatientRecords(sheet.patientId); // get patient records
        }

        this.showView(sheet, false); // navigate to view mode
      } catch (error) {
        // if any error occurs setting the record data, send user to new record edit page
        if (this.debugMode) {
          console.log("SheetsComponent:", error);
        }
        this.showAddNewPatient();
      }
    })
      .catch((error: any) => {
        if (this.debugMode) {
          console.log("SheetsComponent:", error);
        }
        this.snackBar.open("Error getting scanned record.");
      });
  }

  /** function to find template by its ID */
  findTemplateById(templateId: string): StampTemplate {
    let template = this.templates.find(
      (template) => template._id === templateId
    );
    return template;
  }

  /** function to find a patient by its ID */
  findPatientById(patientId: string): Patient {
    let patient = this.patients.find((patient) => patient._id === patientId);
    return patient;
  }

  /** function to find a report series given its ID */
  findReportSeriesById(reportSeriesId: string): ReportSeries {
    if (!reportSeriesId) {
      return null;
    }
    let reportSeries = this.filteredReportSeriesList.find(
      (series) => series._id === reportSeriesId
    );
    return reportSeries;
  }

  /**
   * Function return a series with same provided serial number
   * @param serialNumber serial number
   * @returns { ReportSeries }
   */
  findReportSeriesBySerialNumber(serialNumber: number): ReportSeries {
    if (!serialNumber) {
      return null;
    }
    return this.filteredReportSeriesList.find(
      (series) => series.serialNumber === serialNumber
    );
  }

  /** scan next template in series */
  scanNextTemplateInSeries() {
    this.navigatedUsingNextButton = true;
    const previousReportSeriesId = this.currentSheet.reportSeriesId;
    this.templateSeriesService.getSeriesById(this.currentSheetTemplate.seriesId)
      .then(async series => {
        // make a copy of the original series then update it with facility filtered templates seriesTemplateIds
        const series_copy = new TemplateSeries(series);
        series_copy.templateIds =
          this.templateSeriesService.filterTemplateIdsInSeries(
            this.templatesEditForm,
            series_copy
          );
        this.currentTemplateSeries = series;

        const nextTempId = this.templateSeriesService.getNextTemplateIdInSeries(this.currentSheetTemplate, series, series_copy);
        this.currentSheetTemplate = this.templates.find(t => t._id === nextTempId);

        // set the new current sheet with temp and patient ids
        this.currentSheet = new AnswerSheet();
        this.currentSheet.stampTemplateId = nextTempId;
        this.currentSheet.reportSeriesId = this.appSettings.recordSettings.enableReportSeries ? previousReportSeriesId : null;

        if (this.appSettings.recordSettings.enableReportSeries) {
          if (!this.currentReportSeries || this.currentReportSeries._id !== previousReportSeriesId) {
            this.currentReportSeries = this.findReportSeriesById(previousReportSeriesId);

            if (!this.currentReportSeries) {
              try {
                this.currentReportSeries = await this.reportSeriesService.getSeriesById(previousReportSeriesId);
              } catch (error) {
                this.snackBar.open('Error getting report series by id', 'Error', { duration: 6000 });
              }
            }
          }

          // pre-fill current sheet data with report-series data
          this.prefillScanRecordWithData(this.currentReportSeries);
        }

        if (this.currentSheetTemplate.templateType === TemplateTypes[0].shortName) {
          this.currentSheet.otherAnswers = {
            genexpert: { specimenId: "", result: "", facility: "" },
          };
        }

        // skip edit screen if skipEditScreenOnNextRecord set to true in settings
        this.showEdit(this.currentSheet, true, this.currentSheetTemplate, this.navigatedUsingNextButton);
      }).catch((error) => console.log("Error loading series:", error));
  }

  /** Scan the next farm record for same pen (Farm Workflow) */
  scanNextFarmRecord() {
    this.navigatedUsingNextButton = true;
    const previousEncounterDate = new Date(this.currentSheet.encounterDate);

    this.currentSheet = new AnswerSheet();
    this.currentSheet.patientId = this.currentPatient
      ? this.currentPatient._id
      : null;
    this.currentSheet.penNumber = this.currentSheetPenNumber;
    this.currentSheet.stampTemplateId = this.currentSheetTemplate._id;

    // set next encounter date as next day, if prev encounter is today next date is today
    this.currentSheet.encounterDate =
      new Date().setHours(0, 0, 0, 0) ===
        previousEncounterDate.setHours(0, 0, 0, 0)
        ? new Date()
        : new Date(
          previousEncounterDate.setDate(previousEncounterDate.getDate() + 1)
        );

    this.showEdit(this.currentSheet, true, this.currentSheetTemplate);
  }

  /** Fetch records by pen */
  async getFarmRecords() {
    try {
      if (this.currentSheet.penNumber) {
        this.farmRecords = await this.sheetService.getPenSheets(
          this.currentSheet.penNumber
        );
      }
    } catch (error) {
      if (this.debugMode) {
        console.log("Error getting farm records:", error);
      }
    }
  }

  /** Add a new scan for same patient */
  scanNextRecord(
    stampTemplate: StampTemplate = this.templates[0],
    serialNumber: number = null,
    preFillSheetData: boolean = false
  ) {
    this.currentSheet = new AnswerSheet();
    this.currentSheet.patientId = this.currentPatient
      ? this.currentPatient._id
      : null;
    this.currentSheet.stampTemplateId = stampTemplate._id;
    this.currentSheetTemplate = stampTemplate;
    if (serialNumber) {
      this.currentSheet.serialNumber = serialNumber;
    }
    if (this.appSettings.recordSettings.enableReportSeries) {
      this.currentSheet.reportSeriesId = this.currentReportSeries._id;
    }
    this.selectedFacility =
      this.appSettings.uiViewSettings.migoriWorkflow &&
        this.facility.facilityOptions.isResearchAssistantFacility
        ? this.facilities.find(
          (f) => f._id === this.currentPatient.createFacility
        )
        : null;
    if (
      this.currentSheetTemplate &&
      this.currentSheetTemplate.templateType === TemplateTypes[0].shortName
    ) {
      this.currentSheet.otherAnswers = {
        genexpert: { specimenId: "", result: "", facility: "" },
      };
    }

    if (preFillSheetData || this.currentSheetTemplate._id === this.appSettings.farmSettings.incomeExpenseTemplate) {
      this.prefillScanRecordWithData(this.currentReportSeries);
    }

    // skip edit screen if skipEditScreenOnNextRecord set to true in settings
    if (this.appSettings.uiViewSettings.skipEditScreenOnNextRecord) {
      this.currentSheet.encounterDate = this.datePipe.transform(this.currentReportSeries.seriesStartDate.toLocaleString('en-US', { timeZone: this.userTimezone }), 'MM/dd/YYYY');
      if (this.appSettings.uiViewSettings.polioWorkflow) {
        this.currentSheet.customBeforeFieldsData.teamNumber = this.currentReportSeries.teamNumber;
        this.currentSheet.customBeforeFieldsData.immunizationDate = this.currentReportSeries.seriesStartDate;
      }
      // update url viewMode param
      if (this.route.snapshot.paramMap.get("viewMode") !== "camera") {
        this.router.navigate(["/new_record", { viewMode: "camera" }]);
      }
      this.hideCameraBack = true;
      this.showCamera(); // proceed to camera
    } else {
      this.showEdit(this.currentSheet, true, this.currentSheetTemplate);
    }
  }

  /** Display list view */
  showList() {
    if (this.debugMode) {
      console.log("SheetsComponent: show list");
    }
    this.exitScan().then((exitScanConfirmed) => {
      // if there are no pending saved changes navigate
      if (exitScanConfirmed) {
        this.router.navigate(["/scanned_records/list"]);
      } // else do nothing
    });
  }

  /** Display edit form with selected record */
  showEdit(sheet: AnswerSheet, newRecord: boolean = true, currentTemplate = null, navigatedUsingNextButton: boolean = false) {
    if (this.debugMode) { console.log('SheetsComponent: show add/edit form'); }
    // update url viewMode param
    if (
      !(
        this.route.snapshot.paramMap.get("viewMode") === "edit" ||
        this.route.snapshot.paramMap.get("viewMode") === "nextRecord"
      )
    ) {
      this.router.navigate(["/new_record", { viewMode: "edit" }]);
    }
    this.newRecord = newRecord;
    this.hideCameraBack = false;
    if (!sheet) {
      sheet = new AnswerSheet();
    } // editing and empty sheet, show add form instead
    if (!this.newRecord) {
      this.backupSheet = new AnswerSheet(sheet);
    } // make a backup if editing existing record
    this.currentSheetTemplate = currentTemplate;
    this.currentSheet = sheet; // edit a copy of sheet
    this.currentSheet.stampTemplateId = this.currentSheetTemplate ? this.currentSheetTemplate._id : '';
    // if navigating from view record screen using the navigatedUsingNextButton and skipEditScreenOnNextRecord set to true, show camera screen; otherwise show edit screen
    if (navigatedUsingNextButton && this.appSettings.uiViewSettings.skipEditScreenOnNextRecord) {
      // update url viewMode param
      if (this.route.snapshot.paramMap.get('viewMode') !== 'camera') { this.router.navigate(['/new_record', { viewMode: 'camera' }]); }
      this.hideCameraBack = true;
      this.showCamera(); // proceed to camera
      this.cd.detectChanges();
    } else {
      this.viewMode = ViewModes.edit;
      this.cd.detectChanges();
    }
  }

  /** Display view mode */
  showView(sheet: AnswerSheet, newRecord: boolean = true) {
    if (this.debugMode) {
      console.log("SheetsComponent: navigate to view record");
    }

    // update url viewMode param
    if (this.route.snapshot.paramMap.get("viewMode") !== "view") {
      this.router.navigate([`/new_record`, { viewMode: "view" }]);
    }

    this.newRecord = newRecord;
    this.currentSheet = sheet;
    if (!newRecord && !this.newScan) {
      if (this.appSettings.recordSettings.enableReportSeries) {
        this.getReportSeriesRecords(this.currentSheet.reportSeriesId);
      }
      this.getAttachment();
    } // only get attachment if its an old record and no re-scan taken
    this.viewMode = ViewModes.view;
  }

  /** Display add form but deselect current patient */
  showAddNewPatient() {
    if (this.debugMode) {
      console.log("SheetsComponent: showing add form for new patient");
    }
    this.navigatedUsingNextButton = false;
    this.exitScan().then((exitScanConfirmed) => {
      if (exitScanConfirmed) {
        this.reset(); // reset current items in memory
        this.hasChanges = false;
        this.showEdit(this.currentSheet, true, this.currentSheetTemplate);
        this.cd.detectChanges();
      } // else do nothing
    });
  }

  /** Function to reset all the current set items */
  reset() {
    this.currentPatient = null;
    this.selectedFacility = null;
    this.currentReportSeries = null;
    if (this.currentSheetTemplate && this.currentSheetTemplate._id !== this.appSettings.recordSettings.defaultTemplate) {
      this.currentSheetTemplate = null;
    }
    this.navigatedUsingNextButton = false;
    this.currentSheet = new AnswerSheet();
    this.setDefaultTemplate(); // set default template
    if (this.sheetEditComponent) { this.sheetEditComponent.reset(); }
  }

  /** Edit form has updated an existing record, save it and view */
  sheetEditUpdate() {
    if (this.debugMode) {
      console.log("SheetsComponent: update existing sheet and view");
    }
    this.updateSheet(this.currentSheet)
      .then((updatedSheet) => {
        // save images
        if (this.newRecord || this.newScan) {
          this.currentAttachment.answerSheetId = updatedSheet._id;
          this.currentAttachment.sharedFacilities =
            updatedSheet.sharedFacilities;
          this.updateAttachment(this.currentAttachment)
            .then(() => console.log("Attachment updated"))
            .catch((err) => console.log("Error updating attachment", err));
        }

        this.currentSheet = updatedSheet;
        this.showView(this.currentSheet, this.newRecord);
      })
      .catch((error) => {
        this.snackBar.open("Error updating answer sheet:" + error, "Error", {
          duration: 6000,
        });
      });
  }

  /** Move on from sheet edit to camera */
  sheetEditNavigateNext() {
    if (this.debugMode) {
      console.log("SheetsComponent: navigate from sheet edit to camera");
    }

    let duplicateDateTime; let duplicateDate; let duplicateType;
    const currentEncounter = this.datePipe.transform(this.currentSheet.encounterDate, 'MM/dd/YYYY');

    if (
      this.currentSheetTemplate.requireEncounterTime ||
      (this.currentSheet.encounterTime &&
        this.currentSheet.encounterTime !== "")
    ) {
      // check if duplicate for date and time
      duplicateDateTime = this.patientRecords.find(
        (sheet) =>
          sheet.patientId === this.currentSheet.patientId &&
          new Date(sheet.encounterDate).getTime() ===
          new Date(currentEncounter).getTime() &&
          sheet.stampTemplateId === this.currentSheet.stampTemplateId &&
          sheet.encounterTime === this.currentSheet.encounterTime
      );

      if (duplicateDateTime) {
        duplicateType = "dateTime";
      }
    } else {
      // check if duplicate for date only
      duplicateDate = this.patientRecords.find(
        (sheet) =>
          sheet.patientId === this.currentSheet.patientId &&
          new Date(sheet.encounterDate).getTime() ===
          new Date(currentEncounter).getTime() &&
          sheet.stampTemplateId === this.currentSheet.stampTemplateId
      );

      if (duplicateDate) {
        duplicateType = "date";
      }
    }

    let duplicate = duplicateDateTime ? duplicateDateTime : duplicateDate;

    // for blood-safe deploy check if record is of the same serial number & template
    if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
      duplicate = null;
      const records: AnswerSheet[] = this.serialNumberRecords.length > 0 ? this.serialNumberRecords : this.patientRecords;
      const duplicates = records.find(sheet =>
      (
        (sheet.stampTemplateId === this.currentSheet.stampTemplateId) &&
        (sheet.serialNumber === this.currentSheet.serialNumber)
      ));

      if (duplicates) {
        duplicateType = 'serialNumber';
        duplicate = true;
      }
    }

    if (this.appSettings.uiViewSettings.farmAlertWorkflow) {
      let duplicateRecord = this.farmRecords.find(sheet => sheet.stampTemplateId === this.currentSheet.stampTemplateId &&
        this.datePipe.transform(sheet.encounterDate, 'MM/dd/YYYY') === currentEncounter && sheet.penNumber === this.currentSheet.penNumber)

      if (duplicateRecord) {
        duplicateType = !this.currentSheet.penNumber
          ? "income_expense"
          : "penNumber";
        duplicate = true;
      }
    }

    // for polio a duplicate is a record with the same (team #, encounter-date & template (A))
    if (this.appSettings.uiViewSettings.polioWorkflow) {
      const duplicates = this.teamNumberRecords.find(
        (sheet) =>
          sheet.stampTemplateId === this.currentSheet.stampTemplateId &&
          this.datePipe.transform(
            sheet.customBeforeFieldsData?.immunizationDate,
            "YYYY-MM-dd"
          ) ===
          this.datePipe.transform(
            this.currentSheet.customBeforeFieldsData?.immunizationDate,
            "YYYY-MM-dd"
          ) &&
          sheet.customBeforeFieldsData?.teamNumber ===
          this.currentSheet.customBeforeFieldsData?.teamNumber
      );

      if (duplicates) {
        duplicateType = "teamNumber";
        duplicate = true;
      }
    }

    // for mhGAP a duplicate is a record with same (serial #, encounter-date & template)
    if (this.appSettings.uiViewSettings.mhGapWorkFlow) {
      const duplicates = this.serialNumberRecords.find(sheet =>
      (
        (sheet.stampTemplateId === this.currentSheet.stampTemplateId) &&
        (sheet.serialNumber === this.currentSheet.serialNumber)
      ));

      if (duplicates) {
        duplicateType = "serialNumber";
        duplicate = true;
      }
    }

    if (duplicate) {
      this.dialogRef = this.dialog.open(
        ConfirmAddDuplicateScanDialogComponent,
        {
          maxWidth: "400px",
          data: {
            duplicateType: duplicateType,
            appSettings: this.appSettings,
          },
        }
      );
      this.dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          this.currentReportSeries =
            this.currentSheet.reportSeriesId &&
              this.currentSheet.reportSeriesId !== ""
              ? this.findReportSeriesById(this.currentSheet.reportSeriesId)
              : null;
          return;
        } else {
          this.hasChanges = this.hasChanges || this.sheetEditComponent.dirty;

          if (this.appSettings.recordSettings.enableReportSeries) {
            // for blood-safe flow find the best fit report series
            this.currentReportSeries = this.appSettings.uiViewSettings.safeBloodWorkflow || this.appSettings.uiViewSettings.mhGapWorkFlow ?
              this.getReportSeries(false) :
              this.initializeReportSeries(this.currentPatient, this.currentSheet);

            // update report-series with template A details
            if (this.appSettings.uiViewSettings.safeBloodWorkflow && this.appSettings.recordSettings.defaultTemplate === this.currentSheetTemplate._id) {
              this.currentReportSeries.seriesStartDate = this.currentSheet.encounterDate;
              this.currentReportSeries.seriesStartTime = this.currentSheet.encounterTime;
              this.currentReportSeries.facilityWard = this.currentSheet.facilityWard;
            }

            // update report-series with template A details (mhGapWorkFlow)
            if (this.appSettings.uiViewSettings.mhGapWorkFlow && this.appSettings.recordSettings.defaultTemplate === this.currentSheetTemplate._id) {
              this.currentReportSeries.seriesStartDate = this.currentSheet.encounterDate;
              // TO-DO: save the scanning organisation to the report series
            }

            this.currentSheet.reportSeriesId = this.currentReportSeries._id;  // update current series with report series id
          }
          this.showCamera(); // proceed
        }
      });
    } else {
      this.hasChanges = this.hasChanges || this.sheetEditComponent.dirty;

      if (this.appSettings.recordSettings.enableReportSeries) {
        this.currentReportSeries = this.getReportSeries();
        this.currentSheet.reportSeriesId = this.currentReportSeries._id;  // update current series with report series id
      }

      this.showCamera(); // proceed
    }
  }

  /** find and set current report series */
  getReportSeries(initialize: boolean = true): ReportSeries {
    if (!this.appSettings.recordSettings.enableReportSeries) {
      return null;
    }

    // if current report series exist return the series
    if (
      this.currentReportSeries &&
      this.currentReportSeries._id &&
      this.currentReportSeries._rev
    ) {
      return this.currentReportSeries;
    }

    // if current sheet has a set report series, return the series
    if (
      this.currentSheet.reportSeriesId &&
      this.currentSheet.reportSeriesId !== ""
    ) {
      const series = this.findReportSeriesById(
        this.currentSheet.reportSeriesId
      );
      if (series && series._id && series._rev) {
        return series;
      }
    }

    // check how many report series exist for a patient
    const reportSeriesCount = !this.filteredReportSeriesList
      ? 0
      : this.filteredReportSeriesList.length;
    if (this.debugMode) {
      console.log("SheetsComponent: Report series count:", reportSeriesCount);
    }

    let localCurrentReportSeries: ReportSeries;
    if (reportSeriesCount < 1) {
      if (this.debugMode) {
        console.log(
          "SheetsComponent: Report series: Less than one, initialize new series?",
          initialize
        );
      }
      if (!initialize) {
        return (localCurrentReportSeries = null);
      } // if initialize if false return null

      // no series object for this patient; count === 0, initialize report series
      localCurrentReportSeries = this.initializeReportSeries(
        this.currentPatient,
        this.currentSheet
      );
    } else {
      if (this.debugMode) {
        console.log(
          "SheetsComponent: Report series count: is greater than zero"
        );
      }
      const seriesObject = this.findReportSeriesBySerialNumber(
        this.currentSheet.serialNumber
      );

      // initialize if no series object was found
      if (!seriesObject && initialize) {
        if (this.debugMode) {
          console.log(
            "Find series by serial number not found! initializing a new object"
          );
        }
        localCurrentReportSeries = this.initializeReportSeries(
          this.currentPatient,
          this.currentSheet
        );
      } else {
        if (this.debugMode) {
          console.log("Series by serial number found a series object");
        }
        localCurrentReportSeries = seriesObject;
      }
    }

    return localCurrentReportSeries;
  }

  /** Function find if current scan template exist in a series object */
  isCurrentTemplateExistInSeries(reportSeries: ReportSeries): {
    reportSeries: ReportSeries;
    warnUser: boolean;
  } {
    // find if new scan template exist in the series
    const scannedRecords: string[] = this.findScannedRecords.transform(
      reportSeries._id,
      this.patientRecords
    ); // returns templates ids
    const scanTemplateExistInSeries = scannedRecords.includes(
      this.currentSheetTemplate._id
    );

    return { reportSeries: reportSeries, warnUser: scanTemplateExistInSeries };
  }

  /** function initialize a report series object
   * @returns { ReportSeries } */
  initializeReportSeries(patient: Patient, sheet: AnswerSheet): ReportSeries {
    const seriesStartDate = !this.appSettings.uiViewSettings.polioWorkflow
      ? sheet.encounterDate
      : sheet.customBeforeFieldsData?.immunizationDate;
    return new ReportSeries({
      _id: String(new Date().getTime()),
      patientId: patient ? patient._id : null,
      seriesStartDate: this.datePipe.transform(seriesStartDate.toLocaleString('en-US', { timeZone: this.userTimezone }), 'MM/dd/YYYY'),
      seriesStartTime: sheet.encounterTime,
      serialNumber: this.appSettings.recordSettings.showSerialNumberField ? sheet.serialNumber : null,
      facilityWard: sheet.facilityWard,
      sharedFacilities: [this.facility._id].concat(this.facility.facilityOptions.defaultShareTo)
    });
  }

  /** Retry camera stage */
  retryCamera() {
    if (this.debugMode) {
      console.log("SheetsComponent: retrying camera");
    }
    this.showCamera();
    this.cameraComponent.unPause();
  }

  /** Display camera mode */
  showCamera() {
    if (this.debugMode) {
      console.log("SheetsComponent: show camera");
    }
    if (!this.newRecord && !this.newScan) {
      this.getAttachment();
    }
    if (this.appSettings.uiViewSettings.cicWorkflow && this.isCHAUser) {
      this.hideCameraBack = !this.newRecord ? true : false;
    }
    this.viewMode = ViewModes.camera;
  }

  /** Display manual edit mode */
  showManualEdit() {
    if (this.debugMode) {
      console.log("SheetsComponent: show manual edit");
    }
    // update url viewMode param
    // if (this.route.snapshot.paramMap.get('viewMode') !== 'manual') { this.router.navigate(['/new_record', { viewMode: 'manual' }]); }
    this.viewMode = ViewModes.manual;
  }

  /** Results edit has asked for an update */
  resultsEditUpdate() {
    console.log("SheetsComponent: update existing sheet and view");

    // keep track of sheets we need to update
    let sheetsToUpdate: AnswerSheet[] = [];
    sheetsToUpdate.push(this.currentSheet);

    // check if record is new & CIC-work-flow & cha user & template A & new encounter date
    if (this.sheetResultsEditComponent.newEncounterDate) {
      // update the encounter dates of the other templates in series
      this.groupedSheetsInSeries.forEach((nSheet: AnswerSheet) => {
        nSheet.encounterDate = this.currentSheet.encounterDate;
      });
      sheetsToUpdate = sheetsToUpdate.concat(this.groupedSheetsInSeries);
    }

    // update all the sheets
    this.updateSheets(sheetsToUpdate).then((results) => {
      console.log('Successfully saved answer sheets: ', results);
      this.processingSheetUpdate = false;
      this.hasChanges = false;
      this.showView(this.currentSheet, this.newRecord);
    }).catch(error => {
      console.log('Error saving answer sheets');
      this.processingSheetUpdate = false;
      this.snackBar.open('Error saving answer sheets:' + error, 'Error', { duration: 6000 });
    });

  }

  /** update an array of answerSheets at once */
  updateSheets(sheetsToUpdate: AnswerSheet[]): Promise<string> {
    return new Promise((resolve, reject) => {
      // track if we have finished processing all sheets before we resolve
      const total = sheetsToUpdate.length;
      let updated = 0;

      sheetsToUpdate.forEach((sheet) => {
        // update the sheet
        this.updateSheet(sheet)
          .then((updatedSheet) => {
            // if the updated sheet is the current sheet, update the current sheet
            if (updatedSheet._id === this.currentSheet._id) {
              this.currentSheet = updatedSheet;

              if ((this.newRecord || this.newScan) && this.currentAttachment) {
                if (this.sheetService.sheets) {
                  this.sheetService.sheets.push(updatedSheet); // for new records update array in service
                }

                // if report series is enabled save report series
                if (this.appSettings.recordSettings.enableReportSeries) {
                  this.updateReportSeries(); // save report series
                }

                // if its a new scan or new record update the current attachment
                this.currentAttachment.answerSheetId = updatedSheet._id;

                this.updateAttachment(this.currentAttachment)
                  .then(() => {
                    console.log("Success updating attachment");
                    if (++updated === total) {
                      resolve("Success, updated " + updated + " sheets");
                    }
                  })
                  .catch((attachmentUpdateError) => {
                    console.log(
                      "Error updating attachment",
                      attachmentUpdateError
                    );
                    reject("Error updating attachment");
                  });
              } else {
                // not a new scan or record, no need to update attachment
                console.log("Success updating current answer sheet");
                if (++updated === total) {
                  resolve("Success, updated " + updated + " sheets");
                }
              }
            } else {
              console.log("Success updating answer sheet");
              if (++updated === total) {
                resolve("Success, updated " + updated + " sheets");
              }
            }
          })
          .catch((sheetUpdateError) => {
            console.log("Error updating sheet", sheetUpdateError);
            reject("Error updating answer sheet");
          });
      });
    });
  }

  /** Process updated answersheet from camera view */
  processScan(scannedImage: Blob) {
    if (this.debugMode) {
      console.log("SheetsComponent: Processing scan", scannedImage);
    }

    // clear all sheet errors before starting the extraction process
    this.currentSheet.sheetErrors = [];

    // init attachments object if missing
    this.newScan = true; // indicate its a new scan
    // only create a new attachment object when its a new record or the current attachment is undefined for older records
    if (this.newRecord || !this.currentAttachment) {
      this.createAttachmentObject();
    }
    if (this.debugMode) {
      console.log("SheetsComponent: adding fullFile to attachment");
    }
    this.currentAttachment._attachments["fullFile"] = {
      content_type: scannedImage.type,
      data: scannedImage,
    };

    // start border detection
    const url = URL.createObjectURL(scannedImage);
    const img = new Image();
    img.onload = () => {
      this.detectBorderQR(img);
    };
    img.src = url;
  }

  /** set attachment info into current attachment object then show results edit */
  setAttachmentShowResultsEdit(correctedImageBlob: Blob) {
    // set flags
    // this.extractionReady = true;
    this.detectQRInProgress = false;

    // convert canvas to blob and append file to sheet
    this.currentAttachment._attachments['file'] = { content_type: correctedImageBlob.type, data: correctedImageBlob };
    this.showResultsEdit();
  }

  /** Display results edit mode */
  showResultsEdit() {
    if (this.debugMode) { console.log('SheetsComponent: show results edit'); }
    // update url viewMode param
    this.viewMode = ViewModes.results;
  }

  /** Display results editing mode */
  showResults(sheet: AnswerSheet, newRecord: boolean = true) {
    if (this.debugMode) { console.log('SheetsComponent: show results edit from view screen'); }
    // update url viewMode param
    this.newRecord = newRecord;
    if (!sheet) {
      this.currentSheet = new AnswerSheet();
    } // editing and empty sheet, show add form instead
    if (!this.newRecord) {
      this.backupSheet = new AnswerSheet(sheet);
    } // make a backup if editing existing record
    this.currentSheet = new AnswerSheet(sheet); // edit a copy of sheet

    // TODO: Update this section with report series for cic-flow or move the functionality to another component
    // this.groupSheetsInSeries requires an array of answersheets that we are not loading in this component
    if (
      this.appSettings.uiViewSettings.cicWorkflow &&
      this.isCHAUser &&
      !this.newRecord &&
      this.templateSeries[0].templateIds.indexOf(
        this.currentSheet.stampTemplateId
      ) === 0
    ) {
      this.groupedSheetsInSeries = this.groupSheetsInSeries(
        this.backupSheet,
        []
      ); // pass in the sheets array
    }

    this.showResultsEdit();
  }

  /** get the sheets scanned from templates in templateSeries (B-F)
   * created within 10 minutes of sheet scanned from template A
   * and have the same report scan month and report scan year */
  groupSheetsInSeries(backupSheet, sheets: AnswerSheet[]) {
    const period = 10 * 60000; // period in ms; use 10 minutes
    // template A
    let reportMonth: any = this.sheetService.getAnswers(
      this.currentSheetTemplate.groups[0].groupTitle,
      backupSheet
    );
    let reportYear: any = this.sheetService.getAnswers(
      this.currentSheetTemplate.groups[1].groupTitle,
      backupSheet
    );

    reportYear =
      reportYear && reportYear.length !== 1 ? "Unknown" : reportYear[0];
    reportMonth =
      reportMonth && reportMonth.length !== 1 ? "Unknown" : reportMonth[0];
    // get the the answer sheets where the difference is less than {{period}} minutes and the report year and report month are the same
    return sheets.filter(
      (s) =>
        s._id !== this.currentSheet._id &&
        s.stampTemplateId !== this.currentSheet.stampTemplateId &&
        s.createdBy === this.currentSheet.createdBy &&
        new Date(s.dateAdded).getTime() >
        new Date(this.currentSheet.dateAdded).getTime() &&
        new Date(s.dateAdded).getTime() -
        new Date(this.currentSheet.dateAdded).getTime() <=
        period &&
        (reportYear === "Unknown" ||
          reportMonth === "Unknown" ||
          (moment(s.encounterDate).format("YYYY") === reportYear &&
            moment(s.encounterDate).format("MMMM") === reportMonth))
    ); // "28/unknown/2021 or 28/06/unknown" or 28/unknown/unknown
  }

  /** function set default settings */
  setDefaultTemplate() {
    if (!this.currentSheet) {
      return;
    } // check if current sheet is set

    // set the current template to the set default template
    if (
      this.appSettings.recordSettings.defaultTemplate &&
      !this.currentSheetTemplate &&
      this.templates.length > 0
    ) {
      this.currentSheetTemplate = this.templates.find(
        (t) => t._id === this.appSettings.recordSettings.defaultTemplate
      );
      this.currentSheet.stampTemplateId = this.currentSheetTemplate
        ? this.currentSheetTemplate._id
        : "";
    }
  }

  /** Show confirmations if edits have been made */
  exitScan() {
    if (this.debugMode) {
      console.log(
        "SheetsComponent: exiting scan process: new record?",
        this.newRecord,
        "dirty?",
        this.dirty
      );
    }
    return new Promise((resolve, reject) => {
      if (this.newRecord) {
        // new sheet
        if (!this.dirty) {
          if (this.debugMode) {
            console.log(
              "SheetsComponent: no changes to new sheet, exiting scan"
            );
          }
          return resolve(true);
        }

        this.confirmExitScan().then((exit) => {
          if (exit) {
            if (this.debugMode) {
              console.log("SheetsComponent: confirmed");
            }
            // save progress
            this.currentSheet.failed = true;
            this.updateSheet(this.currentSheet)
              .then((updatedSheet) => {
                // save images
                if (this.currentAttachment) {
                  this.currentAttachment.answerSheetId = updatedSheet._id;
                  this.currentAttachment.sharedFacilities =
                    updatedSheet.sharedFacilities;
                  this.updateAttachment(this.currentAttachment)
                    .then(() => console.log("Attachment updated"))
                    .catch((err) =>
                      console.log("Error updating attachment", err)
                    );
                } else {
                  console.log(
                    "SheetsComponent: there seems to be no attachment images to save for this sheet"
                  );
                }
              })
              .catch((error) => {
                console.log(
                  "SheetsComponent: Error updating answer sheet:",
                  error
                );
              });
            resolve(true);
          } else {
            if (this.debugMode) {
              console.log("SheetsComponent: not confirmed");
            }
            resolve(false);
          }
        });
      } else {
        // existing sheet
        if (!this.dirty) {
          if (this.debugMode) {
            console.log(
              "SheetsComponent: no changes to existing sheet, restore and exiting scan"
            );
          }
          if (this.viewMode === ViewModes.view) {
            // do nothing if in view or list mode (there is no sheet being edited & no backup available)
          } else {
            this.currentAttachment = null;
            this.restoreBackup(); // restore backup
          }
          return resolve(true);
        }

        this.confirmDiscardChanges().then((discard) => {
          if (discard) {
            // cancel editing
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  /** confirm discard changes */
  confirmDiscardChanges() {
    if (this.debugMode) {
      console.log("SheetsComponent: confirm discard changes");
    }
    return new Promise((resolve, reject) => {
      this.dialogRef = this.dialog.open(ConfirmDiscardDialogComponent);
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.debugMode) {
            console.log("SheetsComponent: confirmDiscardChanges true");
          }
          resolve(true);
        } else {
          if (this.debugMode) {
            console.log("SheetsComponent: confirmDiscardChanges false");
          }
          resolve(false);
        }
      });
    });
  }

  /** confirm exit scanning process, save and exit */
  confirmExitScan() {
    if (this.debugMode) {
      console.log(
        "SheetsComponent: confirm exiting scan process for new record"
      );
    }

    return new Promise((resolve, reject) => {
      this.dialogRef = this.dialog.open(ExitScanDialogComponent);
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.debugMode) {
            console.log("SheetsComponent: confirmExitScan true");
          }
          resolve(true);
        } else {
          if (this.debugMode) {
            console.log("SheetsComponent: confirmExitScan false");
          }
          resolve(false);
        }
      });
    });
  }

  /** function save new or update report series */
  updateReportSeries(): void {
    // no update of report series
    if (!this.currentReportSeries || (this.currentReportSeries._rev && this.appSettings.recordSettings.defaultTemplate !== this.currentSheetTemplate._id)) {
      if (this.debugMode) { console.log('SheetComponent: report series already saved or not set') };
      this.currentReportSeries = null;
      return;
    }

    this.reportSeriesService.updateReportSeries(this.currentReportSeries).then((reportSeries: ReportSeries) => {
      // while updating the report series, remove outdated series in memory
      if (this.currentReportSeries._rev) {
        this.reportSeriesService.reportSeries = this.reportSeriesService.reportSeries.filter(report => report._id !== reportSeries._id);
        this.filteredReportSeriesList = this.filteredReportSeriesList.filter(report => report._id !== reportSeries._id);
      }

      // update the arrays in memory with the latest report series
      this.reportSeriesService.reportSeries.push(reportSeries);
      this.filteredReportSeriesList.push(reportSeries);
      this.currentReportSeries = null;
    }).catch((error: any) => {
      if (this.debugMode) { console.log('SheetsComponent: Error updating report series', error); }
      this.snackBar.open('Error updating report series', 'Error', { duration: 6000 });
    });
  }

  /** Save updated sheet to database */
  updateSheet(sheet: AnswerSheet): Promise<AnswerSheet> {
    if (this.debugMode) {
      console.log("SheetsComponent: update answer sheet data to db");
    }
    return new Promise((resolve, reject) => {
      // if new answer sheet, give it an id
      if (!sheet._id) {
        sheet._id = String(new Date().getTime());
      }

      if (!sheet.sharedFacilities) {
        sheet.sharedFacilities = [];
      }
      // if Migori workflow && RA facility, then add the defaultShareTo to the list of sharedFacilities
      if (
        this.appSettings.uiViewSettings.migoriWorkflow &&
        this.facility.facilityOptions.isResearchAssistantFacility
      ) {
        sheet.sharedFacilities.push(
          this.selectedFacility._id,
          this.facility._id
        );
      }

      // format date
      sheet.encounterDate = moment(sheet.encounterDate).format("MM/DD/YYYY");

      // add to database
      this.sheetService
        .updateSheet(
          sheet,
          this.selectedFacility ? this.selectedFacility._id : null
        )
        .then((updatedSheet) => {
          // update sheets array with the latest record
          this.patientRecords = this.patientRecords.filter(
            (s: AnswerSheet) => s._id !== sheet._id
          );
          if (!updatedSheet.failed) {
            this.patientRecords.push(updatedSheet);
          }
          this.snackBar.open("Answer sheet updated", "Success", {
            duration: 6000,
          });
          resolve(updatedSheet);
        })
        .catch((error) => {
          if (this.debugMode) {
            console.log("SheetsComponent: Error updating answer sheet", error);
          }
          reject(error);
        });
    });
  }

  /** function set the current report series */
  setReportSeries(reportSeries: ReportSeries) {
    this.currentReportSeries = reportSeries;
  }

  /** get stamp templates from template service */
  async getTemplates(useCache = true) {
    await this.templateService
      .getTemplates(useCache)
      .then((templates: Array<StampTemplate>) => {
        this.loadingTemplates = false;
        this.templates = templates.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        this.setDefaultTemplate();
        this.filterTemplatesBySettings(this.appSettings);
      })
      .catch((error) => {
        if (this.debugMode) {
          console.log("SheetsComponent: Error loading templates: " + error);
        }
        const snackBarRef = this.snackBar.open(
          "Error loading template data, please try again.",
          "Retry"
        );
        snackBarRef.onAction().subscribe(() => {
          if (this.debugMode) {
            console.log("SheetsComponent: Retrying");
          }
          this.getTemplates();
        });
      });
  }

  /** filter out lab template if facility isn't a lab (TB workflow) */
  filterOutLabTemplate() {
    try {
      if (
        this.facility &&
        this.facility.facilityOptions &&
        !this.facility.facilityOptions.hasLab
      ) {
        console.log("Not a lab facility");
        this.templatesEditForm = this.templates.filter(
          (t) => t.templateType !== TemplateTypes[0].shortName
        );
      } else {
        this.templatesEditForm = this.templates;
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  /** filter templates based on the facility (safe blood workflow) */
  filterTemplateByFacilityType() {
    try {
      if (
        this.facility &&
        this.facility.facilityOptions &&
        this.facility.facilityOptions.isMainFacility
      ) {
        // show all templates
        console.log("Safe blood: is a RA facility");
        this.templatesEditForm = this.templates;
      } else if (
        this.facility &&
        this.facility.facilityOptions &&
        this.facility.facilityOptions.hasLab
      ) {
        // show lab templates only
        console.log("Safe blood: is a LAB facility");
        this.templatesEditForm = this.templates.filter(
          (t: StampTemplate) => t.templateType === TemplateTypes[2].shortName
        );
      } else {
        // filter out lab templates
        console.log("Safe blood: is a ward facility");
        this.templatesEditForm = this.templates.filter(
          (t: StampTemplate) => t.templateType !== TemplateTypes[2].shortName
        );
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  /** filter templates by the settings */
  filterTemplatesBySettings(settings: AppSettings) {
    if (settings.uiViewSettings.tbWorkflow) {
      this.filterOutLabTemplate(); // tb workflow
    } else if (settings.uiViewSettings.safeBloodWorkflow) {
      this.filterTemplateByFacilityType(); // safe blood workflow
    } else {
      // cic workflow || migori workflow || paperEMR
      this.templatesEditForm = this.templates;
    }
  }

  /** get stamp templates from template service */
  getTemplateSeries(useCache = true): void {
    this.templateSeriesService
      .getTemplateSeries(useCache)
      .then((series: Array<TemplateSeries>) => {
        this.loadingSeries = false;
        this.templateSeries = series;
      })
      .catch((error) => {
        if (this.debugMode) {
          console.log(
            "SheetsComponent: Error loading template-series: " + error
          );
        }
        const snackBarRef = this.snackBar.open(
          "Error loading template series data, please try again.",
          "Retry"
        );
        snackBarRef.onAction().subscribe(() => {
          if (this.debugMode) {
            console.log("SheetsComponent: Retrying load template series");
          }
          this.getTemplateSeries();
        });
      });
  }

  /** function load app settings from the database */
  async getAppSettings() {
    await this.appSettingsService
      .loadAppSettings(this.env.settingsId)
      .then((loadedAppSettings) => {
        this.loadingAppSettings = false;
        this.appSettings = new AppSettings(loadedAppSettings);
        this.loadData(); // load apps required data
        this.setDefaultTemplate();
        this.filterTemplatesBySettings(this.appSettings);
        if (!this.loggedInUser) {
          this.loggedInUser = this.auth.getUser();
        }
        this.isCHAUser = this.loggedInUser.userRoles
          ? this.loggedInUser.userRoles.includes(
            this.repository.enumSelector(UserRoles)[3].title
          )
          : false;
      })
      .catch((error) => {
        if (this.debugMode) { console.log('SheetsComponent: Error loading app settings', error); }
      });
  }

  /** function to get sheet image */
  getAttachment() {
    this.attachmentService
      .getAttachmentBySheet(this.currentSheet._id)
      .then((attachment: Attachment) => {
        this.currentAttachment = attachment;
        if (this.debugMode) {
          console.log(
            "SheetsComponent: Current Attachment:",
            this.currentAttachment._id
          );
        }
      })
      .catch((error) => {
        console.log(
          "SheetsComponent: Error getting images: Scanned Image not synced"
        );
      });
  }

  /** get all the patients from the database */
  async getPatients(useCache: boolean = true) {
    if (!this.appSettings.patientSettings.enablePatientObjects) {
      return;
    }

    await this.patientService
      .getPatients(useCache)
      .then((patients: Patient[]) => {
        this.loadingPatients = false;
        this.patients = this.patientService.patients;
        if (!this.isCHAUser && this.appSettings.uiViewSettings.cicWorkflow) {
          // when a chv user is logged in, set the patientId field to current user
          this.currentPatient = this.patients.find(
            (p) => p._id === this.loggedInUser.id.toString()
          );
        }
      })
      .catch((error) => {
        this.loadingPatients = false;
        console.log("Error::", error);
        this.snackBar.open("Error loading list of patients", "Error", {
          duration: 6000,
        });
      });
  }

  /** get all the facilities from the database */
  async getFacilities() {
    await this.facilityService
      .getFacilities()
      .then((facilities: Facility[]) => {
        this.facilities = facilities;
        this.getFacility(this.loggedInUser.facility); // get user facility
      })
      .catch((error) => {
        console.log("Error getting facilities::", error);
        this.snackBar.open("Error loading list of facilities", "Error", {
          duration: 6000,
        });
      });
  }

  /** get specific patient from the database by patient UUID */
  getPatientById(_id: string): Promise<Patient> {
    return new Promise((resolve, reject) => {
      this.patientService
        .getPatient(_id)
        .then((patient: Patient) => {
          resolve(patient);
        })
        .catch((error) => {
          console.log("Error::", error);
          this.snackBar.open("Error loading patient by ID", "Error", {
            duration: 6000,
          });
          reject("Error loading patient by ID");
        });
    });
  }

  /** call function to load new patient dialog form */
  addPatient(component) {
    component.addPatient();
  }

  /** detect template border using qr method */
  detectBorderQR(scannedImage: any) {
    let qrDetectStartTime = performance.now(); // record start time
    if (this.debugMode) { console.log('SheetsComponent QR: Scanning started at:', qrDetectStartTime); }

    // subscribe to results of detect border qr
    this.detectBorderSubscription = this.detectBorderServiceQR.subject.subscribe(result => {
      console.log('qr: detectBorderServiceQR result', result);
      let qrDetectEndTime = performance.now(); // record end time
      this.qrCode = result.qrCode;
      if (this.debugMode) { console.log('QR: Scanning process took:', qrDetectEndTime - qrDetectStartTime, 'milliseconds'); }
      if (result.status === DetectionStatus.success) {
        // detection succeeded, process the output
        if (this.debugMode) { console.log('SheetsComponent: Success detecting the qr template border'); }
        this.zone.run(() => {
          this.setAttachmentShowResultsEdit(result.correctedImageBlob);
          // this.extract(result.correctedImageBlob);
        });
      } else if (result.status === DetectionStatus.error) {
        // detection failed, retry camera step
        if (this.viewMode === ViewModes.camera) {
          this.currentAttachment._attachments['file'] = { content_type: scannedImage.type, data: scannedImage };
          this.zone.run(() => {
            this.detectQRInProgress = false;
            this.showManualEdit();
          });
        }
        if (this.debugMode) { console.log('SheetsComponent: Error detecting the qr template border', result.errorMessage); }
        this.snackBar.open(result.errorMessage, 'Error', { duration: 6000 });
      }
      // done with detect border subscription
      if (this.detectBorderSubscription) { this.detectBorderSubscription.unsubscribe(); }
    });

    // start the detect border qr process
    console.log('SheetsComponent: detectQRBorder', this.currentSheetTemplate.templateWidth, this.currentSheetTemplate.templateHeight);
    this.detectBorderServiceQR.detectQRBorderFromImage(scannedImage,
      this.currentSheetTemplate.templateWidth, this.currentSheetTemplate.templateHeight, this.debugMode);
  }

  /** Manual edit has updated the corners, process */
  manualEditUpdate(correctedImageBlob: Blob) {
    if (this.debugMode) { console.log('SheetsComponent: manual edit updated'); }
    this.setAttachmentShowResultsEdit(correctedImageBlob);
  }

  /** Image file selected for debug input */
  fileSelected(event: Event) {
    const target: HTMLInputElement = <HTMLInputElement>event.target;
    const files: FileList = target.files;

    Array.from(files).forEach((file) => {
      if (this.debugMode) {
        console.log("SheetsComponent: file selected", file.type, file.name);
      }
      const url = URL.createObjectURL(file);

      // get thumbnail
      this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(url);

      // TODO: replace this block by adding the full file to the current attachment
      if (!this.currentAttachment || !this.currentAttachment._attachments) {
        this.createAttachmentObject();
      }
      this.currentAttachment._attachments["fullFile"] = {
        content_type: file.type,
        data: file,
      };

      // load image and send to scan
      const img = new Image();
      img.onload = () => {
        this.detectBorderQR(img);
      };
      img.src = url;
    });
  }

  /** create attachment object function */
  createAttachmentObject(): any {
    // set the attachments fields values here
    if (this.debugMode) {
      console.log("SheetsComponent: Creating new attachment object");
    }
    const timestamp = new Date().getTime();
    this.currentAttachment = new Attachment({
      id: timestamp,
      _id: timestamp.toString(),
      attachmentFileName:
        "scan_" +
        this.currentSheetTemplate.name +
        this.currentSheetTemplate.description +
        this.currentSheetTemplate.version +
        "_" +
        this.currentSheet.patientId,
      _attachments: {},
    });
  }

  /** function to save attachment to database
   * @param currentAttachment attachment object */
  updateAttachment(currentAttachment: Attachment): Promise<Attachment> {
    return new Promise((resolve, reject) => {
      this.attachmentService
        .updateAttachment(
          currentAttachment,
          this.selectedFacility ? this.selectedFacility._id : null
        )
        .then((updatedAttachment) => {
          this.newScan = false;
          console.log("SheetsComponent: Attachment updated successfully");
          resolve(updatedAttachment);
        })
        .catch((error) => {
          console.log("SheetsComponent: Error updating attachment");
          reject(error);
        });
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.detectBorderSubscription) {
      this.detectBorderSubscription.unsubscribe();
    }
  }

  /** get the current user facility
   * @param facilityCode string Facility code */
  getFacility(facilityCode: string) {
    this.facility = this.facilities.find(
      (facility) => facility._id === facilityCode
    );
    if (!this.facility) {
      this.snackBar.open("User Facility was not loaded");
    }
    this.filterTemplatesBySettings(this.appSettings);
    this.loadingFacility = false;
  }

  /**
   * Function fetch records by template id
   * @param templateId records to fetch by
   */
  async getRecordsByTemplate(templateId: string) {
    this.farmRecords = await this.sheetService.getAnswersByTemplate(templateId);
  }

  /**
   * Function get report series by a specific field
   * @param filterBy field name
   * @param filterValue field value
   */
  async getReportSeriesBy(filterBy: string, filterValue: any) {
    this.filteredReportSeriesList =
      await this.reportSeriesService.getReportSeriesBy(filterBy, filterValue);
  }

  /** Function that returns list of answer sheets in a report series */
  async getReportSeriesRecords(reportSeriesId: string) {
    this.loadingReportAnswerSheets = true;
    try {
      this.reportSeriesRecords = await this.sheetService.getReportAnswerSheets(reportSeriesId);
      this.loadingReportAnswerSheets = false;
    } catch (error) {
      this.loadingReportAnswerSheets = false;
      this.snackBar.open("Error loading report series records");
      console.log(error);
    }

  }

  /** Function get patients records */
  async getPatientRecords(patientId: string = this.currentPatient._id) {
    this.loadingPatientRecords = true;
    const startTime = performance.now();
    try {
      // get patient scanned records
      this.patientRecords = await this.sheetService.getPatientSheets(patientId);

      // if report series is enabled get patient report series
      if (this.appSettings.recordSettings.enableReportSeries) {
        this.getReportSeriesBy("patientId", patientId);
      }

      const endTime = performance.now();
      this.loadingPatientRecords = false;
      if (this.debugMode) {
        console.log('Get getPatientRecords took:', (endTime - startTime) / 1000, 'seconds');
      }
    } catch (error) {
      console.log(error);
      this.loadingPatientRecords = false;
      this.snackBar.open("Error loading patient records");
    }
  }

  /**
   * Function fetch report series id from the database
   * @param id report series id
   */
  async getReportSeriesById(id: string) {
    try {
      this.currentReportSeries = await this.reportSeriesService.getSeriesById(id);
    } catch (error) {
      this.snackBar.open("Error loading report series report");
    }
  }

  /**
   * Onchange of patient filter patient records
   * Warn the user if navigated using next template button
   * @param patientId updated patient id
   */
  async filterPatientRecords(patientId: string) {
    if (
      this.navigatedUsingNextButton &&
      this.currentReportSeries &&
      this.currentReportSeries.patientId
    ) {
      if (this.currentPatient._id === this.currentReportSeries.patientId) {
        this.sheetEditComponent.sheetForm.controls["patientId"].setErrors(null);
      } else {
        // set form error
        this.sheetEditComponent.sheetForm.controls["patientId"].setErrors({
          differentPatientNumber: true,
        });

        // warn the user
        this.dialogRef = this.dialog.open(PatientChangedDialog, {
          data: {
            patientLabel: this.appSettings.patientSettings.patientIdLabel,
          },
        });
        this.dialogRef.afterClosed().subscribe(async (result) => {
          if (result) {
            this.reset(); // reset current items in memory
            if (this.route.snapshot.paramMap.get("viewMode") !== "edit") {
              this.router.navigate(["/new_record", { viewMode: "edit" }]);
            }
            this.showAddNewPatient();
          } else {
            // user selected go back, retain them in same edit screen
            this.currentPatient = this.findPatientById(
              this.currentReportSeries.patientId
            );
            this.currentSheet.patientId = this.currentReportSeries.patientId;
          }
          this.cd.detectChanges();
        });
      }
    } else {
      await this.getPatientRecords(patientId);
    }
  }

  /**
   * Perform actions when the template has changed
   */
  async templateChanged() {
    if (this.currentSheetTemplate._id === this.appSettings.farmSettings.incomeExpenseTemplate) {
      // get records by template
      this.farmRecords = await this.sheetService.getAnswersByTemplate(this.currentSheetTemplate._id);
    }

    this.prefillScanRecordWithData(this.currentReportSeries);
  }

  /**
   * Function get records for the same team number on update
   * @param teamNumber selected team number
   * @returns
   */
  async teamNumberChanged(teamNumber: number) {
    if (this.appSettings.uiViewSettings.polioWorkflow) {
      // get sheets by team number
      try {
        this.loadingPatientRecords = true;
        this.teamNumberRecords = await this.sheetService.getSheetsByTeamNumber(
          teamNumber
        );
        this.loadingPatientRecords = false;
      } catch (error) {
        this.snackBar.open("Error loading records by team number", "Error", {
          duration: 6000,
        });
      }
      return;
    }
  }

  /**
   * Function check if a serial number has been changed and warn the user
   * @param serialNumber updated serial number
   */
  async serialNumberChange(serialNumber: number) {
    if (!serialNumber) {
      this.showAddNewPatient();
      return;
    }

    try {
      this.loadingPatientRecords = true;
      // get sheets by serial number
      this.serialNumberRecords = await this.sheetService.getSheetsBySerialNumber(serialNumber);
      await this.getReportSeriesBy('serialNumber', serialNumber);
      this.currentReportSeries = this.filteredReportSeriesList.find(series => series.serialNumber = serialNumber);

      if (this.currentReportSeries) {
        this.patientRecords = [];
        // load form with report series data
        this.prefillScanRecordWithData(this.currentReportSeries);
      } else {
        this.loadingPatientRecords = false;
        if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
          // reset current items in memory
          this.patientRecords = [];
          this.currentPatient = null;
          this.currentSheetTemplate = null
          this.currentSheet.patientId = null;
          this.currentSheet.facilityWard = null;
          this.currentSheet.encounterTime = null;
          this.currentSheet.reportSeriesId = null;
          this.currentSheet.encounterDate = null;
          this.setDefaultTemplate(); // set default template
          this.appSettings.uiViewSettings.disableTemplateField = true;
        }

        // warn user: changing the serial number will reset the form
        // if (!this.navigatedUsingNextButton) { return; }
        // if (!this.currentReportSeries || !this.currentReportSeries.serialNumber) { return; }
        //   if (serialNumber === this.currentSheet.serialNumber) {
        //     this.sheetEditComponent.sheetForm.controls['serialNumber'].setErrors(null);
        //   } else {
        //     // set form error
        //     this.sheetEditComponent.sheetForm.controls['serialNumber'].setErrors({ 'differentSerialNumber': true });

        //     // alert user
        //     this.dialogRef = this.dialog.open(SerialNumberChanged);
        //     this.dialogRef.afterClosed().subscribe(result => {
        //       if (result) {
        //         this.reset(); // reset current items in memory
        //         if (this.route.snapshot.paramMap.get('viewMode') !== 'edit') { this.router.navigate(['/new_record', { viewMode: 'edit' }]); }
        //         this.showAddNewPatient();
        //       }
        //       this.dialogRef.close();
        //     });
        //   }
      }

      this.loadingPatientRecords = false;
      this.disablePatientField = this.serialNumberRecords.length > 0 || this.patientRecords.length > 0;
      this.cd.detectChanges();
    } catch (error) {
      if (this.debugMode) { console.log(error) }
      this.snackBar.open('Error loading records by serial number', 'Error', { duration: 6000 })
    }
  }

  /** Prefill all the scanned record with data from the report-series */
  async prefillScanRecordWithData(reportSeries: ReportSeries) {
    if (!reportSeries) {
      return;
    }

    try {
      if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
        this.appSettings.uiViewSettings.disableTemplateField = false;
        this.currentPatient = await this.getPatientById(reportSeries.patientId);
        this.prefillBloodSafeData(reportSeries);
      } else if (this.appSettings.uiViewSettings.polioWorkflow) {
        this.currentSheet.encounterDate = reportSeries.seriesStartDate;
        this.currentSheet.customBeforeFieldsData.teamNumber = reportSeries.teamNumber;
        this.currentSheet.customBeforeFieldsData.immunizationDate = reportSeries.seriesStartDate;
      } else if (this.appSettings.uiViewSettings.mhGapWorkFlow) {
        this.prefillmhGAPData(reportSeries);
      } else if (this.appSettings.uiViewSettings.migoriWorkflow) {
        this.currentSheet.patientId = this.currentPatient ? this.currentPatient._id : null;
        this.currentSheet.encounterDate = null
        this.selectedFacility = this.facility.facilityOptions.isResearchAssistantFacility
          ? this.facilities.find(f => f._id === this.currentPatient.createFacility) : null;
      } else if (this.appSettings.uiViewSettings.tbWorkflow) {
        this.currentSheet.patientId = this.currentPatient ? this.currentPatient._id : null;;
        this.currentSheet.encounterDate = reportSeries.seriesStartDate;
      }

      // set the form IO data
      if (this.sheetEditComponent) {
        this.sheetEditComponent.formIoData = !this.currentSheet.customBeforeFieldsData ? {} : this.currentSheet.customBeforeFieldsData;
      }

      this.cd.detectChanges();
    } catch (error) {
      if (this.debugMode) { console.log(error) }
      this.snackBar.open('Error setting default values from report-series', 'Error', { duration: 6000 });
    }
  }

  /** Prefill records data for blood-safe flow */
  prefillBloodSafeData(reportSeries: ReportSeries) {
    this.disablePatientField = this.serialNumberRecords.length > 0 || this.patientRecords.length > 0;
    this.currentSheet.serialNumber = reportSeries.serialNumber;
    this.currentSheet.patientId = reportSeries.patientId;
    this.currentSheet.facilityWard = reportSeries.facilityWard;

    if (this.currentSheetTemplate.name === 'BldReq-B') {
      this.sheetEditComponent.sheetForm.controls['facilityWard'].disable();
    } else {
      this.sheetEditComponent.sheetForm.controls['facilityWard'].enable();
    }

    this.currentSheet.encounterDate = (this.currentSheetTemplate && this.currentSheetTemplate.name === 'BldReq-C') ? null : new Date(reportSeries.seriesStartDate);
    this.currentSheet.encounterTime = (this.currentSheetTemplate && this.currentSheetTemplate.name === 'BldReq-C') ? '' : reportSeries.seriesStartTime;
    this.sheetEditComponent.sheetForm.controls['encounterDate'].setValue(this.currentSheet.encounterDate);

    this.cd.detectChanges();
  }

  /** Prefill records data for mental health flow */
  prefillmhGAPData(reportSeries: ReportSeries) {
    this.currentSheet.serialNumber = reportSeries.serialNumber;
    this.currentSheet.encounterDate = new Date(reportSeries.seriesStartDate);
    // copy screening org from report series
    this.currentSheet.customBeforeFieldsData.screeningOrg = this.currentReportSeries.screeningOrg;

    this.cd.detectChanges();
  }

  /** Validate date when record in series date changes */
  async dateTimeChanged() {
    if (this.currentSheetTemplate._id === this.appSettings.recordSettings.defaultTemplate) {
      if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
        const currentDate = this.datePipe.transform(this.currentSheet.encounterDate, 'MM/dd/YYYY');
        const currentTime = this.currentSheet.encounterTime ? this.currentSheet.encounterTime : '23:59:59';
        const currentDateTime = new Date(`${currentDate} ${currentTime}`);

        // for template A validate transfusion date with requisition date and time (template A) in the series
        if (this.currentSheetTemplate.name === 'BldReq-A') {
          const transfusionDate = this.datePipe.transform(this.currentSheet.plannedTransfusionDate, 'MM/dd/YYYY');
          const transfusionTime = this.currentSheet.plannedTransfusionTime ? this.currentSheet.plannedTransfusionTime : '23:59:59';
          const transfusionDateTime = new Date(`${transfusionDate} ${transfusionTime}`);

          // transfusionDateTime should not be older than the requisition date and time
          const transfusionSetError = (transfusionDateTime.getTime() < currentDateTime.getTime()) ? { 'invalidateTransfusionOlderThanA': true } : null;
          this.sheetEditComponent?.transfusionDateTimeComponent?.form.controls['transfusionDate'].setErrors(transfusionSetError);
          this.sheetEditComponent?.transfusionDateTimeComponent?.form.controls['transfusionTime'].setErrors(transfusionSetError);
          this.cd.detectChanges();
        }
      }
      return;
    }

    if (this.appSettings.uiViewSettings.safeBloodWorkflow) { // blood-safe flow    
      // for time, if time is missing for older series default to 00:00 hrs and for newer record default to 23:59 hrs
      const seriesTime = this.currentReportSeries.seriesStartTime ? this.currentReportSeries.seriesStartTime : '00:00:00';
      const seriesDateTime = new Date(`${this.currentReportSeries.seriesStartDate} ${seriesTime}`);

      const currentDate = this.datePipe.transform(this.currentSheet.encounterDate, 'MM/dd/YYYY');
      const currentTime = this.currentSheet.encounterTime ? this.currentSheet.encounterTime : '23:59:59';
      const currentDateTime = new Date(`${currentDate} ${currentTime}`);

      // for template C & D validate with other dates in the series
      if (this.currentSheetTemplate.name === 'BldReq-C') {
        if (!(currentDateTime.getTime() > new Date().getTime())) {
          const setError = (seriesDateTime.getTime() >= currentDateTime.getTime()) ? { 'invalidateOlderThanA': true } : null;
          this.sheetEditComponent?.sheetForm.controls['encounterDate'].setErrors(setError);
          this.sheetEditComponent?.sheetForm.controls['encounterTime'].setErrors(setError);
        }
      }

      if (this.currentSheetTemplate.name === "BldReq-D") {
        // get template C record
        const templateC = this.templates.find(
          (template) => template.name === "BldReq-C"
        );
        if (!templateC) {
          return;
        }

        const recordOfC = this.patientRecords.filter(r => r.stampTemplateId === templateC._id);
        const recordC = recordOfC.find(r => r.reportSeriesId === this.currentReportSeries._id);
        if (!recordC) { return }

        // for time, if time is missing default to 00:00 hrs
        const templateCDateTime = new Date(`${recordC.encounterDate} ${recordC.encounterTime ? recordC.encounterTime : '00:00:00'}`);
        const error = (seriesDateTime.getTime() > currentDateTime.getTime() || templateCDateTime.getTime() > currentDateTime.getTime()) ?
          { 'invalidateOlderThanAorC': true } : null
        this.sheetEditComponent.sheetForm.controls['encounterDate'].setErrors(error);
        this.sheetEditComponent.sheetForm.controls['encounterTime'].setErrors(error);
      }
      this.cd.detectChanges();
    } else {
      // implement for other deploys CIC, Migori, TB
      console.log("Other flows have no set date validations");
    }
  }
}
