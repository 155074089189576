import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { AnswerSheet } from "../../../models/answer-sheet";
import { StampTemplate, AnswerTemplate } from "../../../models/stamp-template";
import { TemplateService } from "../../../services/template.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { fabric } from "fabric";
import { Attachment } from "../../../models/attachment";
import { AttachmentService } from "../../../services/attachment.service";
import {
  DetectBorderServiceQR,
  QRCode,
} from "../../../services/detect-border-qr.service";

@Component({
  selector: "paper-manual-edit",
  styleUrls: ["manual-edit.component.css"],
  templateUrl: "manual-edit.component.html",
})
export class ManualEditComponent implements OnInit, OnDestroy {
  @Input() template: StampTemplate;
  @Input() currentAttachment: Attachment;

  @Output() manualEditCancel: EventEmitter<any> = new EventEmitter<any>(); // cancel button pressed
  @Output() manualEditNavigateBack: EventEmitter<any> = new EventEmitter<any>(); // navigate back button pressed
  @Output() manualEditUpdate: EventEmitter<any> = new EventEmitter<Blob>(); // update button pressed

  @Input() set sheet(sheet: AnswerSheet) {
    this._sheet = sheet;
    this.getTemplateAttachment(sheet.stampTemplateId);
  }
  get sheet(): AnswerSheet {
    return this._sheet;
  }
  _sheet: AnswerSheet;

  @Input() qrCode: QRCode;
  @Output() qrCodeChange: EventEmitter<QRCode> = new EventEmitter<QRCode>();

  canvasManual: any = null; // fabric canvas
  poly: any = null;

  /** track whether layer images loaded/displayed */
  templateImageLoaded: boolean = false;
  scannedImage: HTMLImageElement;
  scannedImageLayer: any;
  imageCloneLayer: any;
  scannedImageLayerLoaded: boolean = false;
  showTemplateOverlay: boolean = false;
  showScannedImageOverlay: boolean = true;
  cornerRadius: number = 30;
  strokeWidth: number = 5;

  COLOR_CHECKED = "lightgreen";
  COLOR_UNCHECKED = "orange";

  // dimensions
  dimensions: {
    maxWidth: number;
    maxHeight: number;
    originalTemplateHeight: number;
    originalTemplateWidth: number;
    scannedImageScale: number;
    templateScale: number;
  } = {
    maxWidth: Math.min(500, window.innerWidth), // allow for padding
    maxHeight: Math.min(600, window.innerHeight - 27),
    originalTemplateHeight: null,
    originalTemplateWidth: null,
    scannedImageScale: null, // scale factor for fitting canvases to window
    templateScale: null, // scale factor for fitting template to window
  };

  constructor(
    private snackbar: MatSnackBar,
    private templateService: TemplateService,
    private detectBorderServiceQR: DetectBorderServiceQR,
    private attachmentService: AttachmentService
  ) {}

  /**
   * Initialize canvas and register mouse click listener
   */
  ngOnInit() {
    // initialize manual edit canvas
    fabric.Object.prototype.objectCaching = false;
    this.setupEditor();
  }

  /** Destroy unused elements to recover memory */
  ngOnDestroy(): void {
    this.canvasManual.clear();
    this.canvasManual.dispose();
    this.canvasManual = null;
    if (this.scannedImageLayer) {
      this.scannedImageLayer.dispose();
    }
    this.scannedImageLayer = null;
  }

  /**
   * Function used to load template _attachment
   */
  getTemplateAttachment(templateId) {
    this.templateService
      .getTemplateAttachment(templateId)
      .then((blob) => {
        // create a url from the blob
        const url = URL.createObjectURL(blob);
        // creat instance of an image
        const templateImage = new Image();
        // onload of template image
        templateImage.onload = () => {
          this.templateImageLoaded = true;

          // set dimensions original template width/height to template image width/height
          this.dimensions.originalTemplateHeight = templateImage.height;
          this.dimensions.originalTemplateWidth = templateImage.width;

          // Add function to get scanned image( sheet attachment file )
          this.getAttachment();
          console.log("ManualEditComponent: Template image file loaded");
        };
        templateImage.src = url;
      })
      .catch((error) => {
        console.log(
          "ManualEditComponent: Unable to load template image",
          error
        );
      });
  }

  /**
   * function to get attachment file from the database
   */
  getAttachment() {
    // fetch all attachment files from the sheet object
    if (!this.currentAttachment || !this.currentAttachment._attachments) {
      this.showTemplateOverlay = true;
      this.showScannedImageOverlay = false;
      console.log(
        "ManualEditComponent: No attachments: unable to list attachments"
      );
      return;
    }

    if (
      !this._sheet._id ||
      this.currentAttachment._attachments["fullFile"].data
    ) {
      // this is a new record, load attachments directly
      console.log(
        "ManualEditComponent: Attempting to add scanned image layer",
        this._sheet._id,
        this.currentAttachment._attachments
      );
      this.addScannedImageLayer(
        this.currentAttachment._attachments["fullFile"].data
      );
    } else {
      // this is an existing record, load attachments from pouch
      this.attachmentService
        .getSheetAttachment(this.currentAttachment._id, "fullFile")
        .then((blob) => {
          this.addScannedImageLayer(blob);
        })
        .catch((err) => {
          console.log(
            "ManualEditComponent: Unable to load scanned image file",
            err
          );
          this.snackbar.open(
            "ManualEditComponent: Unable to load scanned image file",
            "Error",
            { duration: 6000 }
          );
        });
    }
  }

  /**
   * function to add scanned image layer
   * @param imageBlob scanned image blob
   */
  addScannedImageLayer(imageBlob) {
    console.log("ManualEditComponent: adding scanned image layer");
    const url = URL.createObjectURL(imageBlob);

    this.scannedImage = new Image();
    this.scannedImage.onload = () => {
      // calculate scale to fit template image inside video
      this.dimensions.scannedImageScale = Math.min(
        this.dimensions.maxWidth / this.scannedImage.width,
        this.dimensions.maxHeight / this.scannedImage.height
      );

      // set width/height of canvas to the template image width/height
      // this.canvasManual.setWidth(this.scannedImage.width * this.dimensions.scannedImageScale);
      // this.canvasManual.setHeight(this.scannedImage.height * this.dimensions.scannedImageScale);
      // this.canvasManual.setWidth(this.dimensions.maxWidth);
      // this.canvasManual.setHeight(this.dimensions.maxHeight);
      this.canvasManual.setWidth(this.scannedImage.width);
      this.canvasManual.setHeight(this.scannedImage.height);

      // copy scanned image to fabric
      this.scannedImageLayer = new fabric.Image(this.scannedImage, {
        left: 0,
        top: 0,
        width: this.scannedImage.width,
        height: this.scannedImage.height,
        // scaleX: this.dimensions.scannedImageScale,
        // scaleY: this.dimensions.scannedImageScale,
        opacity: 1,
        selectable: false,
        evented: false,
      });
      console.log("ManualEditComponent: scanned image layer added");
      this.scannedImageLayerLoaded = true;

      // zoom layer: see https://stackoverflow.com/a/30410948 for maths
      let zmrScale = 2,
        zmrX = 0,
        zmrY = 0,
        zmrRadius = 100;
      this.imageCloneLayer = fabric.util.object.clone(this.scannedImageLayer);
      this.imageCloneLayer.set({
        width: this.scannedImage.width * zmrScale,
        height: this.scannedImage.height * zmrScale,
        opacity: 0,
        scaleX: zmrScale,
        scaleY: zmrScale,
      });

      // add scanned image to the back of the canvas
      this.canvasManual.add(this.scannedImageLayer, this.imageCloneLayer);
      this.canvasManual.sendToBack(this.scannedImageLayer);

      // time handle out to hide zoomer
      let timeoutHandle;

      this.canvasManual.on("object:moving", (e) => {
        this.updateManualCanvas();

        // show zoomer
        this.imageCloneLayer.opacity = 1;

        // get center of zoomer circle
        zmrX = e.target.left + e.target.radius + e.target.strokeWidth;
        zmrY = e.target.top + e.target.radius + e.target.strokeWidth;

        const scaleChange = zmrScale - 1; // 1 is original scale
        const zmrLeft = -(zmrX * scaleChange);
        const zmrTop = -(zmrY * scaleChange);
        this.imageCloneLayer.set("left", zmrLeft);
        this.imageCloneLayer.set("top", zmrTop);
        this.imageCloneLayer.clipPath = new fabric.Circle({
          radius: zmrRadius,
          left: zmrX - zmrRadius,
          top: zmrY - zmrRadius,
          originX: "left",
          originY: "top",
          absolutePositioned: true,
        });
        this.canvasManual.renderAll();
        // console.log('zmrX:', zmrX, ', zmrY:', zmrY, ', zmrLeft:', zmrLeft, ', zmrTop:', zmrTop);

        // hide zoomer after a bit
        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(() => {
          this.imageCloneLayer.opacity = 0;
          this.canvasManual.renderAll();
        }, 1200);
      });

      // hide zoomer after a few seconds
      // this.canvasManual.on('mouse:dblclick', (e) => {
      //     setTimeout(() => this.imageCloneLayer.opacity = 0, 1200);
      // });

      // update dimensions
      this.setDimensions();
    };
    this.scannedImage.src = url;
  }

  /**
   * reset the canvas
   */
  resetCanvas() {
    this.canvasManual.clear();
  }

  /**
   * User has pressed cancel button
   */
  cancel() {
    console.log("ManualEditComponent: cancel has been pressed");
    this.manualEditCancel.emit();
  }

  /**
   * User has pressed update button
   */
  update() {
    console.log("ManualEditComponent: Delete the editing UI stuff");
    this.deleteEditUI();

    this.canvasManual.renderAll();
    console.log("ManualEditComponent: new coordinates are ", this.poly);
    const corners = [
      this.poly.array[0].x,
      this.poly.array[0].y,
      this.poly.array[1].x,
      this.poly.array[1].y,
      this.poly.array[2].x,
      this.poly.array[2].y,
      this.poly.array[3].x,
      this.poly.array[3].y,
    ];

    // check if we have QR code
    if (!this.qrCode) {
      this.qrCode = {
        failed: false,
        matrix: null,
        location: {
          topRightCorner: null,
          topLeftCorner: null,
          bottomRightCorner: null,
          bottomLeftCorner: null,
          topRightFinderPattern: null,
          topLeftFinderPattern: null,
          bottomLeftFinderPattern: null,
          bottomRightFinderPattern: null,
          dimension: null,
          topDimension: null,
          sideDimension: null,
          moduleSize: null,
          finderPatterns: null,
          finderPatternGroups: null,
        },
      };
    }

    // update qr code information
    this.qrCode.location.topLeftCorner = {
      x: this.poly.array[0].x,
      y: this.poly.array[0].y,
    };
    this.qrCode.location.topRightCorner = {
      x: this.poly.array[1].x,
      y: this.poly.array[1].y,
    };
    this.qrCode.location.bottomRightCorner = {
      x: this.poly.array[2].x,
      y: this.poly.array[2].y,
    };
    this.qrCode.location.bottomLeftCorner = {
      x: this.poly.array[3].x,
      y: this.poly.array[3].y,
    };
    this.qrCodeChange.emit(this.qrCode);

    // unwarp skewed image using provided QR corner points
    console.log("ManualEditComponent: unwarping and passing back result");
    const exportCanvas = this.canvasManual.toCanvasElement();
    this.detectBorderServiceQR
      .unWarp(
        exportCanvas.getContext("2d", { willReadFrequently: true }),
        corners,
        exportCanvas.width,
        exportCanvas.height
      )
      .then((correctedImageBlob) => {
        this.manualEditUpdate.emit(correctedImageBlob);
      })
      .catch((error) => console.log("Error unwarping QR output", error));
  }

  /**
   * User has pressed 'next' button
   */
  back() {
    console.log("ManualEditComponent: back has been pressed");
    this.manualEditNavigateBack.emit();
  }

  // POLYGON EDITOR
  setupEditor() {
    this.canvasManual = new fabric.Canvas("canvasManual");
    this.canvasManual.id = "canvasManual";
    this.canvasManual.selection = false;
    this.poly = { points: [], array: [] };

    // update dimensions
    this.setDimensions();
  }

  /** calculate dimensions based on template and scanned image once loaded */
  setDimensions() {
    if (!this.template) {
      console.log("Error: Template dimensions missing");
      return;
    }

    if (!this.template.templateWidth || !this.template.templateHeight) {
      console.log("Error: Template dimensions missing");
      return;
    }

    if (!this.scannedImage) {
      console.log("Error: Scanned image not loaded");
      return;
    }

    // calculate template width and height to fit into display
    this.dimensions.templateScale = Math.min(
      this.canvasManual.width / this.dimensions.originalTemplateWidth,
      this.canvasManual.height / this.dimensions.originalTemplateHeight
    );

    const ZOOM = 0.8;
    const tplWidth =
      this.dimensions.originalTemplateWidth *
      this.dimensions.templateScale *
      ZOOM;
    const tplHeight =
      this.dimensions.originalTemplateHeight *
      this.dimensions.templateScale *
      ZOOM;
    console.log(
      "Manual Edit Dimensions:",
      "\n canvas.width set to",
      this.canvasManual.width,
      "\n canvas.height set to",
      this.canvasManual.height,
      "\n max width is ",
      this.dimensions.maxWidth,
      "\n max height is ",
      this.dimensions.maxHeight,
      "\n original template.height is",
      this.dimensions.originalTemplateHeight,
      "\n original template.width is",
      this.dimensions.originalTemplateWidth,
      "\n scanned image height is",
      this.scannedImage.height,
      "\n scanned image width is",
      this.scannedImage.width,
      "\n scanned image scale factor is",
      this.dimensions.scannedImageScale,
      "\n template scale factor is",
      this.dimensions.templateScale,
      "\n template display.width is",
      tplWidth,
      "\n template display.height factor is",
      tplHeight
    );

    this.drawInitialPoints(tplWidth, tplHeight);
  }

  /** draw edit UI using dimensions of selected template */
  drawInitialPoints(tplWidth: number, tplHeight: number) {
    const padTop = (this.canvasManual.height - tplHeight) / 2;
    const padSide = (this.canvasManual.width - tplWidth) / 2;

    let topLeftCorner = { x: padSide, y: padTop };
    let topRightCorner = { x: tplWidth + padSide, y: padTop };
    let bottomRightCorner = { x: tplWidth + padSide, y: tplHeight + padTop };
    let bottomLeftCorner = { x: padSide, y: tplHeight + padTop };

    if (this.qrCode && this.qrCode.location) {
      if (this.qrCode.location.topLeftFinderPattern) {
        topLeftCorner = this.qrCode.location.topLeftFinderPattern;
      }
      if (this.qrCode.location.topRightFinderPattern) {
        topRightCorner = this.qrCode.location.topRightFinderPattern;
      }
      if (this.qrCode.location.bottomRightFinderPattern) {
        bottomRightCorner = this.qrCode.location.bottomRightFinderPattern;
      }
      if (this.qrCode.location.bottomLeftFinderPattern) {
        bottomLeftCorner = this.qrCode.location.bottomLeftFinderPattern;
      }
      if (this.qrCode.location.topLeftCorner) {
        topLeftCorner = this.qrCode.location.topLeftCorner;
      }
      if (this.qrCode.location.topRightCorner) {
        topRightCorner = this.qrCode.location.topRightCorner;
      }
      if (this.qrCode.location.bottomRightCorner) {
        bottomRightCorner = this.qrCode.location.bottomRightCorner;
      }
      if (this.qrCode.location.bottomLeftCorner) {
        bottomLeftCorner = this.qrCode.location.bottomLeftCorner;
      }
    }

    [
      topLeftCorner,
      topRightCorner,
      bottomRightCorner,
      bottomLeftCorner,
    ].forEach((point) => {
      const new_point = new fabric.Circle({
        top: point.y - (this.cornerRadius + this.strokeWidth),
        left: point.x - (this.cornerRadius + this.strokeWidth),
        radius: this.cornerRadius,
        fill: "white",
        opacity: 0.3,
        strokeWidth: this.strokeWidth,
        padding: 40,
        stroke: "#40ff40",
        selectable: true,
        evented: true,
        hasControls: false,
        id: this.guid(),
      });
      this.poly.points.push(new_point);
      this.canvasManual.add(new_point);
    });

    this.updateManualCanvas();
  }

  /** delete polygons from edit UI */
  deletePolygons() {
    const polygons = this.canvasManual.getObjects("polygon");
    for (const i of Object.keys(polygons)) {
      this.canvasManual.remove(polygons[i]);
    }
  }

  /** delete lines from edit UI */
  deleteLines() {
    const lines = this.canvasManual.getObjects("line");
    for (const i of Object.keys(lines)) {
      this.canvasManual.remove(lines[i]);
    }
  }

  /** delete polygons and circles making up the edit UI */
  deleteEditUI() {
    this.deletePolygons();
    this.deleteLines();
    this.imageCloneLayer.opacity = 0;
    const circles = this.canvasManual.getObjects("circle");
    for (const i of Object.keys(circles)) {
      this.canvasManual.remove(circles[i]);
    }
  }

  /** redraw the manual edit UI */
  updateManualCanvas() {
    this.deletePolygons();
    this.deleteLines();

    // get actual x y for all coordinates, account for circle width & stroke
    this.poly.array = [];
    this.poly.points.map((p) => {
      const point = {
        x: p.left + this.cornerRadius + this.strokeWidth,
        y: p.top + this.cornerRadius + this.strokeWidth,
      };
      // draw crosshair
      this.drawCrossHair(point.x, point.y);

      // add to poly array
      this.poly.array.push(point);
    });

    // account for width of stroke of the polygon
    const polyCorners = [];
    this.poly.points.map((p) => {
      polyCorners.push({
        x: p.left + this.cornerRadius + this.strokeWidth / 2,
        y: p.top + this.cornerRadius + this.strokeWidth / 2,
      });
    });

    // draw polygon
    if (this.poly.points.length >= 3) {
      this.canvasManual.add(
        new fabric.Polygon(polyCorners, {
          stroke: "#eee",
          strokeWidth: this.strokeWidth,
          opacity: 0.2,
          selectable: false,
          evented: false,
          hasControls: false,
        })
      );
    }
  }

  // draw crosshatch
  drawCrossHair(x: number, y: number) {
    length = this.cornerRadius + this.strokeWidth - 2; // add allowance for width of stroke
    const horizontal = this.drawLine([x - length, y - 1, x + length, y - 1]);
    const vertical = this.drawLine([x - 1, y - length, x - 1, y + length]);
    this.canvasManual.add(horizontal, vertical);
  }

  /** draw given line onto canvas */
  drawLine(coords) {
    return new fabric.Line(coords, {
      stroke: "black",
      strokeWidth: 2,
      selectable: false,
      evented: false,
    });
  }

  // Generate a unique GUID identifier
  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }
}
