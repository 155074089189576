import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { StampTemplate } from "../../../models/stamp-template";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "paper-camera",
  styleUrls: ["camera.component.css"],
  templateUrl: "camera.component.html",
})
export class CameraComponent implements OnInit, OnDestroy {
    @Output() cameraCancel = new EventEmitter();
    @Output() cameraNavigateBack = new EventEmitter();
    @Input() detectQRInProgress: boolean;
    @Output() detectQRInProgressChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() cameraCropCaptured: EventEmitter<Blob> = new EventEmitter<Blob>();

  @ViewChild("video", { static: true }) videoElement: any;
  @ViewChild("videoHolder", { static: true }) videoHolderElement: any;
  @ViewChild("guideBox", { static: true }) guideBoxElement: any;

  @Input() set template(template: StampTemplate) {
    this._template = template;
    this.setDimensions();
  }
  get template() {
    return this._template;
  }
  _template: StampTemplate;

  video: any;
  videoHolder: any;
  selectedCamera: number;
  guideBox: any;
  mediaInputDevices: Array<any> = [];
  videoSource: any;
  videoStream: any;

  // Settings
  ZOOM_FACTOR = <number>1; // zoom factor !!!if you reenable make sure calculation for getting image out takes it into consideration

  // booleans to control UI state
  videoLoaded: boolean = false;
  @Input() hideCameraBack: boolean;

  // track that we are destroying this component so that set dimensions is not called
  destroyingCameraComponent = false;

  // dimensions
  dimensions: {
    maxWidth: number;
    maxHeight: number;
    originalVideoHeight: number;
    originalVideoWidth: number;
    videoScale: number;
    templateScale: number;
  } = {
    maxWidth: Math.min(500, window.innerWidth),
    maxHeight: Math.min(600, window.innerHeight - 27), // allow for size of buttons bar

    originalVideoHeight: 300, // default value
    originalVideoWidth: 450, // default value
    videoScale: 1, // scale factor for fitting video element to max width height

    templateScale: 0.9, // scale factor for fitting canvases to video, out of max 3
  };

  // refresh dimensions if window resized, unless we are scrolling
  currentWindowWidth: any; // keep track of current width of window to check for scrolling
  @HostListener("window:resize", ["$event"])
  onResize() {
    // don't resize if its just chrome adding a scrollbar
    if (
      this.currentWindowWidth - window.innerWidth >=
      window.innerWidth - document.documentElement.clientWidth
    ) {
      console.log("resize detected, resetting");
      this.setDimensions();
    }
  }

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    matIconRegistry: MatIconRegistry
  ) {
    matIconRegistry.addSvgIcon(
      "flip-camera-android",
      sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/flip-camera-android.svg"
      )
    );
  }

  /** Initialize element references and definitions */
  ngOnInit(): void {
    this.video = this.videoElement.nativeElement;
    this.videoHolder = this.videoHolderElement.nativeElement;
    this.guideBox = this.guideBoxElement.nativeElement;
    this.initCamera();
  }

  /** Destroy unused elements to recover memory */
  ngOnDestroy(): void {
    this.disableCamera();
  }

  /** Disable camera */
  disableCamera() {
    console.log("Disabling camera");
    this.destroyingCameraComponent = true;
    this.video.pause();
    this.video.src = "";
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  /** Toggle between available cameras */
  switchCamera() {
    this.selectedCamera++;
    if (this.selectedCamera >= this.mediaInputDevices.length) {
      this.selectedCamera = 0;
    }
    this.initCamera();
  }

  /** Initialize camera */
  initCamera() {
    console.log("initializing cameras");
    navigator.mediaDevices
      .enumerateDevices()
      .then((mediaInputDevices) => {
        // reset devices list before re-adding
        this.mediaInputDevices = [];
        for (let i = 0; i !== mediaInputDevices.length; ++i) {
          const deviceInfo = mediaInputDevices[i];
          if (deviceInfo.kind === "videoinput") {
            // console.log (deviceInfo);
            this.mediaInputDevices.push({
              id: deviceInfo.deviceId,
              label: "Cam " + (this.mediaInputDevices.length + 1),
            });
          }
        }

        // if more than one device, set video src to the last item, should be second cam by default
        if (this.selectedCamera === undefined) {
          if (this.mediaInputDevices.length > 1) {
            this.selectedCamera = this.mediaInputDevices.length - 1;
          } else {
            this.selectedCamera = 0;
          }
        }
        this.videoSource = this.mediaInputDevices[this.selectedCamera].id;

        const browser = <any>navigator;

        if (this.video) {
          this.video.pause();
        }
        if (this.videoStream) {
          this.videoStream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        const config = {
          video: {
            deviceId: this.videoSource
              ? { exact: this.videoSource }
              : undefined,
          },
          audio: false,
        };

        browser.getUserMedia =
          browser.getUserMedia ||
          browser.webkitGetUserMedia ||
          browser.mozGetUserMedia ||
          browser.msGetUserMedia;

        browser.mediaDevices.getUserMedia(config).then((stream) => {
          this.videoStream = stream;
          // this.video.src = window.URL.createObjectURL(stream);
          // this.video.src = (window.URL ? URL : webkitURL).createObjectURL(stream);
          this.video.srcObject = stream;

          // let us know when its loaded
          this.renderer.listen(this.video, "loadedmetadata", () => {
            if (this.destroyingCameraComponent) {
              return;
            }
            this.dimensions.originalVideoWidth = this.video.videoWidth;
            this.dimensions.originalVideoHeight = this.video.videoHeight;
            console.log(
              "media loaded vw, vh:",
              this.dimensions.originalVideoWidth,
              this.dimensions.originalVideoHeight
            );
            // update dimensions
            this.setDimensions();
          });

          this.unPause();
        });
      })
      .catch((error) => {
        console.log("navigator.getUserMedia error: ", error);
      });
  }

  /** Sets dimensions of canvas objects, fits the canvas to the size of the video window */
  setDimensions() {
    console.log(
      "Setting dimensions",
      this.template.templateWidth,
      this.template.templateHeight
    );

    if (!this.template) {
      console.log("Error: Template dimensions missing");
      return;
    }

    if (!this.template.templateWidth || !this.template.templateHeight) {
      console.log("Error: Template dimensions missing");
      return;
    }

    if (!this.video) {
      console.log("Error: Video not loaded");
      return;
    }

    // calculate scale to fit video inside max width height
    this.dimensions.videoScale = Math.min(
      this.dimensions.maxWidth / this.dimensions.originalVideoWidth,
      this.dimensions.maxHeight / this.dimensions.originalVideoHeight
    );

    // set video holder to crop
    this.videoHolder.style.width =
      this.dimensions.originalVideoWidth * this.dimensions.videoScale + "px";
    this.videoHolder.style.height =
      this.dimensions.originalVideoHeight * this.dimensions.videoScale + "px";

    // set video element to account for hidden margin
    // width of original - width of smaller holder / half
    const marginLeft =
      (this.dimensions.originalVideoWidth *
        this.dimensions.videoScale *
        this.ZOOM_FACTOR -
        this.dimensions.originalVideoWidth * this.dimensions.videoScale) *
      0.5;
    const marginTop =
      (this.dimensions.originalVideoHeight *
        this.dimensions.videoScale *
        this.ZOOM_FACTOR -
        this.dimensions.originalVideoHeight * this.dimensions.videoScale) *
      0.5;
    this.video.style.left = "-" + marginLeft + "px";
    this.video.style.top = "-" + marginTop + "px";

    // video element
    this.video.width =
      this.dimensions.originalVideoWidth *
      this.dimensions.videoScale *
      this.ZOOM_FACTOR;
    this.video.height =
      this.dimensions.originalVideoHeight *
      this.dimensions.videoScale *
      this.ZOOM_FACTOR;

    // calculate scale to fit template image inside video
    this.dimensions.templateScale = Math.min(
      (this.dimensions.videoScale * this.dimensions.originalVideoWidth) /
        this.template.templateWidth,
      (this.dimensions.videoScale * this.dimensions.originalVideoHeight) /
        this.template.templateHeight
    );

    // guide boxes
    const boxWidth =
      this.template.templateWidth * this.dimensions.templateScale * 0.9;
    const boxHeight =
      this.template.templateHeight * this.dimensions.templateScale * 0.9;
    const boxMarginLeft =
      (this.dimensions.originalVideoWidth * this.dimensions.videoScale -
        this.template.templateWidth * this.dimensions.templateScale * 0.9) *
      0.5;
    const boxMarginTop =
      (this.dimensions.originalVideoHeight * this.dimensions.videoScale -
        this.template.templateHeight * this.dimensions.templateScale * 0.9) *
      0.5;

    this.guideBox.style.left = boxMarginLeft + "px";
    this.guideBox.style.top = boxMarginTop + "px";
    this.guideBox.style.width = boxWidth + "px";
    this.guideBox.style.height = boxHeight + "px";

    // debug
    console.log(
      "Camera Component Dimensions:",
      "\n canvas.height set to",
      this.template.templateHeight * this.dimensions.templateScale,
      "\n canvas.width set to",
      this.template.templateWidth * this.dimensions.templateScale,
      "\n window height is ",
      window.innerHeight,
      "\n window width is ",
      window.innerWidth,
      "\n max width is ",
      this.dimensions.maxWidth,
      "\n max height is ",
      this.dimensions.maxHeight,
      "\n original template.height is",
      this.template.templateHeight,
      "\n original template.width is",
      this.template.templateWidth,
      "\n original video.height is",
      this.dimensions.originalVideoHeight,
      "\n original video.width is",
      this.dimensions.originalVideoWidth,
      "\n video scale factor is",
      this.dimensions.videoScale,
      "\n template scale factor is",
      this.dimensions.templateScale,
      "\n zoom factor is",
      this.ZOOM_FACTOR
    );
  }

  /** Save camera capture to the answer-sheet and notify parents */
  scan() {
    console.log("CameraComponent: scan has been pressed");
    if (this.detectQRInProgress) {
        console.log('already scanning, ignore click;')
        return;
    }
    this.detectQRInProgress = true;
    this.detectQRInProgressChange.emit(true);
    this.video.pause();
    this.emitCroppedImage();
  }

  /** extract cropped image blob from camera canvas */
  emitCroppedImage() {
    return new Promise((resolve, reject) => {
      // create canvas for cropped image
      let canvas = document.createElement("canvas");
      canvas.setAttribute("id", "canvasScan");
      let context = canvas.getContext("2d", { willReadFrequently: true });

      // set canvas to cropped image size
      canvas.width =
        this.template.templateWidth * this.dimensions.templateScale;
      canvas.height =
        this.template.templateHeight * this.dimensions.templateScale;

      // calculate outer boundary locations
      const boxWidth =
        this.template.templateWidth * this.dimensions.templateScale * 0.9;
      const boxHeight =
        this.template.templateHeight * this.dimensions.templateScale * 0.9;
      const boxMarginLeft =
        (this.dimensions.originalVideoWidth * this.dimensions.videoScale -
          this.template.templateWidth * this.dimensions.templateScale * 0.9) *
        0.5;
      const boxMarginTop =
        (this.dimensions.originalVideoHeight * this.dimensions.videoScale -
          this.template.templateHeight * this.dimensions.templateScale * 0.9) *
        0.5;
      const [sx, sy, swidth, sheight] = [
        boxMarginLeft / this.dimensions.videoScale,
        boxMarginTop / this.dimensions.videoScale,
        boxWidth / this.dimensions.videoScale,
        boxHeight / this.dimensions.videoScale,
      ];

      console.log(
        "input canvas.height set to",
        this.template.templateHeight * this.dimensions.templateScale,
        "\n input canvas.width set to",
        this.template.templateWidth * this.dimensions.templateScale,
        "\n sx",
        sx,
        "\n sy",
        sy,
        "\n swidth",
        swidth,
        "\n sheight",
        sheight,
        "\n original template.height is",
        this.template.templateHeight,
        "\n original template.width is",
        this.template.templateWidth,
        "\n original video.height is",
        this.dimensions.originalVideoHeight,
        "\n original video.width is",
        this.dimensions.originalVideoWidth,
        "\n video scale factor is",
        this.dimensions.videoScale,
        "\n template scale factor is",
        this.dimensions.templateScale,
        "\n zoom factor is",
        this.ZOOM_FACTOR
      );

      // crop to inner window and send
      context.drawImage(
        this.video,
        sx,
        sy,
        swidth,
        sheight,
        0,
        0,
        this.template.templateWidth * this.dimensions.templateScale,
        this.template.templateHeight * this.dimensions.templateScale
      );

      // pass out updated blob
      canvas.toBlob(
        (blob) => {
          this.cameraCropCaptured.emit(blob);
          canvas = null;
          context = null;
          resolve("cropped image created successfully");
        },
        "image/jpeg",
        0.95
      );
    });
  }

  /** If we have paused camera after a scan call this function to get it running again quickly */
  unPause() {
    this.video.play();
  }

  /** User has pressed 'back' button */
  back() {
    console.log("CameraComponent: back has been pressed");
    this.cameraNavigateBack.emit();
  }

  /** User has pressed 'cancel' button */
  cancel() {
    console.log("CameraComponent: cancel has been pressed");
    this.cameraCancel.emit();
  }
}
