import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";

import { User, UserRoles } from "../models/user";
import { Facility } from "../models/facility";
import { AppSettings } from "../models/settings";

import { FacilityService } from "../services/facility.service";
import { RepositoryObserver } from "../services/repository-observer";
import { AuthenticationService } from "../services/authentication.service";
import {
  RepositoryService,
  SyncStatus,
  AppTypes,
} from "../services/repository.service";

import { map } from "rxjs/operators";
import { Observable, merge, of, fromEvent, BehaviorSubject } from "rxjs";

@Component({
  selector: "paper-top-nav",
  templateUrl: "top-nav-user.component.html",
})
export class TopNavComponent implements RepositoryObserver {
  @Input() appSettings: AppSettings = new AppSettings();
  // timer handles for notify
  facilityHandle;

  public syncStatusEnum = SyncStatus;
  public appTypeEnum = AppTypes;
  online: Observable<boolean>;
  appVersion: string;
  facility: Facility;
  user: User;
  isCHAUser: boolean;
  userRoles = UserRoles;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    public repository: RepositoryService,
    private matIconRegistry: MatIconRegistry,
    private facilityService: FacilityService,
    public authService: AuthenticationService
  ) {
    this.appVersion = environment.appVersion;
    this.matIconRegistry.addSvgIcon(
      "microscope",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/microscope.svg"
      )
    );

    // get facility information
    this.user = this.authService.getUser();
    this.getFacility();

    // TODO: fix for angular 6
    this.online = new BehaviorSubject<boolean>(false);
    this.online = merge(
      of(navigator.onLine),
      fromEvent(window, "online").pipe(map(() => true)),
      fromEvent(window, "offline").pipe(map(() => false))
    );

    this.isCHAUser = this.authService.getUser().userRoles
      ? this.authService
          .getUser()
          .userRoles.includes(this.repository.enumSelector(UserRoles)[3].title)
      : true;
  }

  /**
   * Listen for notifications from pouch
   */
  notify(objectType: string): void {
    if (objectType === Facility.type) {
      clearTimeout(this.facilityHandle);
      this.facilityHandle = setTimeout(() => {
        console.log("get updated facilities");
        this.getFacility();
      }, 3000);
    }
  }

  /** log out current user */
  userLogout() {
    this.authService.makeGlobalsFalse();
    this.router.navigate(["login"]);

    // signout the user from couchdb
    this.authService.userSignOut().then((response) => {
      if (response["ok"] || response["ok"] === true) {
        this.snackBar.open("Successfully logged out.", "Success", {
          duration: 6000,
        });
      }
    });
  }

  /** get loged in user facility */
  getFacility() {
    this.facilityService
      .getFacility(this.user.facility)
      .then((facility) => {
        if (!facility) {
          console.log("No facility found");
        } else {
          this.facility = facility;
        }
      })
      .catch((error) => {
        console.log("Error loading user facility", error);
        console.log("The facility may not have synced yet, We will attempt to load this facility again if more facility information is synced");
      });
  }
}
