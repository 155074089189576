<!-- Content Container -->
<div style="padding: 0" [hidden]="loadingOpenCV">
  <router-outlet></router-outlet>
</div>

<div class="width100" *ngIf="loadingOpenCV" style="top: 30%; position: absolute; width: 100%; text-align: center; height: 100%;">
  <img src="assets/images/logo.svg" height="60" /><br />
  <span *ngIf="loadingOpenCV">Loading libraries.<br /> </span>
  Please wait.<br />
  <mat-progress-spinner color="primary" mode="indeterminate" class="uk-margin uk-align-center"></mat-progress-spinner>
</div>
