import { PouchObject } from './pouch-object';

/**
 * Store filled in copies of the rubberStamp that have been scanned into the app
 * answers are stored in answers property
 */
export class AnswerSheet implements PouchObject {
  public static type: string = 'answersheet';
  public static dateFields: string[] = ['seriesStartDate','immunizationDate','encounterDate', 'plannedTransfusionDate', 'transfusionEndDate', 'transfusionStartDate'];
  public static fields = [
    '_id', '_rev', '_deleted', 'type', 'serialNumber',
    'stampTemplateId', 'patientId', 'answers', 'encounterDate', 'encounterTime', 'dateAdded', 'deviceInfo', 'failedGroups', 'location',
    'facilityWard', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility', 'failed', 'sharedFacilities', 'skipped',
    'otherAnswers', 'plannedTransfusionDate', 'plannedTransfusionTime', 'transfusionEndDate', 'transfusionEndTime', 'reportSeriesId',
    'sheetErrors', 'fixedErrors', 'handwritingResults', 'penNumber', 'emailAddress', 'phone', 'customBeforeFieldsData', 'customAfterFieldsData'];

  stampTemplateId: string; // reference to template object
  patientId: string; // reference to patient object
  encounterDate: any; // date of encounter
  encounterTime?: string; // time of encounter
  location?: any; // gps coordinates when scan took place
  answers: AnswerSheetAnswerGroup; // store list of properties with field name as index indicating whether circle is filled or not
  otherAnswers?: OtherAnswers;
  failedGroups?: string[];
  facilityWard?: string; //ward field for blood safe
  plannedTransfusionDate: Date;
  plannedTransfusionTime: string;
  transfusionEndDate: Date;
  transfusionEndTime: string;
  reportSeriesId: string;
  serialNumber: number;
  handwritingResults?: HandwritingResultGroup; // store results extracted from fields written by hand
  sheetErrors?: SheetError[];
  fixedErrors?: SheetError[];
  penNumber: number;

  customBeforeFieldsData?: any = {};
  customAfterFieldsData?: any = {};

  emailAddress: string;
  phone: string;

  type: string;
  _id: string;
  _rev: string;
  _deleted: boolean = false;
  sharedFacilities: string[];

  dateAdded: Date | string;
  dateUpdated: Date | string;
  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;
  failed: boolean = false;
  skipped: boolean = false;

  deviceInfo?: PsocDeviceInfo;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class SheetError {
  status: SheetErrorStatusCodes; // any of pending, fixed
  code: SheetErrorCodes;
  message: string;
  data?: any; // store extra data for the error
}

export enum SheetErrorCodes {
  commodityCalculationFailed,
  invalidReportDate,
  commodityCountMismatch,
  countVsMaxMismatch,
  exclusiveOMRGroupHasMultiples,
  mismatchTotalHWvsMaxOMR,
  mismatchTotalHWvsSumHW,
  mismatchPolioDosesCalculation,
  mismatchImmunizationDateHWvsForm,
  requiredOMRGroupNotFilled,
  requiredHWGroupNoValues,
  newlyAFPMustHavePhone,
  newlyAFPMustHaveAge,
  screenedBeforeMustHaveScreenedTimes,
  referredMustHaveReferredTo,
  referredRequired,
  mismatchTeamNumberHWvsForm,
  hwDateInvalid,
  mismatchDFACodeHWvsUser,
  mismatchTotalHWvsCountOMR
}

export enum SheetErrorStatusCodes {
  pending,
  fixed
}

export interface PsocDeviceInfo {
  // Device type
  userAgent: string;
  os: any;
  device: any;
  client: any;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;

  // IP Address
  ipAddress: string;

  // WebGL Precision supported
  supportedPrecision: string;

  // Location information
  locationInfo?: any;

  // App version
  appVersion: string;
  templateInfo?: any;

  omrExtractionInfo: any;
}

export interface HandwritingResultGroup {
  [index: string]: HandwritingResult; // track a group of handwriting results
}

export interface HandwritingResult {
  [index: string]: HandwritingResultData; // track data in a handwriting field
}

export interface HandwritingResultData {
  userInputVal: string; // result entered by user
  mlResult: string; // result from ML engine
  userValidatedResult: string; // user-validated result
  adminValidatedVal: string; // admin-validated result
  displayVal: string; // result from ML engine at first then corrected value by admin
  modelVersion: string; // name and version of ML model used to detect mlResult
  confidence: number; // confidence of ML result
  adminValidationDate: Date; // date admin validation took place
}

export interface AnswerSheetAnswerGroup {
  [index: string]: AnswerSheetAnswer; // track a group of answers
}

export interface AnswerSheetAnswer {
  [index: string]: boolean; // track whether this answer has been checked or not
}

export interface OtherAnswers {
  [groupName: string]: OtherFieldAnswer;
}

export interface OtherFieldAnswer {
  [fieldName: string]: string;
}

/** Sample answer sheet object */
export const sampleAnswerSheet: AnswerSheet = {
  _id: 'UUIDFROMCOUCH',
  patientId: '234678',
  stampTemplateId: 'UUIDOFSTAMPTEMPLATE',
  encounterDate: new Date(),
  encounterTime: 'time o\'clock',
  type: 'answersheet',
  plannedTransfusionDate: new Date(),
  plannedTransfusionTime: 'Time check',
  transfusionEndDate: new Date(), // bloodsafe
  transfusionEndTime: 'Time check', // bloodsafe
  reportSeriesId: 'some_series_id',
  serialNumber: 1234,
  penNumber: 4,  // farmalert
  emailAddress: 'd@d.com',
  phone: '+2547',
  answers: {
    'gender': {
      'male': true,
      'female': false
    },
    'age': {
      '15-18': true,
      '19-38': true
    }
  },
  handwritingResults: {
    'phone': {
      'phone_0': {
        mlResult: "9", userInputVal: "4", userValidatedResult: "9", displayVal: "9", adminValidatedVal: '9', modelVersion: 'V1',
        confidence: 9, adminValidationDate: new Date('2022-11-16')
      },
      'phone_1': {
        mlResult: "9", userInputVal: "4", userValidatedResult: "9", displayVal: "9", adminValidatedVal: '9', modelVersion: 'V1',
        confidence: 9, adminValidationDate: new Date('2022-11-16')
      },
    }
  },
  otherAnswers: {},
  customAfterFieldsData: {
    encounterTime: '0900 o\'clock',
    failed: true
  },
  customBeforeFieldsData: {},
  // pouch/couch/app metadata
  _rev: '3-16a1ad0d23434d60b84aafd5bb6653db',
  _deleted: false,
  dateAdded: new Date('2017-10-18T07:23:20.619Z'),
  dateUpdated: new Date('2017-10-18T07:23:20.619Z'),
  updatedBy: 'kennie',
  createdBy: 'kennie',
  createFacility: 'IRC',
  updateFacility: 'IRC',
  failed: false,
  skipped: false,
  sharedFacilities: []
};
