import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      'required': 'This field is required',
      'invalidCreditCard': 'Is invalid credit card number',
      'invalidEmailAddress': 'Invalid email address',
      'invalidPassword': 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      'minlength': `Minimum length ${validatorValue.requiredLength}`,
      'maxlength': `Maximum length ${validatorValue.requiredLength}`,
      'invalidDateInPast': 'This Date must be in the past',
      'invalidDateFormat': 'Invalid date format, please use DD/MM/YYYY',
      'invalidTimeDateInPast': 'The Time and Date selected must be in the past',
      'invalidConfirmPassword': 'The passwords do not match',
      'invalidSpecimenId': 'Specimen Id should start with LS followed by numbers',
      'invalidPhoneNumber': 'Phone number should start with 01,07,08 or 09',
      'invalidPhoneNumberString': 'Phone number should NOT contain alphabets A-z',
      'invalidStudyIdFormat': 'Study ID should match the pattern 1234-678-1',
      'invalidStudyId': 'The Study ID is invalid.',
      'invalidNumber': 'The value entered is not a number',
      'minLengthRequired': `Minimum length required is: ${validatorValue.requiredLength}`,
      'maxLengthRequired': `Maximum length required is: ${validatorValue.requiredLength}`,
      'invalidateTransfusionOlderThanA': 'Date and Time should not be in the past of Requisition Date and Time.',
      'invalidateOlderThanA': 'Date and Time should not be older than that of template A.',
      'invalidateOlderThanAorC': 'Date and Time should not be older than that of template A OR C.',
      'differentSerialNumber': 'The serial number is different from the current series.',
      'differentPatientNumber': 'The reg # is different from the current series.',
      'selectAnOption': 'Make sure to select an option from the list.',
      'valueExist': `${validatorValue.message}`,
      'emailNotManagedByGoogle': 'We currently only support gmail or google-managed email addresses.',
      'accountExist': 'An account with the same mobile number already exist, proceed to login or use another mobile number.'
    };

    return config[validatorName];
  }

  static specimenIdValidator(control: any) {
    if (!control.value) { return; }
    // ^[Ll][Ss]\d+$ - Assert specimen ID starts with LS followed by numbers
    if (control.value.match(/^[Ll][Ss]\d+$/)) {
      return null;
    } else {
      return { 'invalidSpecimenId': true };
    }
  }

  static creditCardValidator(control: any) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    // eslint-disable-next-line max-len
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }

  static emailValidator(control: any) {
    // RFC 2822 compliant regex
    if (control.value) {
      // eslint-disable-next-line max-len
      if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
        return null;
      } else {
        return { 'invalidEmailAddress': true };
      }
    }
  }

  static passwordValidator(control: any) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (!control.value) { return }
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,50}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }

  /**
   * function check if a number meets the required validation of max and min values
   * @param params {min?: number, max?: number} min max values passed
   * @returns if error or not error
   */
  static numberMinMax(params: any = {}) {
    return (control: any): { [key: string]: any } => {
      let val: number = control.value;

      if (!val) { return null; } // check if value is null
      if (isNaN(val)) { return { 'invalidNumber': true } } // check if value is a number or not

      const valString = val.toString(); // convert to string for length checking

      if (valString.length < params.min) {
        return { 'minLengthRequired': true, 'requiredLength': params.min }
      } else if (valString.length > params.max) {
        return { 'maxLengthRequired': true, 'requiredLength': params.max }
      } else {
        return null;
      }

    };
  }

  static dateInPastValidator(control: any) {
    // date of birth must be in past
    const dob = new Date(control.value);
    if (dob.getTime() < (new Date()).getTime()) {
      return null;
    } else {
      return { 'invalidDateInPast': true };
    }
  }

  static passwordConfirm(passwordControl: any, confirmPasswordControl: any): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const password = group.controls[passwordControl], confirmPassword = group.controls[confirmPasswordControl];
      if (password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ 'invalidConfirmPassword': true });
      } else {
        confirmPassword.setErrors(null);
      }
      return;
    };
  }

  static dateFormatValid(dateControl: any) {
    // value must be valid format
    // TODO: Update the date format while working on timezones task
    if (moment(dateControl.value, 'MM/DD/YYYY').isValid()) {
      return null;
    } else {
      return { 'invalidDateFormat': true };
    }
  }

  static timeDateInPastValidator(timeControlName: any, dateControlName: any) {
    // combination of date and time controls must be in past

    return (group: FormGroup) => {

      const dateControl = group.controls[dateControlName];
      const timeControl = group.controls[timeControlName];

      const now = new Date();
      const dateVal = new Date(dateControl.value);
      const timeVal = this.parseTime(timeControl.value);

      if (timeVal === undefined) {
        return;
      }

      // eslint-disable-next-line max-len
      const dateAndTime = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), timeVal.getHours(), timeVal.getMinutes(), 0);
      // console.log('validate date & time dateControl.value:', dateControl.value,
      //   'dateVal:', dateVal,
      //   'timeControl.value:', timeControl.value,
      //   'timeVal:', timeVal,
      //   'dateAndTime:', dateAndTime);

      if (dateAndTime < now) {
        return timeControl.setErrors(null);
      } else {
        return timeControl.setErrors({ 'invalidTimeDateInPast': true });
      }

    };

  }

  static parseTime(t) {
    const d = new Date();
    if (!t) { return; }
    const time = t.match(/(\d+)(?::(\d\d))?\s*([AaPp][Mm])/);
    const hour = parseInt(time[1], 10)
    d.setHours(hour + ((time[3] === 'PM' && hour !== 12) || (time[3] === 'AM' && hour === 12) ? 12 : 0));
    d.setMinutes(parseInt(time[2], 10) || 0);
    return d;
  }

  static studyIdValidator(control: any) {
    const id = control.value?.patientId ? `${control.value?.patientId}` : `${control.value}`;
    if (!id) { return; }
    const idNumber = id.replace(/-/g, ''); // remove the hyphens ("-") from the study id
    return ((id.match(/^\d{4}-\d{3}-\d{1}$/)) ?
      (
        ValidationService.validateStudyId(idNumber) ?
          null : { 'invalidStudyId': true }
      ) : { 'invalidStudyIdFormat': true });
  }

  static phoneNumberValidation(phoneNumberControl: any) {
    const phoneNumber = phoneNumberControl.value;
    if (phoneNumber) {
      if (phoneNumber.match(/^-?\d+$/)) {
        const phone = phoneNumber.split('');
        if (phone[0] !== '0' || !(phone[1] !== '1' || phone[1] !== '7' || phone[1] !== '8' || phone[1] !== '9')) {
          return { 'invalidPhoneNumber': true };
        } else {
          return null;
        }
      } else {
        return { 'invalidPhoneNumberString': true };
      }
    }
  }

  /**
   * Function validate if study id is a valid number, using Luhn algorithm
   * @param number number to be validated 
   * @returns boolean
   */
  static validateStudyId(number: string): boolean {
    const last = +(number.slice(- 1)), code = number.slice(0, -1);
    const sum = this.getSum(code);
    const lastVal = (10 - (sum % 10) % 10);
    return last === lastVal;
  }

  /**
   * function sum numbers, starting from the right, moving left, double the value of every second digit (including the rightmost digit)
   * https://en.wikipedia.org/wiki/Luhn_algorithm#Example_for_computing_check_digit
   * @param code the code/number to sum the numbers
   * @returns sum
   */
  static getSum(code: string): number {
    const splitCode = code.split('');
    let sum = 0, shouldDouble = true;

    splitCode.reverse().map((val, i) => {
      let digit = +val;

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) { // if sum is > 9 then sum is (sum - 9) else sum
          digit -= 9;
        }
      }
      sum += digit;
      shouldDouble = !shouldDouble;
    });
    return sum;
  }
}
