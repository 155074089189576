import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toLocalTime'
})
export class ToLocalTimePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) return value;
    try{
        const valueWithoutOffset = value.replace(/([+-]\d{2}:\d{2})$/, '');
        const momentDate = moment.utc(valueWithoutOffset);
        return momentDate;
    }
    catch(error){
        const momentDate = moment(value);
        return momentDate;
    }
  }
}
