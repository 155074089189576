<!-- summary info header -->
<header class="uk-comment-header">
  <div class="uk-comment-meta">
    <span *ngIf="appSettings.recordSettings.showSerialNumberField">
      <strong>{{serialNumberLabel}}: </strong>
      <span *ngIf="appSettings.recordSettings.enableReportSeries; else nonReportSeries">
        <a routerLink="/patients/report_series/{{sheet.reportSeriesId}}"
          style="color: #0000EE; text-decoration: underline; cursor: pointer;">{{sheet.serialNumber}}</a>
      </span>
      <ng-template #nonReportSeries>{{sheet.serialNumber}}</ng-template>,
    </span>
    <span *ngIf="appSettings.patientSettings.enablePatientObjects; else nonPatient">
      <strong>{{appSettings.patientSettings.patientIdLabel}}: </strong>
      <span *ngIf="currentPatient">
        <a routerLink="/patients/patient/{{currentPatient._id}}"
          style="color: #0000EE; text-decoration: underline; cursor: pointer;">{{currentPatient.patientId}}</a>
      </span>
      <span *ngIf="!currentPatient">{{sheet.patientId}}</span>,
    </span>
    <ng-template #nonPatient>
      <span *ngIf="showPen">
        <strong>Pen #: </strong>{{sheet.penNumber}},
      </span>
    </ng-template>

    <span *ngIf="appSettings.uiViewSettings.polioWorkflow; else elseFacilityBlock">
      {{facility?.name}}; {{facility?.code}},
    </span>

    <ng-template #elseFacilityBlock>
      <strong>Facility: </strong>{{sheet.createFacility | getFacilityName: facilities}},
    </ng-template>

    <span *ngIf="appSettings.uiViewSettings.safeBloodWorkflow">
      <strong>Ward: </strong>{{sheet.facilityWard | getFacilityWard: facility.facilityWards}},
    </span>

    <span *ngIf="appSettings.uiViewSettings.mhGapWorkFlow">
      <strong>Screening Org: </strong>{{sheet.customBeforeFieldsData?.screeningOrg}},
    </span>

    <strong>Template:</strong> {{template.name}} {{template.description}} {{template.version}},
    <strong>Date: </strong>
    <span>{{sheet | workFlowDate: appSettings:appSettings.recordSettings.dateMaskFormat  }}</span>
    <span *ngIf="sheet.encounterTime"> : {{sheet.encounterTime}}</span>,

    <span matTooltip="Created: {{sheet.dateAdded | toLocalTime | date: appSettings.recordSettings.dateMaskFormat}} {{sheet.createdBy}} ({{sheet.createFacility}})
      Modified: {{sheet.dateUpdated | toLocalTime | date:appSettings.recordSettings.dateMaskFormat}} {{sheet.updatedBy}} ({{sheet.updateFacility}})">
      <i class="fa fa-calendar" aria-hidden="true"></i> {{sheet.dateUpdated | toLocalTime | amTimeAgo:true}} ago.
    </span>
  </div>
</header>
<hr />

<!-- loading spinner -->
<div class="uk-text-center" *ngIf="loadingPatientRecords">
  <mat-progress-bar mode="query" class="uk-margin-small-bottom"></mat-progress-bar>
  <span>Loading selected {{appSettings.patientSettings.patientIdLabel}}...</span>
</div>

<!-- top buttons row -->
<div class="form-group uk-margin-bottom uk-clearfix">
  <ng-template isAuthorized [authorizedRoles]="[userRoles.editor]">
    <!--Button to edit sheet flow -->
    <button mat-raised-button type="submit" (click)="editSheet()" class="uk-margin-small-right" *ngIf="(appTypeEnum[repository.getApp()] === 'adminApp' && appSettings.recordSettings.enableEditRecordInAdmin) ||
    (appSettings.recordSettings.enableEditRecordInUser && userAllowedEditFlow())">
      Edit {{appSettings.uiViewSettings.recordLabel}}
    </button>
  </ng-template>

  <ng-template isAuthorized [authorizedRoles]="[userRoles.mediator]">
    <!--Button to proceed to Next Template-->
    <button mat-raised-button type="button"
      *ngIf="((templateInSeries && facilityTemplates.length > 1) || showFarmNextRecordButton) && nextTemplateButton"
      (click)="showFarmNextRecordButton ? nextFarmRecordScan.emit() : nextTemplateScan.emit(); nextClicked=true"
      class="uk-float-right uk-margin-small-right next-button" [ngStyle]="nextClicked ? {'width': '140px'} : {'':''}"
      [disabled]="disableShowNextFarmRecordBtn || isLastInSeries || nextClicked">
      <span *ngIf="showFarmNextRecordButton; else defaultNextRecordButton">Next Record (same pen)</span>
      <ng-template #defaultNextRecordButton>Next {{appSettings.uiViewSettings.templateFieldLabel}}
        <mat-icon>navigate_next</mat-icon>
      </ng-template>
    </button>

    <!-- next record in report view -->
    <button mat-raised-button type="button" class="uk-float-right uk-margin-small-right"
      (click)="navigateRecordsInReport('next')"
      [disabled]="disableNextRecordInReport"
      *ngIf="(appSettings.recordSettings.enableReportSeries && templateInSeries && facilityTemplates.length > 1) && !nextTemplateButton">
      <mat-icon class="uk-margin-center uk-margin-small-right uk-margin-small-left">navigate_next</mat-icon>
    </button>
    
    <!-- prev record in report view -->
    <button mat-raised-button type="button" class="uk-float-right uk-margin-small-right"
      (click)="navigateRecordsInReport('previous');"
      [disabled]="disablePreviousRecordInReport"
      *ngIf="(appSettings.recordSettings.enableReportSeries && templateInSeries && facilityTemplates.length > 1) && !nextTemplateButton">
      <mat-icon class="uk-margin-center uk-margin-small-right uk-margin-small-left">navigate_before</mat-icon>
    </button>

    <div class="uk-text-center uk-margin-small-top" *ngIf="loadingRecordInReport">
      <mat-progress-bar mode="query" class="uk-margin-small-top uk-margin-small-bottom"></mat-progress-bar>
      <span>Loading {{viewDirection}} record in report ...</span>
    </div>
  </ng-template>
</div>

<!-- display scanned data -->
<ul class="uk-list uk-list-line"
  *ngIf="!appSettings.uiViewSettings.cicWorkflow || (appSettings.uiViewSettings.cicWorkflow && isCHAUser)">
  <!-- user input data fields here -->
  <li *ngIf="template.showEncounterDate">
    <strong>{{dateFieldLabel}}:</strong> {{sheet.encounterDate | date:appSettings.recordSettings.dateMaskFormat }}
  </li>
  <li *ngIf="template.showEncounterTime">
    <strong>{{timeFieldLabel}}:</strong> {{sheet.encounterTime }}
  </li>
  <li *ngIf="appSettings.uiViewSettings.safeBloodWorkflow && template.showPlannedTransfusionDate">
    <strong>Planned Transfusion Date:</strong> {{sheet.plannedTransfusionDate | date:appSettings.recordSettings.dateMaskFormat }}
  </li>
  <li *ngIf="appSettings.uiViewSettings.safeBloodWorkflow && template.showPlannedTransfusionTime">
    <strong>Planned Transfusion Time:</strong> {{sheet.plannedTransfusionTime}}
  </li>
  <li *ngIf="appSettings.uiViewSettings.safeBloodWorkflow && template.showTransfusionEndDate">
    <strong>Transfusion End date:</strong> {{sheet.transfusionEndDate | date:appSettings.recordSettings.dateMaskFormat }}
  </li>
  <li *ngIf="appSettings.uiViewSettings.safeBloodWorkflow && template.showTransfusionEndTime">
    <strong>Transfusion End Time:</strong> {{sheet.transfusionEndTime}}
  </li>
  <li *ngIf="appSettings.uiViewSettings.polioWorkflow">
    <strong *ngIf="appSettings.uiViewSettings.userLabel; else otherLabel;"> {{appSettings.uiViewSettings.userLabel}}: </strong> 
    <ng-template #otherLabel>DFA area code:</ng-template>
    {{sheet.createdBy}}
  </li>

  <!-- farmAlert pen # field -->
  <li *ngIf="showPen">
    <strong>Pen #:</strong> {{sheet.penNumber}}
  </li>

  <!-- handwriting data fields here -->
  <li *ngFor="let templateHWGroup of filterHiddenHWGroups(template.handwritingGroups)">

    <strong>{{ templateHWGroup.groupLabel ? templateHWGroup.groupLabel : templateHWGroup.groupTitle }}</strong>:

    <span [ngSwitch]="templateHWGroup.dataFormat" matTooltip="Data Format: {{ templateHWGroup.dataFormat }}">
      <!-- data to be shown when there is any other or no data type -->
      <ng-container *ngSwitchDefault>
        {{ (templateHWGroup | getHWValues : template.handwritingFields : sheet.handwritingResults[templateHWGroup.groupTitle]) || 'Not filled' }}
      </ng-container>

      <!-- data to be shown HW group datatype date so we can send date mask -->
      <ng-container *ngSwitchCase="'date'">
        {{ templateHWGroup | getHWValues
        : template.handwritingFields
        : sheet.handwritingResults[templateHWGroup.groupTitle] 
        : appSettings.recordSettings.dateMaskFormat }}
      </ng-container>
    </span>

  </li>

  <!-- OMR data fields here -->
  <li *ngFor="let answerGroup of filterHiddenOMRGroups(template.groups)">
    <strong>{{answerGroup.groupLabel ? answerGroup.groupLabel : answerGroup.groupTitle}}</strong>:
    <span>{{getAnswers(answerGroup.groupTitle) | getCountValue: answerGroup.groupTitle : template }}</span>
  </li>

  <!-- custom data fields here -->
  <li *ngFor="let customField of getFields(sheet.customBeforeFieldsData)">
    <ng-container *ngIf="displayFormioFieldInView(customField)">
      <strong>{{getFormioFieldLabel(customField)}}</strong>:
      {{sheet.customBeforeFieldsData[customField]}}
    </ng-container>
  </li>

</ul>

<!-- errors display if enabled -->
<div *ngIf="sheet.sheetErrors?.length > 0 && appSettings.uiViewSettings.showSheetErrors">
  <hr />
  <strong>Validation Errors</strong>
  <ul class="uk-list uk-list-line">
    <li *ngFor="let sheetError of sheet.sheetErrors">

      <div [ngSwitch]="sheetError.code">

        <!-- message to be shown when there is any other error code -->
        <ng-container *ngSwitchDefault>
          <strong class="uk-text-danger">Error:</strong> {{sheetError.message}}
        </ng-container>

        <!-- message to be shown when there is invalid report date -->
        <ng-container *ngSwitchCase="SheetErrorCodes.invalidReportDate">
          <strong class="uk-text-danger">Error:</strong> {{sheetError.message}}
        </ng-container>

        <!-- message to be shown when there is a commodity count mismatch -->
        <ng-container *ngSwitchCase="SheetErrorCodes.commodityCountMismatch">
          <strong class="uk-text-danger">Error:</strong> {{sheetError.message}}
        </ng-container>

        <!-- message to be shown when there is a calculation error -->
        <ng-container *ngSwitchCase="SheetErrorCodes.commodityCalculationFailed">
          <strong class="uk-text-danger">Error:</strong> {{sheetError.message}}
          <table class="uk-margin-top-remove uk-table uk-table-striped uk-table-condensed"
            style="border: 2px solid rgba(34, 34, 34, 0.1);">
            <thead>
              <tr>
                <th colspan="3">{{ sheetError.data.groupName }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Beginning balance</td>
                <td>+</td>
                <td>{{ sheetError.data.groupResults['Beginning Balance'] }}</td>
              </tr>
              <tr>
                <td>Qty received:</td>
                <td>+</td>
                <td>{{ sheetError.data.groupResults['Quantity Received'] }}</td>
              </tr>
              <tr>
                <td>Qty dispensed:</td>
                <td>-</td>
                <td>{{ sheetError.data.groupResults['Quantity Dispensed'] }}</td>
              </tr>
              <tr>
                <td>Added:</td>
                <td>+</td>
                <td>{{ sheetError.data.groupResults['Stock Added'] }}</td>
              </tr>
              <tr>
                <td>Removed:</td>
                <td>-</td>
                <td>{{ sheetError.data.groupResults['Stock Removed'] }}</td>
              </tr>
              <tr>
                <td>Ending balance:</td>
                <td>=</td>
                <td class="uk-text-danger">{{ sheetError.data.groupResults['Ending Balance'] }}</td>
              </tr>
            </tbody>
          </table>
          <!-- end of cic stock calculation error display -->

        </ng-container>
      </div>
    </li>
  </ul>
</div>

<!-- show scanned image -->
<h3>Scanned Image {{scannedImageNotAvailable}}</h3>
<div style="margin-left: -20px; overflow: hidden; position: relative">
  <canvas id="canvasViewCircles"></canvas>

  <!-- Handwriting Field Results -->
  <div style="position: absolute; top:0; left:0">
    <ng-container *ngFor="let handwritingField of template?.handwritingFields; let item_index=index">
      <div *ngIf="!(handwritingField | hideHwFieldInView: template)">
        <a class="handwritingSelector" disabled
          *ngIf="sheet && sheet.handwritingResults && sheet.handwritingResults[handwritingField.groupName] && sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName]"
          [ngStyle]="{ 
          'top': handwritingField.y * dimensions.templateScale + 'px', 
          'left': handwritingField.x * dimensions.templateScale + 'px', 
          'width': template.handwritingFieldWidth * dimensions.templateScale + 'px',
          'height': template.handwritingFieldHeight * dimensions.templateScale + 'px' 
        }">

          <span
            *ngIf="sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal.toLowerCase() === 'blank'; else othersResults"></span>

          <ng-template #othersResults>
            <span
              *ngIf="sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal?.toLowerCase() === 'unknown'; else HWResults">?</span>
          </ng-template>

          <ng-template #HWResults>
            {{sheet.handwritingResults[handwritingField.groupName][handwritingField.fieldName].displayVal  }}
          </ng-template>
        </a>
      </div>
    </ng-container>
  </div>
</div>

<!-- bottom buttons row -->
<div class="form-group uk-margin-top">
  <ng-template isAuthorized [authorizedRoles]="[userRoles.editor]">
    <!--Button to edit sheet flow -->
    <button mat-raised-button type="submit" (click)="editSheet()" class="uk-margin-small-right" *ngIf="(appTypeEnum[repository.getApp()] === 'adminApp' && appSettings.recordSettings.enableEditRecordInAdmin) ||
    (appSettings.recordSettings.enableEditRecordInUser && userAllowedEditFlow())">
      Edit {{appSettings.uiViewSettings.recordLabel}}
    </button>
  </ng-template>

  <ng-template isAuthorized [authorizedRoles]="[userRoles.mediator]">
    <!--Button to proceed to Next Template-->
    <button mat-raised-button type="button"
      *ngIf="((templateInSeries && facilityTemplates.length > 1) || showFarmNextRecordButton) && nextTemplateButton"
      (click)="showFarmNextRecordButton ? nextFarmRecordScan.emit() : nextTemplateScan.emit(); nextClicked=true"
      class="uk-float-right uk-margin-small-right next-button" [ngStyle]="nextClicked ? {'width': '140px'} : {'':''}"
      [disabled]="disableShowNextFarmRecordBtn || isLastInSeries || nextClicked">
      <span *ngIf="showFarmNextRecordButton; else defaultNextRecordButton">Next Record (same pen)</span>
      <ng-template #defaultNextRecordButton>Next {{appSettings.uiViewSettings.templateFieldLabel}}
        <mat-icon>navigate_next</mat-icon>
      </ng-template>
    </button>

    <!-- next record in report view -->
    <button mat-raised-button type="button" class="uk-float-right uk-margin-small-right"
      (click)="navigateRecordsInReport('next')"
      [disabled]="disableNextRecordInReport"
      *ngIf="(appSettings.recordSettings.enableReportSeries && templateInSeries && facilityTemplates.length > 1) && !nextTemplateButton">
      <mat-icon class="uk-margin-center uk-margin-small-right uk-margin-small-left">navigate_next</mat-icon>
    </button>
    
    <!-- prev record in report view -->
    <button mat-raised-button type="button" class="uk-float-right uk-margin-small-right"
      (click)="navigateRecordsInReport('previous')"
      [disabled]="disablePreviousRecordInReport"
      *ngIf="(appSettings.recordSettings.enableReportSeries && templateInSeries && facilityTemplates.length > 1) && !nextTemplateButton">
      <mat-icon class="uk-margin-center uk-margin-small-right uk-margin-small-left">navigate_before</mat-icon>
    </button>

    <div class="uk-text-center uk-margin-small-top" *ngIf="loadingRecordInReport">
      <mat-progress-bar mode="query" class="uk-margin-small-top uk-margin-small-bottom"></mat-progress-bar>
      <span>Loading {{viewDirection}} record in report ...</span>
    </div>

  </ng-template>
</div>

<!-- <span *ngIf="sheet">
  <strong>answer sheet:</strong>
  <pre><code>{{sheet | json}}</code></pre><br />
</span>
<span *ngIf="template"><strong>template.customBeforeFormFields:</strong>
  <pre><code>{{template.customBeforeFormFields | json}}</code></pre><br />
</span>
<span *ngIf="sheet"><strong>sheet.customBeforeFieldsData:</strong>
  <pre><code>{{sheet.customBeforeFieldsData | json}}</code></pre><br />
</span>
<span *ngIf="template"><strong>template.customBeforeFormFields:</strong>
  <pre><code>{{template.customBeforeFormFields | json}}</code></pre><br />
</span>
<span *ngIf="sheet"><strong>sheet.customAfterFieldsData:</strong>
  <pre><code>{{sheet.customAfterFieldsData | json}}</code></pre><br />
</span>

<span *ngIf="template"><strong>template.handwritingGroups:</strong>
  <pre><code>{{template.handwritingGroups | json}}</code></pre><br />
</span>
<span *ngIf="template"><strong>template.handwritingFields:</strong>
  <pre><code>{{template.handwritingFields | json}}</code></pre><br />
</span> -->