<!-- Top Nav -->
<paper-top-nav></paper-top-nav>
<div *ngIf="!isRoot; else userManageBlock">
  <h2>User Management</h2>
  <p>
    Sorry, this functionality is only available for database root users. Please log in as a database root user.
  </p>
</div>

<ng-template #userManageBlock>
  <mat-progress-bar mode="indeterminate" *ngIf="loadingFacilities || loadingUsers"></mat-progress-bar>
  <div *ngIf="loadingFacilities || loadingUsers">
    Loading
    <span *ngIf="loadingUsers">users</span>
    <span *ngIf="loadingFacilities">facilities</span>
  </div>

  <div fxLayout="row" fxFill fxLayoutGap="20px" fxLayoutAlign="start start">
    <div fxFlex="50">
      <paper-users-list [users]="users" (userEdit)="editUser($event)" (userDelete)="deleteUser($event)"></paper-users-list>
    </div>
    <div fxFlex *ngIf="!selectedUser">
      <paper-user-add (userAdded)="addedUser($event)" [facilities]="facilities"></paper-user-add>
    </div>
    <div fxFlex *ngIf="selectedUser">
      <paper-user-edit [user]="selectedUser" [facilities]="facilities" (userUpdated)="updateUser($event)" (editingCancel)="cancelEditing($event)"></paper-user-edit>
    </div>
  </div>
</ng-template>
