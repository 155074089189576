import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../services/user.service";
import { FacilityService } from "../services/facility.service";
import { AuthenticationService } from "../services/authentication.service";
import { User } from "../models/user";
import { Facility } from "../models/facility";

@Component({
  selector: "paper-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  users: User[];
  selectedUser: User;
  filterUser: User;
  loadingFacilities: boolean = false;
  loadingUsers: boolean = false;
  facilities: Facility[];
  isRoot: boolean = false;

  constructor(
    private facilityService: FacilityService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private ref: ChangeDetectorRef,
    private authService: AuthenticationService,
  ) {
    this.authService.isRoot.subscribe((isRoot) => (this.isRoot = isRoot));
  }

  getUsers(): void {
    this.loadingUsers = true;
    this.userService
      .getAllUsers()
      .then((users) => {
        this.users = users;
        this.loadingUsers = false;
      })
      .catch((error) => {
        console.log(error);
        this.snackBar.open("Error loading users", "Error");
      });
  }

  deleteUser(user: User): void {
    user.username = user._id.replace("org.couchdb.user:", "");
    console.log("Deleting user: ", user);

    // delete user
    this.userService
      .deleteUser(user)
      .then(() => {
        this.users = this.users.filter((h) => h !== user);
        this.snackBar.open("User deleted", "Success", { duration: 6000 });
      })
      .catch((error) => {
        this.snackBar.open("Error deleting user", "Error", { duration: 6000 });
      });
  }

  getFacilities() {
    this.loadingFacilities = true;
    this.facilityService
      .getFacilities()
      .then((response) => {
        this.facilities = response;
        this.loadingFacilities = false;
      })
      .catch((error) => {
        this.snackBar.open("Error loading facilities list", "Error");
      });
  }

  editUser(user: User) {
    user.username = user._id.replace("org.couchdb.user:", "");
    this.selectedUser = JSON.parse(JSON.stringify(user));
    this.filterUser = user;
  }

  cancelEditing(cancel: string) {
    this.selectedUser = null;
  }

  addedUser(user: User): void {
    // user has been added, lets refresh the users list
    // this.users.push(user) would be faster but the added user is lacking dateAdded in correct format
    this.getUsers();
    this.selectedUser = null;
  }

  updateUser(user: User) {
    // update user
    this.authService
      .userChangeProfile(user)
      .then((updatedUser) => {
        this.users = this.users.filter((h) => h !== this.filterUser);
        updatedUser._id = "org.couchdb.user:" + updatedUser.username;
        this.users.push(updatedUser);
        this.selectedUser = null;
        this.snackBar.open("User Updated.", "Success", { duration: 6000 });
      })
      .catch((error) => {
        console.log("Error");
        this.snackBar.open("Error updating user: " + error, "Error", {
          duration: 6000,
        });
      });
  }

  ngOnInit() {
    this.getUsers();
    this.getFacilities();
  }
}
