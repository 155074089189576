<h2>
  <button mat-raised-button mat-button [routerLink]="['/new_record']" class="mat-button-small uk-margin-top-remove">
    <mat-icon>navigate_before</mat-icon>
  </button>
  User Profile
</h2>

<table class="uk-table uk-table-striped">
  <thead>
    <!-- profile data -->
    <tr>
      <th width="200px">Profile Label</th>
      <th>Profile Data</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <mat-icon>accessibility</mat-icon>
        Username
      </td>
      <td>{{userModel.username}}</td>
    </tr>
    <tr>
      <td>
        <mat-icon>person</mat-icon>
        Name
      </td>
      <td>{{userModel.firstName}}
        {{userModel.lastName}}</td>
    </tr>
    <tr>
      <td>
        <mat-icon>message</mat-icon>
        Email Address
      </td>
      <td>{{userModel.emailAddress}}</td>
    </tr>
    <tr>
      <td>
        <mat-icon>phone</mat-icon>
        Telephone
      </td>
      <td>{{userModel.phone}}</td>
    </tr>
    <tr>
      <td>
        <mat-icon>work</mat-icon>
        Facility
      </td>
      <td>{{getFacilityNameByCode(userModel.facility)}}
        ({{userModel.facility}})</td>
    </tr>
    <tr>
      <td>
        <mat-icon>assignment ind</mat-icon>
        User Role
      </td>
      <td>{{userModel.userRole}}</td>
    </tr>
    <tr>
      <td>
        <mat-icon>alarm</mat-icon>
        Date Added
      </td>
      <td>{{userModel.dateAdded | date}}</td>
    </tr>
    <!-- Edit Profile -->
    <tr>
      <td>
        <mat-icon>edit</mat-icon>
        Update Profile
      </td>
      <td>
        <div *ngIf="!changeProfile">
          <button mat-raised-button (click)="toggleProfileForm(true)">
            Edit Profile
          </button>
        </div>
        <div *ngIf="changeProfile">
          <form [formGroup]="userEditForm">
            <mat-form-field>
              <input matInput type="email" formControlName="emailAddress" [(ngModel)]="userModel.emailAddress"
                placeholder="Email" autocomplete="off">
            </mat-form-field>
            <validation-messages [control]="userEditForm.controls['emailAddress']"></validation-messages>
            <button mat-raised-button aria-label="cancel" mat-button (click)="toggleProfileForm(false)">Cancel</button>
            <button align="end" mat-raised-button (click)="editProfile()" aria-label="edit profile"
              [disabled]="!userEditForm.valid">Update Profile</button>
          </form>
        </div>
      </td>
    </tr>
    <!-- Change Password -->
    <tr>
      <td>
        <mat-icon>lock</mat-icon>
        Change Password
      </td>
      <td>
        <div *ngIf="!changePassword">
          <button mat-raised-button (click)="togglePasswordForm(true)">
            Change Password
          </button>
        </div>
        <div *ngIf="changePassword">
          <form [formGroup]="changePassForm" (ngSubmit)="editPassword(changePassForm.value)">
            <mat-form-field>
              <input matInput type="password" formControlName="password" placeholder="Password" autocomplete="off">
            </mat-form-field>
            <validation-messages [control]="changePassForm.controls.password"></validation-messages>
            <mat-form-field>
              <input matInput type="password" formControlName="confirm_password" placeholder="Confirm Password"
                autocomplete="off">
            </mat-form-field>
            <validation-messages [control]="changePassForm.controls.confirm_password"></validation-messages>
            <button mat-raised-button aria-label="cancel" mat-button (click)="togglePasswordForm(false)">Cancel</button>
            <button align="end" mat-raised-button type="submit" aria-label="change password"
              [disabled]="!changePassForm.valid">Reset Password</button>
          </form>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<!-- Diagnostics -->
<h2>Diagnostics</h2>

<table class="uk-table uk-table-striped">
  <tr>
    <td class="uk-align-right">
      <strong>App Version</strong>
    </td>
    <td>paperEMR v{{appVersion}}
      <span *ngIf="isRoot">(Root User)</span>
    </td>
  </tr>
  <tr>
    <td class="uk-align-right">
      <strong>Database Info</strong>
    </td>
    <td>Loading info from
      {{appDb}}.</td>
  </tr>
  <tr>
    <td class="uk-align-right">
      <strong>Sync Status</strong>
    </td>
    <td>{{syncStatusEnum[repository.syncStatus]}}</td>
  </tr>
  <tr>
    <td class="uk-align-right">
      <strong>Turn Sync On/Off</strong>
    </td>
    <td>
      <mat-slide-toggle [(ngModel)]="syncImages" (change)="toggleImageSyncing()">Sync Images</mat-slide-toggle>
    </td>
  </tr>
  <tr>
    <td class="uk-align-right"><strong>Pending UP</strong></td>
    <td>{{repository.unsyncedObjects.length}}</td>
  </tr>
  <tr>
    <td class="uk-align-right"><strong>Pending Down</strong></td>
    <td>{{repository.pendingDownSum}}</td>
  </tr>
  <tr>
    <td class="uk-align-right">
      <strong>Online offline status</strong>
    </td>
    <td>
      <span [style.color]="(online | async) ? 'green' : 'red'">{{(online | async) ? 'online' : 'offline'}}</span>
    </td>
  </tr>
  <tr>
    <td class="uk-align-right">
      <strong>Remote server login status</strong>
    </td>
    <td>
      <span [style.color]="(sessionInfo.ok) ? 'green' : 'red'">{{sessionInfo | json}}</span>
    </td>
  </tr>
  <tr>
    <td class="uk-align-right">
      <strong>Refresh remote server login</strong>
    </td>
    <td>
      <form [formGroup]="refreshForm" (ngSubmit)="refreshLogin()">
        <mat-form-field>
          <input matInput type="password" formControlName="password" placeholder="Password" autocomplete="off">
        </mat-form-field>

        <button mat-raised-button class="mat-primary" color="primary" type="submit" aria-label="login"
          [disabled]="!refreshForm.valid">Refresh Login</button>
      </form>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Database Tools</mat-panel-title>
          <mat-panel-description>Warning: Use this tools responsibly</mat-panel-description>
        </mat-expansion-panel-header>
  <tr>
    <td class="uk-align-right">
      <strong>Force Sync</strong>
    </td>
    <td>
      <button mat-raised-button (click)="forceSync()">Force Sync</button>
    </td>
  </tr>

  <!-- delete database -->
  <tr>
    <td class="uk-align-right">
      <strong>Delete Images database</strong>
    </td>
    <td>
      <button mat-raised-button color="warn" (click)="deleteImagesDatabase()"
        [disabled]="repository.unsyncedObjects.length > 0">Delete Images Database</button>
    </td>
  </tr>

  <!-- <tr>
    <td class="uk-align-right">
      <strong>Delete Main database</strong>
    </td>
    <td>
      <button mat-raised-button color="warn" (click)="deleteMainDatabase()"
        [disabled]="repository.unsyncedObjects.length > 0">Delete Main Database</button>
    </td>
  </tr> -->

  </mat-expansion-panel>
  </td>
  </tr>
  <tr>
    <td colspan="2">
      <h4>Unsynced objects list -
        {{repository.unsyncedObjects.length}}
        objects</h4>

      <mat-expansion-panel *ngFor="let unsyncedObject of repository.unsyncedObjects">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{unsyncedObject.type}}
            object id
            {{unsyncedObject._id}}
          </mat-panel-title>
          <mat-panel-description>
            added on
            {{unsyncedObject.dateAdded}}
            at
            {{unsyncedObject.createFacility}}
          </mat-panel-description>
        </mat-expansion-panel-header>

        {{unsyncedObject | json}}
      </mat-expansion-panel>

    </td>
  </tr>
</table>