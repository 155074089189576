<!-- Results Edit Component Navigation -->
<mat-toolbar class="blackBar">
  <div class="container">
    <div class="toolbar-row">
      <div class="left">
        <button mat-raised-button (click)="back()" class="mat-button-small">
          <mat-icon>navigate_before</mat-icon>
        </button>
      </div>
      <div class="center">
        <button mat-raised-button (click)="update()" style="color: #40ff40;" class="mat-button-small">
          <mat-icon>done</mat-icon>
        </button>
      </div>
      <div class="right">
        <button mat-raised-button (click)="cancel();" class="mat-button-small">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>

<!-- Editable Scanned Image -->
<div style="overflow: hidden; position: relative">
  <canvas id="canvasManual"></canvas>
</div>
