<div class="uk-container-center uk-margin-left uk-margin-right">
  <mat-card style="max-width: 400px; min-width: 200px; margin-top: 100px" class="uk-align-center">
    <img *ngIf="!env.partnerLogo" src="assets/images/logo_tagline.svg" max-height="80" alt="Health-E-Net"
      class="uk-align-center" />

    <img *ngIf="env.partnerLogo" src="{{env.partnerLogo}}" max-height="80"
      style="max-width: 90%; padding-top: 15px; padding-bottom: 15px" alt="partner-logo" class="uk-align-center" />
    <mat-card-content>

      <form [formGroup]="loginForm" (ngSubmit)="login()" layout="column" layout-fill layout-padding layout-margin>

        <mat-form-field class="width100">
          <input matInput type="text" formControlName="username" placeholder="Username" autocomplete="off" />
        </mat-form-field>
        <mat-form-field class="width100">
          <input matInput type="password" formControlName="password" placeholder="Password" autocomplete="off" />
        </mat-form-field>

        <div *ngIf="!loginProcessing">
          <button mat-raised-button class="mat-primary width100" color="primary" type="submit" aria-label="login"
            [disabled]="!loginForm.valid" id="login-btn">Sign In</button>
        </div>

        <div *ngIf="loginProcessing && !loadingProfileFailed">
          <button mat-raised-button class="mat-primary width100" color="primary" disabled="true">
            {{loginProcessingMessage}}</button>
          <mat-progress-bar color="primary" mode="query"></mat-progress-bar>
        </div>

        <div *ngIf="loadingProfileFailed">
          <button mat-raised-button class="mat-primary width100" (click)="retryLoadProfile()">
            Loading User Profile Failed - Retry </button>
          <mat-progress-bar color="warn" mode="determinate" value="100"></mat-progress-bar>
        </div>

      </form>

      <!-- sign-up -->
      <div style="overflow: hidden; padding-top: 8px;" *ngIf="env.userManageOwnAccount">
        <p class="uk-float-left uk-text-left">
          <a routerLink="/recover_account">Forgot username/password?</a>
        </p>
        <p class="uk-float-right uk-text-right">No account? <a routerLink="/signup">Signup</a></p>
      </div>
    </mat-card-content>
    <img *ngIf="env.partnerLogo" src="assets/images/powered-by-paperemr-logo.svg" max-height="80" alt="Health-E-Net"
      class="uk-align-center" />
  </mat-card>
  <div *ngIf="env.helpDeskContact" style="position: fixed; bottom: 20px; right: 20px">
    <a mat-raised-button style="background-color: #25d366; color: white" class="uk-float-right uk-text-right"
      href="{{env.helpDeskContact}}" target="_blank">
      <i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i>
      Contact Support
    </a>
  </div>
</div>