import { Injectable, EventEmitter } from '@angular/core';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class Globals {
  // set global current user
  public currentUser: BehaviorSubject<User> = new BehaviorSubject(new User());
}
