import { StampTemplate } from '../models/stamp-template';

import { Injectable } from '@angular/core';
import { TemplateSeries } from '../models/series';
import { RepositoryService, Databases } from './repository.service';


@Injectable()
export class TemplateSeriesService {

  public templateSeries: TemplateSeries[]; // store all templateSeries to be returned
  private templateSeriesLoaded: boolean = false;  // track whether cached version of templateSeries available

  constructor(private repository: RepositoryService) { }

  /**
   * filter templateSeries based on facility type and return the new list of templateSeries template ids in the right order
   */
  filterTemplateIdsInSeries(localTemplates: StampTemplate[], localSeries: TemplateSeries) {
    const templateIds = localTemplates.map(t => {
      return t._id;
    });

    // return templateSeries with right template ids in the right order
    return localSeries.templateIds.filter(s => templateIds.includes(s));
  }

  /** Is this template in a templateSeries (Has templateSeriesId property) */
  isTemplateInSeries(template: StampTemplate) {
    if (!template) {
      return false;
    } else if (!template.seriesId) {
      return false;
    } else { return true; }
  }

  /** is this template the last in its templateSeries */
  isLastInSeries(template: StampTemplate, templateSeries: TemplateSeries) {
    if (!this.isTemplateInSeries(template)) { return null; }
    if (!templateSeries) { return null; }
    return templateSeries.templateIds.indexOf(template._id) + 1 === templateSeries.templateIds.length;
  }

  /** what is ID of next template in templateSeries */
  getNextTemplateIdInSeries(template: StampTemplate, templateSeries: TemplateSeries, filteredTemplateSeries: TemplateSeries) {
    if (!this.isTemplateInSeries(template)) { return null; }
    if (!filteredTemplateSeries) { return null; }
    if (this.isLastInSeries(template, filteredTemplateSeries)) {
      return this.getFirstTemplateIdInSeries(template, filteredTemplateSeries);
    } else if (filteredTemplateSeries.templateIds.indexOf(template._id) !== -1) {
      return filteredTemplateSeries.templateIds[filteredTemplateSeries.templateIds.indexOf(template._id) + 1];
    } else {
      // current template is not accessible for scanning in the current facility
      const currTempIndex = templateSeries.templateIds.indexOf(template._id);
      // then check if the template id exists in copy
      for (let index = currTempIndex; index < templateSeries.templateIds.length; index++) {
        const temp = templateSeries.templateIds[index];
        if (template._id === temp) {
          // find the next template in copy
          const value = filteredTemplateSeries.templateIds.find(id => id === templateSeries.templateIds[index + 1]);
          if (value) { return value; }
        }
      }
    }
  }

  /** what is ID of first template in templateSeries*/
  getFirstTemplateIdInSeries(template: StampTemplate, templateSeries: TemplateSeries) {
    if (!this.isTemplateInSeries(template)) { return null; }
    if (!templateSeries) { return null; }
    return templateSeries.templateIds[0];
  }

  /** Return a specific template templateSeries from db */
  getSeriesById(templateSeries_id): Promise<TemplateSeries> {
    return new Promise((resolve, reject) => {
      this.repository
        .fetchObject(TemplateSeries.type, templateSeries_id, TemplateSeries.fields, Databases.templatesDb)
        .then((result) => {
          const templateSeries: TemplateSeries = result.docs.map((doc: any) => this.mapObjectToSeries(doc))[0];
          resolve(templateSeries);
        })
        .catch(error => {
          console.error('An error occurred loading template templateSeries by id', error);
          reject(error);
        });
    });
  }

  /** provide a new or updated templateSeries and save it to the db */
  updateTemplateSeries(templateSeries: TemplateSeries): Promise<TemplateSeries> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(templateSeries, TemplateSeries.type, Databases.templatesDb)
        .then((pouchObject) => {
          const updatedTemplateSeries: TemplateSeries = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedTemplateSeries);
        })
        .catch(error => {
          console.error('An error occurred updating this template templateSeries', error);
          reject(error);
        });
    });
  }

  /** Return list of template templateSeries from cached list if available, or call loadTemplateSeries */
  getTemplateSeries(useCache = true): Promise<TemplateSeries[]> {
    return new Promise((resolve, reject) => {
      if (this.templateSeriesLoaded && useCache) {
        console.log('already loaded templateSeries');
        resolve(this.templateSeries);
      } else {
        this.loadTemplateSeries()
          .then((allSeries) => {
            this.templateSeries = allSeries;
            this.templateSeriesLoaded = true;
            resolve(this.templateSeries);
          })
          .catch(error => {
            console.error('An error occurred getting templateSeries', error);
            reject(error);
          });
      }
    });
  }

  /** Returns all template templateSeries from DB */
  loadTemplateSeries(): Promise<TemplateSeries[]> {
    return new Promise((resolve, reject) => {

      this.repository
        .fetchObjects(TemplateSeries.type, Databases.templatesDb)
        .then((result) => {
          const templateSeries: TemplateSeries[] = result.docs.map((doc: any) => this.mapObjectToSeries(doc));
          resolve(templateSeries as TemplateSeries[]);
        })
        .catch(error => {
          console.error('An error occurred loading templateSeries', error);
          reject(error);
        });

    });
  }

  /** takes an object and maps it to a TemplateSeries Object */
  private mapObjectToSeries(object: any): TemplateSeries {
    let template: TemplateSeries = new TemplateSeries();
    return template = { ...object };
  }

}
