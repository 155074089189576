import { Injectable } from '@angular/core';
import { Patient } from '../models/patient';
import { Databases, RepositoryService } from '../../app/services/repository.service';
import { SheetService } from './sheet.service';

@Injectable()
export class PatientService {
  patients: Patient[] = [];
  patientsLoaded: boolean;
  constructor(private repository: RepositoryService, private answerSheetService: SheetService) { }

  /**
   * function update patient object in database
   * @param patient patient object
   */
  updatePatient(patient: Patient, createFacility: string = null) {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(patient, Patient.type, Databases.mainDb, false, createFacility)
        .then((pouchObject) => {
          const updatedPatient: Patient = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedPatient);
        }).catch(error => {
          console.error('An error occurred updating this patient', error);
          reject(error);
        });
    });
  }

  /** Fetch specific patient record from the database */
  getPatient(patientId: string): Promise<Patient> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObject(Patient.type, patientId, Patient.fields)
        .then(pouchObject => {
          const patient: Patient = pouchObject.docs.map((doc: any) => this.mapObjectToPatient(doc))[0];
          resolve(patient);
        })
        .catch(error => {
          console.error('An error occurred fetching this patient', error);
          reject(error);
        });
    });
  }

  // TODO: - remove unused
  /** Fetch a single patient by patientId */
  // getPatientByPatientId(patientId: string): Promise<Patient> {
  //   return new Promise((resolve, reject) => {
  //     this.repository.fetchObjectsBy(Patient.type, 'patientId', patientId)
  //       .then(pouchObject => {
  //         const patient: Patient = pouchObject.docs.map((doc: any) => this.mapObjectToPatient(doc))[0];
  //         resolve(patient);
  //       })
  //       .catch(error => {
  //         console.error('An error occurred updating this patient', error);
  //         reject(error);
  //       });
  //   });
  // }

  /**
   * function fetch all patients from the pouch database
   * @param useCache boolean use cached data
   */
  getPatients(useCache: boolean = true): Promise<Patient[]> {
    return new Promise((resolve, reject) => {
      if (this.patientsLoaded && useCache) {
        console.log('already loaded patients');
        resolve(this.patients);
      } else {
        this.loadPatients().then((allPatients) => {
          this.patients = allPatients;
          this.patientsLoaded = true;
          resolve(this.patients);
        }).catch(error => {
          console.error('An error occurred getting patients', error);
          reject(error);
        });
      }
    });
  }

  /**
   * Returns all failed answer sheets from DB
   */
  loadPatients(): Promise<Patient[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Patient.type)
        .then((result) => {
          const patients: Patient[] = result.docs.map((doc: any) => this.mapObjectToPatient(doc));
          resolve(patients as Patient[]);
        })
        .catch(error => {
          console.error('An error occurred loading templates', error);
          reject(error);
        });
    });
  }

  /**
   * share current patient details to a facility
   * @param patient current patient object
   */
  sharePatient(patient: Patient, facilities: string[]) {
    return new Promise((resolve, reject) => {
      const shareToFacilities: string[] = facilities;

      // check if the patient has been shared with all the facilities 
      if (facilities.some(facility => !patient.sharedFacilities.includes(facility))) {
        // patient not shared with some facilities, update the patient object
        (async () => {
          try {
            const fullPatient = await this.getPatient(patient._id);
            await this.updatePatient(fullPatient);
            console.log('Patient updated');
          } catch (error) {
            console.log('Patient update error:', error);
          }
        })();
      }

      // get all patients records
      this.answerSheetService.getPatientSheets(patient._id).then(sheets => {
        if (sheets.length === 0) { resolve(`No Records Found for patient: ${patient._id}`); }  // do nothing if no records for patient
        
        const promise = [];
        for (const sheet of sheets) {
          (async () => {
            try {
              // get full sheet
              const fullSheet = await this.answerSheetService.getSheet(sheet._id);

              // check if patient record has been shared with all the facilities
              if (shareToFacilities.some(facility => !fullSheet.sharedFacilities.includes(facility))) {
                // patient record not shared with some facilities, update record
                fullSheet.sharedFacilities = shareToFacilities;
                promise.push(
                  new Promise((resolveInner, rejectInner) => {
                    this.answerSheetService.updateSheet(fullSheet).then(() => resolveInner(true)).catch(error => rejectInner(error));
                  })
                );
              }
            } catch (error) {
              console.log('error updating patient records');
            }

          })();
        }

        // resolve all
        Promise.all(promise).then(() => resolve('Updated Records')).catch(error => reject(error));
      });
    });
  }

  /**
   * Takes an object and maps it to Patient
   * @param object
   */
  private mapObjectToPatient(object: any): Patient {
    let patient: Patient = new Patient();
    return patient = { ...object };
  }

}
