import { Injectable } from '@angular/core';
import { AppSettings } from '../models/settings';
import { StampTemplate, Routine } from '../models/stamp-template';
import { RepositoryService, Databases } from './repository.service';

@Injectable()
export class TemplateService {

  public routines: Routine[];
  private routinesLoaded: boolean = false;
  public templates: StampTemplate[]; // store all templates to be returned
  private templatesLoaded: boolean = false;  // track whether cached version of templates available

  constructor(private repository: RepositoryService) { }

  /** provide a new or updated stampTemplate and save it to the db */
  updateTemplate(template: StampTemplate): Promise<StampTemplate> {
    if (template.file) {
      const file = { content_type: template.file.type, data: template.file };
      template._attachments = { file };
      delete template.file;
    }

    return new Promise((resolve, reject) => {
      this.repository.updateObject(template, StampTemplate.type, Databases.templatesDb)
        .then((pouchObject) => {
          const updatedTemplate: StampTemplate = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedTemplate);
        })
        .catch(error => {
          console.error('An error occurred updating this template', error);
          reject(error);
        });
    });
  }

  /** Return list of stampTemplates from cached list if available, or call loadTemplates */
  getTemplates(useCache = true): Promise<StampTemplate[]> {
    return new Promise((resolve, reject) => {
      if (this.templatesLoaded && useCache) {
        console.log('already loaded templates');
        resolve(this.templates);
      } else {
        this.loadTemplates()
          .then((allTemplates) => {
            this.templates = allTemplates;
            this.templatesLoaded = true;
            resolve(this.templates);
          })
          .catch(error => {
            console.error('An error occurred getting templates', error);
            reject(error);
          });
      }
    });
  }

  /** Return a list of routines from cached list if available, or call loadRoutines */
  getRoutines(useCache = true): Promise<Routine[]> {
    return new Promise((resolve, reject) => {
      if (this.routinesLoaded && useCache) {
        console.log('already loaded routines');
        resolve(this.routines);
      } else {
        this.loadRoutines()
          .then((allRoutines) => {
            this.routines = allRoutines;
            this.routinesLoaded = true;
            resolve(this.routines);
          }).catch(error => {
            console.error('An error occurred getting routines', error);
            reject(error);
          });
      }
    });
  }

  getTemplateAttachment(AttachmentName: string): Promise<any> {
    return this.repository.getAttachment(AttachmentName, Databases.templatesDb)
      .then(response => response as StampTemplate[])
      .catch(error => {
        console.error('An error occurred loading the attachment', error);
        return Promise.reject(error);
      });
  }

  /** Returns all stampTemplates from DB */
  loadTemplates(): Promise<StampTemplate[]> {
    return new Promise((resolve, reject) => {

      this.repository
        .fetchObjects(StampTemplate.type, Databases.templatesDb)
        .then((result) => {
          const templates: StampTemplate[] = result.docs.map((doc: any) => this.mapObjectToTemplate(doc));
          resolve(templates as StampTemplate[]);
        })
        .catch(error => {
          console.error('An error occurred loading templates', error);
          reject(error);
        });

    });
  }

  /** Returns all routines from DB */
  loadRoutines(): Promise<Routine[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(Routine.type, Databases.templatesDb)
        .then((result) => {
          const routines: Routine[] = result.docs.map((doc: any) => this.mapObjectToRoutine(doc));
          resolve(routines as Routine[]);
        })
        .catch(error => {
          console.error('An error occurred loading routines', error);
          reject(error);
        });
    });
  }

  /** function return date and time labels depending on template selected */
  getDateTimeLabels(name: string, settings: AppSettings) {
    let dateTimeLabel: any = { dateLabel: settings.recordSettings.dateFieldLabel, timeLabel: settings.recordSettings.timeFieldLabel };
    switch (name) {
      case 'BldReq-A':
        dateTimeLabel = { dateLabel: 'Requisition Date', timeLabel: 'Requisition Time' };
        break;
      case 'BldReq-C':
        dateTimeLabel = { dateLabel: 'Cross-match Date', timeLabel: 'Cross-match Time' };
        break;
      case 'BldReq-D':
        dateTimeLabel = { dateLabel: 'Transfusion Start Date', timeLabel: 'Transfusion Start Time' };
        break;
      default:
        dateTimeLabel = dateTimeLabel;
        break;
    }
    if (settings.uiViewSettings.farmAlertWorkflow) {
      dateTimeLabel = { dateLabel: 'Record Date', timeLabel: '' }
    }
    if (settings.uiViewSettings.polioWorkflow) {
      dateTimeLabel = { dateLabel: 'Immunization Date', timeLabel: '' }
    }
    return dateTimeLabel;
  }

  /** takes an object and maps it to a StampTemplate Object */
  private mapObjectToTemplate(object: any): StampTemplate {
    let template: StampTemplate = new StampTemplate();
    return template = { ...object };
  }

  /** takes an object and maps it to a StampTemplate Object */
  private mapObjectToRoutine(object: any): Routine {
    let routine: Routine = new Routine();
    return routine = { ...object };
  }

}
