import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { AnswerSheet } from '../models/answer-sheet';
import { Patient } from '../models/patient';
import { AppSettings } from '../models/settings';
import { StampTemplate } from '../models/stamp-template';
import { ValidationService } from '../validation/validation.service';

@Pipe({ name: 'getScannedCount' })
export class GetScannedCount implements PipeTransform {
  transform(seriesId: string, answerSheets: AnswerSheet[]) {
    // get count of scanned records of a report-series
    return answerSheets.filter(sheet => sheet.reportSeriesId === seriesId).length;
  }
}

@Pipe({ name: 'findScannedRecords' })
export class FindScannedRecords implements PipeTransform {
  transform(seriesId: string, answerSheets: AnswerSheet[], serialNumber: number = null) {
    // find scanned templates for the report series
    return answerSheets.filter(sheet => (!serialNumber ? (sheet.reportSeriesId === seriesId) :
      (sheet.reportSeriesId === seriesId && sheet.serialNumber === serialNumber))).map(sheet => sheet.stampTemplateId);
  }
}

@Pipe({ name: 'getTemplateNameAlphabet' })
export class GetTemplateNameAlphabet implements PipeTransform {
  transform(templateId: string, templates: StampTemplate[], appSettings: AppSettings) {
    let templateAlphabet;

    // find template name and return alphabet
    const templateName = templates.find(template => template._id === templateId).name;

    // consider other deploys name formats
    if (appSettings.uiViewSettings.safeBloodWorkflow || appSettings.uiViewSettings.mhGapWorkFlow || appSettings.uiViewSettings.polioWorkflow) {
      templateAlphabet = templateName[templateName.length - 1];
    }

    return templateAlphabet.trim();
  }
}

@Pipe({ name: 'getLastEncounterDate' })
export class GetLastEncounterDate implements PipeTransform {
  transform(patient: Patient, answerSheets: AnswerSheet[]) {
    // find sheets for this patient
    const patientSheets = answerSheets.filter(sheet => sheet.patientId === patient._id);

    // if none found
    if (patientSheets.length < 1) {
      return '';
    }

    // sort by date
    const sorted = patientSheets.sort((a: AnswerSheet, b: AnswerSheet) => {
      return getDateAndTime(b).getTime() - getDateAndTime(a).getTime();
    });

    // return latest date
    return moment(sorted[0].encounterDate).format('DD MMM, YYYY');
  }
}

function getDateAndTime(sheet: AnswerSheet): Date {
  const dateVal = sheet.encounterDate ? new Date(sheet.encounterDate) : new Date(0);
  const timeVal = sheet.encounterTime ? ValidationService.parseTime(sheet.encounterTime) : new Date(0);
  return new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), timeVal.getHours(), timeVal.getMinutes(), 0);
}
