import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../environments/environment";

import { User } from "../../models/user";
import { Patient } from "../../models/patient";
import { Facility } from "../../models/facility";
import { AppSettings } from "../../models/settings";
import { TemplateSeries } from "../../models/series";
import { AnswerSheet } from "../../models/answer-sheet";
import { StampTemplate } from "../../models/stamp-template";
import { ReportSeries } from "../../models/report-series";

import { SheetService } from "../../services/sheet.service";
import { PatientService } from "../../services/patient.service";
import { FacilityService } from "../../services/facility.service";
import { TemplateService } from "../../services/template.service";
import { AppSettingsService } from "../../services/settings.service";
import { AuthenticationService } from "../../services/authentication.service";
import { TemplateSeriesService } from "../../services/template-series.service";
import { RepositoryService } from "../../services/repository.service";
import { ReportSeriesService } from "../../services/report-series.service";

@Component({
  selector: "paper-shared",
  template: "",
})
export class SharedComponent {
  env = environment;
  debugMode: boolean = this.env.debugMode;

  loggedInUser: User;
  appSettings: AppSettings = new AppSettings();
  facility: Facility;
  facilities: Facility[];
  patients: Patient[];
  sheets: AnswerSheet[];
  templates: StampTemplate[];
  templateSeries: TemplateSeries;
  reportSeries: ReportSeries[];

  // loaders
  loadingAppSettings: boolean = false;
  loadingFacility: boolean = false;
  loadingPatients: boolean = false;
  loadingAnswerSheets: boolean = false;
  loadingTemplates: boolean = false;
  loadingTemplateSeries: boolean = false;
  loadingReportSeries: boolean = false;

  constructor(
    public snackBar: MatSnackBar,
    public sheetService: SheetService,
    public auth: AuthenticationService,
    public repository: RepositoryService,
    public patientService: PatientService,
    public facilityService: FacilityService,
    public templateService: TemplateService,
    public appSettingsService: AppSettingsService,
    public templateSeriesService: TemplateSeriesService,
    public reportSeriesService: ReportSeriesService
  ) {
    this.loggedInUser = this.auth.getUser();
  }

  /**
   * function load app settings from the database
   */
  getAppSettings(useCache: boolean = false) {
    this.loadingAppSettings = true;
    this.appSettingsService
      .loadAppSettings(this.env.settingsId, useCache)
      .then((loadedAppSettings) => {
        this.appSettings = new AppSettings(loadedAppSettings);
        this.loadingAppSettings = false;
        this.appSettings;
        this.loadingPatients = !this.appSettings.patientSettings.enablePatientObjects
          ? false : this.loadingPatients;
        this.getFacility(); // get user facility
      })
      .catch((error) => {
        this.loadingAppSettings = true;
        if (this.debugMode) {
          console.log(
            "GlobalComponent: Error loading loading app settings",
            error
          );
        }
      });
  }

  /**
   * get all the facilities from the database
   */
  getFacilities(useCache: boolean = false) {
    this.loadingFacility = true;
    this.facilityService
      .getFacilities(useCache)
      .then((facilities: Facility[]) => {
        this.facilities = facilities;
        this.getFacility(); // get user facility
      })
      .catch((error) => {
        if (this.debugMode) {
          console.log("GlobalComponent: Error loading facilities::", error);
        }
        this.snackBar.open("Error loading list of facilities", "Error", {
          duration: 6000,
        });
      });
  }

  /**
   * get the current user facility
   * By Default it will get the logged in user facility
   * @param facilityCode string Facility code
   */
  getFacility(facilityCode: string = this.loggedInUser.facility) {
    if (!this.facilities || !this.appSettings || !this.appSettings._id) {
      return;
    }

    this.facility = this.facilities.find(
      (facility) => facility._id === facilityCode
    );
    if (!this.facility) {
      this.snackBar.open("User Facility was not loaded");
      return;
    }
    this.facilityService.userFacility = this.facility; // set user facility in service
    this.loadingFacility = false;
  }

  /**
   * get all the patients from the database
   */
  getPatients(useCache: boolean = false) {
    if (!this.appSettings.patientSettings.enablePatientObjects) {
      return;
    }
    this.loadingPatients = true;
    this.patientService
      .getPatients(useCache)
      .then((patients: Patient[]) => {
        this.loadingPatients = false;
        this.patients = patients;

        // if (!this.isCHAUser && this.appSettings.uiViewSettings.cicWorkflow) {
        //     // when a chv user is logged in, set the patientid field to current user
        //     this.currentPatient = this.patients.find(p => p._id === this.loggedInUser.id.toString());
        // }
      })
      .catch((error) => {
        this.loadingPatients = false;
        if (this.debugMode) {
          console.log("GlobalComponent: Error loading patients", error);
        }
        this.snackBar.open("Error loading list of patients", "Error", {
          duration: 6000,
        });
      });
  }

  /**
   * get answer sheets from sheet service
   */
  getSheets(useCache: boolean = true): void {
    this.loadingAnswerSheets = true;
    this.sheetService
      .getSheets(useCache)
      .then((sheets: Array<AnswerSheet>) => {
        this.sheets = sheets;
        this.loadingAnswerSheets = false;
      })
      .catch((error) => {
        this.loadingAnswerSheets = false;
        if (this.debugMode) {
          console.log("GlobalComponent: Error loading answer sheets:", error);
        }
        const snackBarRef = this.snackBar.open(
          "Error loading answer sheet data, please try again.",
          "Retry"
        );
        snackBarRef.onAction().subscribe(() => {
          if (this.debugMode) {
            console.log("GlobalComponent: Retrying");
          }
          this.getSheets(useCache);
        });
      });
  }

  /**
   * get stamp templates from template service
   */
  getTemplates(useCache: boolean = false): void {
    this.loadingTemplates = true;
    this.templateService
      .getTemplates(useCache)
      .then((templates: Array<StampTemplate>) => {
        this.loadingTemplates = false;
        this.templates = templates;
      })
      .catch((error) => {
        if (this.debugMode) {
          console.log("SheetsComponent: Error loading templates:", error);
        }
        const snackBarRef = this.snackBar.open(
          "Error loading template data, please try again.",
          "Retry"
        );
        snackBarRef.onAction().subscribe(() => {
          if (this.debugMode) {
            console.log("SheetsComponent: Retrying");
          }
          this.getTemplates(useCache);
        });
      });
  }

  /**
   * get stamp templates from template service
   */
  getTemplateSeries(useCache: boolean = false): void {
    this.loadingTemplateSeries = true;
    this.templateSeriesService
      .getTemplateSeries(useCache)
      .then((series: Array<TemplateSeries>) => {
        this.loadingTemplateSeries = false;
        this.templateSeries = series[0]; // TODO: Store the facility template series id in settings.. for cases where we might have multiple template series
      })
      .catch((error) => {
        if (this.debugMode) {
          console.log(
            "ReportSeriesComponent: Error loading template-series:",
            error
          );
        }
        const snackBarRef = this.snackBar.open(
          "Error loading template series data, please try again.",
          "Retry"
        );
        snackBarRef.onAction().subscribe(() => {
          if (this.debugMode) {
            console.log("ReportSeriesComponent: Retrying load template series");
          }
          this.getTemplateSeries(useCache);
        });
      });
  }

  /**
   * get report series from report series service
   */
  getReportSeries(useCache: boolean = false) {
    this.loadingReportSeries = true;
    this.reportSeriesService
      .getAllReportSeries(useCache)
      .then((reportSeries: ReportSeries[]) => {
        this.reportSeries = reportSeries;
        this.loadingReportSeries = false;
      })
      .catch((error) => {
        this.loadingReportSeries = false;
        if (this.debugMode) {
          console.log(
            "ReportSeriesComponent: Error loading reports series",
            error
          );
        }
        const snackBarRef = this.snackBar.open(
          "Error loading list of report series, please try again.",
          "Retry"
        );
        snackBarRef.onAction().subscribe(() => {
          if (this.debugMode) {
            console.log("ReportSeriesComponent: Retrying");
          }
          this.getReportSeries(useCache);
        });
      });
  }
}
