import { Injectable } from '@angular/core';
import { Facility } from '../models/facility';
import { RepositoryService, Databases } from '../../app/services/repository.service';

@Injectable()
export class FacilityService {

  facilitiesLoaded: boolean = false;
  facilities: Facility[];
  userFacility: Facility;

  constructor(private repository: RepositoryService) { }

  addFacility(newFacility: Facility): Promise<Facility> {
    newFacility._id = newFacility.code;
    newFacility.encryptionKey = this.generateKey();

    return new Promise((resolve, reject) => {
      this.repository.updateObject(newFacility, Facility.type, Databases.facilitiesDb)
        .then((pouchObject) => {
          const updatedFacility: Facility = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedFacility);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }
  updateFacility(facility: Facility): Promise<Facility> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(facility, Facility.type, Databases.facilitiesDb)
        .then((pouchObject) => {
          const updatedFacility: Facility = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedFacility);
        })
        .catch(error => {
          console.error('An error occurred', error);
          reject(error);
        });
    });
  }

  deleteFacility(facility: Facility): Promise<void> {
    return this.repository.deleteObject(facility, Databases.facilitiesDb)
      .then(() => null)
      .catch(error => {
        console.error('An error occurred', error);
        return Promise.reject(error);
      });
  }

  /**
   * Function fetch facilities from the database
   * @param useCache use cached copy of facilities
   * @returns list of facilities
   */
  getFacilities(useCache: boolean = true): Promise<Facility[]> {
    return new Promise((resolve, reject) => {
      if (this.facilitiesLoaded && useCache) {
        console.log('already loaded answer facilities');
        resolve(this.facilities);
      } else {
        this.repository.fetchObjects(Facility.type, Databases.facilitiesDb).then((result) => {
          const facilities: Facility[] = result.docs.map((doc: any) => this.mapObjectToFacility(doc));
          this.facilities = facilities;
          this.facilitiesLoaded = true;
          resolve(facilities);
        })
          .catch(error => {
            console.error('An error occurred', error);
            reject(error);
          });
      }
    });
  }

  getFacility(facilityId: string): Promise<Facility> {
    return this.repository.fetchObject(Facility.type, facilityId, Facility.fields, Databases.facilitiesDb)
      .then(result => result.docs.map((doc: any) => this.mapObjectToFacility(doc))[0])
      .catch(error => {
        console.error('An error occurred', error);
        return Promise.reject(error);
      });
  }

  private generateKey() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 15; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  private mapObjectToFacility(object: any): Facility {
    let facility: Facility = new Facility();
    return facility = { ...object };
  }
}
