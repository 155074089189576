import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "paper-conflict-dialog",
  templateUrl: "conflict-modal.component.html",
  styles: [
    `
      table,
      td,
      th {
        border-collapse: collapse;
        border-left: 1px solid #000000;
        border-right: 1px solid #000000;
        cursor: pointer;
      }
      table,
      th {
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
      }
    `,
  ],
})
export class ConflictDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {}
}
