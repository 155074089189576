import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { MatTableModule } from "@angular/material/table";
import { MomentModule } from "ngx-moment";

import { AppMaterialModule } from "../material/app-material.module";
import { ValidationModule } from "../validation/validation.module";

import { LoginComponent } from "./login.component.web";
import {
  ConfirmTurnSyncOnDialogComponent,
  ConfirmDeleteDatabaseDialogComponent,
} from "./profile.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    MomentModule,
    ValidationModule,
  ],
  declarations: [
    LoginComponent,
    ConfirmTurnSyncOnDialogComponent,
    ConfirmDeleteDatabaseDialogComponent,
  ],
  exports: [LoginComponent],
})
export class AuthenticationModule {}
