import { Pipe, PipeTransform } from '@angular/core';
import { AnswerSheet } from '../models/answer-sheet';

@Pipe({ name: 'checkReportHasErrors' })
export class CheckReportHasErrors implements PipeTransform {
  transform(reportSeriesId: string, answerSheets: AnswerSheet[]) {
    const records = answerSheets.filter(sheet => sheet.reportSeriesId === reportSeriesId);
    const hasErrors = records.find(record => record.sheetErrors.length > 0);
    return hasErrors ? ' Yes' : '';
  }
}
