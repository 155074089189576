import { Component, Inject, ViewChild, AfterViewInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BarcodeScannerLivestreamComponent } from "ngx-barcode-scanner";
import { ValidationService } from "../../../validation/validation.service";
import { AppSettings } from "../../../models/settings";

@Component({
  template: `<h2 mat-dialog-title>Warning: Duplicate scan in series</h2>
  <mat-dialog-content>
    <p>
      A scan of this template already exists for the same SBRF serial number.
      <br />
      Record will be added as a duplicate scan.
    </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="uk-float-right"
        (click)="dialogRef.close(true)"
      >
        Continue
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        class="uk-float-right uk-margin-small-right"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>
    </mat-dialog-actions> `,
})
export class RecordExistInSeries {
  constructor(public dialogRef: MatDialogRef<any>) {}
}

@Component({
  template: `<h3 mat-dialog-title >Warning: Serial number has changed</h3>
  <mat-dialog-content>
    <p>
      The SBRF serial # is different from that of current series.<br />
      Please make sure you are scanning the right BR Form.
    </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-raised-button
        color="warn"
        class="uk-float-left"
        (click)="dialogRef.close(true)"
      >
        Start new scan
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="uk-float-right"
        (click)="dialogRef.close(false)"
      >
        Go Back
      </button>
    </mat-dialog-actions> `,
})
export class SerialNumberChanged {
  constructor(public dialogRef: MatDialogRef<any>) {}
}

@Component({
  template: `<h3 mat-dialog-title >Warning: {{ patientLabel }} has changed</h3>
  <mat-dialog-content>
    <p>
      The {{ patientLabel }} has been changed, and does not match the previous
      one.<br />
      Please start a new scan if you wish to scan for the selected
      {{ patientLabel }}.
    </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-raised-button
        color="warn"
        class="uk-float-left"
        (click)="dialogRef.close(true)"
      >
        Start new scan
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="uk-float-right"
        (click)="dialogRef.close(false)"
      >
        Go Back
      </button>
    </mat-dialog-actions> `,
})
export class PatientChangedDialog {
  patientLabel: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {
    this.patientLabel = data.patientLabel;
  }
}

@Component({
  template: `
    <h3 mat-dialog-title >{{ title }}</h3>
    <mat-dialog-content>
    <p>{{ message }}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        *ngIf="showSuccessBtn"
        type="button"
        mat-raised-button
        color="primary"
        class="uk-float-right"
        (click)="dialogRef.close(true)"
      >
        {{ successBtnTxt }}
      </button>
      <button
        *ngIf="showCancelBtn"
        type="button"
        mat-raised-button
        color="warn"
        class="uk-float-right uk-margin-small-right"
        (click)="dialogRef.close()"
      >
        {{ cancelBtnTxt }}
      </button>
      <div></div>
    </mat-dialog-actions>
  `,
})
export class PaperDialogComponent {
  title: string = "";
  message: string = "";
  successBtnTxt: string = "";
  cancelBtnTxt: string = "";
  showSuccessBtn: boolean = false;
  showCancelBtn: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {
    const {
      title,
      message,
      successBtnTxt,
      cancelBtnTxt,
      showSuccessBtn,
      showCancelBtn,
    } = data;

    this.title = title;
    this.message = message;
    this.successBtnTxt = successBtnTxt;
    this.showSuccessBtn = showSuccessBtn;
    this.cancelBtnTxt = cancelBtnTxt;
    this.showCancelBtn = showCancelBtn;
  }
}

@Component({
  template: `
  <mat-dialog-content>
    <!-- Loading Spinners -->
    <div class="width100" *ngIf="!barcodeStarted">
      <div
        style="width:200px"
        class="uk-container-center uk-margin-large-top uk-text-center"
      >
        <mat-progress-spinner
          color="primary"
          mode="indeterminate"
          class="uk-margin uk-align-center"
          [diameter]="50"
          id="barcode-loading-spinner"
        ></mat-progress-spinner>
        Opening barcode scanner please wait...
      </div>
    </div>

    <barcode-scanner-livestream
      [type]="['code_128', 'code_39', 'ean_8']"
      [torch]="torch"
      (valueChanges)="onValueChanges($event)"
      (started)="onStarted($event)"
    >
    </barcode-scanner-livestream>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      color="warn"
      *ngIf="barcodeStarted"
      (click)="dialogRef.close(false)"
      style="float: right"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
  `,
})
export class BarcodeDialogComponent implements AfterViewInit {
  @ViewChild(BarcodeScannerLivestreamComponent)
  barcodeScanner: BarcodeScannerLivestreamComponent;
  torch: boolean = false;
  barcodeValue: string;

  barcodeStarted: boolean = false;

  constructor(public dialogRef: MatDialogRef<any>) {}

  ngAfterViewInit() {
    this.barcodeScanner.start();
  }

  onValueChanges(result: any) {
    if (ValidationService.validateStudyId(result.codeResult.code)) {
      this.barcodeScanner.stop();
      this.barcodeValue = result.codeResult.code;
      this.dialogRef.close(this.barcodeValue);
    } else {
      console.log("Barcode value Not Valid");
    }
  }

  onStarted(started: any) {
    this.barcodeStarted = started;
    console.log(started);
  }
}

@Component({
  template: ` 
    <h2 mat-dialog-title >Confirm Exit and Save</h2>
    <mat-dialog-content>
    <p>
      Please note that you will lose all of your changes and exit the scanning
      process.
    </p>
    <p><b>Notice:</b> This action cannot be undone</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="uk-float-right"
        (click)="dialogRef.close(true)"
      >
        Confirm
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        class="uk-float-right uk-margin-small-right"
        (click)="dialogRef.close(false)"
      >
        Cancel
      </button>
    </mat-dialog-actions>`,
})
export class ExitScanDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}
@Component({
  selector: "exit-scan-dialog-component",
  template: ` <h3 mat-dialog-title >Confirm Exit and Discard</h3>
  <mat-dialog-content>
    <p>Please note that your changes will be discarded.</p>
    <p><b>Notice:</b> This action cannot be undone</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="uk-float-right"
        (click)="dialogRef.close(true)"
      >
        Discard
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        class="uk-float-right uk-margin-small-right"
        (click)="dialogRef.close(false)"
      >
        Cancel
      </button>
    </mat-dialog-actions>`,
})
export class ConfirmDiscardDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: "confirm-discard-dialog-component",
  template: `<h2 mat-dialog-title>Warning: Duplicate scan</h2>
  <mat-dialog-content>
    <p *ngIf="data.duplicateType === 'date'">
      A {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }} for the same
      {{ appSettings.patientSettings.patientIdLabel }} on the same date already
      exists.Do you wish to add this
      {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }}?
    </p>
    <p *ngIf="data.duplicateType === 'dateTime'">
      A {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }} for the same
      {{ appSettings.patientSettings.patientIdLabel }} on the same date & time
      already exists.Do you wish to add this
      {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }}?
    </p>
    <p *ngIf="data.duplicateType === 'penNumber'">
      A {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }} for the same
      pen number on the same date & time already exists. Do you wish to add this
      {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }}?
    </p>

    <p *ngIf="data.duplicateType === 'income_expense'">
      A {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }} for the same
      date already exist. Do you wish to add this
      {{ appSettings.uiViewSettings.recordLabel.toLowerCase() }}?
    </p>

    <p *ngIf="data.duplicateType === 'serialNumber'">
      A medical record for the same {{appSettings.uiViewSettings.serialNumberLabel}} already exists.<br>
      Do you wish to add this medical record?
    </p>

    <p *ngIf="data.duplicateType === 'teamNumber'">
      A {{appSettings.uiViewSettings.recordLabel.toLowerCase()}} for the same date and team number already exist.
      Do you wish to add this {{appSettings.uiViewSettings.recordLabel.toLowerCase()}}?
    </p>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-raised-button mat-dialog-close color="warn" class="uk-margin-small-right">Cancel</button>
    <button mat-button mat-raised-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>Continue</button>
  </mat-dialog-actions>
  `
})
export class ConfirmAddDuplicateScanDialogComponent {
  appSettings: AppSettings;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {
    this.appSettings = data.appSettings;
  }
}
