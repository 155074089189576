<h2>Edit user
  {{user._id |removeUserPrefix}}</h2>

<form [formGroup]="userForm">

  <div class="form-group">
    <label for="firstName" class="psoc-label">First name*:</label>
    <mat-form-field>
      <input matInput type="text" formControlName="firstName" [(ngModel)]="user.firstName" placeholder="First name" autocomplete="off">
    </mat-form-field>
    <validation-messages [control]="userForm.controls['firstName']"></validation-messages>
  </div>

  <div class="form-group">
    <label for="lastName" class="psoc-label">Last name*:</label>
    <mat-form-field>
      <input matInput type="text" formControlName="lastName" [(ngModel)]="user.lastName" placeholder="Last name" autocomplete="off">
    </mat-form-field>
    <validation-messages [control]="userForm.controls['lastName']"></validation-messages>
  </div>

  <div class="form-group">
    <label for="emailAddress" class="psoc-label">Email*:</label>
    <mat-form-field class="width50">
      <input matInput type="email" formControlName="emailAddress" placeholder="Email Address..." [(ngModel)]="user.emailAddress">
      <mat-hint align="end">Email Address</mat-hint>
    </mat-form-field>
    <validation-messages [control]="userForm.controls['emailAddress']"></validation-messages>
  </div>

  <div class="form-group">
    <label for="phone" class="psoc-label">Telephone*:</label>
    <mat-form-field>
      <input matInput type="tel" formControlName="phone" [(ngModel)]="user.phone" placeholder="Telephone" autocomplete="off"
      (blur)="formatNumber()">
    </mat-form-field>
    <validation-messages [control]="userForm.controls['phone']"></validation-messages>
  </div>

  <div class="form-group uk-margin-bottom uk-margin-top">
    <label for="facility" class="psoc-label">Facility*:</label>
    <mat-form-field>
      <mat-select formControlName="facility" [(ngModel)]="user.facility" placeholder="Select Facility">
        <mat-option *ngFor="let facility of facilities" [value]="facility.code">{{ facility.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <validation-messages [control]="userForm.controls['facility']"></validation-messages>
    <div class="uk-text-warning uk-margin-top" *ngIf="userForm.controls['facility'].dirty">
      WARNING: Please note that changing the facility of an existing user may adversely affect sync for this user.</div>
    </div>

  <div class="form-group uk-margin-top">
    <label for="userRole" class="psoc-label">Role*:</label>
    <mat-radio-group formControlName="userRole" [(ngModel)]="user.userRole" class="width90">
      <mat-radio-button *ngFor="let role of userRoleOptions" name="user_role_options" [value]="role">
        {{role}}
        &nbsp;
      </mat-radio-button>
    </mat-radio-group>
    <validation-messages [control]="userForm.controls['userRole']"></validation-messages>
  </div>

  <div class="form-group uk-margin-top">
    <label class="psoc-label">User Roles*:</label>
    <mat-form-field>
      <mat-select formControlName="userRoles" multiple [(ngModel)]="user.userRoles" placeholder="Select User Roles">
        <mat-option *ngFor="let role of userRolesOptions" [value]="role.title">{{role.value}}</mat-option>
      </mat-select>
    </mat-form-field>
    <validation-messages [control]="userForm.controls['userRoles']"></validation-messages>
  </div>

  <div class="form-group uk-margin-top">
    <button mat-raised-button type="button" (click)="cancel();">Cancel</button>
    <button mat-raised-button type="submit" (click)="updateUser()" [disabled]="!userForm.valid">Submit</button>
  </div>

</form>
