import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppMaterialModule } from '../../material/app-material.module';
import { RepositoryService } from '../../../app/services/repository.service';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: "app-update-notification",
  imports: [AppMaterialModule, CommonModule],
  providers: [RepositoryService],
  template: `
    <h2 mat-dialog-title>
      {{ data.type === "available" ? "Update Available" : "Update Required" }}
    </h2>
    <mat-dialog-content>
      {{
        data.type === "available"
          ? "A new version of the app is available. Would you like to update now?."
          : data.hasUnsynced
          ? ""
          : "A critical update is required. The app will update and reload now."
      }}
      {{
        data.hasUnsynced &&
          "You have some unsynced records. Please make sure you are connected to the internet. Once syncing is completed, the update will be available. "
      }}
      <p *ngIf="!data.hasUnsynced" style="margin-top: 15px;">
        Latest Version:
        <span style="font-weight: bold">{{ data.version }}</span>
      </p>
      <p *ngIf="data.hasUnsynced" style="margin-top: 15px;">
        Unsynced records:
        <span style="font-weight: bold">{{
          repository.unsyncedObjectsCount | async
        }}</span>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions style="display: flex; justify-content: flex-end;">
      <button
        mat-button
        [mat-dialog-close]="'later'"
        *ngIf="data.type === 'available' || data.hasUnsynced"
      >
        Later
      </button>
      <button
        *ngIf="!data.hasUnsynced"
        mat-button
        [mat-dialog-close]="'update'"
        cdkFocusInitial
        style="color: white; background-color: #3F50B5;"
      >
        Update Now
      </button>
    </mat-dialog-actions>
  `,
})
export class AppUpdateDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AppUpdateDialogComponent>,
    public repository: RepositoryService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: "available" | "forced";
      version: `0.0.0`;
      hasUnsynced: false;
    }
  ) {}
}
