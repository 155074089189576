import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSelect } from "@angular/material/select";
import { DomSanitizer } from "@angular/platform-browser";

import { AnswerSheet } from "../../../models/answer-sheet";
import { Facility, FacilityFields } from "../../../models/facility";
import { Patient } from "../../../models/patient";
import { TemplateSeries } from "../../../models/series";
import { AppSettings, DefaultMinDate } from "../../../models/settings";
import { StampTemplate, TemplateTypes } from "../../../models/stamp-template";
import { User, UserRoles } from "../../../models/user";

import { AuthenticationService } from "../../../services/authentication.service";
import { PatientService } from "../../../services/patient.service";
import { RepositoryService } from "../../../services/repository.service";
import { TemplateSeriesService } from "../../../services/template-series.service";
import { TemplateService } from "../../../services/template.service";
import { ValidationService } from "../../../validation/validation.service";

import { AddNewPatientDialogComponent } from "../../../patients/modal/patient-modal.component";
import { GetFacilityName } from "../../../pipes/getFacilityNamePipe";
import { BarcodeDialogComponent, PaperDialogComponent } from "../dialogs/sheets-dialogs.component";
import { TransfusionDateTimeComponent } from '../transfusion-date-time/transfusion-date-time.component';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { Subscription } from 'rxjs';
import * as shortid from 'shortid';
const moment = _rollupMoment || _moment;

@Component({
  selector: "paper-sheet-edit",
  templateUrl: "sheet-edit.component.html",
  styleUrls: ["sheet-edit.component.css"],
  providers: [GetFacilityName],
})
export class SheetEditComponent implements OnInit, OnDestroy {
  @Output() dateTimeUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() penNumberUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() sheetEditUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() sheetEditCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() sheetEditNavigateNext: EventEmitter<any> = new EventEmitter<any>();
  @Output() serialNumberChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() teamNumberChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() loggedInUser: User;
  // @Input() processing: boolean;
  @Input() loadingPatientRecords: boolean;
  @Input() disablePatientField: boolean;

  _facilities: Facility[];
  _selectedFacility: Facility;
  @Input() set selectedFacility(facility: Facility) {
    if (facility && !facility.facilityfields) { facility.facilityfields = new FacilityFields; }
    if (this.appSettings.uiViewSettings.migoriWorkflow && this.facility.facilityOptions.isResearchAssistantFacility) {
      facility && this.sheetForm.controls['createFacility'].value ?
        this.sheetForm.controls['patientId'].enable() : this.sheetForm.controls['patientId'].disable();
      this.filteredPatients = facility ? this.patients.filter(p => p.createFacility === facility._id) : [];
    }
    this._selectedFacility = facility ? facility : null;
  } get selectedFacility(): Facility { return this._selectedFacility }
  @Output() selectedFacilityChange: EventEmitter<Facility> = new EventEmitter<Facility>();

  @Input() set facilities(facilities: Facility[]) {
    this._facilities = facilities;
  } get facilities(): Facility[] { return this._facilities; }
  @Input() patients: Patient[];
  filteredPatients: Patient[];
  @Input() currentTemplateSeries: TemplateSeries;
  @Output() currentTemplateSeriesChange: EventEmitter<TemplateSeries> = new EventEmitter<TemplateSeries>();
  @Output() filterPatientRecords: EventEmitter<string> = new EventEmitter<string>();
  @Input() set appSettings(appSettings: AppSettings) {
    console.log('SheetEditComponent: SettingsApp loaded into component');
    this._appSettings = appSettings;
    this.minDate = appSettings.recordSettings.setMinDate ? appSettings.recordSettings.minDate : DefaultMinDate;
    if (this.appSettings.uiViewSettings.cicWorkflow) {
      // TODO: if users roles list changes remember to update the index
      this.isCHAUser = this.loggedInUser.userRoles
        ? this.loggedInUser.userRoles.includes(this.repositoryService.enumSelector(UserRoles)[3].title) : false;
      if (this.isCHAUser && this.loggedInUser.chvUsersList && this.loggedInUser.chvUsersList.length > 0) {
        // cha user logged in
        this.chvUsers = this.loggedInUser.chvUsersList.map(c => (this.patients.find(p => p.patientId === c.patientId)));
        this.getGroupedCHVListByFacility(); // get the chv facilities for grouping purposes
      } else { this.chvUsers = []; }
    }
    this.loadDateTimeLabels(this._template ? this._template.name : '');
    this.setSelectDisableProperty();
  } get appSettings(): AppSettings { return this._appSettings; }
  _appSettings: AppSettings;
  formIoData: any;
  _template: StampTemplate;
  templateSeries: TemplateSeries; // series record for current template
  @Input() set template(template: StampTemplate) {
    this._template = template;
    this.loadTemplateSeries(template);
  }
  get template(): StampTemplate { return this._template; }
  _facility: Facility;
  @Input() set facility(facility: Facility) {
    if (!facility.facilityfields) { facility.facilityfields = new FacilityFields; }
    this._facility = facility;
  } get facility(): Facility { return this._facility; }
  @Input() series: TemplateSeries[];
  @Output() patientsChange: EventEmitter<Patient[]> = new EventEmitter<Patient[]>();
  _currentPatient: Patient;
  @Input() set currentPatient(currentPatient: Patient) {
    console.log('SheetEditComponent: Current user loaded into component');
    if (!currentPatient && this.appSettings.uiViewSettings.cicWorkflow && !this.isCHAUser) {
      currentPatient = this.patients.find(p => this.loggedInUser.username === p.patientId);
    }
    this._currentPatient = currentPatient;
  } get currentPatient(): Patient { return this._currentPatient; }
  @Output() currentPatientChange: EventEmitter<Patient> = new EventEmitter<Patient>();
  @Output() templateChange: EventEmitter<StampTemplate> = new EventEmitter<StampTemplate>();
  @Input() templates: StampTemplate[];
  @Input() set sheet(sheet: AnswerSheet) {
    console.log('SheetEditComponent: Answer sheet has been input to the component');
    this._sheet = sheet;

    // Initialize customBeforeFieldsData if it's not already set
    this.formIoData = !this.sheet.customBeforeFieldsData ? {} : this.sheet.customBeforeFieldsData;

    if (!this.isCHAUser && this.appSettings.uiViewSettings.cicWorkflow) {
      this.patientSelectionChanged(this.currentPatient);
    }
    this.cd.detectChanges();
  }

  get sheet(): AnswerSheet {
    return this._sheet;
  }
  dateFieldLabel: string;
  timeFieldLabel: string;
  maxDate: Date = new Date();
  minDate: Date = new Date();
  // data
  _sheet: AnswerSheet; // store current answer sheet
  sheetForm: FormGroup; // form for editing sheet metadata
  _valid: boolean = true; // boolean to store validity of form
  refreshComponent: boolean;
  isCHAUser: boolean;
  isRAUser: boolean;
  chvUsers: Patient[] = [];
  groupedChvList = [];

  formValidity: Subscription;

  // dialog reference
  dialogRef: MatDialogRef<any>; // dialog for confirm save and exit

  /** check if our form has changes so we can prompt before refresh */
  get dirty(): boolean {
    return this.sheetForm.dirty && this.formioDirty;
  }

  /** check if our form is valid */
  get valid() {
    return this._valid;
  }
  // check if formio valid
  formioValid: boolean = false;
  formioDirty: boolean = false;

  @ViewChild('stampTemplateId', { static: true }) stampTemplateId: MatSelect;
  @ViewChild(TransfusionDateTimeComponent, { static: false }) transfusionDateTimeComponent: TransfusionDateTimeComponent;

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // get user timezone

  constructor(
    private _fb: FormBuilder,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public auth: AuthenticationService,
    public templateService: TemplateService,
    public patientService: PatientService,
    private getFacilityName: GetFacilityName,
    public seriesService: TemplateSeriesService,
    private repositoryService: RepositoryService,
    public datePipe: DatePipe
  ) {
    // set up form controls
    this.sheetForm = this._fb.group({
      'encounterDate': [],
      'encounterTime': [''],
      'stampTemplateId': ['', [Validators.required]],
      'createFacility': [''],
      'patientId': [''],
      'email': ['', [ValidationService.emailValidator]],
      'phoneNumber': [''],
      'penNumber': [''],
      'facilityWard': [''],
      'specimenId': [''],
      'serialNumber': ['']
    }, { validator: ValidationService.timeDateInPastValidator('encounterTime', 'encounterDate') });

    matIconRegistry.addSvgIcon('barcode_scanner', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/barcode_scanner.svg'));
  }

  /**
   * function to get chv list grouped by facilities
   */
  getGroupedCHVListByFacility() {
    console.log("Sheet Edit Component: getting chv facilities");
    // get the facilities of the chv users assigned to the loggedInUser(CHA)
    const userFacilities: any = this.chvUsers
      .filter((c) => c)
      .map((u) => u.createFacility);
    this.groupedChvList = userFacilities
      .filter((v, i) => userFacilities.indexOf(v) === i)
      .map((u) => ({
        facilityCode: u,
        chvs: this.chvUsers.filter((c) => c.createFacility === u),
        facilityName: this.getFacilityName.transform(u, this.facilities),
      }));
  }

  /**
   * function to set the selected year
   * @param normalizedYear year selected
   */
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = moment(this.sheetForm.controls["encounterDate"].value);
    ctrlValue.year(normalizedYear.year());
    this.sheetForm.controls["encounterDate"].setValue(ctrlValue);
  }

  /**
   * function to set the selected month
   * @param normalizedMonth selected month
   * @param datepicker date picker component
   */
  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = moment(this.sheetForm.controls["encounterDate"].value);
    ctrlValue.month(normalizedMonth.month());
    this.sheetForm.controls["encounterDate"].setValue(ctrlValue);
    datepicker.close();
  }

  /** function detect date changed and trigger a function */
  dateTimeChanged() {
    this.dateTimeUpdated.emit();
  }

  /** function to detect change to penNumber and load records */
  penChanged() {
    this.penNumberUpdated.emit();
  }

  /** update data in the sheet if it is changed in the custom form fields */
  onCustomBeforeFormFieldsChange(event: any) {
    if (!event.data) { return; }
    if (event.data.teamNumber && event.data.teamNumber !== 'null') {
      // emit team number changed
      this.teamNumberChanged.emit(event.data.teamNumber);
    }
    this.sheet.customBeforeFieldsData = event.data;
    if (this.appSettings.uiViewSettings.polioWorkflow) {
      this.sheet.encounterDate = this.datePipe.transform(event.data?.immunizationDate.toLocaleString('en-US', { timeZone: this.userTimezone }), 'MM/dd/YYYY');
    }
    // TODO: Fix, this will fail if the formio form has no required fields
    this.formioValid = event.isValid && (event.isModified || this.isFormDataValid(event.data));
    this.formioDirty = event.isModified;
    console.log("formioValid", this.formioValid);
  }

  isFormDataValid(dictionary: any) {
    // Check if the dictionary is not empty
    if (Object.keys(dictionary).length === 0) {
      return false;
    }
    // Remove keys with null, undefined, "null", or empty string values
    for (const key in dictionary) {
      if (dictionary[key] === null || dictionary[key] === undefined || dictionary[key] === "null" || dictionary[key] === "") {
        delete dictionary[key];
      }
    }
    // Check if the dictionary length is equal to the number of template customBeforeFormFields
    if (Object.keys(dictionary).length !== this.template.customBeforeFormFields.components.length) {
      return false;
    }
    return true;
  }

  /**
   * Initialize element references and definition
   */
  ngOnInit() {    
    // update validity if form status changes
    this.formValidity = this.sheetForm.statusChanges.subscribe(status => {
      this._valid = status === "VALID";
    });

    this.extraFormValidations();
  }

  /**
   * Function to toggle disable property for mat select field (bug since Ng17 that makes it not work on HTML)
   */
  setSelectDisableProperty() {
    // toggle facility ward select field enable/disable property based on workflow and stampTemplateId
    if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
      this.sheetForm.get('stampTemplateId').valueChanges.subscribe((value) => {
        value === this.templates[1]._id ? this.sheetForm.controls['facilityWard'].disable() :
          this.sheetForm.controls['facilityWard'].enable();
      });

      if (this.templates.length < 1) {
        this.sheetForm.controls['facilityWard'].disable()
      } else {
        this.sheetForm.controls['facilityWard'].enable();
      }
    }

    // toggle template select field enable/disable property based on setting
    if (this.appSettings.uiViewSettings.disableTemplateField) {
      this.sheetForm.controls['stampTemplateId'].disable();
    }

    // toggle select field enable/disable property based on patient field value
    this.sheetForm.get('patientId').valueChanges.subscribe((value) => {
      if (!value) {
        if (this.appSettings.patientSettings.enablePatientObjects) {
          this.sheetForm.controls['stampTemplateId'].setValue(this.template._id);
          this.sheetForm.controls['stampTemplateId'].disable();
        } else if (this.appSettings.uiViewSettings.cicWorkflow && this.isCHAUser) {
          this.sheetForm.controls['stampTemplateId'].disable();
        } else if (this.appSettings.uiViewSettings.migoriWorkflow && this.facility.facilityOptions.isResearchAssistantFacility) {
          this.sheetForm.controls['stampTemplateId'].enable();
        } else if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
          this.sheetForm.controls['facilityWard'].disable();
        }
      } else {
        if (this.appSettings.uiViewSettings.migoriWorkflow && this.facility.facilityOptions.isResearchAssistantFacility) {
          this.sheetForm.controls['createFacility'].disable();
        }
        if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
          this.sheetForm.controls['facilityWard'].enable();
        }
        if(!this.sheet.encounterDate && this.appSettings.recordSettings.defaultTemplate == this.template._id){
          this.sheetForm.controls['stampTemplateId'].disable();
        }
        else{
          this.sheetForm.controls['stampTemplateId'].enable();
        }
      }
    });

    this.sheetForm.controls['patientId'].setValue(this.sheetForm.get('patientId').value)
  }

  /**
   * Add extra validators for fields depending on the app settings
   */
  extraFormValidations() {
    // TODO: Add validation for specific patient # format
    // if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
    //     this.sheetForm.get('patientId').clearValidators();
    //     this.sheetForm.get('patientId').setValidators([Validators.required, ValidationService.studyIdValidator]);
    // }

    if (!this.appSettings.uiViewSettings.polioWorkflow) {
      this.sheetForm.get('encounterDate').clearValidators();
      this.sheetForm.get('encounterDate').setValidators([ValidationService.dateFormatValid, ValidationService.dateInPastValidator, Validators.required]);
    }

    if (this.appSettings.patientSettings.patientIdRequired) {
      this.sheetForm.get("patientId").clearValidators();
      this.sheetForm.get("patientId").setValidators([Validators.required]);
    }

    if (this.appSettings.recordSettings.showSerialNumberField) {
      this.sheetForm.get("serialNumber").clearValidators();
      this.sheetForm.get("serialNumber").setValidators([
        Validators.required,
        ValidationService.numberMinMax({
          min: this.appSettings.recordSettings.serialNumberMin,
          max: this.appSettings.recordSettings.serialNumberMax,
        }),
      ]);
    }

    this.cd.detectChanges();
  }

  /** load a specific template series from the database */
  loadTemplateSeries(template: StampTemplate) {
    this.templateSeries = null;
    if (!this.seriesService.isTemplateInSeries(template)) {
      return;
    }

    if (template.seriesId) {
      this.seriesService
        .getSeriesById(template.seriesId)
        .then((templateSeries) => {
          this.templateSeries = templateSeries;
          this.currentTemplateSeriesChange.emit(templateSeries);
        })
        .catch((e) => console.log(e));
    }
  }

  /** is current template in a series */
  isTemplateInSeries() {
    return this.seriesService.isTemplateInSeries(this._template);
  }

  // /** get next template in series */
  // getNextTemplateIdInSeries() {
  //     return this.seriesService.getNextTemplateIdInSeries(this._template, this.templateSeries);
  // }

  /** is current template last in its series */
  isLastInSeries() {
    return this.seriesService.isLastInSeries(
      this._template,
      this.templateSeries
    );
  }

  /** get first template in current series */
  getFirstTemplateIdInSeries() {
    return this.seriesService.getFirstTemplateIdInSeries(
      this._template,
      this.templateSeries
    );
  }

  trackByFn(item: Patient) {
    return item._id;
  }

  /** Destroy unused elements to recover memory */
  ngOnDestroy(): void {
    this.formValidity.unsubscribe();
  }

  /**
   * Reset form and values
   */
  reset() {
    console.log('resetting form');
    this.sheetForm.reset();
    // clear errors so fields don't show as red when we reset
    this.sheetForm.controls['encounterDate'].setErrors(null);
    this.sheetForm.controls['encounterTime'].setErrors(null);
    this.sheetForm.controls['stampTemplateId'].setErrors(null);
    this.sheetForm.controls['stampTemplateId'].setValue(this.template._id);
    this.sheetForm.controls['patientId'].setErrors(null);
    this.sheetForm.controls['facilityWard'].setErrors(null);
    this.sheetForm.controls['specimenId'].setErrors(null);
    this.sheetForm.controls['encounterDate'].setErrors(null);
    this.sheetForm.controls['createFacility'].setErrors(null);
    this.sheetForm.controls['serialNumber'].setErrors(null);
    this.sheetForm.controls['penNumber'].setErrors(null);
    this.sheetForm.controls['email'].setErrors(null);
    this.sheetForm.controls['phoneNumber'].setErrors(null);

    this.extraFormValidations();
  }

  /**
   * User has pressed cancel button
   */
  cancel() {
    console.log("SheetEditComponent: cancel has been pressed");
    this.sheetEditCancel.emit();
  }

  /** New template has been selected, pass it to parent */
  templateSelected() {
    console.log(
      "SheetEditComponent: template selected, passing back to parent"
    );
    this.template = this.templates.find(
      (template) => template._id === this._sheet.stampTemplateId
    );
    if (this.template.templateType === TemplateTypes[0].shortName) {
      this.sheet.otherAnswers = {
        genexpert: { specimenId: "", result: "", facility: "" },
      };
    } else {
      this.sheetForm.controls["specimenId"].reset();
      if (this.sheet.otherAnswers && this.sheet.otherAnswers.genexpert) {
        delete this.sheet.otherAnswers.genexpert;
      }
    }

    this.loadDateTimeLabels(); // load date & time fields

    this.templateChange.emit(this.template);
    this.sheetForm.controls["encounterTime"].setErrors(null);
    this.sheetForm.controls["specimenId"].setErrors(null);

    if (
      this.template._id ===
      this.appSettings.farmSettings.incomeExpenseTemplate ||
      this.template._id ===
      this.appSettings.farmSettings.smartRegistrationTemplate
    ) {
      this.sheetForm.get("penNumber").clearValidators();
      this.sheetForm.get("penNumber").reset();
    } else {
      this.sheetForm.get("penNumber").reset();
      this.sheetForm
        .get("penNumber")
        .setValidators([ValidationService.numberMinMax({ min: 1, max: 2 })]);
    }

    if (this.template.name !== "SmartFarm Registration") {
      this.sheetForm.get("phoneNumber").clearValidators();
      this.sheetForm.get("phoneNumber").reset();

      this.sheetForm.get("email").clearValidators();
      this.sheetForm.get("email").reset();
    } else {
      this.sheetForm
        .get("email")
        .setValidators([ValidationService.emailValidator]);
    }

    this.cd.detectChanges();
  }

  /** Hide pen based on template */
  showPen() {
    if (
      this.template._id ===
      this.appSettings.farmSettings.incomeExpenseTemplate ||
      this.template._id ===
      this.appSettings.farmSettings.smartRegistrationTemplate
    ) {
      return false;
    } else {
      return true;
    }
  }

  /** function get date and time fields labels */
  loadDateTimeLabels(TempName: string = this.template.name) {
    // update the date & time fields labels depending on the selected template
    const { dateLabel, timeLabel } = this.templateService.getDateTimeLabels(
      TempName,
      this.appSettings
    );
    this.dateFieldLabel = dateLabel;
    this.timeFieldLabel = timeLabel;
  }

  /**
   * CHV User selected, pass patient to parent
   */
  chvSelected(e) {
    console.log(
      "SheetEditComponent: chv user selected, passing back to parent",
      e.value
    );
    this.currentPatient = this.patients.find((p) => p.patientId === e.value);
    if (this.currentPatient) {
      this.patientSelectionChanged(this.currentPatient);
    } else {
      // TODO: alert that the user has no patient object
      this.sheet.patientId = ""; // remove patient id if another patient had been selected before
      console.log("patient does not exist");
    }
  }

  /**
   * User has pressed update button
   */
  update() {
    console.log("SheetEditComponent: update has been pressed");
    this.sheetEditUpdate.emit();
  }

  /**
   * User has pressed 'next' button
   */
  next() {
    console.log("SheetEditComponent: next has been pressed");
    this.sheetEditNavigateNext.emit();
  }

  /**
   * Detect patient selection and add new if not exist
   */
  addPatientToSelect() {
    this.selectedFacilityChange.emit(this.selectedFacility);
    const patientId: string = this.currentPatient.patientId;
    const existingPatient =
      this.appSettings.uiViewSettings.migoriWorkflow &&
        this.facility.facilityOptions.isResearchAssistantFacility
        ? this.filteredPatients.find((p) => p.patientId === patientId)
        : this.patients.find((p) => p.patientId === patientId);
    if (!existingPatient) {
      this.dialogRef = this.dialog.open(AddNewPatientDialogComponent, {
        data: {
          facility: this.facility,
          selectedFacility:
            this.appSettings.uiViewSettings.migoriWorkflow &&
              this.facility.facilityOptions.isResearchAssistantFacility
              ? this.selectedFacility
              : this.facility,
          patientId: patientId,
          patients: this.patients,
          appSettings: this.appSettings,
        },
      });
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // set patient here from the dialog
          this.currentPatient = result;
          this.addPatient(this.currentPatient); // add new patient
          this.sheetForm.patchValue({ stampTemplateId: this.templates[0]._id });
          this.templateSelected();
        } else {
          console.log("Don't add new patient");
          this.refreshComponent = true;
          this.cd.detectChanges();
          this.refreshComponent = false;
          this.currentPatient = null; // cancel adding new patient
        }
      });
      // return;
    } else {
      console.log("Existing patient, don't show add dialog");
      // when we set the patient from code, set 7 emit the current patient and sheet
      this.patientSelectionChanged(existingPatient);
    }
  }

  /**
   * Detect when a patient has been selected
   * @param e change object
   */
  patientSelectionChanged(e: any) {
    if (!e) {
      this.sheetForm.controls['patientId'].reset();
      this.sheetForm.controls['facilityWard'].reset();
      this.sheetForm.controls['serialNumber'].reset();
      this.cd.detectChanges();
      return;
    }
    this.currentPatient = e;
    if (!this.currentPatient._id) {
      this.addPatientToSelect();
    } else {
      this.sheet.patientId = this.currentPatient._id;
      this.currentPatientChange.emit(e);

      // for migori deploy only and logged in as a research assistant
      this.selectedFacility =
        this.appSettings.uiViewSettings.migoriWorkflow &&
          this.facility.facilityOptions.isResearchAssistantFacility
          ? this.facilities.find(
            (f) => f._id === this.currentPatient.createFacility
          )
          : null;

      // if no facility selected return all the patients
      this.filteredPatients = this.patients;
      if (this.selectedFacility) {
        this.filteredPatients = this.patients.filter(
          (p) => p.createFacility === this.selectedFacility._id
        );
        this.selectedFacilityChange.emit(this.selectedFacility);
      }

      this.stampTemplateId.focus();
      this.filterPatientRecords.emit(this.sheet.patientId);
    }
  }

  /**
   * Add new patient to the database
   */
  addPatient(patient: Patient) {
    this.sheet.patientId = patient._id;
    this.patients = [...this.patients, patient];
    this.patientService.patients = [...this.patientService.patients, patient];
    this.patientsChange.emit(this.patients);
    this.currentPatientChange.emit(patient);
    this.filterPatientRecords.emit(patient._id);
    this.stampTemplateId.focus();
  }

  /** generate a random patient registration number */
  randomRegNumber() {
    this.currentPatient = new Patient({ patientId: shortid.generate() });
    this.addPatientToSelect();
  }

  /**
   * Function emit value of serial number changed
   * @param event change event
   */
  serialNumberChange(event: Event) {
    const value = +(event.target as HTMLInputElement).value;
    this.serialNumberChanged.emit(value);
  }

  /**
   * Function to trigger barcode scanner dialog
   */
  scanBarcode() {
    // this.barcodeScannerOverlay.show();
    this.dialogRef = this.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      id: "barcode-pane",
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // validate barcode results
        this.validateBarcodeResult(result);
      } else {
        // enter manually
        console.log("Barcode scanner closed");
      }
    });
  }

  /**
   * Function triggered when barcode has returned some data
   * @param barcodeValue scanned results
   */
  validateBarcodeResult(barcodeValue: string) {
    if (this.appSettings.uiViewSettings.safeBloodWorkflow) {
      // study id should be 8 characters and numbers only
      if (
        barcodeValue.match(/^\d{4}-\d{3}-\d{1}$/) ||
        (barcodeValue.replace(/-|\s/g, "").match(/^\d+$/) &&
          barcodeValue.length === 8)
      ) {
        // convert string to required format xxxx-xxx-x
        const studyId = barcodeValue.match(/^\d{4}-\d{3}-\d{1}$/)
          ? barcodeValue
          : barcodeValue.replace(/(\d{4})(\d{3})(\d{1})/, "$1-$2-$3");

        this.currentPatient = new Patient({
          patientId: studyId,
        });

        this.addPatientToSelect(); // call adding a new patient flow
      } else {
        // validation failed alert retry
        this.dialogRef = this.dialog.open(PaperDialogComponent, {
          data: {
            title: "Valid Study ID not detected",
            message: `Scanned StudyID (${barcodeValue}) does not match the expected format (8 digits).`,
            showSuccessBtn: true,
            successBtnTxt: "Scan Again",
            showCancelBtn: true,
            cancelBtnTxt: "Enter manually",
          },
        });

        this.dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            // re-scan barcode
            this.scanBarcode();
          } else {
            // enter manually
            console.log("Barcode scanner exited, scanning manually");
          }
        });
      }
    }
  }

  //  open(){
  //   this.dialogRef = this.dialog.open(RecordExistInSeries);
  //   this.dialogRef.afterClosed().subscribe((result) => {
  //     if (result) {// proceed
  //     } else {
  //       // retain user in the edit form
  //       return;
  //     }
  //   });
  // }

  // /** check for updated values after view */
  // ngAfterViewChecked() {
  //     console.log('afterviewchecked');
  //     this.cd.detectChanges();
  // }
}
