import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AuthenticationService } from "../../services/authentication.service";
import { RepositoryService } from "../../services/repository.service";
import { ValidationService } from "../../validation/validation.service";

import { Facility } from '../../models/facility';
import { User, UserRoles, UserStatuses } from '../../models/user';
import { FormatPhone } from '../../pipes/formatPhone';
@Component({
  selector: "paper-user-add",
  templateUrl: "./user-add.component.html",
})
export class UserAddComponent {
  @Output() userAdded: EventEmitter<User> = new EventEmitter<User>();
  @Input() facilities: Facility[];
  userRolesOptions = this.repositoryService.enumSelector(UserRoles);

  userForm: FormGroup;
  user = new User();
  userRoleOptions = [];

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private repositoryService: RepositoryService
  ) {
    this.userForm = this.formBuilder.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        phone: [
          "",
          [
            Validators.required,
            ValidationService.phoneNumberValidation,
            Validators.minLength(10),
            Validators.maxLength(11),
            Validators.pattern("[0-9]*"),
          ],
        ],
        username: ["", Validators.required],
        emailAddress: [
          "",
          [Validators.required, ValidationService.emailValidator],
        ],
        password: ["", Validators.required],
        confirmPassword: ["", Validators.required],
        facility: ["", Validators.required],
        userRole: ["", Validators.required],
        userRoles: ["", Validators.required],
      },
      {
        validator: ValidationService.passwordConfirm(
          "password",
          "confirmPassword"
        ),
      }
    );
    this.userRoleOptions = this.authService.userRoleOptions;
  }
  resetForm() {
    this.user = new User();
    this.userForm.reset();
  }

  addUser() {
    // set user as approved
    this.user.userStatus = UserStatuses.approved;
    
    // attempt to sign up user
    this.authService
      .addUser(this.user)
      .then((user) => {
        console.log("User added" + JSON.stringify(user));

        this.snackBar.open(
          this.user.userRole +
            " " +
            this.user.username +
            " successfully registered.",
          "Success",
          { duration: 6000 }
        );

        user._id = "org.couchdb.user:" + user.username;
        this.userAdded.emit(user);
        this.resetForm();
      })
      .catch((error) => {
        this.snackBar.open("Error adding new user: " + error, "Error");
      });
  }

  /** Format the phone number when user has left the input field */
  formatNumber() {
    this.user.phone = FormatPhone.formatPhoneNumber(this.user.phone).shortPhone;
  }
}
