import { Injectable, NgZone } from '@angular/core';
import * as tf from '@tensorflow/tfjs';
import { fabric } from 'fabric';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AnswerSheetAnswerGroup, HandwritingResultData } from '../models/answer-sheet';
import { StampTemplate } from '../models/stamp-template';
import { AppSettingsService } from './settings.service';
import { CustomMobileNet, load } from './utils/custom-mobilenet';

@Injectable()
export class ExtractResultsService {
  env = environment;
  // settings
  THRESHOLD_MINIMUM_DARK = 25; // pixels below this threshold are assumed to be dark lines, useful for calculating avg of lighter areas
  THRESHOLD_DARKNESS_RATIO = <number>0.9; // ratio of pixels in the scanned area that counts as a filled circle
  THRESHOLD_DARKNESS: number; // between the darkest and the lightest pixel, which pixel will we consider dark
  debugMode: boolean = this.env.debugMode; // keep track of whether we are in debugMode, off by default

  // template: StampTemplate;
  sheetAnswers: AnswerSheetAnswerGroup;
  // _sheetHandwritingResults: HandwritingResultGroup;
  // sheetHandwritingResults: BehaviorSubject<HandwritingResultGroup> = new BehaviorSubject(null);
  omrExtractionInfo: any;

  // canvases
  canvas: any;
  fabricCanvas: any;
  processedImageLayer: any;

  // prediction model
  predictionModel: CustomMobileNet;
  modelLoaded: Boolean = false; // track if model has already been loaded so that we don't do it again
  modelWarmed: { hwFieldHeight: number, hwFieldWidth: number } = { hwFieldHeight: 0, hwFieldWidth: 0 }; // track if model has already been warmed so that we don't do it again

  constructor(private appSettingsService: AppSettingsService, private zone: NgZone) { }

  // function to preload the model and warm it up so first predictions are faster
  async loadAndWarmModel(hwFieldHeight: number, hwFieldWidth: number) {
    if (hwFieldHeight === 0 && hwFieldWidth === 0) { return; }
    // load models
    if (!this.modelLoaded) {
      const appSettings = await this.appSettingsService.loadAppSettings(
        this.env.settingsId
      );
      let modelKey = "testKey01";
      if (appSettings) {
        modelKey = appSettings.modelKey;
      }
      //console.log('modelKey', modelKey);
      const loadModelStartTime = performance.now();
      await load(modelKey, "assets/mobilenet_large_3e_with_mixup/model.json", "assets/mobilenet_large_3e_with_mixup/metadata.json")
        .then(loadedModel => {
          this.predictionModel = loadedModel;
          this.modelLoaded = true;
          const loadModelEndTime = performance.now();
          if (this.debugMode) { console.log('SheetsComponent: Loading model done in: ' + ((loadModelEndTime - loadModelStartTime) / 1000) + ' Seconds'); }
        })
        .catch(error => { console.log('Error loading model', error); });
    }

    if (!(this.modelWarmed.hwFieldHeight === hwFieldHeight && this.modelWarmed.hwFieldWidth === hwFieldWidth)) {
      if (this.debugMode) { console.log('SheetsComponent: Warming up model with expected image width & height', hwFieldWidth, hwFieldHeight) }
      const warmStartTime = performance.now();
      const warmupResult: tf.Tensor<tf.Rank> = this.predictionModel.model.execute(tf.zeros([1, hwFieldWidth, hwFieldHeight, 3])) as tf.Tensor;
      warmupResult.dataSync(); // we don't care about the result
      warmupResult.dispose();
      this.modelWarmed = { hwFieldHeight, hwFieldWidth };
      const warmEndTime = performance.now();
      if (this.debugMode) { console.log('SheetsComponent: Warming up model done in: ' + ((warmEndTime - warmStartTime) / 1000) + ' Seconds'); }
    }
  }

  /** subject/observable to return each handwriting result as it is processed */
  handwritingResultUpdate: Subject<{ handwritingGroupName: string, handwritingFieldName: string, fieldUpdateData: HandwritingResultData }>;
  public getHandwritingResultUpdateObservable(): Observable<{ handwritingGroupName: string, handwritingFieldName: string, fieldUpdateData: HandwritingResultData }> {
    this.handwritingResultUpdate = new Subject();
    return this.handwritingResultUpdate.asObservable();
  }

  /** run the OCR and HW extraction process */
  extractProcess(template: StampTemplate, transformedImage: HTMLImageElement): Promise<any> {
    if (this.debugMode) { console.log('Starting extraction process'); }

    return new Promise(async (resolve, reject) => {
      if (this.debugMode) { console.log('Transformed image W and H', transformedImage.width, transformedImage.height); }

      // this.template = template;
      this.sheetAnswers = {};

      // create fabric canvas for working on
      this.canvas = document.createElement('canvas');
      this.canvas.setAttribute('id', 'fabricCanvas');
      this.fabricCanvas = new fabric.Canvas(this.canvas);
      this.fabricCanvas.setWidth(template.templateWidth);
      this.fabricCanvas.setHeight(template.templateHeight);

      // get processed image from output of processing
      this.processedImageLayer = new fabric.Image(transformedImage, {
        left: 0,
        top: 0,
        scaleX: template.templateWidth / transformedImage.width,
        scaleY: template.templateHeight / transformedImage.height,
        selectable: false,
        evented: false
      });

      // add the video frame to the canvas, on the bottom layer
      this.fabricCanvas.add(this.processedImageLayer);
      this.fabricCanvas.renderAll();
      let fabricContext = this.fabricCanvas.toCanvasElement().getContext('2d', { willReadFrequently: true })

      // convert the canvas to grayscale, get the value of the average brightness of a field 
      this.convertCanvasToGrayScale(fabricContext, template);

      // apply filter to lighten the background for fields with the faint 'm' 'y' or 'd' in the background
      this.clearUnfilledGuideFields(fabricContext, template)

      // display on canvas in debug view 
      if (this.debugMode) {
        console.log('display grey canvas in debug')
        const canvasGrey = <HTMLCanvasElement>document.getElementById('canvasGrey');
        const canvasGreyContext: CanvasRenderingContext2D = canvasGrey.getContext('2d', { willReadFrequently: true });
        canvasGrey.height = template.templateHeight
        canvasGrey.width = template.templateWidth;
        canvasGreyContext.drawImage(this.fabricCanvas.toCanvasElement(), 0, 0);
      }

      // scan processed image
      this.scanOMRFields(fabricContext, template);

      // do machine learning handwriting recognition
      if (!this.predictionModel || !this.modelLoaded || !this.modelWarmed) { await this.loadAndWarmModel(template.handwritingFieldHeight, template.handwritingFieldWidth); }
      this.scanHandwritingFields(fabricContext, template, this.predictionModel);

      resolve([this.sheetAnswers, this.omrExtractionInfo]);
    });
  }

  /** convert the fabric canvas to grayscale for processing */
  convertCanvasToGrayScale(fabricContext: CanvasRenderingContext2D, template: StampTemplate) {
    // Apply black white filter
    console.log('ConvertGrayscale filter is running');

    const imageData = fabricContext.getImageData(0, 0, template.templateWidth, template.templateHeight);
    const len = imageData.data.length;

    let averagePixel = 0;
    let averageSum = 0;
    let averageSumCount = 0;
    for (let i = 0; i < len; i += 4) {
      // convert to grayscale
      const avg = Math.round((imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3);
      imageData.data[i] = avg;
      imageData.data[i + 1] = avg;
      imageData.data[i + 2] = avg;

      averageSum += avg; averageSumCount++;
    }

    averagePixel = averageSum / averageSumCount;
    console.log('averageSum / averageSumCount = averagePixel', averageSum, averageSumCount, averagePixel);

    fabricContext.putImageData(imageData, 0, 0)
    this.fabricCanvas.renderAll();

    if (averagePixel < this.THRESHOLD_MINIMUM_DARK) {
      if (this.debugMode) { console.log('EXTRACTION: This page is too dark'); }
    }

    if (this.debugMode) {
      console.log(
        '\n Overall Average brightness', averagePixel,
        '\n THRESHOLD_DARKNESS_RATIO: ', this.THRESHOLD_DARKNESS_RATIO,
      );
    }

  }

  /** remove 'm' 'y' 'd' in background of unfilled handwriting fields */
  clearUnfilledGuideFields(fabricContext: CanvasRenderingContext2D, template: StampTemplate) {
    if (template.handwritingFields) {
      // Apply filter for blanks with mm/yy/dd to individual HW fields
      for (const handwritingField of template.handwritingFields) {
        const left = handwritingField.x;
        const top = handwritingField.y;
        const width = template.handwritingFieldWidth;
        const height = template.handwritingFieldHeight;

        // get digit image data
        const fieldData = fabricContext.getImageData(left, top, width, height);

        // set up vars for processing
        let darkestPixel = 255;
        let lightestPixel = 0;
        let localAvgPixel = 0, localSum = 0, localSumCount = 0;
        let len = fieldData.data.length;

        // calculate values
        for (let i = 0; i < len; i += 4) {
          // get avg color
          const avg = Math.round((fieldData.data[i] + fieldData.data[i + 1] + fieldData.data[i + 2]) / 3);

          // get average, darkest and lightest
          if (avg < darkestPixel) { darkestPixel = avg; }
          if (avg > lightestPixel) { lightestPixel = avg; }

          localSum += avg; localSumCount++;
        }
        localAvgPixel = localSum / localSumCount;

        // lighten the background
        for (let j = 0; j < len; j += 4) {
          const avg = Math.round((fieldData.data[j] + fieldData.data[j + 1] + fieldData.data[j + 2]) / 3);
          let color = fieldData.data[j];
          // if pixel is in range, replace it
          if (color > .85 * lightestPixel) {
            fieldData.data[j] = 0.85 * lightestPixel; // R
            fieldData.data[j + 1] = 0.85 * lightestPixel; // G
            fieldData.data[j + 2] = 0.85 * lightestPixel; // B
          }
        }

        // add the filtered image to the main canvas
        fabricContext.putImageData(fieldData, left, top)
      };
    }

    // update display
    this.fabricCanvas.renderAll();
  }

  /** clean up unused objects */
  destroy() {
    console.log('cleaning up extract results service objects');
    this.fabricCanvas.clear();
    this.fabricCanvas.dispose();
    this.fabricCanvas = null;
    if (this.canvas && this.canvas.parentNode) {
      this.canvas.parentNode.removeChild(this.canvas);
      this.canvas = null;
    }
    this.processedImageLayer.dispose();
    this.processedImageLayer = null;
    // this.predictionModel = null;
  }

  /** Perform ML detection on handwriting fields */
  async scanHandwritingFields(fabricContext: CanvasRenderingContext2D, template: StampTemplate, warmModel: CustomMobileNet) {
    return new Promise(async (resolve, reject) => {
      let handwritingStartTime = performance.now();

      console.log('predicting handwriting fields');

      // clear debug canvases displaying handwriting results
      if (this.debugMode) {
        const handwritingDiv = document.getElementById('handwritingDiv');
        while (handwritingDiv.firstChild) { handwritingDiv.removeChild(handwritingDiv.firstChild); }
      }

      if (!template.handwritingFields) {
        console.log('no handwriting fields defined, skipping')
        return;
      }

      Promise.all(template.handwritingFields.map(async handwritingField => {
        const left = handwritingField.x;
        const top = handwritingField.y;
        const width = template.handwritingFieldWidth;
        const height = template.handwritingFieldHeight;

        const fieldData = fabricContext.getImageData(left, top, width, height);

        // draw to debug
        const newCanvas = document.createElement('canvas');
        newCanvas.width = width;
        newCanvas.height = height;
        const newContext = newCanvas.getContext('2d', { willReadFrequently: true });
        newContext.putImageData(fieldData, 0, 0);

        // console.canvas(newCanvas);

        // if resize needed
        // const resizeHeight = 34, resizeWidth = 34;
        // newCanvas.width = resizeWidth; // width;
        // newCanvas.height = resizeHeight; // height;
        // newContext.putImageData(fieldData, 0, 0, 0, 0, resizeWidth, resizeHeight);

        if (this.debugMode) {
          const handwritingDiv = document.getElementById('handwritingDiv');
          handwritingDiv.appendChild(newCanvas);
        }
        // get prediction for field from model
        const predictStartTime = performance.now();
        this.predictWithImageData(warmModel, newCanvas).then(({ prediction, probability }) => {
          if (this.debugMode) {
            console.log('Predictions for field', handwritingField.fieldName,
              'prediction:', prediction,
              'probability:', probability);
          }
          const predictEndTime = performance.now();
          if (this.debugMode) { console.log('SheetsComponent: Prediction for field' + handwritingField.fieldName + ' done in: ' + ((predictEndTime - predictStartTime) / 1000) + ' Seconds'); }
          this.updateHandwritingField(handwritingField.groupName, handwritingField.fieldName, prediction, probability, warmModel.getMetadata().modelName);
        });

      })).catch(error => console.log('error promise.all all HW fields', error))
        .then(done => {
          this.handwritingResultUpdate.complete(); // let the observers know we are done processing
          this.handwritingResultUpdate = null; // end this observable so we can open a new one
          let handwritingEndTime = performance.now(); // track performance of extraction
          if (this.debugMode) { console.log('SheetsComponent: Handwriting fields processed in: ' + ((handwritingEndTime - handwritingStartTime) / 1000) + ' Seconds'); }
          resolve('success');
        });
    });
  }

  /** function which when given a model and an image will provide a prediction for a given field */
  async predictWithImageData(predictionModel: CustomMobileNet, imageData: HTMLCanvasElement) {
    const tensor = tf.browser.fromPixels(imageData);
    const { shape } = tensor;
    // console.log('Image Tensor is ', tensor, shape);
    const normalized = tensor.div(tf.scalar(255));
    const batched = normalized.reshape([1, shape[0], shape[1], 3]);

    // get prediction results from model
    const predictionResult: tf.Tensor<tf.Rank> = await predictionModel.model.execute(batched) as tf.Tensor;
    // console.log('predictionResult', predictionResult.dataSync())

    // make sense of results
    let prediction = '', probability;

    const predictedIndex = predictionResult.argMax(1).dataSync()[0];
    probability = predictionResult.dataSync()[predictedIndex];
    prediction = predictionModel.getClassLabels()[predictedIndex];

    // normalize the probability from -10 to 10 to be from 0-1
    const normalize = (val, max, min) => (val - min) / (max - min);
    probability = normalize(probability, 10, -10);

    return { prediction: prediction, probability: probability };
  };

  /** Update a circle on the canvas given only the answerTemplate and value */
  updateHandwritingField(handwritingGroupName: string, handwritingFieldName: string, value: string, probability: number, modelName: string) {
    const fieldUpdateData: HandwritingResultData = {
      mlResult: value, userInputVal: null, userValidatedResult: null, displayVal: value, adminValidatedVal: null,
      adminValidationDate: null, confidence: probability, modelVersion: modelName
    };
    this.zone.run(() => {
      this.handwritingResultUpdate.next({ handwritingGroupName, handwritingFieldName, fieldUpdateData });
    })
  }

  /** Perform the scanning phase for the OMR fields */
  async scanOMRFields(fabricContext: CanvasRenderingContext2D, template: StampTemplate) {
    if (this.debugMode) { console.log('preparing working canvases for extraction process'); }
    // const contextDebugCircles: CanvasRenderingContext2D = this.canvasDebugCircles.getContext('2d', { willReadFrequently: true });
    const squareLength = Math.floor(Math.sqrt(Math.pow(template.circle_radius, 2) / 2));
    const csRatios: { serialNumber: number, csRatio: number }[] = [];

    // canvas or debug output
    let canvasCS = <HTMLCanvasElement>document.getElementById('canvasCS');
    let canvasCSContext: CanvasRenderingContext2D = canvasCS.getContext('2d', { willReadFrequently: true });
    if (this.debugMode) {
      canvasCS.height = template.templateHeight;
      canvasCS.width = template.templateWidth;
      canvasCSContext.drawImage(this.fabricCanvas.toCanvasElement(), 0, 0);
    }

    if (this.debugMode) { console.log('extracting circle data'); }

    // perform scan for each answer field, get center/surround ratios
    template.answerTemplates.forEach(answerTemplate => {

      const left = answerTemplate.x - squareLength;
      const top = answerTemplate.y - squareLength;
      const width = squareLength * 2;
      const height = squareLength * 2;

      const outerSquareLength = squareLength * 1.5;
      const outerLeft = left - outerSquareLength,
        outerTop = top - outerSquareLength,
        outerWidth = width + (outerSquareLength * 2),
        outerHeight = height + (outerSquareLength * 2);

      const circleData = fabricContext.getImageData(left, top, width, height);
      const circleSurround = fabricContext.getImageData(outerLeft, outerTop, outerWidth, outerHeight);

      // draw inside square and outside square on debug canvas
      if (this.debugMode) {
        this.drawLine(canvasCSContext, { x: left, y: top }, { x: left, y: top + height }, 'yellow'); // left side
        this.drawLine(canvasCSContext, { x: left, y: top }, { x: left + width, y: top }, 'yellow');  // top side
        this.drawLine(canvasCSContext, { x: left, y: top + height }, { x: left + width, y: top + height }, 'yellow');  // bottom side
        this.drawLine(canvasCSContext, { x: left + width, y: top }, { x: left + width, y: top + height }, 'yellow');  // right side

        this.drawLine(canvasCSContext, { x: outerLeft, y: outerTop }, { x: outerLeft, y: outerTop + outerHeight }, 'red'); // outerLeft side
        this.drawLine(canvasCSContext, { x: outerLeft, y: outerTop }, { x: outerLeft + outerWidth, y: outerTop }, 'red');  // outerTop side
        this.drawLine(canvasCSContext, { x: outerLeft, y: outerTop + outerHeight }, { x: outerLeft + outerWidth, y: outerTop + outerHeight }, 'red');  // bottom side
        this.drawLine(canvasCSContext, { x: outerLeft + outerWidth, y: outerTop }, { x: outerLeft + outerWidth, y: outerTop + outerHeight }, 'red');  // right side

        this.drawCircle(canvasCSContext, answerTemplate.x, answerTemplate.y, template.circle_radius, 'green'); // circle
      }

      // get center/surround ratio
      const surroundAvg = this.getAverageLightPixels(circleSurround);
      const centerAvg = this.scanGrey(circleData);
      const csRatio = (centerAvg / surroundAvg);
      answerTemplate.csRatio = csRatio;
      csRatios.push({ serialNumber: answerTemplate.serialNumber, csRatio: csRatio });

      if (this.debugMode) {
        console.log(
          'field:', answerTemplate.groupName, answerTemplate.fieldName,
          ', csRatio:', csRatio,
          ', center:', centerAvg,
          ', surround:', Math.round(surroundAvg));
      }

      // contextDebugCircles.putImageData(circleData, left, top);
      // contextDebugCircles.putImageData(circleSurround, left - outerSquareLength, top - outerSquareLength);
    });

    // find highest center/surround ratio
    csRatios.sort((a, b) => { return a.csRatio - b.csRatio; });
    const allCSdiffs = [];
    let largestDiff = 0; let largestDiffIndex = 0;
    // iterate 0 to length -4, ignore last three fields due to edge effects
    for (let i = 0; i < csRatios.length - 1; i++) {
      const diff = csRatios[i + 1].csRatio - csRatios[i].csRatio;
      allCSdiffs.push(diff);
      if (this.debugMode) { console.log(i, csRatios[i].csRatio, diff); }
      if (diff > largestDiff) { largestDiff = diff; largestDiffIndex = i; }
    }
    if (this.debugMode) { console.log('Largest cs Difference:', largestDiff) }

    const { standardDeviation, variance, skewness } = this.calculateStandardDeviation(allCSdiffs); // get StDev, variance & skew values of allCSdiffs

    // TODO: Review for cases where OMR fields are less than 4
    const iqr = allCSdiffs.length < 4 ? allCSdiffs[0] : this.getInterQuartileRange(allCSdiffs);
    if (this.debugMode) { console.log('Standard deviation, variance & skewness of all csDiffs:', standardDeviation, variance, skewness) }

    // get the value to multiply the stDev with
    const multiplyBy = (allCSdiffs.length <= 30) ? 3 : 5;
    if (this.debugMode) { console.log('(', allCSdiffs.length, ') Multiply by:', multiplyBy) }

    let nAboveSD = allCSdiffs.filter(csDiff => csDiff > (multiplyBy * standardDeviation));
    if (this.debugMode) { console.log(`csDiffs above the (${multiplyBy}xstDev): ${nAboveSD}, ${nAboveSD.length}`) }

    // for records with many OMR fields multiply by 3
    if (nAboveSD.length === 0 && allCSdiffs.length > 30) {
      nAboveSD = allCSdiffs.filter(csDiff => csDiff > (3 * standardDeviation));
    }

    // check if MaxCSdiff is greater than 0.05
    if (largestDiff >= 0.05) {
      this.THRESHOLD_DARKNESS_RATIO = this.getThresholdRatio(csRatios, allCSdiffs, nAboveSD, largestDiffIndex, multiplyBy);
    } else {
      if (this.debugMode) { console.log('Largest cs difference is less than 0.05, IRQ:', iqr) }
      // Using IQR: // if (iqr < 0.001) {
      // if (this.debugMode) { console.log('IRQ is less than 0.001') }
      // TODO: This will fail for cases where all the fields are filled, though its unlikely scenario that we will have all the fields filled
      if (variance < 0.0009) {
        if (this.debugMode) { console.log('Variance is less than 0.0001') }
        this.THRESHOLD_DARKNESS_RATIO = null;
      } else {
        this.THRESHOLD_DARKNESS_RATIO = this.getThresholdRatio(csRatios, allCSdiffs, nAboveSD, largestDiffIndex, multiplyBy);
      }
    }

    if (this.debugMode) { console.log('THRESHOLD_DARKNESS_RATIO set to ', this.THRESHOLD_DARKNESS_RATIO); }

    // store the extraction info
    this.omrExtractionInfo = {
      maxCSDiff: largestDiff,
      standardDeviation: standardDeviation,
      variance: variance,
      skewness: skewness,
      nAboveSD: nAboveSD.length,
      iqr: iqr,
      omrThreshold: this.THRESHOLD_DARKNESS_RATIO,
      csRatios: csRatios
    }

    // check if answers property is available
    if (!this.sheetAnswers) { this.sheetAnswers = {}; }

    // decide if fields are checked based on center/surround ratio
    template.answerTemplates.forEach(answerTemplate => {
      let scanResult;
      // if threshold has not been set mark all points to false
      if (this.THRESHOLD_DARKNESS_RATIO === null) {
        scanResult = false;
      } else {
        scanResult = answerTemplate.csRatio <= this.THRESHOLD_DARKNESS_RATIO;
      }
      this.updateField2(answerTemplate.groupName, answerTemplate.fieldName, scanResult);
      if (this.debugMode) {
        console.log(
          'field:', answerTemplate.groupName, answerTemplate.fieldName,
          ', csRatio:', answerTemplate.csRatio,
          ', result:', scanResult);
      }
      delete answerTemplate.csRatio;
    });

  }

  /**
   * Get the threshold ratio of all cs diffs
   * @param csRatios array of all ration
   * @param allCSdiffs array of all cs diffs
   * @param nAboveSD number of values above SD
   * @param largestDiffIndex largest difference index
   * @param multiplyBy value to multiply by
   * @returns { float } threshold ratio
   */
  getThresholdRatio(csRatios: { serialNumber: number, csRatio: number }[], allCSdiffs: number[], nAboveSD: number[], largestDiffIndex: number, multiplyBy: number) {
    // check how many values (of allCSdiffs) are greater than multiplyBy x standardDeviation
    let THRESHOLD_RATIO = null;
    if (nAboveSD.length === 0) {
      THRESHOLD_RATIO = csRatios[largestDiffIndex].csRatio;
      if (this.debugMode) { console.log(`No value above (${multiplyBy}xstDev), set threshold to the largest diff`) }
    } else if (nAboveSD.length === 1) {
      THRESHOLD_RATIO = csRatios[allCSdiffs.indexOf(nAboveSD[0])].csRatio;
    } else {
      THRESHOLD_RATIO = csRatios[allCSdiffs.indexOf(nAboveSD[nAboveSD.length - 1])].csRatio;
    }

    return THRESHOLD_RATIO;
  }

  /** Function to calculate the median */
  median(arr) {
    const middle = Math.floor(arr.length / 2);
    if (arr.length % 2 === 0) {
      return (arr[middle - 1] + arr[middle]) / 2;
    } else {
      return arr[middle];
    }
  }

  /**
   * Get the inter quartile range
   * @param allCSdiffs array of all cs diffs
   * @returns Inter Quartile Range
   */
  getInterQuartileRange(allCSdiffs: number[]) {
    // Step 1: Sort the array
    const newAllCSdiffs = new Array(...allCSdiffs); // initiate a new array to avoid updating the original
    const values = newAllCSdiffs.sort((a, b) => a - b);

    // Step 2: Find Q1 and Q3
    const middleIndex = Math.floor(values.length / 2);
    const q1 = this.median(values.slice(0, middleIndex));
    const q3 = this.median(values.slice(-middleIndex));

    // Step 3: Calculate IQR
    const iqr = q3 - q1;

    return iqr;
  }

  /**
   * Calculate the standard deviation for an array of values
   * @param numbers array of numbers
   * @returns number
   */
  calculateStandardDeviation(numbers: number[]) {
    // Step 1: Calculate the mean
    const mean = numbers.reduce((acc, num) => acc + num, 0) / numbers.length;

    // Step 2: Calculate the squared differences from the mean
    const squaredDifferences = numbers.map(num => Math.pow(num - mean, 2));

    // Step 3: Calculate the mean of the squared differences (variance)
    const variance = squaredDifferences.reduce((acc, diff) => acc + diff, 0) / numbers.length;

    // Step 4: Take the square root to get the standard deviation
    const standardDeviation = Math.sqrt(variance);

    // step 5: calculate the skewness
    const cubedDifferences = numbers.map(val => Math.pow(val - mean, 3));
    const skewness = cubedDifferences.reduce((acc, val) => acc + val, 0) / (numbers.length * Math.pow(variance, 3 / 2));

    return { standardDeviation, variance, skewness };
  }

  /** Get the threshold for a given area based on average dark or light above minimum */
  getAverageLightPixels(imageData) {
    const data = imageData.data, len = data.length;
    let averageSum = 0, averageSumCount = 0;
    for (let i = 0; i < len; i += 4) {
      const avg = Math.round((data[i] + data[i + 1] + data[i + 2]) / 3);
      if (avg > this.THRESHOLD_MINIMUM_DARK) { averageSum += avg; averageSumCount++; }
    }
    return averageSum / averageSumCount;
  }

  /** Get the average grayness of an imageData object  */
  scanGrey(imageData): number {
    const data = imageData.data, len = data.length;

    // count total of pixel values then get average
    let colorTotal = 0;
    for (let i = 0; i < len; i += 4) {
      colorTotal += data[i] + data[i + 1] + data[i + 2];
    }

    // round off and only use three quarters of the length because we don't use the transparency channel (rgba)
    return Math.round(colorTotal / (len * 0.75));
  }

  /** Update a circle on the canvas given only the answer template and value */
  updateField2(groupName: string, fieldName: string, value: boolean) {
    if (!this.sheetAnswers[groupName]) {
      // if group does not exist add it
      this.sheetAnswers[groupName] = { [fieldName]: false };
    }
    this.sheetAnswers[groupName][fieldName] = value;
  }

  /** draw given line onto canvas */
  drawLine(canvasContext, begin, end, color, width = 2) {
    canvasContext.beginPath();
    canvasContext.moveTo(begin.x, begin.y);
    canvasContext.lineTo(end.x, end.y);
    canvasContext.lineWidth = width;
    canvasContext.strokeStyle = color;
    canvasContext.stroke();
  }

  /** draw given circle onto canvas */
  drawCircle(canvasContext, x, y, radius, color, width = 2) {
    canvasContext.beginPath();
    canvasContext.arc(x, y, radius, 0, 2 * Math.PI);
    canvasContext.lineWidth = width;
    canvasContext.strokeStyle = color;
    canvasContext.stroke();
  }

}

