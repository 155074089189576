import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AppMaterialModule } from '../material/app-material.module';
import { TopNavComponent } from './top-nav-user.component';
import { UserHasAuthorityDirective } from '../directives/authority.directive';


@NgModule({
  declarations: [
    TopNavComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    RouterModule,
    UserHasAuthorityDirective,
  ],
  exports: [
    TopNavComponent
  ]
})
export class NavigationModule { }
