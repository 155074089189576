import { Injectable } from '@angular/core';
import { Attachment } from '../models/attachment';
import { RepositoryService, Databases } from './repository.service';

@Injectable()
export class AttachmentService {
  constructor(private repository: RepositoryService) { }

  /**
   * Provide new or updated image for saving in the db
   * @param attachment
   */
  updateAttachment(attachment: Attachment, createFacility: string = null): Promise<Attachment> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(attachment, Attachment.type, Databases.imagesDb, false, createFacility)
        .then(pouchObject => {
          const updatedAttachment: Attachment = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedAttachment);
        }).catch(error => {
          console.log('An error occurred when updating this attachment', error);
          reject(error);
        });
    });
  }

  /**
   * Return an attachment from pouch, based on the answer sheet
   * @param sheetId
   */
  getAttachmentBySheet(sheetId: string) {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(Attachment.type, 'answerSheetId', sheetId, Databases.imagesDb).then((result) => {
        const attachment: Attachment = result.docs.map((doc: any) => this.mapObjectToAttachment(doc));
        resolve(attachment[0]);
      }).catch(error => {
        console.error('An error occurred loading attachment', error);
        reject(error);
      });
    });
  }

  /**
 * get sheet attachment from pouch
 */
  getSheetAttachment(attachmentId: string, file: string = 'file'): Promise<Attachment> {
    return this.repository.getAttachment(attachmentId, Databases.imagesDb, file)
      .then(response => response as Attachment[])
      .catch(error => {
        console.error('An error occurred loading the attachment', error);
        return Promise.reject(error);
      });
  }

  /**
   * Takes an object and maps it to Attachment
   * @param object
   */
  private mapObjectToAttachment(object: any): Attachment {
    let attachment: Attachment = new Attachment();
    return attachment = { ...object };
  }

  /** admin function to get list of attachments from couch */
  getAttachmentsList(): Promise<String[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchAllObjectsBy(Attachment.type, 'attachments_list', Databases.imagesDb).then((result) => {
        // const attachment: Attachment = result.docs.map((doc: any) => this.mapObjectToAttachment(doc));
        resolve(result.docs);
      }).catch(error => {
        console.error('An error occurred loading the attachments list', error);
        reject(error);
      });
    });
  }
}
