<!-- show logged in facility -->
<div *ngIf="appSettings.uiViewSettings.showFacilityNameOnEditForm">
  <p style="color: #BABCBE; margin: 0.5em 0 0.6em 0;">
    <strong *ngIf="!appSettings.uiViewSettings.polioWorkflow">Facility:</strong>
    {{facility.name}}<span *ngIf="appSettings.uiViewSettings.polioWorkflow">; {{facility.code}}</span>
  </p>
</div>

<!-- edit medical record form -->
<form [formGroup]="sheetForm">

  <!-- custom before form fields, position above -->
  <div
    *ngIf="template && template?.customBeforeFormFields?.components.length > 0 && template?.beforeFieldsPosition === 'above'">
    <formio [form]="template.customBeforeFormFields" [submission]="{data: formIoData}"
      (change)="onCustomBeforeFormFieldsChange($event)"></formio>
  </div>

  <!-- Migori: RA Workflow -->
  <div class="form-group" fxLayout="row"
    *ngIf="appSettings.uiViewSettings.migoriWorkflow && facility.facilityOptions.isResearchAssistantFacility">
    <mat-form-field class="width100">
      <mat-select #createFacility formControlName="createFacility" [(ngModel)]="selectedFacility"
        placeholder="Select Facility">
        <mat-option *ngFor="let facility of facilities" [value]="facility">{{ facility.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <validation-messages [control]="sheetForm.controls['createFacility']"></validation-messages>
  </div>
  <!-- END OF Migori: RA Workflow -->

  <!-- serial number -->
  <div *ngIf="appSettings.recordSettings.showSerialNumberField">
    <mat-form-field class="width100">
      <input matInput type="number" [(ngModel)]="sheet.serialNumber" formControlName="serialNumber"
        [placeholder]="appSettings.uiViewSettings.serialNumberLabel" (blur)="serialNumberChange($event)"
        autocomplete="off" required>
    </mat-form-field>
    <validation-messages [control]="sheetForm.controls['serialNumber']"></validation-messages>
  </div>

  <!-- Patient Reg # -->
  <div class="form-group" fxLayout="row"
    *ngIf="!appSettings.uiViewSettings.cicWorkflow && !appSettings.uiViewSettings.farmAlertWorkflow && !appSettings.uiViewSettings.polioWorkflow && appSettings.patientSettings.enablePatientObjects">
    <!-- Select for selecting or adding patient -->
    <ng-select *ngIf="!refreshComponent" formControlName="patientId" [readonly]="disablePatientField"
      [placeholder]="appSettings.patientSettings.patientIdLabel" bindLabel="patientId"
      [items]="selectedFacility !== null && appSettings.uiViewSettings.migoriWorkflow && facility.facilityOptions.isResearchAssistantFacility ? filteredPatients : patients"
      (change)="patientSelectionChanged($event)"
      [addTag]="appSettings.patientSettings.enablePatientAdding ? true : false" [trackByFn]="trackByFn"
      [(ngModel)]="currentPatient" [style.width]="(facility?.facilityfields?.randomRegNumber && appSettings.patientSettings.enablePatientAdding) ||
      appSettings.patientSettings.enableBarcodeScanning ? '-webkit-fill-available' : '100%'">

      <ng-template ng-tag-tmp let-search="searchTerm" *ngIf="appSettings.patientSettings.enablePatientAdding">
        <span class="uk-comment-meta">Add new {{appSettings.patientSettings.patientIdLabel}}: </span>{{search}}
      </ng-template>
    </ng-select>

    <!-- randomize the reg number if nothing to enter -->
    <div *ngIf="(facility?.facilityfields?.randomRegNumber && appSettings.patientSettings.enablePatientAdding)"
      style="width:100px">
      <button mat-button (click)="randomRegNumber()" class="uk-float-right uk-margin-top">Random Reg</button>
    </div>
    <div *ngIf="appSettings.patientSettings.enableBarcodeScanning" id="barcode-scanner-wrapper">
      <mat-icon svgIcon="barcode_scanner" (click)="scanBarcode()" class="uk-float-right">
      </mat-icon>
    </div>
  </div>

  <validation-messages [control]="sheetForm.controls['patientId']"></validation-messages>

  <div class="form-group" fxLayout="row" *ngIf="appSettings.uiViewSettings.cicWorkflow && isCHAUser">
    <mat-form-field class="width100">
      <mat-select formControlName="patientId" [placeholder]="appSettings.patientSettings.patientIdLabel"
        bindLabel="patientId" (selectionChange)="chvSelected($event)"
        [(ngModel)]="currentPatient ? currentPatient.patientId : currentPatient">
        <mat-optgroup *ngFor="let group of groupedChvList" [label]="group.facilityName">
          <mat-option *ngFor="let chv of group.chvs" [value]="chv.patientId">
            {{chv.firstName}} {{chv.lastName}}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- pen number -->
  <div *ngIf="appSettings.uiViewSettings.farmAlertWorkflow && template && showPen()">
    <mat-form-field class="width100">
      <input matInput type="number" [(ngModel)]="sheet.penNumber" formControlName="penNumber" placeholder="Pen #"
        (ngModelChange)="penChanged()" autocomplete="off"
        [required]="appSettings.uiViewSettings.farmAlertWorkflow && template && template._id !== appSettings.farmSettings.incomeExpenseTemplate">
    </mat-form-field>
    <validation-messages [control]="sheetForm.controls['penNumber']"></validation-messages>
  </div>

  <!-- email input field -->
  <div *ngIf="appSettings.uiViewSettings.farmAlertWorkflow && template && template.name === 'SmartFarm Registration'">
    <mat-form-field class="width100">
      <input matInput type="email" formControlName="email" [(ngModel)]="sheet.emailAddress" placeholder="Email"
        autocomplete="off">
    </mat-form-field>
    <validation-messages [control]="sheetForm.controls['email']"></validation-messages>
  </div>

  <!-- Phone number input field -->
  <div *ngIf="appSettings.uiViewSettings.farmAlertWorkflow && template && template.name === 'SmartFarm Registration'">
    <mat-form-field class="width100">
      <ngx-mat-intl-tel-input [preferredCountries]="['ng', 'ke']" [enablePlaceholder]="false" [(ngModel)]="sheet.phone"
        [onlyCountries]="['us', 'ke','ng', 'ug', 'tz']" [enableSearch]="true" name="phone" describedBy="phoneInput"
        formControlName="phoneNumber" placeholder="Mobile number" autocomplete="none"></ngx-mat-intl-tel-input>
    </mat-form-field>
    <validation-messages [control]="sheetForm.controls['phoneNumber']"></validation-messages>
  </div>

  <!-- facility ward field -->
  <div class="form-group" *ngIf="appSettings.uiViewSettings.safeBloodWorkflow">
    <mat-form-field class="width100">
      <mat-select formControlName="facilityWard" placeholder="Select Ward *" [(ngModel)]="sheet.facilityWard" required>
        <mat-option *ngFor="let ward of facility.facilityWards" [value]="ward._id">
          {{ward.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <validation-messages [control]="sheetForm.controls['facilityWard']"></validation-messages>
  </div>

  <!-- template select -->
  <div class="form-group">
    <mat-form-field class="width100">
      <mat-select #stampTemplateId formControlName="stampTemplateId" [(ngModel)]="sheet.stampTemplateId"
        [placeholder]="'Select ' + appSettings.uiViewSettings.templateFieldLabel"
        (selectionChange)="templateSelected()">
        <mat-option *ngFor="let template of templates" [value]="template._id">{{ template.name }} {{
          template.description }} {{template.version}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <validation-messages [control]="sheetForm.controls['stampTemplateId']"></validation-messages>
  </div>

  <div *ngIf="(template && sheetForm.controls['stampTemplateId'].value) && (sheet.patientId || appSettings.uiViewSettings.farmAlertWorkflow ||
    appSettings.uiViewSettings.polioWorkflow || !appSettings.patientSettings.enablePatientObjects)">

    <div *ngIf="template.showEncounterDate">
      <!-- date field /1st cic flow mm/yyyy -->
      <div class="form-group" *ngIf="appSettings.uiViewSettings.cicWorkflow; else elseDateBlock">
        <mat-form-field class="width100" paperYearMonthFormat>
          <input matInput [matDatepicker]="pickerMMYYY" [min]="minDate" [max]="maxDate" formControlName="encounterDate"
            [placeholder]="dateFieldLabel" [(ngModel)]="sheet.encounterDate" [required]="template.requireEncounterDate"
            (dateInput)="dateTimeChanged()">
          <mat-datepicker-toggle matSuffix [for]="pickerMMYYY"></mat-datepicker-toggle>
        </mat-form-field>
        <mat-datepicker #pickerMMYYY touchUi startView="multi-year" (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, pickerMMYYY)"></mat-datepicker>
        <validation-messages [control]="sheetForm.controls['encounterDate']"></validation-messages>
      </div>

      <!-- others flows dd/mm/yyyy -->
      <ng-template #elseDateBlock>
        <div class="form-group">
          <mat-form-field class="width100">
            <input matInput [matDatepicker]="pickerDDMMYY" [min]="minDate" [max]="maxDate"
              formControlName="encounterDate" [placeholder]="dateFieldLabel" [(ngModel)]="sheet.encounterDate"
              [required]="template.requireEncounterDate" (dateInput)="dateTimeChanged()">
            <mat-datepicker-toggle matSuffix [for]="pickerDDMMYY"></mat-datepicker-toggle>
          </mat-form-field>
          <mat-datepicker #pickerDDMMYY touchUi></mat-datepicker>
          <validation-messages [control]="sheetForm.controls['encounterDate']"></validation-messages>
        </div>
      </ng-template>
    </div>

    <!-- time field -->
    <div class="form-group" *ngIf="template.showEncounterTime && appSettings.uiViewSettings.encounterTime">
      <!-- TODO: Enable digital time picker when discussed
      <span class="faux-mat-label">Time of Encounter <span *ngIf="template.requireEncounterTime">*</span></span>
      <ngx-timepicker-field [required]="template.requireEncounterTime" formControlName="encounterTime" placeholder="Time of Encounter" 
      [(ngModel)]="sheet.encounterTime"></ngx-timepicker-field>
      -->
      <mat-form-field class="width100">
        <input matInput [ngxMatTimepicker]="pickerEncounterTime" [required]="template.requireEncounterTime"
          formControlName="encounterTime" [placeholder]="timeFieldLabel" [(ngModel)]="sheet.encounterTime"
          (ngModelChange)="dateTimeChanged()">
        <ngx-mat-timepicker-toggle matSuffix [for]="pickerEncounterTime">
        </ngx-mat-timepicker-toggle>
      </mat-form-field>
      <ngx-mat-timepicker #pickerEncounterTime></ngx-mat-timepicker>
      <validation-messages [control]="sheetForm.controls['encounterTime']"></validation-messages>
    </div>

    <!-- safe Blood additional date & time -->
    <div *ngIf="appSettings.uiViewSettings.safeBloodWorkflow">
      <!-- transfusion start date field -->
      <paper-transfusion-date [form]="sheetForm" [answerSheet]="sheet" [appSettings]="appSettings" [template]="template"
        (dateTimeChanged)="dateTimeChanged()"></paper-transfusion-date>

      <!-- transfusion end date field -->
      <paper-transfusion-end-date [form]="sheetForm" [answerSheet]="sheet" [appSettings]="appSettings"
        [template]="template" (dateTimeChanged)="dateTimeChanged()"></paper-transfusion-end-date>
    </div>

    <div class="form-group" *ngIf="template.showSpecimenId">
      <mat-form-field class="width100">
        <input matInput [required]="template.requireSpecimenId" formControlName="specimenId" placeholder="Specimen ID"
          [(ngModel)]="sheet.otherAnswers['genexpert']['specimenId']">
      </mat-form-field>
      <validation-messages [control]="sheetForm.controls['specimenId']"></validation-messages>
    </div>
  </div>

  <div class="uk-text-center" *ngIf="loadingPatientRecords">
    <mat-progress-bar mode="query" class="uk-margin-small-bottom"></mat-progress-bar>
    <span>Loading filtered records...</span>
  </div>

  <!-- custom before form fields, position below (default) -->
  <div
    *ngIf="template && template?.customBeforeFormFields?.components.length > 0 && (template?.beforeFieldsPosition === 'below' || !template?.beforeFieldsPosition)">
    <formio [form]="template.customBeforeFormFields" [submission]="{data: formIoData}"
      (change)="onCustomBeforeFormFieldsChange($event)"></formio>
  </div>

  <div class="form-group uk-margin-top">
    <button mat-raised-button (click)="cancel();" style="color: red" class="uk-margin-small-right">
      <mat-icon>clear</mat-icon>
    </button>
    <button mat-raised-button *ngIf="sheet._id" (click)="update()"
      [disabled]="!sheetForm.valid || (template?.customBeforeFormFields?.components.length > 0 && !formioValid)"
      style="color: #40ff40">
      <mat-icon>done</mat-icon>
    </button>

    <button mat-raised-button (click)="next()" class="uk-float-right next-button" [disabled]="!sheetForm.valid || loadingPatientRecords ||
      (template?.customBeforeFormFields?.components.length > 0 && !formioValid) || !sheet.stampTemplateId || 
      (template?.showEncounterDate && !sheet.encounterDate) ||
      (appSettings.uiViewSettings.safeBloodWorkflow && !sheet.facilityWard) ||
      (appSettings.uiViewSettings.tbWorkflow && !sheet.patientId) ||
      (appSettings.uiViewSettings.migoriWorkflow && !sheet.patientId) ||
      (appSettings.uiViewSettings.cicWorkflow && (isCHAUser && !sheet.patientId))">
      Next<mat-icon>navigate_next</mat-icon>
    </button>
  </div>

  <!-- <div *ngIf="template">
    <strong>sheet.patientId</strong> {{ sheet.patientId | json }},<br />
    <strong>series</strong> {{ series | json}}, <br />
    <strong>_templateSeries</strong> {{ _templateSeries | json}}, <br />
    <strong>template.seriesId</strong> {{ template.seriesId | json }},<br />
    <strong>is this template part of a series?</strong> {{ isTemplateInSeries() | json }},<br />
    <strong>isLastInSeries</strong>  {{ isLastInSeries() | json}},<br />
    <strong>current template._id</strong> {{ template?._id }},<br />
    <strong>next template id in series?</strong> {{ getNextTemplateIdInSeries() | json }},<br />
    <strong>getFirstTemplateIdInSeries</strong> {{ getFirstTemplateIdInSeries() | json}},<br />
  </div>

  <strong>form:</strong> <pre><code>{{sheetForm.value | json}}</code></pre><br />
  <strong>model:</strong> <pre><code>{{sheet | json}}</code></pre><br />
  <strong>sheetForm.valid:</strong> {{sheetForm.valid}}, <br />
  <strong>sheetForm.invalid:</strong> {{sheetForm.invalid}}, <br />
  <strong>formioValid:</strong> {{formioValid}}, <br />
  <strong>formioDirty:</strong> {{formioDirty}}, <br />
  <strong>sheet.patientId:</strong> {{sheet.patientId}}, <br />
  <strong>sheet.encounterDate:</strong> {{sheet.encounterDate}}, <br />
  <strong>sheet.stampTemplateId:</strong> {{sheet.stampTemplateId}}, <br />
  <strong>form errors:</strong> <pre><code>{{ getAllErrors(sheetForm) | json }}</code></pre>
  <strong>processingSheetUpdate:</strong> {{ processingSheetUpdate | json }}, <br />
  <strong>appSettings.uiViewSettings.safeBloodWorkflow:</strong> {{ appSettings.uiViewSettings.safeBloodWorkflow | json }}, <br />
  <strong>appSettings.uiViewSettings.tbWorkflow:</strong> {{ appSettings.uiViewSettings.tbWorkflow | json }}, <br />
  <strong>appSettings.uiViewSettings.migoriWorkflow:</strong> {{ appSettings.uiViewSettings.migoriWorkflow | json }}, <br />
  <strong>appSettings.uiViewSettings.cicWorkflow:</strong> {{ appSettings.uiViewSettings.cicWorkflow | json }}, <br />
  <strong>template:</strong> <pre><code>{{template | json}}</code></pre><br />
  <strong>templates:</strong> <pre><code>{{templates | json}}</code></pre><br />
  <strong>currentPatient:</strong> <pre><code>{{ currentPatient | json}}</code></pre><br /> 
  <span *ngIf="template"><strong>sheet.customBeforeFieldsData:</strong> <pre><code>{{sheet.customBeforeFieldsData | json}}</code></pre><br /></span>
  <span *ngIf="template"><strong>template.customBeforeFormFields:</strong> <pre><code>{{template.customBeforeFormFields | json}}</code></pre><br /></span>
  <span *ngIf="template"><strong>sheet.customAfterFieldsData:</strong> <pre><code>{{sheet.customAfterFieldsData | json}}</code></pre><br /></span> -->

</form>