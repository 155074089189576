import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Globals } from './globals';
import { User } from '../models/user';

@Injectable()
export class AuthGuard  {
  private currentUser: User;

  isLoggedIn(): boolean {
    return (this.currentUser !== undefined && this.currentUser.username !== undefined && this.currentUser.username !== '');
  }

  constructor(public router: Router, private globals: Globals) {
    this.globals.currentUser.subscribe(user => this.currentUser = user);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // check we are logged in
    if (!this.isLoggedIn()) {
      // not logged in so redirect to login page
      console.log('AuthGuard: The user is not logged in and can\'t navigate...', state.url);
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // check we are logged in
    if (!this.isLoggedIn()) {
      // not logged in so redirect to login page
      console.log('AuthGuard: The user is not logged in and can\'t navigate to child...');
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
    return true;
  }
}
