import { Pipe, PipeTransform } from '@angular/core';
import { StampTemplate } from '../models/stamp-template';

@Pipe({ name: 'getExclusiveValuePipe' })
export class GetExclusiveValuePipe implements PipeTransform {
  transform(answer: any, groupName: string, template: StampTemplate) {
    let exclusiveValue = '';
    // is this a group of field type count
    const groupExclusive = template.groups.find(g => g.groupTitle === groupName).exclusive;

    if (groupExclusive) {
      if (typeof answer === 'string') {
        answer = answer.split(' ').filter(e => e);
      } else if (typeof answer === 'object') {
        answer = Object.keys(answer).filter(k => answer[k] === true);
      }
      if (answer.length === 0) {
        exclusiveValue = 'Not filled';
      } else if (answer.length > 1) {
        exclusiveValue = 'Unclear';
      } else if (answer.length === 1) {
        exclusiveValue = answer[0];
      }
    }

    return exclusiveValue;
  }
}
