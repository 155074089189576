<!-- Camera Component Navigation -->
<mat-toolbar class="blackBar">
  <div class="container">
    <div class="toolbar-row">
      <div class="left">
        <button mat-raised-button (click)="back()" *ngIf="!hideCameraBack" class="mat-button-small">
          <mat-icon>navigate_before</mat-icon>
        </button>
      </div>
      <div class="center">
        <span>{{template.name}} {{template.description}} {{template.version}}</span>
      </div>
      <div class="right">
        <button mat-raised-button (click)="cancel();" class="mat-button-small">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-toolbar>


<!-- Camera Display -->
<div #videoHolder style="overflow: hidden; position: relative">
  <video #video autoplay="true" style="position: absolute" playsinline></video>
  <div #guideBox style="position: absolute; outline: 300px solid rgba(0, 0, 0, .6)">&nbsp;</div>

  <div style="position: absolute; bottom: 50px" class="uk-container uk-text-center width100">
    <button mat-fab (click)="scan()" [disabled]="detectQRInProgress" class="uk-align-center" color="warn">
      <mat-icon>panorama_fish_eye</mat-icon>
    </button>
  </div>

  <button mat-mini-fab (click)="switchCamera()" color="contrast" style="position: absolute; bottom: 50px; right: 50px"
    *ngIf="mediaInputDevices.length">
    <mat-icon svgIcon="flip-camera-android"></mat-icon>
  </button>

</div>