import { Injectable } from '@angular/core';

import PouchDB from 'pouchdb';
import PouchDBAuthentication from 'pouchdb-authentication';
import PouchDBFind from 'pouchdb-find';
import * as pouchDBLoad from 'pouchdb-load';
import * as CryptoPouch from './crypto-pouch';

import { BehaviorSubject, Subject } from 'rxjs';

import { AnswerSheet } from '../models/answer-sheet';
import { Attachment } from '../models/attachment';
import { Events } from '../models/events';
import { Patient } from '../models/patient';
import { PouchArray } from '../models/pouch-array';
import { PouchObject } from '../models/pouch-object';
import { ReportSeries } from '../models/report-series';

import { AuthenticationService } from '../services/authentication.service';

import { environment } from '../../environments/environment';
import { RepositoryObserver } from './repository-observer';

import { formatDateWithFields, formatSystemGeneratedDate } from '../helpers/formatDate';

export enum SyncStatus {
  error = 0,
  paused = 1,
  active = 2,
  change = 3,
  push = 4,
  pull = 5,
  complete = 6
}

export enum Databases {
  mainDb,
  mainDBDumpPhase,
  mainDBReplicatePhase,
  imagesDb,
  usersDb,
  eventsDb,
  templatesDb,
  facilitiesDb
}

export enum AppTypes {
  userApp,
  adminApp
}

@Injectable()
export class RepositoryService {
  env = environment;
  private batchSize: number = 10;
  private localMainDb: any;
  private remoteMainDb: any;
  private remoteUsersDb: any;
  private localFacilitiesDb: any;
  private remoteFacilitiesDb: any;
  private localTemplatesDb: any;
  private remoteTemplatesDb: any;
  private localImagesDb: any;
  private remoteImagesDb: any;
  private localEventsDb: any;
  private remoteEventsDb: any;
  public pendingDown: Array<number> = [];
  public pendingDownSum = <number>0;
  public pendingDownSumChange = new Subject<number>();
  public syncStatus: SyncStatus;
  public unsyncedObjects: Array<PouchObject> = [];
  public unsyncedObjectsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private observer: Array<RepositoryObserver> = [];
  private app: AppTypes;
  private imagesDbSync: any;

  constructor(private authenticationService: AuthenticationService) {
    // load list of un-synced objects
    this.loadUnsynced();

    // Load pouch plugins
    PouchDB.plugin(PouchDBAuthentication);
    PouchDB.plugin(PouchDBFind);
    PouchDB.plugin(CryptoPouch);
    PouchDB.plugin({ loadIt: pouchDBLoad.load });

    // initialize remote pouchdb databases
    this.initRemoteDbs();
  }

  // initialize remote databases
  initRemoteDbs() {
    this.remoteMainDb = new PouchDB(environment.couchURL + environment.pouchDBName, { skip_setup: true });
    this.remoteUsersDb = new PouchDB(environment.couchURL + '_users', { skip_setup: true });
    this.remoteFacilitiesDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-facilities');
    this.remoteTemplatesDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-templates');
    this.remoteImagesDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-images');
    this.remoteEventsDb = new PouchDB(environment.couchURL + environment.pouchDBName + '-events', { skip_setup: true });
  }

  // initialize local databases
  initLocalDbs(facility: string) {
    this.localFacilitiesDb = new PouchDB(environment.pouchDBName + '-facilities-' + facility);
    this.localTemplatesDb = new PouchDB(environment.pouchDBName + '-templates-' + facility);
    this.localMainDb = new PouchDB(environment.pouchDBName + '-' + facility, { auto_compaction: true });
    this.localImagesDb = new PouchDB(environment.pouchDBName + '-images-' + facility);
    this.localEventsDb = new PouchDB(environment.pouchDBName + '-events');
  }

  // set the current app
  setApp(app: AppTypes) {
    this.app = app;
  }
  // get the current app
  getApp(): AppTypes {
    return this.app;
  }

  // Load and start sync of main db
  loadMainDb(facility: string, key: string) {
    switch (this.app) {
      case AppTypes.userApp: {
        console.log('Logged in as User, init local dbs');

        // initialize local databases
        this.initLocalDbs(facility);

        // sync facilities database
        if (environment.useEncryption) {
          this.localFacilitiesDb.crypto(key, { ignore: ['createFacility', 'type', 'id'] });
        }
        this.updateSync('start', null, Databases.facilitiesDb);
        this.localFacilitiesDb.sync(this.remoteFacilitiesDb, { retry: true })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.facilitiesDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.facilitiesDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.facilitiesDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.facilitiesDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.facilitiesDb); });

        // sync templates database
        this.updateSync('start', null, Databases.templatesDb);
        this.localTemplatesDb.sync(this.remoteTemplatesDb,
          { live: true, retry: true, continuous: true, filter: 'app/by_facility', query_params: { 'facility': facility } })
          .on('change', (event: any) => { this.updateSync('change', event, Databases.templatesDb); })
          .on('paused', (event: any) => { this.updateSync('paused', event, Databases.templatesDb); })
          .on('complete', (event: any) => { this.updateSync('complete', event, Databases.templatesDb); })
          .on('active', (event: any) => { this.updateSync('active', event, Databases.templatesDb); })
          .on('error', (event: any) => { this.updateSync('error', event, Databases.templatesDb); });

        // sync main database
        if (environment.useEncryption) {
          this.localMainDb.crypto(key, { ignore: ['createFacility', 'sharedFacilities', 'type', 'id', 'patientId', '_attachments', 'dateAdded'] });
        }
        if (this.authenticationService.firstTimeLogin) {
          // if first time login load couch dump from the API
          console.log('MainDB first time log in, use load');
          this.mainDBLoad(facility);
        } else {
          // if not first time login use normal sync
          console.log('MainDB normal flow, syncMainDB');
          this.mainDBSync(facility);
        }

        // sync images database
        if (environment.useEncryption) {
          this.localImagesDb.crypto(key, { ignore: ['createFacility', 'sharedFacilities', 'type', 'id', '_attachments', 'dateAdded'] });
        }
        // turn images sync depending on env file
        this.imagesDatabaseSync(environment.syncImages, facility);

        // sync events database
        if (environment.useEncryption) { this.localEventsDb.crypto(key, { ignore: ['createFacility', 'type', 'id'] }); }
        this.localEventsDb.replicate.to(this.remoteEventsDb, { live: true, retry: true });
        break;
      }
      case AppTypes.adminApp: {
        console.log("Logged in as Admin, don't sync to local DBs");
        this.localMainDb = this.remoteMainDb;
        this.localEventsDb = this.remoteEventsDb;
        this.localImagesDb = this.remoteImagesDb;
        this.localTemplatesDb = this.remoteTemplatesDb;
        this.localFacilitiesDb = this.remoteFacilitiesDb;
        break;
      }
      default: {
        console.log('You are NOT logged in to any application');
        break;
      }
    }

    // Set up indexes for queries using find api, only needs to run once for each db
    // TODO: Remove, we are adding the indices in the couchdb
    // this.setupIndexes(this.localMainDb);
    // this.setupIndexes(this.localEventsDb);
    // this.setupIndexes(this.localImagesDb);
    // this.setupIndexes(this.localTemplatesDb);
    // this.setupIndexes(this.localFacilitiesDb);
  }

  /**
   * Sync the main database with couchdb
   * @param facility current logged in facility
   */
  mainDBSync(facility: string, last_seq: any = null) {
    this.updateSync('start', null, Databases.mainDb);
    console.log('MainDB sync starting');

    const config = {
      live: true, retry: true, continuous: true,
      filter: 'app/by_facility', query_params: { 'facility': facility },
      include_docs: true,
      since: last_seq
    }

    // remove last seq if its on normal sync
    if (!last_seq) {
      delete config.since
    }

    this.localMainDb.sync(this.remoteMainDb, config)
      .on('change', (event: any) => { this.updateSync('change', event, Databases.mainDb); })
      .on('paused', (event: any) => { this.updateSync('paused', event, Databases.mainDb); })
      .on('complete', (event: any) => { this.updateSync('complete', event, Databases.mainDb); })
      .on('active', (event: any) => { this.updateSync('active', event, Databases.mainDb); })
      .on('error', (event: any) => { this.updateSync('error', event, Databases.mainDb); });
  }

  /**
   * Preload the main database from dump
   * @param facility current logged in facility
   */
  mainDBLoad(facility: string) {
    this.updateSync('start', null, Databases.mainDBDumpPhase);
    this.localMainDb.loadIt(`${this.env.paper_API}/couch-dump/${facility}`, {
      proxy: environment.couchURL + environment.pouchDBName,
      include_docs: true,
      filter: 'app/by_facility', query_params: { 'facility': facility }
    }).then((res: any) => {
      console.log('MainDB dump loaded successfully', res);
      this.updateSync('complete', null, Databases.mainDBDumpPhase);

      // then two-way, continuous, retry-able sync
      this.mainDBSync(facility);

      // do one way, one-off sync from the server until completion
      // this.updateSync('start', null, Databases.mainDBReplicatePhase);
      // this.localMainDb.replicate.from(environment.couchURL + environment.pouchDBName, {
      //   filter: 'app/by_facility', query_params: { 'facility': facility }, include_docs: true
      // }).on('complete', (event: any) => {
      //   console.log('MainDB one way replication done successfully', res);
      //   this.updateSync('complete', null, Databases.mainDBReplicatePhase);
      //   // then two-way, continuous, retry-able sync
      //  this.mainDBSync(facility, event.last_seq);
      // }).on('error', (event: any) => { this.updateSync('error', event, Databases.mainDBReplicatePhase); });

    }).catch(error => {
      console.log('Error loading dump data', error);
      if (error.statusCode === 404) {
        // missing dump file in the server proceed with normal sync
        console.log(`The dump file for ${facility} does not exit in the server, normal syncing will begin.`)
      }

      // go back to normal sync if we encounter an error with dump
      this.mainDBSync(facility);
    });
  }

  /**
   * function to turn on and off the images database
   * @param imageSync boolean indicating syncing on or off
   * @param facility string with current facility key
   */
  imagesDatabaseSync(imageSync: boolean, facility: string) {
    if (!imageSync) {
      console.log('Canceled Syncing image database...');
      if (this.imagesDbSync) { this.imagesDbSync.cancel(); }  // cancel the syncing of database
      this.localImagesDb.replicate.to(this.remoteImagesDb, { live: true, retry: true, batch_size: this.batchSize });
      return;
    }

    // start the syncing of images database'
    this.updateSync('start', null, Databases.imagesDb);
    this.imagesDbSync = this.localImagesDb.sync(this.remoteImagesDb,
      { live: true, retry: true, filter: 'app/by_facility', query_params: { 'facility': facility }, batch_size: this.batchSize })
      .on('change', (event: any) => { this.updateSync('change', event, Databases.imagesDb); })
      .on('paused', (event: any) => { this.updateSync('paused', event, Databases.imagesDb); })
      .on('complete', (event: any) => { this.updateSync('complete', event, Databases.imagesDb); })
      .on('active', (event: any) => { this.updateSync('active', event, Databases.imagesDb); })
      .on('error', (event: any) => { this.updateSync('error', event, Databases.imagesDb); });
  }

  /**
   * function delete pouch images database
   * @returns boolean
   */
  deleteImagesDatabase(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // delete imagesDB
      this.localImagesDb.destroy().then(res => {
        console.log('Deleted imagesDB:', res);
        resolve(res.ok);
      }).catch(error => {
        console.log('Error deleting ImagesDB database:', error);
        reject(false);
      });
    });
  }

  /**
   * Function delete local pouchdb databases
   * @returns boolean
   */
  async deleteLocalDatabases(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.localMainDb.destroy().then(res => { console.log('Deleted mainDB:', res); }).catch(err => {
        console.log('Error deleting mainDb:', err); reject('error deleting mainDb' + err);
      });
      await this.localEventsDb.destroy().then(res => { console.log('Deleted localEventsDB:', res); }).catch(err => {
        console.log('Error deleting localEventsDb:', err); reject('error deleting localEventsDb' + err);
      });
      await this.localImagesDb.destroy().then(res => { console.log('Deleted localImagesDB:', res); }).catch(err => {
        console.log('Error deleting localImagesDB:', err); reject('error deleting localImagesDB' + err);
      });
      await this.localTemplatesDb.destroy().then(res => { console.log('Deleted localTemplatesDB:', res); }).catch(err => {
        console.log('Error deleting localTemplatesDB:', err); reject('error deleting localTemplatesDB' + err);
      });
      await this.localFacilitiesDb.destroy().then(res => { console.log('Deleted localFacilitiesDB:', res); }).catch(err => {
        console.log('Error deleting localFacilitiesDB:', err); reject('error deleting localFacilitiesDB' + err);
      });
      console.log('Deleted all local databases');
      resolve(true);
    });
  }

  public syncStatusSub = new Subject<{ change: string, database: string }>();

  // update the list of changes to be synced
  updateSync(change: any, event: any, database: Databases) {
    this.syncStatusSub.next({ change: change, database: Databases[database] })
    // console.log(Databases[database] + ' status: ' + change + ', event: ' + JSON.stringify(event));
    switch (change) {
      case 'change': {
        if (event.direction === 'push') {
          this.syncStatus = SyncStatus['push'];
          // remove successfully changed objects from un-syncedObjects
          event.change.docs.forEach((pouchObject: PouchObject) => {
            this.removeUnsynced(pouchObject);
          });
        } else if (event.direction === 'pull') {
          // notify listeners that new objects available
          event.change.docs.forEach((object: any) => {
            const type: string = object.__zone_symbol__value ? object.__zone_symbol__value.type : object.type;
            if (type !== undefined) { this.notifyObserver(type); }
          });

          // get the sum of all the pending down documents from server
          this.pendingDown[database] = event.change.pending;
          this.pendingDownSum = this.pendingDown.reduce((acc, val) => acc + val);
          this.pendingDownSumChange.next(this.pendingDownSum);

          this.syncStatus = SyncStatus['pull'];
        } else {
          this.syncStatus = SyncStatus['change'];
        }
        break;
      }
      case 'paused': {
        this.syncStatus = SyncStatus['paused'];
        break;
      }
      case 'active': {
        if (event.direction === 'push') {
          this.syncStatus = SyncStatus['push'];
        } else if (event.direction === 'pull') {
          this.syncStatus = SyncStatus['pull'];
        } else {
          this.syncStatus = SyncStatus['active'];
        }
        break;
      }
      case 'error': {
        this.syncStatus = SyncStatus['error'];
        break;
      }
      case 'complete': {
        this.syncStatus = SyncStatus['complete'];
        console.log('Sync status complete for db', Databases[database]);
        break;
      }
    }
  }

  /**
   * Get enum value
   * @param definition
   */
  enumSelector(definition) {
    return Object.keys(definition)
      .map(key => ({ value: definition[key], title: key }));
  }

  registerObserver(observer: RepositoryObserver): void {
    if (!this.observer.includes(observer)) {
      this.observer.push(observer);
    }
  }

  unregisterObserver(observer: RepositoryObserver): void {
    const index: number = this.observer.indexOf(observer);
    if (index > -1) {
      this.observer.splice(index, 1);
    }
  }

  notifyObserver(objectType: string): void {
    // console.log(objectType);
    this.observer.forEach((observer: RepositoryObserver) => observer.notify(objectType));
  }

  // add object to list of un-synced objects
  addUnsynced(pouchObject: PouchObject) {
    return new Promise((resolve, reject) => {
      // disable for admin app, has direct access to db
      if (this.getApp() !== AppTypes.userApp) { resolve('Admin app'); return; }

      // check if its already in the list of un-synced objects, add it if not
      if ((this.unsyncedObjects.map((x) => x._id).indexOf(pouchObject._id)) >= 0) {
        this.removeUnsynced(pouchObject);  // remove existing to update the rev
      }

      // add shallow copy of un-synced object to list
      this.unsyncedObjects.push({
        _id: pouchObject._id,
        _rev: pouchObject._rev,
        _deleted: pouchObject._deleted,
        type: pouchObject.type,
        dateAdded: pouchObject.dateAdded,
        dateUpdated: pouchObject.dateUpdated,
        updatedBy: pouchObject.updatedBy,
        createdBy: pouchObject.createdBy,
        createFacility: pouchObject.createFacility,
        updateFacility: pouchObject.updateFacility
      });

      try {
        // save to local storage
        localStorage.setItem('unsyncedObjects', JSON.stringify(this.unsyncedObjects));
      } catch (e) {
        // Storage full, maybe notify user or do some clean-up
        console.log('Error adding object to unsynced objects list', e);
        reject('Error adding object to unsynced objects list');
      }

      // update count
      this.unsyncedObjectsCount.next(this.unsyncedObjects.length);
      resolve('Successfully added object to unsynced objects list');

    });
  }

  // remove object from list of unsynced objects
  removeUnsynced(pouchObject: PouchObject) {
    const elementPos = this.unsyncedObjects.map(function (x) { return x._id; }).indexOf(pouchObject._id);
    console.log('index of changed obj to be removed from unsynced list: ' + elementPos);
    this.unsyncedObjects.splice(elementPos, 1);

    // update count
    this.unsyncedObjectsCount.next(this.unsyncedObjects.length);

    // save to local storage
    localStorage.setItem('unsyncedObjects', JSON.stringify(this.unsyncedObjects));
  }

  // load list of unsynced objects from offline storage
  loadUnsynced() {
    const unsyncedObjects = JSON.parse(localStorage.getItem('unsyncedObjects'));

    if (!Array.isArray(unsyncedObjects)) {
      return;
    }

    unsyncedObjects.forEach((pouchObject: PouchObject) => {
      this.unsyncedObjects.push(pouchObject);
    });

    // update count
    this.unsyncedObjectsCount.next(this.unsyncedObjects.length);
  }

  /**
   * check if unsynced object exist in couch and delete them from local storage
   * Objects: patients, images
   */
  checkUnSyncedInCouch(): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log('checking unsynced objects...');
      if (this.unsyncedObjects.length < 1) { resolve('There are no unsynced Objects'); }

      let remoteDB;
      let count = 0;
      this.unsyncedObjects.forEach(o => {
        const fields = ['_id', '_rev', 'type'];
        if (o.type === AnswerSheet.type || o.type === Patient.type || o.type === ReportSeries.type) {
          remoteDB = this.remoteMainDb;
        } else if (o.type === Attachment.type) {
          remoteDB = this.remoteImagesDb;
        }

        this.fetchObject(o.type, o._id, fields, remoteDB, true).then(result => {
          const doc = result.docs[0];
          const fromCouch_rev = doc._rev.split('-')[0];
          const fromLocal_rev = o._rev.split('-')[0];

          if (doc._id === o._id && fromCouch_rev >= fromLocal_rev) {
            count++;
            this.removeUnsynced(doc);
          } else {
            // not synced
            console.log('Object not synced');
          }
        }).catch(error => {
          reject(error);
        });
      });

      // resolve with the count of synced objects
      resolve(`${count} Objects synced to server`);
    });
  }

  // Pouch Object Update/Query/Delete

  // save or update object to pouch db, optionally specify database
  updateObject(pouchObject: PouchObject, type: string, database = Databases.mainDb, deleteObject: boolean = false, createFacility: string = null): Promise<PouchObject> {
    return new Promise((resolve, reject) => {

      // select database
      const db = this.switchDatabase(database);

      // update type
      pouchObject.type = type;

      // metadata
      const user = this.authenticationService.getUser();
      let eventType = 'updated';

      // check for valid user
      if (user === undefined || user.username === '') { reject('Valid user required to update objects'); }

      // update timestamps
      const timestamp = formatSystemGeneratedDate(new Date());
      if (!pouchObject._rev) {
        pouchObject.dateAdded = timestamp;
        pouchObject.createdBy = user.username;
        pouchObject.createFacility = createFacility ? createFacility : user.facility;

        // set the type of event to added for a new document
        eventType = 'added';
      }
      pouchObject.dateUpdated = timestamp;
      pouchObject.updatedBy = user.username;
      pouchObject.updateFacility = createFacility ? createFacility : user.facility;

      // process deletions
      if (deleteObject) {
        eventType = 'deleted';
        pouchObject._deleted = true;
      }

      // Call the formatDateWithFields function with the pouchObject and AnswerSheet.dateFields
      // This will format the date fields specified in AnswerSheet.dateFields
      pouchObject = formatDateWithFields(pouchObject, AnswerSheet.dateFields);

      if (pouchObject['customBeforeFieldsData']) {
        // If it does, call formatDateWithFields on the 'customBeforeFieldsData' object
        // This will format the date fields specified in AnswerSheet.dateFields
        pouchObject['customBeforeFieldsData'] = formatDateWithFields(pouchObject['customBeforeFieldsData'], AnswerSheet.dateFields);
      }

      if (pouchObject['customAfterFieldsData']) {
        // If it does, call formatDateWithFields on the 'customAfterFieldsData' object
        // This will format the date fields specified in AnswerSheet.dateFields
        pouchObject['customAfterFieldsData'] = formatDateWithFields(pouchObject['customAfterFieldsData'], AnswerSheet.dateFields);
      }

      // save object
      db.put(pouchObject)
        .then((response: any) => {
          pouchObject._rev = response.rev;
          // add to unsynced objects list
          this.addUnsynced(pouchObject).then(msg => console.log(msg)).catch(err => console.log(err));
          console.log('updateObject updated: ' + JSON.stringify(pouchObject));

          // log an event when an update to a document has been added to the db
          this.addEvents(type, pouchObject._id, eventType, user.username, user.ipAddress);

          resolve(pouchObject);
        })
        .catch(reject);
    });
  }

  /** function to save events into the localEvents db
    * add argument below inside brackets below e.g comment = 'status change'
    * include in the model of events plus inside this.localEventsDb
    */
  addEvents(objectType: string, objId: string, eventType: string, username, ipAddress, templateId: string = null,
    routineIndex: number = null) {
    const event: Events = new Events();

    event._id = String((new Date().getTime()));
    event.type = 'events';
    event.objectType = objectType;
    event.objectId = objId;
    event.event = eventType;
    event.userName = username;
    event.ipAddress = ipAddress;
    event.appVersion = environment.appVersion;
    event.templateId = templateId;
    event.routineIndex = routineIndex;
    event.dateAdded = new Date();

    // else save the event without a notification field
    this.localEventsDb.put(event)
      .then((response: any) => {
        // print out the response to the console if success
        console.log('Successfully added event - ' + JSON.stringify(response));
      }).catch((err) => {
        // print out error in the console if any
        console.log('Error adding event ' + JSON.stringify(err));
      });
  }

  /**
   * get pouchdb.attachment for any object
   * @param AttachmentName
   * @param file
   */
  getAttachment(AttachmentName: string, database = Databases.mainDb, file: string = 'file') {
    console.log('Attempting to load attachment: ' + AttachmentName);
    const db = this.switchDatabase(database);
    return db.getAttachment(AttachmentName, file);
  }

  // delete object in pouch, optionally specify database
  deleteObject(pouchObject: PouchObject, database = Databases.mainDb): Promise<PouchObject> {
    return this.updateObject(pouchObject, pouchObject.type, database, true);
  }

  // TODO: Remove un-used function
  // fetches objects of a given type filtered by a given patient
  // fetchObjectsByUser(
  //   type: String,
  //   userName: string,
  //   database = Databases.mainDb): Promise<PouchArray<Object>> {

  //   // select database
  //   const db = this.switchDatabase(database);

  //   return new Promise((resolve, reject) => {
  //     db.query('index_' + type, {
  //       key: +userName
  //     }).then((result: any) => {
  //       console.log('fetchObjectsByUser ' + userName + ' returned ' + result.rows.length + ' ' + type);
  //       resolve(result);
  //     }).catch((error: any) => {
  //       if (error.docId === '_design/index_' + type && error.status === 404) {
  //         console.log('Error loading ' + type + '(s) Wait for design doc: _design/index_' + type + ' to sync');
  //       } else { reject(error); }
  //     });
  //   });
  // }

  // fetches objects of a given type, optionally specify database
  fetchObjects(type: String, database = Databases.mainDb): Promise<PouchArray<Object>> {
    // select database
    const db = this.switchDatabase(database);

    return new Promise((resolve, reject) => {
      db.query('index_' + type).then((results: any) => {
        console.log('fetchObjects returned ' + results.rows.length + ' ' + type);
        const resolveObjects: any = [];
        resolveObjects.docs = results.rows.map(r => r.key);
        resolve(resolveObjects);
      }).catch((error: any) => {
        if (error.docId === '_design/index_' + type && error.status === 404) {
          console.log('Error loading ' + type + '(s) Wait for design doc: _design/index_' + type + ' to sync');
        } else { reject(error); }
      });
    });
  }

  // fetches a single object of a given type with given id, optionally specify database
  fetchObject(
    type: String, _id: String, fields: string[] = null, database = Databases.mainDb, queryCouch: boolean = false
  ): Promise<PouchArray<Object>> {
    // select database
    const db = queryCouch ? database : this.switchDatabase(database);

    return new Promise((resolve, reject) => {
      db.find({ selector: { type: type, _id: _id }, fields: fields, limit: 1 }).then((result: any) => {
        if (JSON.parse(JSON.stringify(result.docs)).length === 0) {
          reject('fetchObject unable to load ' + type + ' object id ' + _id);
        } else {
          console.log('fetchObject returned 1', type);
          resolve(result);
        }
      }).catch((error: any) => reject(error));
    });
  }

  // fetches objects of a given type, option to search for, optionally specify database
  fetchObjectsBy(type: String, ByFieldName: string, ByFieldValue: string | number | boolean, database = Databases.mainDb): Promise<PouchArray<Object>> {
    // select database
    const db = this.switchDatabase(database);
    console.log('Fetching all ' + type + ' where ' + ByFieldName + ' = ' + ByFieldValue + ' from ' + database);

    return new Promise((resolve, reject) => {
      if (ByFieldValue === undefined) { reject('Please specify field value'); }
      let index_;
      switch (ByFieldName) {
        case 'patientId':
          index_ = 'index_patientId_';
          break;
        case 'penNumber':
          index_ = 'index_penNumber_';
          break;
        case 'stampTemplateId':
          index_ = 'index_templateId_';
          break;
        case 'failed':
          index_ = 'index_failed_';
          break;
        case 'answerSheetId':
          index_ = 'index_answersheetId_';
          break;
        case 'reportSeriesId':
          index_ = 'index_reportSeriesId_';
          break;
        case 'serialNumber':
          index_ = 'index_serialNumber_';
          break;
        case 'teamNumber':
          index_ = 'index_teamNumber_';
          break;
        case 'attachments_list':
          index_ = 'index_list_all_';
          break;
        default:
          index_ = '_';
          break;
      }

      db.query(index_ + type, { key: ByFieldValue }).then((results: any) => {
        console.log('fetchObjectsBy returned ' + results.rows.length + ' ' + type);
        const resolveObjects: any = []
        resolveObjects.docs = results.rows.map(r => r.value);
        resolve(resolveObjects);
      }).catch((error: any) => {
        if (error.docId === '_design/' + index_ + type && error.status === 404) {
          console.log('Error loading ' + type + '(s) Wait for design doc: _design/' + index_ + type + ' to sync');
        } else { reject(error); }
      });
    });
  }

  // fetches all objects of a given type, optionally specify database
  fetchAllObjectsBy(type: String, ByFieldName: string, database = Databases.mainDb): Promise<PouchArray<Object>> {
    // select database
    const db = this.switchDatabase(database);
    console.log('Fetching all ' + type + ' where ' + ByFieldName + ' = ' + ' from ' + database);

    return new Promise((resolve, reject) => {
      let index_;
      switch (ByFieldName) {
        case 'patientId':
          index_ = 'index_patientId_';
          break;
        case 'penNumber':
          index_ = 'index_penNumber_';
          break;
        case 'stampTemplateId':
          index_ = 'index_templateId_';
          break;
        case 'failed':
          index_ = 'index_failed_';
          break;
        case 'answerSheetId':
          index_ = 'index_answersheetId_';
          break;
        case 'serialNumber':
          index_ = 'index_serialNumber_';
          break;
        case 'teamNumber':
          index_ = 'index_teamNumber_';
          break;
        case 'attachments_list':
          index_ = 'index_list_all_';
          break;
        default:
          index_ = '_';
          break;
      }

      db.query(index_ + type).then((results: any) => {
        console.log('fetchAllObjectsBy returned ' + results.rows.length + ' ' + type);
        const resolveObjects: any = []
        resolveObjects.docs = results.rows.map(r => r.value);
        resolve(resolveObjects);
      }).catch((error: any) => {
        if (error.docId === '_design/' + index_ + type && error.status === 404) {
          console.log('Error loading ' + type + '(s) Wait for design doc: _design/' + index_ + type + ' to sync');
        } else { reject(error); }
      });
    });
  }

  // helper to choose which database to use
  switchDatabase(database: Databases): any {
    switch (database) {
      case Databases.facilitiesDb: {
        return this.localFacilitiesDb;
      }
      case Databases.templatesDb: {
        return this.localTemplatesDb;
      }
      case Databases.eventsDb: {
        return this.localEventsDb;
      }
      case Databases.imagesDb: {
        return this.localImagesDb;
      }
      case Databases.usersDb: {
        return this.remoteUsersDb;
      }
      default: {
        return this.localMainDb;
      }
    }
  }

  /** set up Persistent queries or design documents
    * TODO: consider removing this function and manually adding indices to db
    */
  public setupIndexes(db: PouchDB.Database) {
    // index by type
    db.createIndex({ index: { fields: ['_id', 'type'], name: 'idxIdType', ddoc: 'idx' } })
      .catch((err) => { console.log('Error creating index for db: ', db.name, err, err.message); });
  }
}


