import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'removeUserPrefix', 
   pure: false 
})
export class RemoveUserPrefix implements PipeTransform {
  transform(value: string): string {
    const newValue = value.replace('org.couchdb.user:', '');
    return `${newValue}`;
  }
}
