import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AppSettings } from '../../../models/settings';
import { AnswerSheet } from '../../../models/answer-sheet';
import { StampTemplate } from '../../../models/stamp-template';

@Component({
  selector: 'paper-transfusion-end-date',
  templateUrl: './transfusion-end-date-time.component.html'
})
export class TransfusionEndDateTimeComponent {
  _form: FormGroup;
  @Input() set form(form: FormGroup) {
    this._form = form;
    this._form.addControl('transfusionEndDate', new FormControl('', []));
    this._form.addControl('transfusionEndTime', new FormControl('', []));
  } get form(): FormGroup { return this._form; }
  @Input() appSettings: AppSettings;
  @Input() answerSheet: AnswerSheet;
  @Input() template: StampTemplate;
}