<!-- Top Nav -->
<paper-top-nav
  [fxHide]="viewMode === ViewModes.camera || viewMode === ViewModes.results || viewMode === ViewModes.manual"
  [appSettings]="appSettings"></paper-top-nav>

<!-- Loading Spinners -->
<div class="width100"
  *ngIf="loadingTemplates || loadingSeries || loadingFacility || loadingAppSettings || loadingPatients">
  <div style="width:200px" class="uk-container-center uk-margin-large-top uk-text-center">
    <mat-progress-spinner color="primary" mode="indeterminate" class="uk-margin uk-align-center"></mat-progress-spinner>
    <span *ngIf="loadingFacility">Loading {{appSettings.uiViewSettings.facilityLabel}}. <br /></span>
    <span *ngIf="loadingTemplates">Loading templates. <br /></span>
    <span *ngIf="loadingPatients">Loading Patients. <br /></span>
    <span *ngIf="loadingAppSettings">Loading app settings. <br /></span>
    <span *ngIf="loadingSeries">Loading template series. <br /></span>
    Please wait.
  </div>
</div>

<!-- Loading scanning in progress Spinner -->
<div class="width100" *ngIf="detectQRInProgress" style="position: absolute; z-index: 1;">
  <div style="width:200px" class="uk-container-center uk-margin-large-top uk-text-center">
    <mat-progress-spinner color="primary" mode="indeterminate" class="uk-margin uk-align-center"></mat-progress-spinner>
    <span>Detecting scanned image area (QR)
      <br />
    </span>
    <span style="color: #fff;">please wait...</span>
  </div>
</div>

<div fxLayout="row" fxFill fxLayoutGap="20px" fxLayoutAlign="space-between start" fxLayoutAlign.xs="start stretch"
  *ngIf="!loadingTemplates && !loadingFacility && !loadingAppSettings && !loadingPatients">

  <div fxFlex="1 1 auto">

    <!-- No Templates Loaded -->
    <div *ngIf="!(templates.length >= 1)">
      <div class="uk-text-center">
        No templates found. Please wait for templates to load.
        <hr class="uk-article-divider" />
        <mat-progress-spinner color="primary" mode="indeterminate" class="uk-margin uk-align-center">
        </mat-progress-spinner>
        Loading templates.
      </div>
    </div>

    <!-- Sheet Add/Edit Component -->
    <div *ngIf="(templates.length >= 1) && viewMode === ViewModes.edit">

      <mat-toolbar>
        <span *ngIf="newRecord; else addTitle">Add</span>
        <ng-template #addTitle>Edit</ng-template> &nbsp;{{appSettings.uiViewSettings.recordLabel}}
        <span class="fill-remaining-space"></span>
        <span>
          <mat-icon (click)="showList()" fxHide fxShow.xs>view_list</mat-icon>
        </span>
      </mat-toolbar>

      <div class="uk-margin-left uk-margin-right">
        <!-- <paper-sheet-edit   -->
        <paper-sheet-edit [loggedInUser]="loggedInUser" [loadingPatientRecords]="loadingPatientRecords"
          [(patients)]="patients" [facilities]="facilities" [templates]="templatesEditForm"
          [(template)]="currentSheetTemplate" [appSettings]="appSettings" [facility]="facility"
          [(currentPatient)]="currentPatient" [series]="templateSeries" [sheet]="currentSheet"
          [(selectedFacility)]="selectedFacility" (sheetEditUpdate)="sheetEditUpdate()"
          (sheetEditCancel)="showAddNewPatient()" (sheetEditNavigateNext)="sheetEditNavigateNext()"
          [(currentTemplateSeries)]="currentTemplateSeries" (filterPatientRecords)="filterPatientRecords($event)"
          (templateChange)="templateChanged()" (dateTimeUpdated)="dateTimeChanged()"
          (serialNumberChanged)="serialNumberChange($event)" (penNumberUpdated)="getFarmRecords()"
          (teamNumberChanged)="teamNumberChanged($event)" [disablePatientField]="disablePatientField">
        </paper-sheet-edit>
      </div>
    </div>

    <!-- Camera Component -->
    <div *ngIf="viewMode === ViewModes.camera">
      <paper-camera [(detectQRInProgress)]="detectQRInProgress" [hideCameraBack]="hideCameraBack"
        [template]="currentSheetTemplate" (cameraCropCaptured)="processScan($event)"
        (cameraNavigateBack)="showEdit(currentSheet, newRecord, currentSheetTemplate)"
        (cameraCancel)="showAddNewPatient()"></paper-camera>
    </div>

    <!-- Manual Edit Component -->
    <div *ngIf="viewMode === ViewModes.manual">
      <paper-manual-edit [sheet]="currentSheet" [template]="currentSheetTemplate"
        [currentAttachment]="currentAttachment" [(qrCode)]="qrCode" (manualEditCancel)="showAddNewPatient()"
        (manualEditNavigateBack)="showCamera()" (manualEditUpdate)="manualEditUpdate($event)"></paper-manual-edit>
    </div>

    <!-- Results Component -->
    <div *ngIf="viewMode === ViewModes.results">
      <paper-sheet-results-edit [loggedInUser]="loggedInUser" [facility]="facility" [newRecord]="newRecord"
        [template]="currentSheetTemplate" [sheet]="currentSheet" [appSettings]="appSettings"
        (manualEditNavigate)="showManualEdit()" [(processingSheetUpdate)]="processingSheetUpdate"
        [currentAttachment]="currentAttachment" (resultsEditCancel)="showAddNewPatient()"
        (resultsEditNavigateBack)="showCamera()" (resultsEditUpdate)="resultsEditUpdate()" [series]="templateSeries"
        [currentPatient]="currentPatient" [currentReportSeries]="currentReportSeries"></paper-sheet-results-edit>
    </div>

    <!-- Sheet View Component -->
    <div *ngIf="viewMode === ViewModes.view">
      <mat-toolbar>
        <span *ngIf="newRecord; else addTitle">{{appSettings.uiViewSettings.recordLabel}} Created</span>
        <ng-template #addTitle>View {{appSettings.uiViewSettings.recordLabel}}</ng-template>
        <span class="fill-remaining-space"></span>
        <span>
          <mat-icon [routerLink]="['/new_record']">note_add</mat-icon>
        </span>
      </mat-toolbar>

      <div class="uk-margin-left uk-margin-right">
        <paper-sheet-view [newRecord]="newRecord" [loggedInUser]="loggedInUser" [appSettings]="appSettings"
          [loadingPatientRecords]="loadingPatientRecords" [(template)]="currentSheetTemplate" [templates]="templates"
          [facilityTemplates]="templatesEditForm" [sheet]="currentSheet" [facilities]="facilities" [facility]="facility"
          [(penNumber)]="currentSheetPenNumber" (sheetViewEditSheet)="showResults($event, false)"
          (newPatientEdit)="showAddNewPatient()" [(currentTemplateSeries)]="currentTemplateSeries"
          [reportSeriesRecords] = "reportSeriesRecords"
          [currentAttachment]="currentAttachment" (nextTemplateScan)="scanNextTemplateInSeries()"
          (nextRecordScan)="scanNextRecord()" (nextFarmRecordScan)="scanNextFarmRecord()"
          [(currentPatient)]="currentPatient">
        </paper-sheet-view>
      </div>
    </div>

  </div>
</div>

<!-- first time notify to user -->
<div *ngIf="firstTimeLogin && repository.pendingDownSum === 0 && appTypeEnum[repository.getApp()] === 'userApp'"
  style="padding: 15px !important;">
  <mat-card>
    <mat-card-content><strong>First time login: </strong>All databases loaded and data synced in
      {{firstTimeLoginEndTime}}.</mat-card-content>
  </mat-card>
</div>

<mat-expansion-panel class="uk-margin-top" [style]="env.production ? {visibility: 'hidden'} : {visibility: 'visible'}">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Settings and Debug Information
    </mat-panel-title>
    <mat-panel-description>

    </mat-panel-description>
  </mat-expansion-panel-header>
  <div style="width: 100%; overflow: scroll">

    <h5>Sample input image(s)</h5>
    <input type="file" multiple (change)="fileSelected($event)" [disabled]="!currentSheetTemplate" />
    <img #fileImage *ngIf="imageSrc" [src]="imageSrc">

    <h5>Debug</h5>
    <div class="form-group">
      <mat-slide-toggle [(ngModel)]="debugMode">
        Enable Debug Mode
      </mat-slide-toggle>
    </div>

    <H5>Handwriting Recognition</H5>
    <div id="handwritingDiv"></div>

    <h5>Extraction</h5>
    <div>
      <P>canvas after greyscale</P>
      <canvas id="canvasGrey"></canvas>
      <hr />
      <p>canvas showing center surround</p>
      <canvas id="canvasCS"></canvas>
      <p>Extraction values</p>
      <p *ngIf="currentSheet">
        {{currentSheet.deviceInfo?.omrExtractionInfo | json}}
      </p>
    </div>

    <h5>QR Code</h5>
    <div>
      canvasQr
      <br />
      <canvas id="canvasQr"></canvas>
      <hr /> canvasQrOutput
      <br />
      <canvas id="canvasQrOutput"></canvas>
      <hr /> canvasQrOutput2
      <br />
      <canvas id="canvasQrOutput2"></canvas>
      <hr /> canvasGl
      <br />
      <div id="glDiv"></div>
      <hr />
    </div>

    <H5>QR Outputs</H5>
    <div id="qrDivs"></div>
  </div>
</mat-expansion-panel>
<!--
    <h5>Dimensions</h5>
    maxWidth: {{dimensions.maxWidth}},<br />
    maxHeight: {{dimensions.maxHeight}},<br />
    templateScale: {{dimensions.templateScale}},<br />
    minimumArea: currently fixed at 500<br />
    detectedRectArea: {{detectedRectArea}}<br />
    totalContoursDetected: {{totalContoursDetected}}<br />
    totalValidContoursDetected:<br />
    canvas input width: {{inputWidth}}<br />
    canvas input height: {{inputHeight}}<br />

    <h5>Parameters</h5>

    <div [hidden]="!extractionReady">
      <canvas #canvasExtraction id="canvasExtraction" style='border: solid 2px pink'></canvas>
    </div>
    <div [hidden]="!scanComplete || extractionReady">
      <canvas id="canvasCircles" style='border: solid 2px indigo'></canvas>
    </div>

    <h5>Debug</h5>
    <figure>
      <canvas #canvasExtractionSource id="canvasExtractionSource" style="border: solid peachpuff 2px"></canvas>
      <figcaption>canvasExtractionSource</figcaption>
    </figure>
    <figure>
      <canvas #canvasDebugCircles id="canvasDebugCircles" style="border: solid red 2px"></canvas>
      <figcaption>canvasDebugCircles</figcaption>
    </figure>

    <h5>OpenCV</h5>
    <figure>
      <canvas #canvasInput id='canvasInput' style="border: solid green 2px"></canvas>
      <figcaption>canvasInput</figcaption>
    </figure>
    <figure>
      <canvas #canvasCannyOutput id='canvasCannyOutput' style="border: solid blue 2px"></canvas>
      <figcaption>canvasCannyOutput</figcaption>
    </figure>
    <figure>
      <canvas #canvasContourOutput id='canvasContourOutput' style="border: solid indigo 2px"></canvas>
      <figcaption>canvasContourOutput</figcaption>
    </figure>
    <figure>
      <canvas #canvasThresholdOutput id='canvasThresholdOutput' style="border: solid indigo 2px"></canvas>
      <figcaption>canvasThresholdOutput</figcaption>
    </figure>
    <figure>
      <canvas #canvasTransformedOutput id='canvasTransformedOutput' style="border: solid violet 2px"></canvas>
      <figcaption>canvasTransformedOutput</figcaption>
    </figure>

 -->