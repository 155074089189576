import moment from "moment";
import { PouchObject } from "../models/pouch-object";

/** Function to format user input dates */
export function formatUserInputDate(dateInput: Date | string): string {
  // Create a new Date object from the input
  const date = new Date(dateInput);
  // format with moment
  return moment(date).format("YYYY-MM-DD")
}

/** Function to format system-generated dates */
export function formatSystemGeneratedDate(dateInput: Date | string): string {
  // Create a new Date object from the input
  const date = new Date(dateInput);
  // Calculate the timezone offset in minutes
  const timeZoneOffset = date.getTimezoneOffset() * -1;
  // Determine the sign of the offset (+ or -)
  const sign = timeZoneOffset >= 0 ? "+" : "-";
  // Calculate the offset hours and pad with a leading zero if needed
  const offsetHours = String(
    Math.floor(Math.abs(timeZoneOffset) / 60)
  ).padStart(2, "0");
  // Calculate the offset minutes and pad with a leading zero if needed
  const offsetMinutes = String(Math.abs(timeZoneOffset) % 60).padStart(2, "0");
  // Construct the formatted date string
  const formattedDate = `${date.toISOString().split(".")[0]}${sign}${offsetHours}:${offsetMinutes}`;
  return formattedDate;
}

/**
 * This function formats the date fields of a PouchObject.
 * It takes an object of type T (which extends PouchObject) and an optional array of field names to update.
 * If the fieldsToUpdate array is not provided or is empty, no fields are updated.
 * If the fieldsToUpdate array is provided, the function logs a message to the console and updates the specified fields by calling the formatUserInputDate function on each field value.
 * The function returns the updated object.
 */
export function formatDateWithFields<T extends PouchObject>(object: T, fieldsToUpdate: string[] = []): T {
  if (fieldsToUpdate.length !== 0) {
    fieldsToUpdate.forEach((field) => {
      if (object[field]) {
        object[field] = formatUserInputDate(object[field]);
      }
    });
  }
  return object;
}
