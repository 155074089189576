export const environment = {
  production: true,
  syncImages: true,
  debugMode: true,
  userManageOwnAccount: true,
  partnerLogo: '',
  settingsId: 'dev-config',
  appVersion: require('../../package.json').version,
  pouchDBName: 'devpaperemr',
  couchURL: 'https://devdb.paperemr.org/',
  appURL: 'dev.paperemr.org',
  webAnalyticURL: 'https://plausible-test.paperemr.org/js/script.js',
  useEncryption: true,
  paper_API: 'http://localhost:3000/api',
  paper_API_SECRET_KEY: 'hbMN9ZEcZru2ph3X6nxMH8WJ',
  helpDeskContact: '',
};
