import { Routes } from '@angular/router';
import { LoginComponent } from '../app/auth/login.component.web';
import { AuthGuard } from '../app/services/auth.guard';
import { SheetsComponent } from '../app/answers/sheets.component';
import { UnsavedChangesGuard } from '../app/services/unsaved-changes.guard';
import { PatientsComponent } from '../app/patients/patients.component';
import { PasswordResetResolver } from '../app/resolvers/password-reset.resolver';
import { ScannedRecordsListResolver } from '../app/resolvers/navigation-auth.resolver';

export const UserRoutingModule: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'signup',
    loadComponent: () => import('../app/auth/signup/components/signup/signup.component').then(m => m.SignupComponent),
  },
  {
    path: 'recover_account',
    loadComponent: () => import('../app/auth/signup/components/recover-account/recover-account.component').then(m => m.RecoverAccountComponent)
  },
  {
    path: 'reset-password/:user/:resetToken',
    resolve: {
      validToken: PasswordResetResolver
    },
    loadComponent: () => import('../app/auth/signup/components/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { preload: true }
  },
  {
    path: 'new_record',
    component: SheetsComponent,
    canDeactivate: [UnsavedChangesGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'patients',
    component: PatientsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadChildren: () => import('../app/patients/patients.module').then(m => m.PatientsModule),
    data: { preload: true }
  },
  {
    path: 'scanned_records',
    resolve: {
      allowedAccess: ScannedRecordsListResolver
    },
    loadChildren: () => import('../app/scanned-records/scanned-records.module').then(m => m.ScannedRecordsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'scanned_lab_records',
    loadChildren: () => import('../app/scanned-lab-records/scanned-lab-records.module').then(m => m.ScannedLabRecordsModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'profile',
    loadChildren: () => import('../app/auth/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { preload: true }
  },
  {
    path: 'new-users',
    loadComponent: () => import('../app/auth/signup/components/new-users/new-users.component').then(m => m.NewUsersListComponent),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: { preload: true }
  },
  {
    path: '**',
    component: LoginComponent,
  }
];
