// import '../app/rxjs-extensions';
import { CdkTableModule } from '@angular/cdk/table';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { AppMaterialModule } from '../app/material/app-material.module';

import { SignupService } from '../app/auth/signup/service/signup.service';
import { AttachmentService } from '../app/services/attachment.service';
import { FacilityService } from '../app/services/facility.service';
import { Globals } from '../app/services/globals';
import { PatientService } from '../app/services/patient.service';
import { RepositoryService } from '../app/services/repository.service';
import { AppSettingsService } from '../app/services/settings.service';
import { SheetService } from '../app/services/sheet.service';
import { TemplateSeriesService } from '../app/services/template-series.service';
import { TemplateService } from '../app/services/template.service';
import { UserService } from '../app/services/user.service';
// import { DetectBorderService } from '../app/services/detect-border.service';
import { AuthenticationService } from '../app/services/authentication.service';
import { ExtractResultsService } from '../app/services/extract-results.service';
import { ReportSeriesService } from '../app/services/report-series.service';
import { UnsavedChangesGuard } from '../app/services/unsaved-changes.guard';

import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SheetsModule } from '../app/answers/sheets.module';
import { AuthenticationModule } from '../app/auth/authentication.module';
import { NavigationModule } from '../app/navigation/navigation.module';
import { PipesModule } from '../app/pipes/pipes.module';
import { AuthGuard } from '../app/services/auth.guard';
import { DetectBorderServiceQR } from '../app/services/detect-border-qr.service';
import { UsersModule } from '../app/users/users.module';
import { AppComponent } from './app-user.component';
import { UserRoutingModule } from './routing-user.module';

import { ServiceWorkerModule } from '@angular/service-worker';
import { ConflictDialogComponent } from '../app/conflict-modal/conflict-modal.component';
import { UpdateService } from '../app/services/update.service';
import { environment } from '../environments/environment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const CUSTOM_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ConflictDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    SheetsModule,
    NavigationModule,
    RouterModule.forRoot(UserRoutingModule, { useHash: false, preloadingStrategy: PreloadAllModules }),
    HttpClientModule,
    FlexLayoutModule,
    MomentModule,
    MatDatepickerModule,
    MatNativeDateModule,
    CdkTableModule,
    UsersModule,
    AuthenticationModule,
    PipesModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/user' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    Globals,
    AuthGuard,
    UserService,
    SheetService,
    SignupService,
    PatientService,
    TemplateService,
    FacilityService,
    RepositoryService,
    AttachmentService,
    AppSettingsService,
    UnsavedChangesGuard,
    ReportSeriesService,
    TemplateSeriesService,
    DetectBorderServiceQR,
    ExtractResultsService,
    AuthenticationService,
    DeviceDetectorService,
    UpdateService
  ],
  bootstrap: [AppComponent]
})
export class UserAppModule { }
