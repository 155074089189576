import { Pipe, PipeTransform } from '@angular/core';
import { StampTemplate } from '../models/stamp-template';

@Pipe({ name: 'getTemplateName' })
export class GetTemplateName implements PipeTransform {
  /** Helper function to get the name of the related template for display */
  transform(templateId: string, templates: StampTemplate[]) {
    const templ = templates.find(template => template._id === templateId);
    if (templ) { return templ.name + ' ' + templ.version; } else { return '[Missing]'; }
  }
}

@Pipe({ name: 'isTemplateOfType' })
export class IsTemplateOfType implements PipeTransform {
  /** Helper function to return tru or false if a template is of supplied type */
  transform(templateId: string, templates: StampTemplate[], type: string) {
    return templates.find(t => t._id === templateId && t.templateType === type) ? true : false
  }
}