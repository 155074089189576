<form [formGroup]="form">
  <!-- transfusion date -->
  <div class="form-group" *ngIf="template.showPlannedTransfusionDate">
    <mat-form-field class="width100">
      <input matInput [matDatepicker]="pickerPlannedDate" formControlName="transfusionDate"
        placeholder="Planned Transfusion Date" [(ngModel)]="answerSheet.plannedTransfusionDate"
        [required]="template.requirePlannedTransfusionDate" (ngModelChange)="dateTimeChanged.emit()" [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="pickerPlannedDate"></mat-datepicker-toggle>
    </mat-form-field>
    <mat-datepicker #pickerPlannedDate touchUi></mat-datepicker>
    <validation-messages [control]="form.controls['transfusionDate']"></validation-messages>
  </div>

  <!-- transfusion time field -->
  <div class="form-group" *ngIf="template.showPlannedTransfusionTime">
    <mat-form-field class="width100">
      <input matInput [ngxMatTimepicker]="pickerPlannedTime" [required]="template.requirePlannedTransfusionTime"
        formControlName="transfusionTime" [(ngModel)]="answerSheet.plannedTransfusionTime"
        placeholder="Planned Transfusion Time" (ngModelChange)="dateTimeChanged.emit()">
      <ngx-mat-timepicker-toggle matSuffix [for]="pickerPlannedTime"></ngx-mat-timepicker-toggle>
    </mat-form-field>
    <ngx-mat-timepicker #pickerPlannedTime></ngx-mat-timepicker>
    <validation-messages [control]="form.controls['transfusionTime']"></validation-messages>
  </div>
</form>