import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from '../../app/services/repository.service';
import { AuthenticationService } from '../../app/services/authentication.service';
import { User } from '../models/user';

@Injectable()
export class UserService {
  constructor(private repository: RepositoryService, private authService: AuthenticationService) { }

  getAllUsers(): Promise<User[]> {
    // return this.repository.fetchUsers()
    return this.repository.fetchObjects(User.type, Databases.usersDb)
      .then(result => {
        const users: User[] = result.docs.map((doc: any) => this.mapObjectToUser(doc));
        // console.log('Users: ', users);
        return (users);
      })
      .catch(error => {
        console.error('An error occurred', error);
        return Promise.reject(error);
      });
  }

  // delete user
  deleteUser(user: User): Promise<any> {
    return new Promise((resolve, reject) => {
      user.type = User.type;

      this.repository.deleteObject(user, Databases.usersDb)
        .then((res) => {
          resolve(null);
        })
        .catch((error) => {
          resolve(error)
        })

    });
  }

  private mapObjectToUser(object: any): User {
    let user: User = new User();
    return user = { ...object };
  }

}
