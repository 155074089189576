import { Pipe, PipeTransform } from '@angular/core';
import { HandwritingResult } from '../models/answer-sheet';
import { DEFAULT_DATE_MASK } from '../models/settings';
import { HandwritingField, HandwritingGroup, StampTemplate } from '../models/stamp-template';
import { ValidDatePipe } from './validDate.pipe';

/** This pipe gets the count value from a group of OMR fields */
@Pipe({ name: 'getCountValue' })
export class GetCountValue implements PipeTransform {
  transform(values: any, groupName: string, template: StampTemplate) {
    // is this a group of field type count
    const isCountGroup = template.groups.find(g => g.groupTitle === groupName).count;

    if (isCountGroup) {
      if (typeof values === 'string') {
        values = values.split(' ').filter(e => e);
      } else if (typeof values === 'object') {
        values = Object.keys(values).filter(k => values[k] === true);
      }

      // get sum value
      values = isCountGroup ?
        values.length :
        values.map(v => parseInt(v, 10));
    }

    return values;
  }
}

/** this pipe returns the calculated value based on data format for a group of HW fields */
@Pipe({ name: 'getHWValues' })
export class GetHWValues implements PipeTransform {
  transform(
    templateHWGroup: HandwritingGroup,
    handwritingFields: HandwritingField[],
    sheetHWResult: HandwritingResult,
    dateMaskFormat: string = DEFAULT_DATE_MASK,
    targetHWfields?: Array<string>) {

    let groupTitle = templateHWGroup.groupTitle;
    let dataFormat = templateHWGroup.dataFormat;
    let decimalPlaces = templateHWGroup.decimalPlaces ? +templateHWGroup.decimalPlaces : 2; // default to 2 decimal places 
    let fieldLabels;

    if (!sheetHWResult) { return 'No HW results to process' }

    // debug setup info, get order of fields
    console.group('Format group', groupTitle, 'as', dataFormat)

    // option 1 - from answer sheet
    const fieldLabelsFromSheet = Object.keys(sheetHWResult);
    console.log('Natural field order', fieldLabelsFromSheet)

    // option 2 - from template
    let fieldLabelsFromTemplate = handwritingFields
      .filter(hwField => hwField.groupName === groupTitle)
      .sort((a, b) => a.serialNumber - b.serialNumber)
      .map(hwField => hwField.fieldName);
    console.log('Sorted field order', fieldLabelsFromTemplate);

    // option 3 - value from param
    console.log('Provided field order', targetHWfields);

    // if not provided in param, use option 2 from template
    fieldLabels = targetHWfields ? targetHWfields : fieldLabelsFromTemplate;
    console.log('Provided data', templateHWGroup, sheetHWResult, targetHWfields)
    console.log('Computed values', fieldLabels.map((hwFieldName) => sheetHWResult[hwFieldName].displayVal))
    console.groupEnd();

    // format data based on dataFormat and return
    switch (dataFormat) {
      case 'date': // return date
        let dateValue: Date;
        let d1 = sheetHWResult[fieldLabels[0]].displayVal,
          d0 = sheetHWResult[fieldLabels[1]].displayVal,
          m1 = sheetHWResult[fieldLabels[2]].displayVal,
          m0 = sheetHWResult[fieldLabels[3]].displayVal,
          y1 = sheetHWResult[fieldLabels[4]].displayVal,
          y0 = sheetHWResult[fieldLabels[5]].displayVal

        // clean the date variable, remove the word blank
        const clean_day = (`${d1}${d0}`).replace(/null|blank/g, '');
        const clean_month = (`${m1}${m0}`).replace(/null|blank/g, '');
        const clean_year = (`${y1}${y0}`).replace(/null|blank/g, '');

        const day: number = clean_day === '' ? NaN : +clean_day
        const month: number = clean_month === '' ? NaN : +clean_month
        const year: number = clean_year === '' ? NaN : +`20${clean_year}`

        // test day range
        if (day < 1 || day > 31) { return `invalid date (${day}/${month}/${year}) - day out of range`; }

        // test month range
        if (month < 1 || month > 12) { return `invalid date (${day}/${month}/${year}) - month out of range`; }

        // test year range
        if (year < 2020 || year > 2030) { return `invalid date (${day}/${month}/${year}) - year out of range`; }

        console.log(`20 y1:${y1} y0:${y0} m1:${m1} m0:${m0} d1:${d1} d0:${d0}`)
        dateValue = new Date(year, month - 1, day);
        let dateResult = new ValidDatePipe().transform(dateValue, dateMaskFormat);
        console.log('Date result', dateResult);
        return dateResult === 'n/a' ? 'Not filled' : dateResult;
        break;
      case 'integer': // return integer
        return +fieldLabels
          .map((hwFieldName) => sheetHWResult[hwFieldName].displayVal ? sheetHWResult[hwFieldName].displayVal : sheetHWResult[hwFieldName].mlResult)
          .map((hwFieldValue) => hwFieldValue.toLowerCase() === 'blank' || hwFieldValue.toLowerCase() === 'unknown' ? '' : hwFieldValue)
          .join('');
        break;
      case 'decimal': // return decimal
        // get string array of all the values
        let valueArray = fieldLabels
          .map((hwFieldName) => sheetHWResult[hwFieldName].displayVal ? sheetHWResult[hwFieldName].displayVal : sheetHWResult[hwFieldName].mlResult)
          .reduce((acc, curr, i) => acc.concat(i === (fieldLabels.length - decimalPlaces) ? ['.', curr] : curr), [],)

        // check if all are blank
        let blanks = fieldLabels
          .map((hwFieldName) => sheetHWResult[hwFieldName].displayVal ? sheetHWResult[hwFieldName].displayVal : sheetHWResult[hwFieldName].mlResult)
          .filter((hwFieldValue) => hwFieldValue.toLowerCase() === 'blank');
        console.log(blanks.length, fieldLabels.length);

        //  return null when all fields in decimal HW group 'blank'
        if (blanks.length === fieldLabels?.length) { return null; }

        // check if blank after number in integer part
        if (invalidIntegerPart(valueArray.slice(0, valueArray.length - (decimalPlaces + 1)))) { return `invalid number (${valueArray.join(' ')})`; }

        // check if blank before number in fraction part
        if (invalidFractionPart(valueArray.slice(valueArray.length - decimalPlaces, valueArray.length))) { return `invalid number (${valueArray.join(' ')})`; }

        // remove blanks & unknown, remove any leading zeros
        return (+valueArray
          .map((hwFieldValue) => hwFieldValue.toLowerCase() === 'blank' || hwFieldValue.toLowerCase() === 'unknown' ? '' : hwFieldValue)
          .join(''))
          .toFixed(decimalPlaces);
        break;

        // function to check if blank after number in integer part
        function invalidIntegerPart(values: Array<string>): boolean {
          let encounteredInteger = false;
          let hasBlankAfterInteger = false;
          for (let i = 0; i < values.length; i++) {
            let fieldValue = values[i];
            if (!encounteredInteger && !isNaN(+fieldValue)) {
              encounteredInteger = true;
              continue;
            } else if (encounteredInteger && isNaN(+fieldValue)) {
              hasBlankAfterInteger = true;
            }
          }
          return hasBlankAfterInteger;
        }

        // function to check if blank before number in fraction part
        function invalidFractionPart(values: Array<string>): boolean {
          let encounteredInteger = false;
          let hasBlankBeforeInteger = false;
          for (let i = values.length - 1; i >= 0; i--) {
            let fieldValue = values[i];
            if (!encounteredInteger && !isNaN(+fieldValue)) {
              encounteredInteger = true;
              continue;
            } else if (encounteredInteger && isNaN(+fieldValue)) {
              hasBlankBeforeInteger = true;
            }
          }
          return hasBlankBeforeInteger;
        }

      case 'phone': // return phone
        return fieldLabels
          .map((hwFieldName) => sheetHWResult[hwFieldName].displayVal ? sheetHWResult[hwFieldName].displayVal : sheetHWResult[hwFieldName].mlResult)
          .map((hwFieldValue) => hwFieldValue.toLowerCase() === 'blank' || hwFieldValue.toLowerCase() === 'unknown' ? '' : hwFieldValue)
          .join('');
        break;
      case 'text':
      default: // return text
        // clean up, ignore blanks and unknowns, return string
        let textString = fieldLabels
          .map((hwFieldName) => sheetHWResult[hwFieldName].displayVal ? sheetHWResult[hwFieldName].displayVal : sheetHWResult[hwFieldName].mlResult)
          .map((hwFieldValue) => !hwFieldValue || hwFieldValue.toLowerCase() === 'blank' || hwFieldValue.toLowerCase() === 'unknown' ? '' : hwFieldValue)
          .join('');

        // if all the values are 'blank', return not filled
        if (textString === '' && fieldLabels
          .map((hwFieldName) => sheetHWResult[hwFieldName].displayVal ? sheetHWResult[hwFieldName].displayVal : sheetHWResult[hwFieldName].mlResult)
          .map((hwFieldValue) => !hwFieldValue || hwFieldValue.toLowerCase() === 'blank' ? '_' : hwFieldValue)
          .every(element => element === '_')) {
          return 'Not filled';
        } else {
          // else return the cleaned text string
          return textString;
        }

        break;
    } // end switch
  } // end transform function
}
