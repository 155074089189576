<div class="uk-container">

  <h2 class="uk-margin-left">Manage Users ({{users?.length}})</h2>

  <!--Search Users -->
  <mat-form-field class="uk-form-width-large" floatLabel="auto">
    <input matInput #filter placeholder="Filter Users" (keyup)="applyUserFilter($event)" />
    <mat-icon matPrefix>search</mat-icon>
  </mat-form-field>

  <table mat-table #usersTable [dataSource]="dataSource" matSort matSortActive="dateAdded" matSortDirection="desc">
    <!-- username Column -->
    <ng-container matColumnDef="_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
      <td mat-cell *matCellDef="let user"> {{ user._id | removeUserPrefix }} </td>
    </ng-container>

    <!-- Email Address Column -->
    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let user"> {{ user.emailAddress }} </td>
    </ng-container>

    <!-- User Role Column -->
    <ng-container matColumnDef="userRole">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
      <td mat-cell *matCellDef="let user"> {{ user.userRole }} </td>
    </ng-container>

    <!-- Facility Column -->
    <ng-container matColumnDef="facility">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Facility </th>
      <td mat-cell *matCellDef="let user"> {{ user.facility }} </td>
    </ng-container>

    <!-- Date Added Column -->
    <ng-container matColumnDef="dateAdded">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Added</th>
      <td mat-cell *matCellDef="let user"> {{ user.dateAdded | date }} {{ user.dateAdded }}</td>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Edit</th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button (click)="editUser(user)"><mat-icon>edit</mat-icon></button>
        <button mat-icon-button (click)="confirmDelete(user)"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8">No users found.</td>
    </tr>

  </table>

  <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 50]" [pageSize]="10"></mat-paginator>

</div>