import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SignupService } from '../auth/signup/service/signup.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetResolver  {
  constructor(private signupService: SignupService, private router: Router) { }

  async resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    const resetToken = route.paramMap.get('resetToken');
    const userName = route.paramMap.get('user');
    if (!resetToken || !userName) {
      this.router.navigate(['/']);
      return false;
    }

    // Validate password reset token against the saved one in db
    try {
      const res = await this.signupService.validateToken(userName, resetToken);
      return res.valid;
    } catch (error) {
      return false;
    }
  }
}