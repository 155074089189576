import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Patient } from "../../models/patient";
import { Facility } from "../../models/facility";
import { AppSettings } from "../../models/settings";

@Component({
  selector: "paper-add-patient-dialog",
  templateUrl: "patient-modal.component.html",
})
export class AddNewPatientDialogComponent {
  patient: Patient = new Patient();
  facility: Facility;
  facilities: Facility[];
  selectedFacility: Facility;
  patients: Patient[];
  filteredPatients: Patient[];
  patientExist: boolean = false;
  appSettings: AppSettings;
  patientLabelName: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {
    dialogRef.disableClose = true;
    this.facility = data.facility;
    this.selectedFacility = data.selectedFacility
      ? data.selectedFacility
      : null;
    this.patients = data.patients;
    this.appSettings = data.appSettings;
    this.filteredPatients =
      this.selectedFacility &&
      this.appSettings.uiViewSettings.migoriWorkflow &&
      this.facility.facilityOptions.isResearchAssistantFacility
        ? this.patients.filter(
            (p) => p.createFacility === this.selectedFacility._id
          )
        : this.patients;
    this.facilities = data.facilities ? data.facilities : [];

    this.patientLabelName = this.appSettings.uiViewSettings.safeBloodWorkflow
      ? this.appSettings.patientSettings.patientIdLabel
      : "Patient";

    // if its an edit of a patient
    if (data.patientId) {
      this.patient.patientId = data.patientId;
    }
  }
}
