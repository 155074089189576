import { Pipe, PipeTransform } from '@angular/core';
import { SheetService } from '../services/sheet.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/** pipe to get an attachment image provided attachment id  */
@Pipe({ name: 'getAttachmentImage' })
export class GetAttachmentImage implements PipeTransform {

    constructor(private sheetService: SheetService, private sanitizer: DomSanitizer) { }

    /** Helper function to get an attachment image provided attachment id */
    async transform(attachmentID: string): Promise<string | SafeUrl> {
        return this.sheetService.getSheetAttachment(attachmentID, 'fullFile').then(attachment => {
            const url = URL.createObjectURL(attachment);
            return this.sanitizer.bypassSecurityTrustUrl(url);
        }).catch(err => {
            console.log('ERROR: Getting attachment file', err);
            return '';
        });
    }
}
