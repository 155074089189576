import { Pipe, PipeTransform } from '@angular/core';
import { Facility, FacilityWard } from '../models/facility';

@Pipe({ name: 'getFacilityName' })
export class GetFacilityName implements PipeTransform {
  transform(facilityCode: string, facilities: Facility[]) {
    let currentFacility: Facility, currentFacilityName = '';

    if (facilities) {
      currentFacility = facilities.find(p => p._id === facilityCode);
      if (currentFacility) { currentFacilityName = currentFacility.name; }
    }
    return currentFacilityName;
  }
}

@Pipe({ name: 'getFacilityWard' })
export class GetFacilityWard implements PipeTransform {
  transform(wardId: string, facilityWards: FacilityWard[]) {
    let wardName = '', facilityWard: FacilityWard;

    if (wardId && facilityWards) {
      facilityWard = facilityWards.find(w => w._id === wardId);
      if (facilityWard) { wardName = facilityWard.name; }
    }
    return wardName;
  }
}
