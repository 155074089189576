import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppMaterialModule } from '../material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ValidationModule } from '../validation/validation.module';
import { UsersComponent } from './users.component';
import { UserAddComponent } from './add/user-add.component';
import { UserEditComponent } from './edit/user-edit.component';
import { UsersListComponent, DeleteUserDialogComponent } from './list/users-list.component';
import { RemoveUserPrefix } from '../pipes/removeUserPrefixPipe';
import { PipesModule } from '../pipes/pipes.module';

import { NavigationModule } from '../navigation/navigation.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppMaterialModule,
        FlexLayoutModule,
        ValidationModule,
        PipesModule,
        NavigationModule
    ],
    providers: [RemoveUserPrefix],
    declarations: [
        UsersComponent,
        UserAddComponent,
        UserEditComponent,
        UsersListComponent,
        DeleteUserDialogComponent
    ]
})
export class UsersModule { }
