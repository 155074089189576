import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, interval, timer } from 'rxjs';
import packageJson from '../../../package.json';
import { environment } from '../../environments/environment';
import { AppSettings } from '../models/settings';
import { AppUpdateDialogComponent } from '../shared/components/app-update-dialog.component';
import { RepositoryService } from './repository.service';
import { AppSettingsService } from './settings.service';

@Injectable({
  providedIn: "root",
})
export class UpdateService {
  env = environment;
  appSettings: AppSettings;
  currentVersion: string;
  constructor(
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    public repository: RepositoryService,
    private appSettingsService: AppSettingsService
  ) {}

  init(firstTimeLogin: boolean) {
    console.log("swUpdate: ", this.swUpdate.isEnabled);
    if (this.swUpdate.isEnabled) {
      if (firstTimeLogin) {
        timer(5 * 60 * 1000).subscribe(() => this.checkForUpdates());
      } else {
        this.checkForUpdates();
      }
      // Check for updates every 12 hours
      interval(12 * 60 * 60 * 1000).subscribe(() => this.checkForUpdates());
      //interval(1 * 60 * 1000).subscribe(() => this.checkForUpdates());
    }
  }

  /**
   * Function load app settings from the database
   */
  private loadAppSettings() {
    this.appSettingsService
      .loadAppSettings(this.env.settingsId, true)
      .then((loadedAppSettings) => {
        this.appSettings = new AppSettings(loadedAppSettings);
      })
      .catch((error) => {
        {
          console.log("Error loading app settings.", error);
        }
      });
  }

  /**
   * Function to check for available app updates through the service worker
   */
  private checkForUpdates() {
    this.currentVersion = packageJson.version;
    if (!this.appSettings) {
      this.loadAppSettings();
    }
    console.log("Checking for updates. current version:", this.currentVersion);
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY")
      )
      .subscribe((evt) => {
        console.log("swUpdate event: ", evt);
        console.log("currentVersion: ", this.currentVersion);
        const latestVersion = this.getLatestVersion();
        console.log("latestVersion: ", latestVersion);
        const minCompatibleVersion = this.getMinCompatibleVersion();
        console.log("minCompatibleVersion: ", minCompatibleVersion);

        if (
          this.compareVersions(this.currentVersion, minCompatibleVersion) < 0
        ) {
          this.showForcedUpdateNotification();
        } else if (
          this.compareVersions(this.currentVersion, latestVersion) < 0
        ) {
          this.showUpdateNotification();
        }
      });

    this.swUpdate.checkForUpdate();
  }

  /**
   * Notify the user if there are some updates to the app
   */
  private showUpdateNotification() {
    console.log("show available update");
    if (this.dialog.openDialogs.length === 0) {
      const updateNotification = this.dialog.open(AppUpdateDialogComponent, {
        data: { type: "available", version: this.getLatestVersion() },
      });

      updateNotification.afterClosed().subscribe((result) => {
        if (result === "update") {
          this.activateUpdate();
        }
      });
    }
  }

  /**
   * Displays a forced update notification dialog to the user.
   * If there are unsynced items, the dialog will notify the user,
   * and the update cannot be postponed indefinitely.
   *
   * - Opens the dialog if none are currently open.
   * - If the user selects "later", the notification will reappear after 30 seconds.
   * - If the user confirms the update, the `forcedUpdate` process is initiated.
   */
  private showForcedUpdateNotification() {
    console.log(`show forced update, unsynced items.`);

    // Only open the dialog if no other dialogs are currently open
    if (this.dialog.openDialogs.length === 0) {
      const forcedUpdateNotification = this.dialog.open(
        AppUpdateDialogComponent,
        {
          data: {
            type: "forced", // Marks the update as a forced one
            version: this.getLatestVersion(), // Retrieves the latest version for the dialog
            hasUnsynced: this.repository.unsyncedObjectsCount.getValue() > 0, // Checks if there are any unsynced objects
          },
          disableClose: true, // Prevents the user from closing the dialog without making a choice
        }
      );

      // Handle what happens after the dialog is closed
      forcedUpdateNotification.afterClosed().subscribe((result) => {
        if (result === "later") {
          // If the user chooses to update later, set a timer for 30 seconds to show the notification again
          timer(5 * 60 * 1000).subscribe(() => {
            console.log("check again in 5 minutes");
            this.showForcedUpdateNotification();
          });
        } else {
          // If the user agrees to update, start the forced update process
          this.forcedUpdate();
        }
      });
    }
  }

  private forcedUpdate() {
    try {
      this.clearDatabaseAndCache().then(() => {
        this.activateUpdate();
      });
    } catch (error) {
      console.error("Error during forced update:", error);
    }
  }

  /**
   * This function clears the browser storage and cache, preparing the application
   * for an update by removing service worker registrations, caches, IndexedDB databases,
   * and local/session storage.
   */
  private async clearDatabaseAndCache() {
    console.log("Clearing storage before update");

    // Check if the browser supports service workers
    if (navigator.serviceWorker) {
      // Retrieve all service worker registrations
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        // Check if the browser supports caches
        if (window.caches) {
          // Retrieve all cache names
          window.caches.keys().then((cacheNames) => {
            console.log("caches", cacheNames);
            // Loop through and delete each cache
            cacheNames.forEach((cacheName) => {
              window.caches
                .delete(cacheName)
                .then((success) => {
                  if (success) {
                    console.log(`Cache ${cacheName} deleted successfully.`);
                  } else {
                    console.log(
                      `Cache ${cacheName} was not found or could not be deleted.`
                    );
                  }
                })
                .catch((error) => {
                  console.error(`Failed to delete cache ${cacheName}:`, error);
                });
            });
          });
        }

        // Unregister all service workers
        registrations.forEach((registration) => {
          registration.unregister().then(() => {});
        });
      });
    }

    // Check if the browser supports IndexedDB
    if (window.indexedDB) {
      // Retrieve all IndexedDB databases
      window.indexedDB.databases().then((databases) => {
        console.log("databases ", databases);
        // Loop through and delete each database
        databases.forEach((database) => {
          const name = database.name;
          const deleteRequest = window.indexedDB.deleteDatabase(database.name);

          // On successful deletion of the database
          deleteRequest.onsuccess = (event) => {
            console.log(`Successfully deleted database ${name}`);
          };

          // Handle errors during deletion of the database
          deleteRequest.onerror = (event) => {
            console.log(`Error deleting database ${name}`);
          };
        });
      });
    }

    // Clear local storage
    window.localStorage.clear();

    // Clear session storage
    window.sessionStorage.clear();
  }

  private activateUpdate() {
    this.swUpdate.activateUpdate().then(() => {
      window.location.reload();
    });
  }

  /**
   * Compare two version of the app and latest
   * @param v1
   * @param v2
   * @returns
   */
  private compareVersions(v1: string, v2: string): number {
    const parts1 = v1.replace("v", "").split(".").map(Number);
    const parts2 = v2.replace("v", "").split(".").map(Number);
    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
      const part1 = parts1[i] || 0;
      const part2 = parts2[i] || 0;
      if (part1 < part2) return -1;
      if (part1 > part2) return 1;
    }
    return 0;
  }

  /**
   * Function get the minimum compatible version from the app settings
   * @returns the minimum Compatible version as documented
   */
  getMinCompatibleVersion(): string {
    // return min compatible version from app settings
    return this.appSettings.appUpdateSettings.minCompatibleVersion;
  }

  /**
   * Function get the latest version from the app settings
   * @returns the latest version as documented
   */
  getLatestVersion(): string {
    // return latest version from app settings
    return this.appSettings.appUpdateSettings.latestVersion;
  }
}
