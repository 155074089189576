import { Injectable } from '@angular/core';
import { RepositoryService, Databases } from './repository.service';
import { AnswerSheet } from '../models/answer-sheet';

@Injectable()
export class SheetService {

  // public currentSheet: AnswerSheet; // store answer sheet for screens
  public sheets: AnswerSheet[] = []; // store all answer sheets to be returned
  private sheetsLoaded: boolean = false;  // track whether cached version of answer sheets available
  private failedSheets: AnswerSheet[] = []; // store all answer sheets to be returned
  private failedSheetsLoaded: boolean = false;  // track whether cached version of answer sheets available

  constructor(private repository: RepositoryService) { }

  /** function get month in numeric form */
  getMonthNumber(month: string): number {
    const currentDate = new Date();
    return new Date(month + ' ' + currentDate.getDay() + ', ' + currentDate.getFullYear()).getMonth() + 1;
  }

  /** get the answers */
  getAnswers(answerGroup: string, sheet: AnswerSheet) {
    // keep track of answers that are true
    return Object.keys(sheet.answers[answerGroup]).filter(k => sheet.answers[answerGroup][k] === true);
  }

  /** provide a new or updated answersheet and save it to the db */
  updateSheet(sheet: AnswerSheet, createFacility: string = null): Promise<AnswerSheet> {
    return new Promise((resolve, reject) => {
      this.repository.updateObject(sheet, AnswerSheet.type, Databases.mainDb, false, createFacility)
        .then((pouchObject) => {
          const updatedSheet: AnswerSheet = JSON.parse(JSON.stringify(pouchObject));
          resolve(updatedSheet);
        })
        .catch(error => {
          console.error('An error occurred updating this answer sheet', error);
          reject(error);
        });
    });
  }

  /** Return list of answer sheets from cached list if available, or call load sheets */
  getSheets(useCache = true): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      if (this.sheetsLoaded && useCache) {
        console.log('already loaded answer sheets');
        resolve(this.sheets);
      } else {
        this.loadSheets().then((allSheets) => {
          this.sheets = allSheets;
          this.sheetsLoaded = true;
          resolve(this.sheets);
        }).catch(error => {
          console.error('An error occurred getting answer sheets', error);
          reject(error);
        });
      }
    });
  }

  /** Return of answer sheet from cached if available, or call load sheets */
  getSheet(_id: string): Promise<AnswerSheet> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObject(AnswerSheet.type, _id, AnswerSheet.fields).then((result) => {
        const sheet: AnswerSheet = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc))[0];
        resolve(sheet);
      }).catch(error => {
        console.error('An error occurred loading answer sheet', error);
        reject(error);
      });
    });
  }

  /** Return list of failed answer sheets from cached list if available, or call load sheets */
  getFailedSheets(useCache = true): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      if (this.failedSheetsLoaded && useCache) {
        console.log('already loaded answer sheets');
        resolve(this.sheets);
      } else {
        this.loadFailedSheets()
          .then((allSheets) => {
            this.failedSheets = allSheets;
            this.failedSheetsLoaded = true;
            resolve(this.failedSheets);
          })
          .catch(error => {
            console.error('An error occurred getting answer sheets', error);
            reject(error);
          });
      }
    });
  }
  /** Return list of report series answer sheets */
  getReportAnswerSheets(reportSeriesId: string): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(AnswerSheet.type, 'reportSeriesId', reportSeriesId)
        .then((result) => {
          const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
          resolve(sheets as AnswerSheet[]);
        }).catch(error => {
          console.error('An error occurred loading answer sheets in a report series', error);
          reject(error);
        });
    })
  }

  /** Return list of patients answer sheets */
  getPatientSheets(patientId: string): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      this.repository
        .fetchObjectsBy(AnswerSheet.type, 'patientId', patientId)
        .then((result) => {
          const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
          resolve(sheets as AnswerSheet[]);
        })
        .catch(error => {
          console.error('An error occurred loading answer sheets', error);
          reject(error);
        });
    });
  }

  /** Return list of answer sheets for a specific farm by penNumber*/
  getPenSheets(penNumber: number, useCache: boolean = true): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      if (this.sheetsLoaded && useCache) {
        console.log('already loaded answer sheets, filter by pen');
        const sheets = this.sheets.filter(sheet => sheet.penNumber === penNumber);
        resolve(sheets);
      } else {
        this.repository.fetchObjectsBy(AnswerSheet.type, 'penNumber', penNumber)
          .then((result) => {
            const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
            resolve(sheets as AnswerSheet[]);
          })
          .catch(error => {
            console.error('An error occurred loading answer sheets', error);
            reject(error);
          });
      }
    });
  }

  /**
   * Return list of answer sheets for a specific serial number
   * @param serialNumber form serial number
   * @param [useCache=true] using cache by default
   * @returns AnswerSheet[]
   * */
  getSheetsBySerialNumber(serialNumber: number, useCache: boolean = true): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      if (this.sheetsLoaded && useCache) {
        console.log('already loaded answer sheets, filter by serial #');
        const sheets = this.sheets.filter(sheet => sheet.serialNumber === serialNumber);
        resolve(sheets);
      } else {
        this.repository.fetchObjectsBy(AnswerSheet.type, 'serialNumber', serialNumber)
          .then((result) => {
            const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
            resolve(sheets as AnswerSheet[]);
          })
          .catch(error => {
            console.error('An error occurred loading answer sheets', error);
            reject(error);
          });
      }
    });
  }

  /**
   * Return list of records for the same team #
   * @param teamNumber current team number
   * @param useCache [useCache=true] using cache by default
   * @returns AnswerSheet[]
   */
  getSheetsByTeamNumber(teamNumber: number, useCache: boolean = true): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      if (this.sheetsLoaded && useCache) {
        console.log('already loaded answer sheets, filter by team #');
        const sheets = this.sheets.filter(sheet => sheet.customBeforeFieldsData?.teamNumber === teamNumber);
        resolve(sheets);
      } else {
        this.repository.fetchObjectsBy(AnswerSheet.type, 'teamNumber', teamNumber)
          .then((result) => {
            const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
            resolve(sheets as AnswerSheet[]);
          })
          .catch(error => {
            console.error('An error occurred loading answer sheets', error);
            reject(error);
          });
      }
    });
  }

  /** Get records by template id */
  getAnswersByTemplate(id: string, useCache: boolean = true): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      if (this.sheetsLoaded && useCache) {
        console.log('already loaded answer sheets, filter by template id');
        const sheets = this.sheets.filter(sheet => sheet.stampTemplateId === id);
        resolve(sheets);
      } else {
        this.repository.fetchObjectsBy(AnswerSheet.type, 'stampTemplateId', id)
          .then((result) => {
            const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
            resolve(sheets as AnswerSheet[]);
          }).catch((error: any) => {
            console.error('An error occurred loading answer sheets', error);
            reject(error);
          });
      }
    });
  }

  /**
   * get sheet attachment from pouch
   */
  getSheetAttachment(attachmentName: string, file: string = 'file'): Promise<any> {
    return this.repository.getAttachment(attachmentName, Databases.imagesDb, file)
      .then(response => response as AnswerSheet[])
      .catch(error => {
        console.error('An error occurred loading the attachment', error);
        return Promise.reject(error);
      });
  }

  /** Returns all answer sheets from DB 
   * by default return answersheets where failed = false
    */
  loadSheets(): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjects(AnswerSheet.type).then((result) => {
        const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
        resolve(sheets as AnswerSheet[]);
      }).catch(error => {
        console.error('An error occurred loading answer sheets', error);
        reject(error);
      });
    });
  }

  /** Returns all failed answer sheets from DB */
  loadFailedSheets(): Promise<AnswerSheet[]> {
    return new Promise((resolve, reject) => {
      this.repository.fetchObjectsBy(AnswerSheet.type, 'failed', true).then((result) => {
        const sheets: AnswerSheet[] = result.docs.map((doc: any) => this.mapObjectToAnswerSheet(doc));
        resolve(sheets as AnswerSheet[]);
      }).catch(error => {
        console.error('An error occurred loading answer sheets', error);
        reject(error);
      });
    });
  }

  /** takes an object and maps it to an answer sheet Object */
  private mapObjectToAnswerSheet(object: any): AnswerSheet {
    let sheet: AnswerSheet = new AnswerSheet();
    return sheet = { ...object };
  }

}
