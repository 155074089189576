import {
  Component,
  OnInit,
  NgZone,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthenticationService } from "../services/authentication.service";
import { User } from "../models/user";
import { Facility } from "../models/facility";
import { Observable, of, fromEvent, merge, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { RepositoryService, SyncStatus } from "../services/repository.service";
import { environment } from "../../environments/environment";
import { RepositoryObserver } from "../services/repository-observer";
import { ValidationService } from "../validation/validation.service";
import { FacilityService } from "../services/facility.service";

@Component({
  template: `<h2 mat-dialog-title >Confirm Turn Sync On</h2>
  <mat-dialog-content>
    <p>Please note that this will sync the images to the database.</p>
    <p><b>Notice:</b> This action cannot be undone</p>
  </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-raised-button
        color="primary"
        (click)="dialogRef.close(true)"
      >
        Confirm
      </button>
      <button
        type="button"
        mat-raised-button
        color="warn"
        (click)="dialogRef.close()"
      >
        Cancel
      </button>
    </mat-dialog-actions>`,
})
export class ConfirmTurnSyncOnDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  template: `<h2 mat-dialog-title >Confirm Database delete</h2>
  <mat-dialog-content>
    <p>
      Please note that this will delete the database. Are you sure you would
      like to delete the database?
    </p>
    <p class="uk-text-small">
      <b>Notice:</b> This action cannot be undone, after deleting you will only
      be able to view records not older than 3 month.
    </p>
  </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        class="uk-align-right uk-margin-left"
        mat-raised-button
        color="warn"
        (click)="dialogRef.close(true)"
      >
        Yes Delete
      </button>
      <button
        type="button"
        class="uk-align-right"
        mat-raised-button
        (click)="dialogRef.close()"
      >
        No
      </button>
    </mat-dialog-actions>`,
})
export class ConfirmDeleteDatabaseDialogComponent {
  constructor(public dialogRef: MatDialogRef<any>) { }
}

@Component({
  selector: "paper-profile",
  templateUrl: "profile.component.html",
})
export class ProfileComponent implements OnInit, RepositoryObserver {
  changeProfile: boolean;
  changePassword: boolean;
  userEditForm: FormGroup;
  changePassForm: FormGroup;
  showMenu: boolean;
  error: string;
  userModel: User;
  online: Observable<boolean>;
  syncImages: boolean = environment.syncImages;
  public syncStatusEnum = SyncStatus;
  appVersion: string;
  appDb: string;
  sessionInfo: any = "Loading...";
  refreshForm: FormGroup;
  isRoot: boolean = false;
  facilities: Facility[];
  loadingFacilities: boolean = false;

  // dialog reference
  dialogRef: MatDialogRef<any>; // dialog for confirm sync turn on

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    public repository: RepositoryService,
    private facilityService: FacilityService,
    private ref: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    // load facilities
    this.getFacilities();

    this.refreshForm = this.formBuilder.group({
      password: ["", Validators.required],
    });

    this.authService.isRoot.subscribe((isRoot) => (this.isRoot = isRoot));

    this.appVersion = environment.appVersion;
    this.appDb = environment.pouchDBName;

    this.repository.registerObserver(this);

    // set current global patient id
    // this.globals.showShareLinkPatientId.emit(+(''));

    // boolean to detect if editing profile
    this.changeProfile = false;
    // boolean to detect if changing password
    this.changePassword = false;

    // the user edit profile form
    this.userEditForm = this.formBuilder.group({
      emailAddress: [
        "",
        [Validators.required, ValidationService.emailValidator],
      ],
    });

    // the change password form
    this.changePassForm = this.formBuilder.group(
      {
        password: ["", Validators.required],
        confirm_password: ["", Validators.required],
      },
      {
        validator: ValidationService.passwordConfirm(
          "password",
          "confirm_password"
        ),
      }
    );

    // TODO: fix for angular 6
    this.online = new BehaviorSubject<boolean>(false);
    this.online = merge(
      of(navigator.onLine),
      fromEvent(window, "online").pipe(map(() => true)),
      fromEvent(window, "offline").pipe(map(() => false))
    );
  }

  getFacilities() {
    this.loadingFacilities = true;
    this.facilityService
      .getFacilities()
      .then((response) => {
        this.facilities = response;
        this.loadingFacilities = false;
      })
      .catch((error) => {
        console.log("Error loading facilities list", error);
        this.snackBar.open("Error loading facilities list", "Error");
        this.loadingFacilities = false;
      });
  }

  getFacilityNameByCode(facilityCode: string) {
    if (this.facilities === undefined) {
      return null;
    }
    const facilityIndex = this.facilities.findIndex(
      (facility) => facility.code === facilityCode
    );
    if (facilityIndex !== -1) {
      return this.facilities[facilityIndex].name;
    } else {
      return "";
    }
  }

  refreshLogin() {
    const password: string = this.refreshForm.controls.password.value;
    const username: string = this.authService.getUser().username;

    this.authService
      .userLogin(username, password)
      .then((response) => {
        // online auth successful, load profile
        this.loadUserProfile(username).then((loadUserProfileSuccess) => {
          const user = this.authService.getUser();
          this.authService.setUser(user);

          // profile loaded, get facility key
          this.authService
            .loadFacilityKeyRemote(user.facility)
            .then((encryptionKey) => {
              const key = encryptionKey;
              // load main database based on user location and begin sync
              this.repository.loadMainDb(user.facility, key);

              // get user ip
              this.authService
                .getClientIp()
                .then((ip) => {
                  user.ipAddress = ip;
                  this.authService.setUser(user);
                  this.authService
                    .createUserDb(user, username, password, key)
                    .catch((err) => { console.log('Error creating local user DB', err) });
                  this.loadSessionInfo();
                })
                .catch((err) => {
                  // error loading user ip, create offline db anyway
                  this.authService
                    .createUserDb(user, username, password, key)
                    .catch((err) => { console.log('Error creating local user DB', err) });
                  console.log("Unable to load user ip", err);
                  this.snackBar.open("Unable to load user ip", "Error", {
                    duration: 6000,
                  });
                });
            })
            .catch((err) => {
              // error loading encryption key
              this.snackBar.open(
                "Unable to load encryption key" + err,
                "Error",
                { duration: 6000 }
              );
            });
        });
      })
      .catch((err) => {
        // error logging in to remote db
        console.log("Error refreshing login to remote DB: ", err);
        this.snackBar.open(
          "Error refreshing login to remote DB: " + err.message,
          "Error"
        );
      });
  }

  loadUserProfile(name: string): Promise<User> {
    console.log("Attempting to load user profile for: " + name);
    return new Promise((resolve, reject) => {
      this.authService
        .loadRemoteUser(name)
        .then((result) => {
          this.snackBar.open("Successful Online login", "Success", {
            duration: 6000,
          });
          resolve(result);
        })
        .catch((error) => {
          this.snackBar.open("Sorry, error getting profile" + error, "Error", {
            duration: 6000,
          });
          reject(error);
        });
    });
  }

  notify(objectType: string): void {
    this.ref.detectChanges();
  }

  loadSessionInfo() {
    this.authService
      .checkUserSession()
      .then((response) => {
        this.sessionInfo = response;
      })
      .catch((error) => {
        this.sessionInfo = "Error: " + error;
      });
  }

  ngOnInit() {
    this.error = "";
    this.userModel = this.authService.getUser();
    this.loadSessionInfo();
  }

  toggleProfileForm(showChangeProfile: boolean) {
    this.changeProfile = showChangeProfile;
  }

  togglePasswordForm(showChangePassword: boolean) {
    this.changePassword = showChangePassword;
  }

  /**
   * toggle function to turn on/off syncing of images
   */
  toggleImageSyncing() {
    if (!this.syncImages) {
      environment.syncImages = this.syncImages;
      // call function to toggle syncing off for images
      this.repository.imagesDatabaseSync(
        this.syncImages,
        this.userModel.facility
      );
      return;
    }

    this.dialogRef = this.dialog.open(ConfirmTurnSyncOnDialogComponent);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.syncImages = false;
        return;
      }
      environment.syncImages = this.syncImages;
      // call function to toggle syncing on for images
      this.repository.imagesDatabaseSync(
        this.syncImages,
        this.userModel.facility
      );
      this.dialogRef = null;
    });
  }

  /**
   * function check unSynced objects if they exist in server and remove them from local
   */
  forceSync() {
    this.repository
      .checkUnSyncedInCouch()
      .then((response) => {
        this.snackBar.open(response, "", { duration: 6000 });
      })
      .catch((error) => {
        console.log("Error:", error);
        this.snackBar.open(
          "Error getting document, please make sure you are connected to the internet",
          "Error",
          { duration: 6000 }
        );
      });
  }

  /** function delete images database */
  deleteImagesDatabase() {
    if (this.repository.unsyncedObjects.length > 0) {
      this.snackBar.open(
        "We can't proceed with deleting the database, make sure all records have been synced to avoid loos of data.",
        "Warning",
        { duration: 6000 }
      );
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmDeleteDatabaseDialogComponent);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.repository
          .deleteImagesDatabase()
          .then(() =>
            this.snackBar.open("SUCCESS: Database deleted", "Success", {
              duration: 6000,
            })
          )
          .catch(() =>
            this.snackBar.open("FAILED: Database not deleted", "Failed", {
              duration: 6000,
            })
          );
      }
      this.dialogRef = null;
    });
  }

  /** function delete main database */
  /*
  deleteMainDatabase() {
    if (this.repository.unsyncedObjects.length > 0) {
      this.snackBar.open('We can\'t proceed with deleting the database, make sure all records have been synced to avoid loos of data.', 'Warning', { duration: 6000 });
      return;
    }
    this.dialogRef = this.dialog.open(ConfirmDeleteDatabaseDialogComponent);
    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repository.deleteMainDatabase()
          .then(() => this.snackBar.open('SUCCESS: Database deleted', 'Success', { duration: 6000 }))
          .catch(() => this.snackBar.open('FAILED: Database not deleted', 'Failed', { duration: 6000 }));
      }
      this.dialogRef = null;
    });
  }
  */

  editPassword(model: any) {
    if (!this.changePassForm.valid) {
      this.snackBar.open("Form is not valid.", "Error");
      return;
    }

    // true|false there is an internet connection
    if (!navigator.onLine) {
      this.snackBar.open(
        "Internet connection required to edit your profile.",
        "Warning"
      );
      this.changePassForm.reset();
      return;
    }

    if (model.password === model.confirm_password) {
      this.authService
        .userChangePassword(this.userModel.username, model.password)
        .then((response) => {
          console.log("userChangePassword:", response);
          if (response["ok"] || response["ok"] === true) {
            this.snackBar.open("Successfully changed password.", "Success", {
              duration: 6000,
            });
          } else {
            this.snackBar.open(response["message"], "Warning");
          }

          this.changePassForm.reset();
          this.togglePasswordForm(false);
        })
        .catch((error) => {
          // to be used later
          // this.error = error;
          this.snackBar.open(error["message"], "Error");
        });
    } else {
      this.snackBar.open("Passwords do not match", "Error");
    }
  }

  editProfile() {
    if (!navigator.onLine) {
      this.snackBar.open(
        "Sorry. Internet connection required to change your profile.",
        "Warning"
      );
      return;
    }

    this.authService
      .userChangeProfile(this.userModel)
      .then((user) => {
        this.userModel = user;
        this.snackBar.open("Successfully changed profile.", "Success", {
          duration: 6000,
        });
        this.toggleProfileForm(false);
      })
      .catch((error) => {
        this.snackBar.open("Error updating user profile" + error, "Error");
      });
  }
}
