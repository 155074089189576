import { PouchObject } from './pouch-object';

export class Attachment implements PouchObject {
    public static type: string = 'attachment';

    public static fields = ['id', '_id', '_rev', '_deleted', 'type', 'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy',
        'createFacility', 'updateFacility', 'file', '_attachments', 'mediatype', 'size', 'dateOnAttachment', 'sheetId',
        'attachmentTitle', 'attachmentFileName', 'sharedFacilities', 'answerSheetId'];

    id: number;
    _id: string;
    _rev: string;
    _deleted: boolean = false;
    type: string;

    answerSheetId: string;
    dateOnAttachment: Date;
    attachmentTitle: string;
    attachmentFileName: string;

    dateAdded: Date;
    dateUpdated: Date;
    updatedBy: string;
    createdBy: string;
    createFacility: string;
    updateFacility: string;
    sharedFacilities?: string[];

    file?: File; //  used for passing attachment from browser to pouchdb
    _attachments?; // store the scanned image
    mediatype?: string;
    size?: string;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}
