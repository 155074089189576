import { Pipe, PipeTransform } from '@angular/core';
import { HandwritingField, StampTemplate } from '../models/stamp-template';

@Pipe({ name: 'hideHwFieldInView' })
export class HideHwFieldInView implements PipeTransform {
  transform(handwritingField: HandwritingField, template: StampTemplate) {
    // get field group
    const group = template.handwritingGroups.find(group => group.groupTitle === handwritingField.groupName);
    return group.hideInView;
  }
}