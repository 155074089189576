<h3 mat-dialog-title >Add New {{patientLabelName}}</h3>
<mat-dialog-content>
  

    <div *ngIf="patient.patientId">
        <p [style.color]="patientExist ? '#ff0000': ''">
            {{patientLabelName}} <strong>{{patient.patientId}}</strong> is
            <span *ngIf="!patientExist"> not</span>
            <span *ngIf="patientExist"> already</span> in the database for facility
            <span *ngIf="!appSettings.uiViewSettings.migoriWorkflow || appSettings.uiViewSettings.migoriWorkflow &&
                !facility.facilityOptions.isResearchAssistantFacility && !selectedFacility">
                <strong> {{facility.name}}</strong>.
            </span>
            <span
                *ngIf="selectedFacility && appSettings.uiViewSettings.migoriWorkflow && facility.facilityOptions.isResearchAssistantFacility">
                <strong> {{selectedFacility.name}} </strong>
            </span>
        </p>
    </div>

    <!-- load patient edit component -->
    <paper-patient-edit [appSettings]="appSettings" [patient]="patient" [facility]="facility" [patients]="patients"
        [(selectedFacility)]="selectedFacility" [filteredPatients]="filteredPatients"
        (addedPatient)="dialogRef.close($event)" (patientEditCancel)="dialogRef.close($event)" [facilities]="facilities"
        [(patientExist)]="patientExist"></paper-patient-edit>
</mat-dialog-content>