<mat-toolbar class="paper-nav" *ngIf="authService.isLoggedIn()">
  <!--Health-E-Net Logos-->
  <span class="uk-visible-large uk-margin-top">
    <a [routerLink]="['/scanned_records/list']"><img src="assets/images/logo.svg" height="30" class="uk-margin-right"
        style="vertical-align: baseline" alt="Health-E-Net" /></a>
  </span>
  <span class="uk-hidden-large">
    <a [routerLink]="['/scanned_records/list']"><img src="assets/images/logo_small.svg" height="30"
        class="uk-margin-right" alt="Health-E-Net" /></a>
  </span>

  <!-- Admin Navigation -->
  <nav>
    <ng-template isAuthorized [authorizedRoles]="[userRoles.admin]">
      <button mat-button [routerLink]="['/templates']" *ngIf="appTypeEnum[repository.getApp()] === 'adminApp'">
        <mat-icon>description</mat-icon>
        <span class="uk-hidden-large">Template</span>
        <span class="uk-visible-large">Stamp Templates</span>
      </button>
    </ng-template>

    <!-- <button mat-button [routerLink]="['/scanned_records/list']" *ngIf="appTypeEnum[repository.getApp()] === 'adminApp'">
      <mat-icon>content_copy</mat-icon>
      <span class="uk-hidden-large">Sheets</span>
      <span class="uk-visible-large">Scanned Sheets</span>
    </button> -->

    <button mat-button [routerLink]="['/patients/patients_list']" *ngIf="
        appSettings.uiViewSettings.tbWorkflow ||
        appSettings.uiViewSettings.migoriWorkflow ||
        (appSettings.uiViewSettings.cicWorkflow && isCHAUser)
      ">
      <mat-icon>people</mat-icon>
      <span *ngIf="appSettings.uiViewSettings.cicWorkflow; else otherBlock">
        <span class="uk-hidden-large">CHVs</span>
        <span class="uk-visible-large">All CHVs</span>
      </span>
      <ng-template #otherBlock>
        <span class="uk-hidden-large">Patients</span>
        <span class="uk-visible-large">All Patients</span>
      </ng-template>
    </button>

    <button mat-button [routerLink]="['/patients/report_series/list']"
      *ngIf="appSettings.recordSettings.enableReportSeries">
      <span class="uk-hidden-large">{{appSettings.uiViewSettings.navigationMenu.reportsListLabel}}</span>
      <span class="uk-visible-large">All {{appSettings.uiViewSettings.navigationMenu.reportsListLabel}}</span>
    </button>

    <button mat-button [routerLink]="['/scanned_records/list']" *ngIf="!appSettings.recordSettings.enableReportSeries">
      <mat-icon>view_list</mat-icon>
      <span *ngIf="appSettings.uiViewSettings.safeBloodWorkflow; else recordsList">
        Scanned Templates
      </span>
      <ng-template #recordsList>
        <span class="uk-hidden-large">{{ appSettings.uiViewSettings.recordLabel }}s</span>
        <span class="uk-visible-large">Scanned {{ appSettings.uiViewSettings.recordLabel }}s</span>
      </ng-template>
    </button>

    <button *ngIf="appSettings.uiViewSettings.farmAlertWorkflow" mat-button [routerLink]="['/new-users']">
      <ng-template isAuthorized [authorizedRoles]="[userRoles.admin, userRoles.fa_admin, userRoles.manager]">
        <mat-icon>people</mat-icon>
        <span> Users </span>
      </ng-template>
    </button>

    <button mat-button *ngIf="appSettings.uiViewSettings.cicWorkflow &&
        facility?.facilityOptions?.hasLab" [routerLink]="['/scanned_lab_records/list']" routerLinkActive="active">
      <mat-icon svgIcon="microscope" style="width: 20px; height: 20px; line-height: 18px"></mat-icon>
      Lab View
    </button>
    <button mat-button *ngIf="facility?.facilityOptions?.hasDispatch" routerLinkActive="active">
      Dispatch View
    </button>
  </nav>

  <span class="fill-remaining-space"></span>

  <!-- Dropdown Navigation-->
  <nav>
    <button mat-button [mat-menu-trigger-for]="menu">
      <span *ngIf="repository.pendingDownSum !== 0">
        {{ repository.pendingDownSum }}
        <i class="fa fa-caret-square-o-down"></i>
      </span>
      <span *ngIf="repository.unsyncedObjectsCount | async">
        {{ repository.unsyncedObjectsCount | async }}
        <i class="fa fa-caret-square-o-up"></i>
      </span>
      <span class="fa-stack fa-lg">
        <i class="fa fa-circle-o-notch fa-stack-2x" [class.fa-spin]="
            !(
              syncStatusEnum[repository.syncStatus] === 'error' ||
              syncStatusEnum[repository.syncStatus] === 'paused'
            )
          " [style.color]="(online | async) ? 'green' : 'red'"></i>
        <i *ngIf="syncStatusEnum[repository.syncStatus] === 'push'" class="fa fa-arrow-up fa-stack-1x fa-inverse"
          style="color: green"></i>
        <i *ngIf="syncStatusEnum[repository.syncStatus] === 'pull'" class="fa fa-arrow-down fa-stack-1x fa-inverse"
          style="color: green"></i>
        <i *ngIf="syncStatusEnum[repository.syncStatus] === 'paused'" class="fa fa-user fa-stack-1x fa-inverse"
          style="color: black"></i>
        <i *ngIf="syncStatusEnum[repository.syncStatus] === 'error'" class="fa fa-exclamation fa-stack-1x fa-inverse"
          style="color: red"></i>
      </span>
      <span class="uk-hidden-small">{{ authService.getUser().username }}</span>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/profile" routerLinkActive="active">
        <mat-icon>person</mat-icon> My Profile
      </button>
      <button mat-menu-item (click)="userLogout()">
        <mat-icon>lock_open</mat-icon>
        Logout
      </button>
      <hr />
      <div *ngIf="appTypeEnum[repository.getApp()] === 'adminApp'">
        <ng-template isAuthorized [authorizedRoles]="[userRoles.admin]">
          <button mat-menu-item routerLink="/facilities" routerLinkActive="active">
            Manage Facilities
          </button>
          <button mat-menu-item routerLink="/users" routerLinkActive="active">
            Manage Users
          </button>
          <button mat-menu-item routerLink="/settings" routerLinkActive="active">
            Manage Settings
          </button>
          <button mat-menu-item routerLink="/records-summary" routerLinkActive="active">
            Records Summary
          </button>
          <button mat-menu-item routerLink="/failed-records" routerLinkActive="active">
            Failed Records
          </button>
          <button mat-menu-item routerLink="/failed-records/single-record" routerLinkActive="active">
            Get Single Record
          </button>
          <hr />
        </ng-template>
      </div>
      <span mat-menu-item class="uk-text-small uk-text-muted">
        paperEMR v{{ appVersion }}
      </span>
    </mat-menu>
  </nav>
</mat-toolbar>