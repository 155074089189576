import { Pipe, PipeTransform } from '@angular/core';
import { CountryCodes } from '../models/patient';

@Pipe({ name: 'formatPhone' })
export class FormatPhone implements PipeTransform {
  /** function to remove spaces from phone numbers */
  static removePhoneSpaces(phoneNumber) {
    return phoneNumber.replace(/\s/g, '');
  }

  /** format phone number, remove + and 254 */
  static formatPhoneNumber(phoneNumber: string = '', countryCode: string = '254') {
    // remove the +
    if (phoneNumber.substr(0, 1) === '+') {
      phoneNumber = phoneNumber.slice(1);
    }

    // remove spaces
    phoneNumber = phoneNumber.replace(/\s/g, '');

    // remove tel:
    phoneNumber = phoneNumber.replace(/tel:/, '');

    // remove the country code from the phone number
    const countryCodes = CountryCodes.map(item => item.code);
    if (countryCodes.includes(phoneNumber.substr(0, 3))) {
      countryCode = phoneNumber.substr(0, 3);
      phoneNumber = phoneNumber.slice(3);
    }

    // remove 0 if full phone number like 0722123456 becomes 722123456
    if (phoneNumber.length > 9 && phoneNumber.substring(0, 1) === '0') {
      phoneNumber = phoneNumber.slice(1);
    }

    // remove o || O string from a number
    if (phoneNumber.substring(0, 1) === 'o' || phoneNumber.substring(0, 1) === 'O') {
      phoneNumber = phoneNumber.slice(1);
    }

    // add 0 to display phone if we have full number, 722123455 becomes 0722123455
    const shortPhone = phoneNumber.substr(0, 1) !== '0' ? '0' + phoneNumber : phoneNumber;

    return {
      phoneNumber: phoneNumber,
      countryCode: countryCode,
      longPhone: '+' + countryCode + phoneNumber,
      shortPhone: shortPhone
    };
  }

  /** get phone number in display format */
  static displayPhoneNumber(phoneNumber: string, countryCode: string = '254', format: string = 'long'): string {
    const formattedNumber = FormatPhone.formatPhoneNumber(phoneNumber, countryCode);
    switch (format) {
      case 'long':
        return formattedNumber.longPhone;
        break;
      case 'short':
        return formattedNumber.shortPhone;
        break;
    }
  }

  /** remove country code from number */
  static removeCountryCode(phoneNumber: string) {
    const countryCodePattern = /^(\+|00)[1-9]\d{0,2}/;
    const localCodePattern = /^07/;
    const startsWith7 = /^7/;

    if (countryCodePattern.test(phoneNumber)) {
      // Remove leading '+' sign or country code
      let sanitizedNumber = phoneNumber.replace(/^\+|\d{1,3}-?/, '');

      // Replace country code with '0'
      sanitizedNumber = sanitizedNumber.replace(/^\d{2,3}/, '0');

      // Remove any non-digit characters
      sanitizedNumber = sanitizedNumber.replace(/\D/g, '');
      return sanitizedNumber;
    } else if (localCodePattern.test(phoneNumber)) {
      // Phone number starts with "07"
      return phoneNumber;
    } else if (startsWith7.test(phoneNumber)) {
      //  Phone start with 7
      return phoneNumber.replace(startsWith7, "0$&");
    } else {
      // Phone number doesn't match any pattern
      return phoneNumber;
    }
  }

  /** use phone number formatter in pipe */
  transform(phoneNumber: string, countryCode: string = '254', format: string = 'long') {
    console.log('Phone number:', phoneNumber);
    if (!phoneNumber) { return ''; }
    return FormatPhone.displayPhoneNumber(phoneNumber, countryCode, format);
  }
}
