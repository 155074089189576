import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
//import { DetectionStatus } from './detect-border.service';
import { Point, PointExtra } from './jsqr/Point';
import { BitMatrix } from './jsqr/BitMatrix';
import { locate, computeDimension } from './jsqr/locator';
import { binarize } from './jsqr/binarizer';
import { extract } from './jsqr/extractor';
import * as fx from 'glfx-es6';

import * as persp from './perspective-transform.js';

export enum Quadrants { tr, tl, br, bl }

export enum DetectionStatus { success, error }
@Injectable()
export class DetectBorderServiceQR {
  // observable subject
  subject = new Subject<any>();

  // settings
  debugMode: Boolean = false; // keep track of whether we are in debugMode

  templateHeight: number;
  templateWidth: number;

  // canvases
  canvasQr: HTMLCanvasElement;
  canvasQrOutput: HTMLCanvasElement;
  canvasQrOutput2: HTMLCanvasElement;

  glCanvas; glContext; // canvas and context for testing webgl support

  canvasGl: fx.GlfxCanvas; // canvas for running webgl transofrmation
  tmpCanvasGl: HTMLCanvasElement; // canvas to replace with webgl canvas
  glTexture: any; // texture for performing umwarp
  _supportedPrecision: string = null;

  // store quadrant info for autocorrect QR
  quadrantPoints: Array<any> = [];
  range = 20; // acceptable difference from 90
  minDiff = 4; // minimum second difference
  minPatternSize = 5; // minimum size for patterns
  maxPatternSize = 20; // max size for patterns

  /**
    * notify listeners that with specified data
    */
  sendResult(status: DetectionStatus, correctedImageBlob: any = Blob, errorMessage: string = '', qrCode: QRCode) {
    this.subject.next({ status: status, correctedImageBlob: correctedImageBlob, errorMessage: errorMessage, qrCode: qrCode });
  }

  /**
   * function to detect the template border from image
   */
  detectQRBorderFromImage(scannedImage: any, templateWidth: number, templateHeight: number, debugMode: boolean = false) {
    this.debugMode = debugMode;
    this.templateHeight = templateHeight;
    this.templateWidth = templateWidth;
    this.canvasQr = <HTMLCanvasElement>document.getElementById('canvasQr');
    this.canvasQrOutput = <HTMLCanvasElement>document.getElementById('canvasQrOutput');
    this.canvasQrOutput2 = <HTMLCanvasElement>document.getElementById('canvasQrOutput2');

    // copy file image to input canvas
    const canvasQrContext: CanvasRenderingContext2D = this.canvasQr.getContext('2d', { willReadFrequently: true });
    this.canvasQr.height = scannedImage.height;
    this.canvasQr.width = scannedImage.width;
    canvasQrContext.drawImage(scannedImage, 0, 0);

    this.detectQRpatterns(canvasQrContext);
  }

  /**
   * function to detect the template border from video frame
   */
  detectQRBorderFromVideo(video: any, templateWidth: number, templateHeight: number, debugMode: boolean = false) {
    this.debugMode = debugMode;
    this.templateHeight = templateHeight;
    this.templateWidth = templateWidth;
    this.canvasQr = <HTMLCanvasElement>document.getElementById('canvasQr');

    // copy file image to input canvas
    const canvasQrContext: CanvasRenderingContext2D = this.canvasQr.getContext('2d', { willReadFrequently: true });
    this.canvasQr.height = video.videoHeight;
    this.canvasQr.width = video.videoWidth;
    canvasQrContext.drawImage(video, 0, 0, this.canvasQr.width, this.canvasQr.height);

    this.detectQRpatterns(canvasQrContext);
  }

  /** function to detect the template border using qr finder patterns */
  async detectQRpatterns(canvasQrContext: CanvasRenderingContext2D) {
    // clear canvases
    const myNode = document.getElementById('qrDivs');
    while (myNode.firstChild) { myNode.removeChild(myNode.firstChild); }

    // get data and perform qr detection step
    const imageData = canvasQrContext.getImageData(0, 0, this.canvasQr.width, this.canvasQr.height);
    let code: QRCode = this.jsQR(imageData.data, imageData.width, imageData.height);

    if (!code) {
      // error: no code detected
      if (this.debugMode) { console.log('Error: Unable to detect QR borders'); }
      this.drawQROutput(code, 'Failed QR Output');
      this.sendResult(DetectionStatus.error, null, 'QR Border detection failed', code);
      return;
    }

    // check code output
    code = this.correctQr(code);
    if (code.failed) {
      // error: qr correction failed
      if (this.debugMode) { console.log('Error: Invalid QR corners detected, unable to repair'); }
      this.sendResult(DetectionStatus.error, null, 'Error: Invalid QR corners detected, unable to repair', code);
      return;
    }

    // extract corners
    code = this.updateCorners(code);
    if (code.failed) {
      if (this.debugMode) { console.log('Problem extracting update QR corners, reason unknown, please retry'); }
      this.sendResult(DetectionStatus.error, null, 'Problem extracting update QR corners, reason unknown, please retry', code);
      return;
    }

    // calculate location corners to unwarp
    const corners = [
      code.location.topLeftCorner.x, code.location.topLeftCorner.y,
      code.location.topRightCorner.x, code.location.topRightCorner.y,
      code.location.bottomRightCorner.x, code.location.bottomRightCorner.y,
      code.location.bottomLeftCorner.x, code.location.bottomLeftCorner.y
    ];

    // unwarp skewed image using provided corner points
    console.log('ManualEditComponent: unwarping and passing back result');
    this.unWarp(
      this.canvasQr.getContext('2d', { willReadFrequently: true }),
      corners,
      this.canvasQr.width,
      this.canvasQr.height)
      .then(correctedImageBlob => {
        this.sendResult(DetectionStatus.success, correctedImageBlob, 'Successfully extracted and corrected QR image', code);
      }).catch(error => console.log('Error unwarping QR output', error));

    // draw output
    if (this.debugMode) {
      console.log('draw QR output data');
      this.drawQROutput(code, 'Successful QR Output');
    }

  }

  /** check if finders detected are correct, attempt to fix if not */
  correctQr(code: QRCode): QRCode {
    if (this.debugMode) { console.log('Check if points returned by QR are valid and try to fix them if not'); }

    // check if finders are correct
    if (this.areDetectedFindersValid(code)) {
      if (this.debugMode) { console.log('Points returned by QR are valid'); }
      return code;
    }

    // sort finder points by size
    if (this.debugMode) { console.log('Sorting finder patterns by QR size'); }
    code.location.finderPatterns = code.location.finderPatterns.sort((a, b) => b.size - a.size); // unnecessary sort?

    // invalid finder patterns
    if (this.debugMode) { console.log('Invalid Points returned by QR, attempting to fix'); }
    this.drawQROutput(code, 'Initial QR failed output');

    // divide into quadrants and attempt to find best points in each
    const halfW = this.canvasQr.width / 2; const halfH = this.canvasQr.height / 2;
    this.quadrantPoints = [
      this.getPointsInRectangle(code.location.finderPatterns, { x: 0, y: 0 }, halfW, halfH),
      this.getPointsInRectangle(code.location.finderPatterns, { x: halfW, y: 0 }, halfW, halfH),
      this.getPointsInRectangle(code.location.finderPatterns, { x: halfW, y: halfH }, halfW, halfH),
      this.getPointsInRectangle(code.location.finderPatterns, { x: 0, y: halfH }, halfW, halfH)
    ];
    if (this.debugMode) {
      console.log('# of QR points in each quadrant',
        this.quadrantPoints[0].length, this.quadrantPoints[1].length, this.quadrantPoints[2].length, this.quadrantPoints[3].length);
    }

    // check we have at least one point in each quadrant
    for (const pointsArray of this.quadrantPoints) {
      if (pointsArray.length < 1) {
        console.log('unable to repair QR points, one or more quadrants has no points');
        code.failed = true;
        return code;
      }
    }

    // find valid points
    [code.location.topLeftFinderPattern,
    code.location.topRightFinderPattern,
    code.location.bottomRightFinderPattern,
    code.location.bottomLeftFinderPattern] = this.findValidPoints([0, 0, 0, 0], code);

    // check if weve been given valid points
    if (
      !code.location.topLeftFinderPattern ||
      !code.location.topRightFinderPattern ||
      !code.location.bottomRightFinderPattern ||
      !code.location.bottomLeftFinderPattern) {
      // failed to fix, draw output and return null
      if (this.debugMode) {
        console.log('unable to repair QR points, run out of points');
        this.drawQROutput(code, 'QR failed output');
      }
      code.failed = true;
      return code;
    }

    // found valid points
    if (this.areDetectedFindersValid(code)) {
      if (this.debugMode) { console.log('Updated points returned by QR are valid'); }
      return code;
    }

    code.failed = true;
    return code;
  }

  /** run different checks to see if we have a valid tl, tr, br, bl */
  areDetectedFindersValid(code): boolean {
    if (!this.validCornerDiffs(code)) {
      if (this.debugMode) { console.log('Invalid QR Points: Large differences detected between angles'); }
      return false;
    } else if (!this.areCornersInDifferentQuads(code)) {
      if (this.debugMode) { console.log('Invalid QR Points: More than one corner in same quadrant'); }
      return false;
    } else if (!this.areCornersOfValidSize(code)) {
      if (this.debugMode) { console.log('Invalid QR Points: Invalid size for one or more detected corners'); }
      return false;
    }

    // else if (!this.areFinderAnglesValid(code)) {
    //   if (this.debugMode) { console.log('Invalid QR Points: Invalid angles found (not near 90deg)'); }
    //   return false;
    // } else if (!this.areCornersTooClose(code)) {
    //   if (this.debugMode) { console.log('Invalid QR Points: Corners too close'); }
    //   return false;
    // }
    if (this.debugMode) { console.log('Points returned by QR are valid'); }
    return true;
  }

  /** make sure differences between corner sums is smooth */
  validCornerDiffs(code) {

    const [tlAngle, trAngle, brAngle, blAngle] = [
      this.getAngle(
        code.location.topRightFinderPattern,
        code.location.topLeftFinderPattern,
        code.location.bottomLeftFinderPattern),
      this.getAngle(
        code.location.topLeftFinderPattern,
        code.location.topRightFinderPattern,
        code.location.bottomRightFinderPattern),
      this.getAngle(
        code.location.bottomLeftFinderPattern,
        code.location.bottomRightFinderPattern,
        code.location.topRightFinderPattern),
      this.getAngle(
        code.location.topLeftFinderPattern,
        code.location.bottomLeftFinderPattern,
        code.location.bottomRightFinderPattern)];

    console.log('QR angles detected', tlAngle, trAngle, brAngle, blAngle);

    const firstAngleDiffs: Array<number> = [tlAngle, trAngle, brAngle, blAngle]
      .map(n => 90 - n)
      .map(n => Math.abs(n))
      .sort((a, b) => a - b);
    console.log('QR angles first differences', firstAngleDiffs);

    const secondAngleDiff: Array<number> = firstAngleDiffs
      .map((n, i) => {
        if (i < firstAngleDiffs.length - 1) {
          return n - firstAngleDiffs[i + 1];
        }
      })
      .map(n => Math.abs(n));
    console.log('QR angles second differences', secondAngleDiff);

    secondAngleDiff.pop(); // remove last NaN element
    const max = Math.max(...secondAngleDiff);
    if (this.debugMode) { console.log('QR max of second differences is', max); }
    const isValid = max < this.minDiff;
    if (isValid) {
      console.log('success: QR biggest second difference is less than cutoff', secondAngleDiff[0]);
      this.drawQROutput(code, 'Successful QR Output', firstAngleDiffs, secondAngleDiff);
    } else {
      console.log('fail: QR biggest second difference is larger than cutoff', secondAngleDiff[0]);
      this.drawQROutput(code, 'Failed QR Output', firstAngleDiffs, secondAngleDiff);
    }

    return isValid;
  }

  /** make sure none of the points are too close to each other */
  areCornersTooClose(code) {
    const [tl, tr, br, bl] = [code.location.topLeftFinderPattern,
    code.location.topRightFinderPattern,
    code.location.bottomRightFinderPattern,
    code.location.bottomLeftFinderPattern];

    return !this.pointsAdjacent(tl, tr) &&
      !this.pointsAdjacent(tl, br) &&
      !this.pointsAdjacent(tl, bl) &&
      !this.pointsAdjacent(tr, br) &&
      !this.pointsAdjacent(tr, bl) &&
      !this.pointsAdjacent(br, bl);
  }

  /** make sure all four points are in different quads */
  areCornersInDifferentQuads(code) {
    const [tl, tr, br, bl] = [
      this.whichQuadrant(code.location.topLeftFinderPattern),
      this.whichQuadrant(code.location.topRightFinderPattern),
      this.whichQuadrant(code.location.bottomRightFinderPattern),
      this.whichQuadrant(code.location.bottomLeftFinderPattern)];
    console.log('QR: areCornersInDifferentQuads?', tl, tr, br, bl);

    return !(tl === tr || tl === br || tl === bl || tr === br || tr === bl || br === bl);
  }

  /** check that all four points are inside min max range */
  areCornersOfValidSize(code) {
    const corners = [code.location.topLeftFinderPattern,
    code.location.topRightFinderPattern,
    code.location.bottomRightFinderPattern,
    code.location.bottomLeftFinderPattern];
    corners.forEach(finder => console.log('qr: corner size ', finder.size));
    corners.filter(finder => this.minPatternSize < finder.size && finder.size < this.maxPatternSize);
    console.log('QR: ' + corners.length + ' are within min max size range');
    return corners.length > 3;
  }

  /** find out which quadrant a given point is in */
  whichQuadrant(p: Point): Quadrants {
    const halfW = this.canvasQr.width / 2; const halfH = this.canvasQr.height / 2;
    // tl?
    if (this.isInRectangle(p, { x: 0, y: 0 }, halfW, halfH)) {
      return Quadrants.tl;
      // tr?
    } else if (this.isInRectangle(p, { x: halfW, y: 0 }, halfW, halfH)) {
      return Quadrants.tr;
    }
    // br?
    if (this.isInRectangle(p, { x: halfW, y: halfH }, halfW, halfH)) {
      return Quadrants.br;
      // bl?
    } else if (this.isInRectangle(p, { x: 0, y: halfH }, halfW, halfH)) {
      return Quadrants.bl;
    }

    return null;
  }

  /** check if two points are close to each other */
  pointsAdjacent(p1: Point, p2: Point) {
    const minDistance = 12;
    const adjacent = (Math.abs(p1.x - p2.x) + Math.abs(p1.y - p2.y)) < minDistance;
    console.log('QR points p1, p2, distance, adjacent?', { x: p1.x, y: p1.y }, { x: p2.x, y: p2.y }, adjacent);
    return adjacent;
  }

  /** given points in quadrant, return first points which pass validity test */
  findValidPoints(finderIndices, code) {
    const attempt = finderIndices[0] + finderIndices[1] + finderIndices[2] + finderIndices[3];

    if (this.debugMode) {
      console.log('QR Attempt: ' + attempt + ' findValidPoints :',
        'indexTL:', finderIndices[0], 'indexTR:', finderIndices[1], 'indexBR:', finderIndices[2], 'indexBL:', finderIndices[3]);
    }

    // get selected finders from quadrant
    const finders: Array<Point> = [
      this.quadrantPoints[0][finderIndices[0]],
      this.quadrantPoints[1][finderIndices[1]],
      this.quadrantPoints[2][finderIndices[2]],
      this.quadrantPoints[3][finderIndices[3]]
    ];

    // calculate angles for each corner finder
    const finderAngles: Array<number> = [
      this.getAngle(finders[3], finders[0], finders[1]),
      this.getAngle(finders[0], finders[1], finders[2]),
      this.getAngle(finders[1], finders[2], finders[3]),
      this.getAngle(finders[2], finders[3], finders[0])
    ];
    if (this.debugMode) { console.log('QR angles', finderAngles); }

    // calculate diff from 90 for each corner angle
    const firstAngleDiffs: Array<number> = finderAngles
      .map(n => 90 - n)
      .map(n => Math.abs(n))
      .sort((a, b) => a - b);
    if (this.debugMode) { console.log('QR angles first differences', firstAngleDiffs); }

    // find second diff for each angle
    const secondAngleDiffs: Array<number> = firstAngleDiffs
      .map((n, i) => {

        if (i < firstAngleDiffs.length - 1) {
          return Math.abs(n - firstAngleDiffs[i + 1]);
        }

      });
    if (this.debugMode) { console.log('QR Attempt: ' + attempt + ', angles second differences', secondAngleDiffs); }

    // check that each angle is less than minDiff
    secondAngleDiffs.pop(); // remove last NaN element
    const max = Math.max(...secondAngleDiffs);
    if (this.debugMode) { console.log('QR max of second differences is', max); }
    const validRect = max < this.minDiff;
    if (!validRect) {
      if (this.debugMode) {
        console.log('QR Attempt ' + attempt + ' failed, largest second difference larger than mindiff ' + this.minDiff);
      }
    }

    // draw output
    [code.location.topLeftFinderPattern,
    code.location.topRightFinderPattern,
    code.location.bottomRightFinderPattern,
    code.location.bottomLeftFinderPattern] = [finders[0], finders[1], finders[2], finders[3]];

    // if points fail validity check, check for points in quadrant with biggest angle
    if (!validRect) {
      // which quadrant has largest angle
      const lgstAnglQdntIdx = finderAngles.indexOf(Math.max(...finderAngles));
      ++finderIndices[lgstAnglQdntIdx];

      if (this.debugMode) {
        console.log('QR: quadrant with largest angle index is ' + lgstAnglQdntIdx + ', retrying next');
        this.drawQROutput(code, 'QR Attempt ' + attempt + ' failed', firstAngleDiffs, secondAngleDiffs);
      }

      // check that quadrant has more points
      if (finderIndices[lgstAnglQdntIdx] >= this.quadrantPoints[lgstAnglQdntIdx].length) {
        if (this.debugMode) {
          console.log('QR: Run out of points in quadrant', lgstAnglQdntIdx, finderIndices[lgstAnglQdntIdx], 'out of range');
        }
        finders[lgstAnglQdntIdx] = null;
        return finders;
      }

      // // if this is first run for this quadrant, re sort by size
      // if (finderIndices[lgstAnglQdntIdx] === 1) {
      //   // resort by size
      //   this.quadrantPoints[lgstAnglQdntIdx].sort((a, b) => b.size - a.size);
      //   // add dummy top point
      //   this.quadrantPoints[lgstAnglQdntIdx].unshift({ x: 0, y: 0, score: 10000, size: 1 });
      // }

      // rerun find valid points again
      return this.findValidPoints(finderIndices, code);
    }

    if (this.debugMode) {
      this.drawQROutput(code, 'QR Attempt ' + attempt + ' success', firstAngleDiffs, secondAngleDiffs);
      console.log('QR Attempt: ' + attempt + ' success :',
        'indexTL:', finderIndices[0], 'indexTR:', finderIndices[1], 'indexBR:', finderIndices[2], 'indexBL:', finderIndices[3]);
    }
    return finders;
  }

  /** find best point in given area */
  getPointsInRectangle(finders: Array<any>, rectTL: Point, rectW: number, rectH: number): Array<any> {
    return finders
      .filter(finder => this.isInRectangle({ x: finder.x, y: finder.y }, rectTL, rectW, rectH))
      .filter(finder => this.minPatternSize < finder.size && finder.size < this.maxPatternSize)
      .sort((a, b) => a.score - b.score);
  }

  /** is a given point inside the rectangle defined by TL point, width & height */
  isInRectangle(point: Point, rectTL: Point, rectW: number, rectH: number) {
    const isit = rectTL.x <= point.x && point.x <= rectTL.x + rectW && rectTL.y <= point.y && point.y <= rectTL.y + rectH;
    return isit;
  }

  /** are given QR corners valid */
  areFinderAnglesValid(code: QRCode) {
    const [tlAngle, trAngle, brAngle, blAngle] = [
      this.getAngle(
        code.location.topRightFinderPattern,
        code.location.topLeftFinderPattern,
        code.location.bottomLeftFinderPattern),
      this.getAngle(
        code.location.topLeftFinderPattern,
        code.location.topRightFinderPattern,
        code.location.bottomRightFinderPattern),
      this.getAngle(
        code.location.bottomLeftFinderPattern,
        code.location.bottomRightFinderPattern,
        code.location.topRightFinderPattern),
      this.getAngle(
        code.location.topLeftFinderPattern,
        code.location.bottomLeftFinderPattern,
        code.location.bottomRightFinderPattern)];

    // this.calculateRange(tlAngle, trAngle, brAngle, blAngle);

    if (this.validAngle(tlAngle) && this.validAngle(trAngle) && this.validAngle(brAngle) && this.validAngle(blAngle)) {
      console.log('QR angles for initial scan are valid.');
      return true;
    } else {
      console.log('Error, invalid QR. Angle found out of range');
      return false;
    }

  }

  calculateRange(tlAngle, trAngle, brAngle, blAngle) {
    const angleDiffs = [];
    angleDiffs.push(
      Math.abs(90 - tlAngle),
      Math.abs(90 - trAngle),
      Math.abs(90 - brAngle),
      Math.abs(90 - blAngle));
    this.range = Math.max(5, (angleDiffs.sort((a, b) => a - b)[0]) * 3);

    console.log('QR: all angle diffs', angleDiffs, 'range is now', this.range);
  }

  /** is an angle withing acceptable tolerance range of the ideal 90 degrees */
  validAngle(angle) {
    const ans = angle >= 90 - this.range && angle <= 90 + this.range;
    console.log('QR: Is', angle, 'valid? less than', 90 + this.range, 'greater than', 90 - this.range, 'answer:', ans);
    return ans;
  }

  /** is an angle less than acceptable tolerance from 90 */
  validObtuse(angle) {
    const ans = angle <= 90 + this.range;
    console.log('QR: Is', angle, 'valid? less than', 90 + this.range, 'answer:', ans);
    return ans;
  }

  /** unwarp the canvas points provided */
  unWarp(fromCanvasContext: CanvasRenderingContext2D, corners: Array<number>, toWidth: number, toHeight: number) {
    return new Promise((resolve, reject) => {

      const unwarpStartTime = performance.now(); // time the scanning process

      const fromCanvas: HTMLCanvasElement = fromCanvasContext.canvas;

      if (this.debugMode) {
        console.log('QR: unwarping QR with from canvas', fromCanvas.id,
          'w:', fromCanvas.width, 'h:', fromCanvas.height, 'to w:', toWidth, 'h:', toHeight);
      }

      // get ref to gl div, empty it
      const constGlDiv = document.getElementById('glDiv');
      while (constGlDiv.firstChild) { constGlDiv.removeChild(constGlDiv.firstChild); }

      // draw input to temp canvas
      const canvasTmp: HTMLCanvasElement = document.createElement('canvas');
      canvasTmp.setAttribute('id', 'canvasTmp');
      constGlDiv.appendChild(document.createElement('H5').appendChild(document.createTextNode('canvasTmp')));
      constGlDiv.appendChild(document.createElement('br'));
      constGlDiv.appendChild(canvasTmp);
      canvasTmp.height = fromCanvas.height;
      canvasTmp.width = fromCanvas.width;
      const contextTmp: CanvasRenderingContext2D = canvasTmp.getContext('2d', { willReadFrequently: true });
      contextTmp.drawImage(fromCanvasContext.canvas, 0, 0, fromCanvas.width, fromCanvas.height);

      // draw polygon to be extracted
      if (this.debugMode) {
        contextTmp.strokeStyle = 'green';
        contextTmp.lineWidth = 1;
        contextTmp.beginPath();
        contextTmp.moveTo(corners[0], corners[1]);
        for (let item = 2; item < corners.length - 1; item += 2) { contextTmp.lineTo(corners[item], corners[item + 1]); }
        contextTmp.closePath();
        contextTmp.stroke();
      }

      // transform coordinates for before and after
      const before = corners;
      const after = [
        0, 0,
        toWidth, 0,
        toWidth, toHeight,
        0, toHeight
      ];

      // copy to new canvas scaled to desired output size
      let outputCanvas: HTMLCanvasElement = document.createElement('canvas');
      outputCanvas.setAttribute('id', 'outputCanvas');
      outputCanvas.height = toHeight;
      outputCanvas.width = toWidth;
      constGlDiv.appendChild(document.createElement('H5').appendChild(document.createTextNode('outputCanvas')));
      constGlDiv.appendChild(document.createElement('br'));
      constGlDiv.appendChild(outputCanvas);
      let outputCtx: CanvasRenderingContext2D = outputCanvas.getContext('2d', { willReadFrequently: true });

      if (this.precisionSupported() === 'highp') {
        // highp phone, use GLFX to transform

        // add canvasGl to glDiv
        if (!this.tmpCanvasGl) {
          this.tmpCanvasGl = document.createElement('canvas');
        }
        this.tmpCanvasGl.setAttribute('id', 'canvasGl');
        this.tmpCanvasGl.height = fromCanvas.height;
        this.tmpCanvasGl.width = fromCanvas.width;
        constGlDiv.appendChild(document.createElement('H5').appendChild(document.createTextNode('canvasGl')));
        constGlDiv.appendChild(document.createElement('br'));
        constGlDiv.appendChild(this.tmpCanvasGl);

        // see docs http://evanw.github.io/glfx.js/docs/#perspective
        this.canvasGl = fx.canvas().replace(document.getElementById('canvasGl'));
        this.glTexture = this.canvasGl.texture(canvasTmp);
        this.canvasGl.draw(this.glTexture).update();
        this.canvasGl.perspective(before, after).update();

        outputCtx.drawImage(this.canvasGl, 0, 0, toWidth, toHeight);
        
        // clean up
        this.glTexture.destroy();
        this.glTexture = null;
        this.canvasGl = null;
        this.tmpCanvasGl = null;

        if (this.debugMode) { console.log('QR: image transformed using glfx'); }

      } else {
        // non highp phone, transform using matrix

        const transform = persp(before, after);
        let t = transform.coeffs;
        t = [t[0], t[3], 0, t[6],
        t[1], t[4], 0, t[7],
          0, 0, 1, 0,
        t[2], t[5], 0, t[8]];
        if (this.debugMode) { console.log('QR: calculated transformation matrix', t); }

        // create two-dimensional array for storing the source data
        const srcData = contextTmp.getImageData(0, 0, canvasTmp.width, canvasTmp.height);

        const srcPixelData = new Array(srcData.width);
        for (let x = 0; x < srcData.width; ++x) {
          srcPixelData[x] = new Array(srcData.height);
        }

        // filling the source array
        let h = 0;
        for (let y = 0; y < srcData.height; ++y) {
          for (let x = 0; x < srcData.width; ++x) {
            srcPixelData[x][y] = {
              r: srcData.data[h++]
              , g: srcData.data[h++]
              , b: srcData.data[h++]
              , a: srcData.data[h++]
            };
          }
        }
        // append width and height for later use
        srcPixelData[srcPixelData.length] = srcData.width;
        srcPixelData[srcPixelData.length] = srcData.height;

        // create output pixel data
        const destData = outputCtx.createImageData(toWidth, toHeight);

        // write the data back to the imagedata array
        if (this.debugMode) {
          console.log('QR: calculating transformed image');
        }
        let i = 0;
        for (let y = 0; y < toHeight; ++y) {
          for (let x = 0; x < toWidth; ++x) {
            const destCoords = transform.transformInverse(x, y); // returns res[0], res[1]
            const rgba = this.interpolateNearestNeighbor({ x: destCoords[0], y: destCoords[1] }, srcPixelData);
            destData.data[i++] = rgba.r;
            destData.data[i++] = rgba.g;
            destData.data[i++] = rgba.b;
            destData.data[i++] = rgba.a;
          }
        }

        // QR: done calculating transforms - display
        outputCtx.putImageData(destData, 0, 0);

        if (this.debugMode) { console.log('QR: image transformed using transformation matrix'); }

      }

      // success, return corrected unwarped image
      outputCanvas.toBlob((correctedImageBlob) => {

        if (this.debugMode) {
          const unwarpEndTime = performance.now();
          console.log('QR: unwarp process took:', unwarpEndTime - unwarpStartTime, 'milliseconds');
          console.log('completed unwarping using QR locations, returning corrected image size w:',
            outputCanvas.width, 'h:', outputCanvas.height);
        }
        outputCanvas = null;
        outputCtx = null;
        resolve(correctedImageBlob);
      }, 'image/jpeg', 0.95);

    });

  }

  interpolateNearestNeighbor(srcCoord, srcPixelData) {
    let x0, y0;
    const w = srcPixelData[srcPixelData.length - 2], h = srcPixelData[srcPixelData.length - 1];

    // set the dest pixel to transparent black if it is outside the source area
    if (srcCoord.x < 0 || srcCoord.x > w - 1 || srcCoord.y < 0 || srcCoord.y > h - 1) {
      return { r: 0, g: 0, b: 0, a: 0 };
    }

    x0 = Math.round(srcCoord.x);
    y0 = Math.round(srcCoord.y);

    return srcPixelData[x0][y0];
  }

  /** function to draw output of QR function to canvas */
  drawQROutput(code: QRCode, title: string = 'QR Output', firstAngleDiffs = null, secondAngleDiffs = null) {

    const constQrDiv = document.getElementById('qrDivs');
    const h5 = document.createElement('H5');
    const t = document.createTextNode(title);
    h5.appendChild(t);
    constQrDiv.appendChild(h5);

    const canvasQRdraw: HTMLCanvasElement = document.createElement('canvas');
    const height = this.canvasQr.height; const width = this.canvasQr.width;
    canvasQRdraw.height = height;
    canvasQRdraw.width = width;
    constQrDiv.appendChild(canvasQRdraw);
    const drawCtx: CanvasRenderingContext2D = canvasQRdraw.getContext('2d', { willReadFrequently: true });
    const canvasQrContext: CanvasRenderingContext2D = this.canvasQr.getContext('2d', { willReadFrequently: true });
    drawCtx.drawImage(canvasQrContext.canvas, 0, 0);

    console.log('QR: drawing midlines');
    this.drawLine(drawCtx, { x: width / 2, y: 0 }, { x: width / 2, y: height }, 'yellow'); // vertical
    this.drawLine(drawCtx, { x: 0, y: height / 2 }, { x: width, y: height / 2 }, 'yellow'); // vertical

    if (!code) {
      console.log('Unable to draw QR location output, no QR detected');
      return;
    }

    if (!code.location) {
      console.log('Unable to draw QR location output, no QR locations data provided');
      return;
    }

    // display module size
    const span = document.createElement('span');
    const t2 = document.createTextNode('Module size: ' + code.location.moduleSize);
    span.appendChild(t2);
    constQrDiv.appendChild(t2);

    console.log('QR: drawing lines');
    if (code.location.topLeftCorner && code.location.topRightCorner) {
      this.drawLine(drawCtx, code.location.topLeftCorner, code.location.topRightCorner, 'purple');
    }
    if (code.location.topRightCorner && code.location.bottomRightCorner) {
      this.drawLine(drawCtx, code.location.topRightCorner, code.location.bottomRightCorner, 'purple');
    }
    if (code.location.bottomRightCorner && code.location.bottomLeftCorner) {
      this.drawLine(drawCtx, code.location.bottomRightCorner, code.location.bottomLeftCorner, 'purple');
    }
    if (code.location.bottomLeftCorner && code.location.topLeftCorner) {
      this.drawLine(drawCtx, code.location.bottomLeftCorner, code.location.topLeftCorner, 'purple');
    }
    if (code.location.topLeftFinderPattern && code.location.topRightFinderPattern) {
      this.drawLine(drawCtx, code.location.topLeftFinderPattern, code.location.topRightFinderPattern, 'orange');
    }
    if (code.location.topRightFinderPattern && code.location.bottomRightFinderPattern) {
      this.drawLine(drawCtx, code.location.topRightFinderPattern, code.location.bottomRightFinderPattern, 'orange');
    }
    if (code.location.bottomRightFinderPattern && code.location.bottomLeftFinderPattern) {
      this.drawLine(drawCtx, code.location.bottomRightFinderPattern, code.location.bottomLeftFinderPattern, 'orange');
    }
    if (code.location.bottomLeftFinderPattern && code.location.topLeftFinderPattern) {
      this.drawLine(drawCtx, code.location.bottomLeftFinderPattern, code.location.topLeftFinderPattern, 'orange');
    }

    console.log('QR: drawing points');
    if (code.location.topRightFinderPattern) { this.drawPoint(drawCtx, code.location.topRightFinderPattern, '#ff2626'); }
    if (code.location.topLeftFinderPattern) { this.drawPoint(drawCtx, code.location.topLeftFinderPattern, '#ff2626'); }
    if (code.location.bottomLeftFinderPattern) { this.drawPoint(drawCtx, code.location.bottomLeftFinderPattern, '#ff2626'); }
    if (code.location.bottomRightFinderPattern) { this.drawPoint(drawCtx, code.location.bottomRightFinderPattern, '#2626ff'); }

    console.log('QR: drawing labels/angles');
    drawCtx.fillStyle = '#26ff26';
    drawCtx.font = 'bold 20px sans-serif';
    if (code.location.topLeftFinderPattern) {
      drawCtx.fillText('TL', code.location.topLeftFinderPattern.x, code.location.topLeftFinderPattern.y);
    }
    if (code.location.topRightFinderPattern) {
      drawCtx.fillText('TR', code.location.topRightFinderPattern.x, code.location.topRightFinderPattern.y);
    }
    if (code.location.bottomRightFinderPattern) {
      drawCtx.fillText('BR', code.location.bottomRightFinderPattern.x, code.location.bottomRightFinderPattern.y);
    }
    if (code.location.bottomLeftFinderPattern) {
      drawCtx.fillText('BL', code.location.bottomLeftFinderPattern.x, code.location.bottomLeftFinderPattern.y);
    }

    if (code.location.topRightFinderPattern && code.location.topLeftFinderPattern && code.location.bottomLeftFinderPattern) {
      drawCtx.font = 'bold 12px sans-serif';
      drawCtx.fillStyle = 'black';
      drawCtx.fillText('TL--- '
        + this.getAngleString(
          code.location.topRightFinderPattern,
          code.location.topLeftFinderPattern,
          code.location.bottomLeftFinderPattern),
        10, 15);
      drawCtx.font = 'bold 9px sans-serif';
      drawCtx.strokeStyle = '#0099ff'; // blue size
      drawCtx.strokeText(Math.round(code.location.topLeftFinderPattern.size).toString(), 25, 10);
      drawCtx.fillText(Math.round(code.location.topLeftFinderPattern.size).toString(), 25, 10);
      drawCtx.strokeStyle = '#cc99ff'; // purple score
      drawCtx.strokeText('' + Math.round(code.location.topLeftFinderPattern.score), 25, 20);
      drawCtx.fillText('' + Math.round(code.location.topLeftFinderPattern.score), 25, 20);
    }
    if (code.location.topLeftFinderPattern && code.location.topRightFinderPattern && code.location.bottomRightFinderPattern) {
      drawCtx.font = 'bold 12px sans-serif';
      drawCtx.fillText('TR--- '
        + this.getAngleString(
          code.location.topLeftFinderPattern,
          code.location.topRightFinderPattern,
          code.location.bottomRightFinderPattern),
        80, 15);
      drawCtx.font = 'bold 9px sans-serif';
      drawCtx.strokeStyle = '#0099ff'; // blue size
      drawCtx.strokeText(Math.round(code.location.topRightFinderPattern.size).toString(), 95, 10);
      drawCtx.fillText(Math.round(code.location.topRightFinderPattern.size).toString(), 95, 10);
      drawCtx.strokeStyle = '#cc99ff'; // purple score
      drawCtx.strokeText('' + Math.round(code.location.topRightFinderPattern.score), 95, 20);
      drawCtx.fillText('' + Math.round(code.location.topRightFinderPattern.score), 95, 20);
    }
    if (code.location.bottomLeftFinderPattern && code.location.bottomRightFinderPattern && code.location.topRightFinderPattern) {
      drawCtx.font = 'bold 12px sans-serif';
      drawCtx.fillText('BR--- '
        + this.getAngleString(
          code.location.bottomLeftFinderPattern,
          code.location.bottomRightFinderPattern,
          code.location.topRightFinderPattern),
        160, 15);
      drawCtx.font = 'bold 9px sans-serif';
      drawCtx.strokeStyle = '#0099ff'; // blue size
      drawCtx.strokeText(Math.round(code.location.bottomRightFinderPattern.size).toString(), 175, 10);
      drawCtx.fillText(Math.round(code.location.bottomRightFinderPattern.size).toString(), 175, 10);
      drawCtx.strokeStyle = '#cc99ff'; // purple score
      drawCtx.strokeText('' + Math.round(code.location.bottomRightFinderPattern.score), 175, 20);
      drawCtx.fillText('' + Math.round(code.location.bottomRightFinderPattern.score), 175, 20);
    }
    if (code.location.topLeftFinderPattern && code.location.bottomLeftFinderPattern && code.location.bottomRightFinderPattern) {
      drawCtx.font = 'bold 12px sans-serif';
      drawCtx.fillText('BL--- '
        + this.getAngleString(
          code.location.topLeftFinderPattern,
          code.location.bottomLeftFinderPattern,
          code.location.bottomRightFinderPattern),
        240, 15);
      drawCtx.font = 'bold 9px sans-serif';
      drawCtx.strokeStyle = '#0099ff'; // blue size
      drawCtx.strokeText(Math.round(code.location.bottomLeftFinderPattern.size).toString(), 255, 10);
      drawCtx.fillText(Math.round(code.location.bottomLeftFinderPattern.size).toString(), 255, 10);
      drawCtx.strokeStyle = '#cc99ff'; // purple score
      drawCtx.strokeText('' + Math.round(code.location.bottomLeftFinderPattern.score), 255, 20);
      drawCtx.fillText('' + Math.round(code.location.bottomLeftFinderPattern.score), 255, 20);
    }

    console.log('QR: drawing first & second diffs');
    drawCtx.font = 'bold 12px sans-serif';
    if (firstAngleDiffs) {
      drawCtx.fillText('FD', 10, 30);
      firstAngleDiffs.forEach((diff, idx) => {
        drawCtx.fillText('|' + Math.round(diff), 30 + (idx * 19), 30);
      });
    }

    if (secondAngleDiffs) {
      drawCtx.fillText('SD', 110, 30);
      secondAngleDiffs.forEach((diff, idx) => {
        drawCtx.fillText('|' + Math.round(diff), 130 + (idx * 19), 30);
      });
    }

    // alignment pattern
    if (code.location.bottomRightAlignmentPattern) {
      this.drawPoint(drawCtx, code.location.bottomRightAlignmentPattern, '#26ff26');
    }
    // drawCtx.fillText('BR_Align', code.location.bottomRightAlignmentPattern.x, code.location.bottomRightAlignmentPattern.y);

    console.log('QR: drawing ' + code.location.finderPatterns.length + ' finder patterns');
    drawCtx.fillStyle = 'black';
    // drawCtx.strokeStyle = 'white';
    drawCtx.font = 'bold 9px sans-serif';

    // draw all points
    code.location.finderPatterns.forEach(finderPattern => {
      this.drawPoint(drawCtx, finderPattern, this.getScoreColor(finderPattern.score));
      this.drawPointRectangle(drawCtx, finderPattern);
    });

    // label top 20 sizes ' '.repeat(Math.round(finderPattern.score).toString().length)
    drawCtx.strokeStyle = '#0099ff'; // blue size
    code.location.finderPatterns
      .slice()
      .sort((a, b) => b.size - a.size)
      .slice(0, 20).forEach((finderPattern, idx) => {
        drawCtx.strokeText(Math.round(finderPattern.size).toString(), finderPattern.x + 5, finderPattern.y);
        drawCtx.fillText(Math.round(finderPattern.size).toString(), finderPattern.x + 5, finderPattern.y);
      });
    // label top 20 scores
    drawCtx.strokeStyle = '#cc99ff'; // purple score
    code.location.finderPatterns
      .slice()
      .sort((a, b) => a.score - b.score)
      .slice(0, 20).forEach((finderPattern, idx) => {
        drawCtx.strokeText('' + Math.round(finderPattern.score), finderPattern.x + 5, finderPattern.y + 10);
        drawCtx.fillText('' + Math.round(finderPattern.score), finderPattern.x + 5, finderPattern.y + 10);
      });

    // key
    // drawCtx.fillStyle = 'black';
    // drawCtx.strokeStyle = 'white';
    // drawCtx.font = 'bold 12px sans-serif';
    // drawCtx.strokeText('Scr/Sze', 5, 15);
    // drawCtx.fillText('Scr/Sze', 5, 15);
    // code.location.finderPatterns.slice(0, 20).forEach((finderPattern, idx) => {
    //   drawCtx.strokeText(idx + ':' + Math.round(finderPattern.score) + ':' + Math.round(finderPattern.size), 5, (idx + 2) * 15);
    //   drawCtx.fillText(idx + ':' + Math.round(finderPattern.score) + ':' + Math.round(finderPattern.size), 5, (idx + 2) * 15);
    // });

    // 0 – red, 60 – yellow, 120 – green, 180 – turquoise, 240 – blue, 300 – pink, 360 – red
    const getColor = v => `hsl(${((1 - v) * 120)},100%,50%)`;

    // draw groups
    code.location.finderPatternGroups.forEach((group, idx) => {
      const idxColor = getColor(this.fixRange(idx, 0, 20, 1, 0));
      // console.log('qr group', group, 'color:', idxColor);
      group.points.forEach(finder => {
        this.drawPointBorder(drawCtx, finder, idxColor);
      });
    });

  }

  getScoreColor(score) {
    // new_value = ( (old_value - old_min) / (old_max - old_min) ) * (new_max - new_min) + new_min
    const max_score = 200;
    const fixed_score = Math.min(score, max_score);
    let val = ((fixed_score - 0) / (max_score - 0)) * (255 - 0) + 0;
    val = 255 - val;
    const color = 'rgb(' + val + ',0,0)';
    // console.log('score ' + score + ' is color ' + color);
    return color;
  }

  fixRange(old_value, old_min, old_max, new_max, new_min) {
    if (old_value > old_max) { console.log('Error value greater than max'); }
    return ((old_value - old_min) / (old_max - old_min)) * (new_max - new_min) + new_min;
  }

  /** draw given point onto canvas */
  drawPoint(canvasContext, position, color) {
    const pointSize = 3; // Change according to the size of the point.
    canvasContext.fillStyle = color;
    canvasContext.beginPath();
    canvasContext.arc(position.x, position.y, pointSize, 0, Math.PI * 2, true);
    canvasContext.fill();
  }

  /** draw rectangle around given point */
  drawPointRectangle(canvasContext: CanvasRenderingContext2D, finderPattern) {
    canvasContext.lineWidth = 2;
    canvasContext.strokeStyle = this.minPatternSize < finderPattern.size && finderPattern.size < this.maxPatternSize ? '#00ff99' : 'red';
    canvasContext.strokeRect(
      finderPattern.x - (finderPattern.size / 2),
      finderPattern.y - (finderPattern.size / 2),
      finderPattern.size,
      finderPattern.size);
  }

  drawPointBorder(canvasContext, position, color) {
    const pointSize = 3; // Change according to the size of the point.
    canvasContext.lineWidth = 2;
    canvasContext.strokeStyle = color;
    canvasContext.beginPath();
    canvasContext.arc(position.x, position.y, pointSize, 0, Math.PI * 2, true);
    canvasContext.stroke();
  }
  /** draw given line onto canvas */
  drawLine(canvasContext, begin, end, color) {
    canvasContext.beginPath();
    canvasContext.moveTo(begin.x, begin.y);
    canvasContext.lineTo(end.x, end.y);
    canvasContext.lineWidth = 4;
    canvasContext.strokeStyle = color;
    canvasContext.stroke();
  }

  /** function to get angle between three points, assumes middle point */
  getAngle(P2, P1, P3): number {
    let result: number = Math.atan2(P3.y - P1.y, P3.x - P1.x) -
      Math.atan2(P2.y - P1.y, P2.x - P1.x);
    result = result * (180 / Math.PI); // convert to degrees
    result = Math.abs(result); // remove negative sign
    result = result > 180 ? 360 - result : result; // only return inner angle
    return result;
  }

  /** function to get angle between three points, assumes middle point, with degrees symbol affixed */
  getAngleString(P2, P1, P3): string { return this.getAngle(P2, P1, P3).toFixed(2) + '°'; }

  /** run the function to get the new mapping for corners again */
  updateCorners(code: QRCode) {

    // recalc dimensions
    console.log('computeDimension',
      code.location.topLeftFinderPattern, code.location.topRightFinderPattern, code.location.bottomLeftFinderPattern, code.matrix);
    let dimension: number;
    let moduleSize: number;
    let topDimension: number;
    let sideDimension: number;
    try {
      ({ dimension, moduleSize, topDimension, sideDimension } =
        computeDimension(code.location.topLeftFinderPattern, code.location.topRightFinderPattern, code.location.bottomLeftFinderPattern,
          code.matrix));
      code.location.dimension = dimension;
      code.location.moduleSize = moduleSize;
      code.location.topDimension = topDimension;
      code.location.sideDimension = sideDimension;
    } catch (e) {
      if (this.debugMode) { console.log('Problem updating transform matrices, please retry'); }
      return null;
    }

    if (this.debugMode) { console.log('updating QR corners extraction'); }
    const location = {
      topRight: code.location.topRightFinderPattern,
      bottomLeft: code.location.bottomLeftFinderPattern,
      topLeft: code.location.topLeftFinderPattern,
      bottomRight: code.location.bottomRightFinderPattern,
      alignmentPattern: code.location.bottomRightAlignmentPattern,
      moduleSize: code.location.moduleSize,
      dimension: code.location.dimension,
      topDimension: code.location.topDimension,
      sideDimension: code.location.sideDimension,
      finderPatterns: code.location.finderPatterns,
      finderPatternGroups: code.location.finderPatternGroups
    };
    const extracted = extract(code.matrix, location);

    console.log('QR: extracted matrix data.length', extracted.matrix.length());

    if (extracted.matrix.length() === 0) {
      console.log('QR extraction invalid, reason unknown, please retry');
      return null;
    }

    extracted.matrix.toImage(this.canvasQrOutput2);

    code.location.topLeftCorner = extracted.mappingFunction(0, 0);
    code.location.topRightCorner = extracted.mappingFunction(location.topDimension, 0);
    code.location.bottomRightCorner = extracted.mappingFunction(location.topDimension, location.sideDimension);
    code.location.bottomLeftCorner = extracted.mappingFunction(0, location.sideDimension);

    console.log('updated QR extracted locations',
      code.location.topLeftCorner, code.location.topRightCorner, code.location.bottomRightCorner, code.location.bottomLeftCorner);

    return code;
  }

  /** function to run the QR code detection steps */
  jsQR(data: Uint8ClampedArray, width: number, height: number): QRCode | null {
    if (this.debugMode) { console.log('binarizing QR'); }
    const { binarized, inverted } = binarize(data, width, height, false);

    if (this.debugMode) { console.log('Locate QR finder patterns'); }
    const location = locate(binarized);

    if (!location) {
      if (this.debugMode) { console.log('QR location not found'); }
      return null;
    }

    // if (this.debugMode) { console.log('extracting QR stuff'); }
    // const extracted = extract(binarized, location);

    // const decoded = decode(extracted.matrix);
    // console.log('QR: extracted matrix data.length', extracted.matrix.length());
    // if (extracted.matrix.length() === 0) {
    //   console.log('QR extraction invalid, reason unknown, please retry');
    //   return null;
    // }
    // extracted.matrix.toImage(this.canvasQrOutput);

    return {
      // binaryData: decoded.bytes,
      // data: decoded.text,
      // chunks: decoded.chunks,
      failed: false,
      matrix: binarized,
      location: {
        // topLeftCorner: extracted.mappingFunction(0, 0),
        // topRightCorner: extracted.mappingFunction(location.topDimension, 0),
        // bottomRightCorner: extracted.mappingFunction(location.topDimension, location.sideDimension),
        // bottomLeftCorner: extracted.mappingFunction(0, location.sideDimension),
        topLeftCorner: null,
        topRightCorner: null,
        bottomRightCorner: null,
        bottomLeftCorner: null,

        topRightFinderPattern: location.topRight,
        topLeftFinderPattern: location.topLeft,
        bottomLeftFinderPattern: location.bottomLeft,
        bottomRightFinderPattern: location.bottomRight,

        bottomRightAlignmentPattern: location.alignmentPattern,

        dimension: location.dimension,
        topDimension: location.topDimension,
        sideDimension: location.sideDimension,
        moduleSize: location.moduleSize,

        finderPatterns: location.finderPatterns,
        finderPatternGroups: location.finderPatternGroups
      },
    };
  }

  /** check which webgl precision is supported  */
  precisionSupported() {
    // if we have already calculated dont recaulculate
    if (this._supportedPrecision) { return this._supportedPrecision; }
   
    // else check supported precision
    if (!this.glCanvas) {
      this.glCanvas = document.createElement('canvas');
    }
    if (!this.glContext){
      this.glContext = this.glCanvas.getContext('webgl') || this.glCanvas.getContext('experimental-webgl');
    }
    let supportedPrecision;
    if (this.glContext) {
      const precisions = ['highp', 'mediump', 'lowp'];
      for (let i = 0; i < precisions.length; i++) {
        if (this.testPrecision(this.glContext, precisions[i])) {
          supportedPrecision = precisions[i];
          break;
        }
      }
    }
    console.log('Supported precision is ' + supportedPrecision);
    this._supportedPrecision = supportedPrecision;
    return supportedPrecision;
  }

  /** test individual precision  */
  testPrecision(gl, precision) {
    const fragmentSource = 'precision ' + precision + ' float;\nvoid main(){}';
    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragmentShader, fragmentSource);
    gl.compileShader(fragmentShader);
    return !gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS) ? false : true;
  }

}

export interface QRCode {
  failed: boolean;
  // binaryData: number[];
  // data: string;
  // chunks: Chunks;
  matrix: BitMatrix;
  location: {
    topRightCorner: Point;
    topLeftCorner: Point;
    bottomRightCorner: Point;
    bottomLeftCorner: Point;

    topRightFinderPattern: PointExtra;
    topLeftFinderPattern: PointExtra;
    bottomLeftFinderPattern: PointExtra;
    bottomRightFinderPattern: PointExtra;

    bottomRightAlignmentPattern?: Point;

    dimension: number;
    topDimension: number;
    sideDimension: number;
    moduleSize: number;

    finderPatterns: any;
    finderPatternGroups: any;
  };
}
