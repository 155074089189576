import { PouchObject } from './pouch-object';

/**
 * Keep track of templates that belong to a series and define their order
 */
export class TemplateSeries implements PouchObject {
    public static type: string = 'series';
    public static fields = [
        '_id', '_rev', '_deleted', 'type',
        'templateIds', 'name', 'sharedTo',
        'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility', 'updateFacility'];

    templateIds: string[]; // array of ids for this series, position is important!
    name: string; // name, mostly for use in reporting

    type: string;
    _id: string;
    _rev: string;
    _deleted: boolean = false;

    dateAdded: Date | string;
    dateUpdated: Date | string;
    updatedBy: string;
    createdBy: string;
    createFacility: string;
    updateFacility: string;
    sharedTo: string[]; // ids of facilities that this sheet is shared to
    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
    }
}

/** sample series object */
export const sampleSeries: TemplateSeries = {
    _id: 'UUIDFROMCOUCH',
    type: 'series',
    sharedTo: [],

    templateIds: ['1562237462125', '646464654646'], // array of ids for this series, position is important!
    name: 'MSCC',

    _rev: '3-16a1ad0d23434d60b84aafd5bb6653db',
    _deleted: false,
    dateAdded: new Date('2017-10-18T07:23:20.619Z'),
    dateUpdated: new Date('2017-10-18T07:23:20.619Z'),
    updatedBy: 'kennie',
    createdBy: 'kennie',
    createFacility: 'HEN',
    updateFacility: 'HEN'
};
