import { PouchObject } from './pouch-object';

/**
 * Defines a Stamp template with name corresponding to the
 * Stamp name defined on the actual rubber-stamp
 * The location and name of each field is stored in the answerTemplates array
 */
export class StampTemplate implements PouchObject {
  public static type: string = 'stamptemplate';
  // list of properties for the TemplateObject, make sure this is updated if the fields change
  public static fields = [
    '_id', '_rev', 'type', '_deleted', 'description',
    'name', 'version', 'seriesId', 'groups', 'answerTemplates', '_attachments', 'requireEncounterDate', 'showEncounterDate', 'beforeFieldsPosition',
    'file', 'circle_radius', 'templateWidth', 'templateHeight', 'sharedTo', 'requireEncounterTime', 'showEncounterTime',
    'requireSpecimenId', 'showSpecimenId', 'routines', 'dateAdded', 'dateUpdated', 'updatedBy', 'createdBy', 'createFacility',
    'updateFacility', 'templateType', 'showPlannedTransfusionDate', 'requirePlannedTransfusionDate', 'showPlannedTransfusionTime',
    'requirePlannedTransfusionTime', 'showTransfusionEndDate', 'requireTransfusionEndDate', 'showTransfusionEndTime',
    'requireTransfusionEndTime', 'handwritingFields', 'handwritingFieldOptions', 'handwritingFieldWidth', 'handwritingFieldHeight',
    'customAfterFormFields', 'customBeforeFormFields', 'freeTextFields'];

  name: string; // name of template corresponding to rubber-stamp name
  version: string; // version number corresponding to version of rubber-stamp
  description?: string; // short description of the template
  seriesId?: string; // optional reference to the series this template belongs to
  groups: Groups[];
  answerTemplates: AnswerTemplate[]; // array of fields for this template
  handwritingFieldWidth = 30;
  handwritingFieldHeight = 35;
  handwritingFields: HandwritingField[]; // array of handwriting fields for this template
  freeTextFields: FreeTextField[]; // array of handwriting fields for this template
  handwritingGroups: HandwritingGroup[]; // groups for each handwriting field eg 'phone', 'mcul'
  _attachments?;  // actual image of the template in png
  file?: File; // used for passing attachment from browser to pouchdb
  circle_radius = 14;
  templateWidth: number;
  templateHeight: number;
  routines: Routine[];

  customBeforeFormFields: any = { components: [] }; // json containing custom form field config (before scan)
  customAfterFormFields: any = { components: [] }; // json containing custom form field config (after scan)

  otherGroups: OtherGroups[];
  otherAnswerTemplates: OtherAnswerTemplates[];

  sharedTo: string[]; // ids of facilities that this sheet is shared to

  // optional fields - required? shown
  templateType?: string;
  beforeFieldsPosition?: 'above' | 'below' = 'below'; // position of custom before fields above or below the other fields
  requireEncounterDate = false;
  showEncounterDate = false;
  requireEncounterTime = false;
  showEncounterTime = false;
  requireSpecimenId = false;
  showSpecimenId = false;

  // transfusion
  showPlannedTransfusionDate = false;
  requirePlannedTransfusionDate = false;
  showPlannedTransfusionTime = false;
  requirePlannedTransfusionTime = false;
  showTransfusionEndDate = false;
  requireTransfusionEndDate = false;
  showTransfusionEndTime = false;
  requireTransfusionEndTime = false;

  type: string;
  _id: string;
  _rev: string;
  _deleted = false;

  dateAdded: Date | string;
  dateUpdated: Date | string;
  updatedBy: string;
  createdBy: string;
  createFacility: string;
  updateFacility: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

/** stores a set of coordinates and definitions for a free text field */
export class FreeTextField {
  serialNumber: number;
  fieldName: string;
  groupName?: string;
  x: number; // top left x coord
  y: number; // top left y coord
  width: number;
  height: number;
  hideInView?: boolean = false;
}

/** stores and individual set of coordinates for a handwriting field */
export class HandwritingField {
  serialNumber: number;
  fieldName: string;
  groupName?: string;
  x: number; // top left x coord
  y: number; // top left y coord
}

export class HWFieldOptionGroup {
  name: string;
  values: string[];
  description: string;
}

export const HandwritingFieldOptionGroups: HWFieldOptionGroup[] = [
  {
    name: 'numeric',
    values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'blank'],
    description: '0 - 9 and blank'
  },
  {
    name: 'alpha',
    values: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'blank'],
    description: 'a - z and blank'
  },
  {
    name: 'alphanumeric',
    values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'blank'],
    description: '0 - 9, a-z, and blank'
  }
]

export class HandwritingGroup {
  groupTitle: string; // title of group for display
  groupLabel?: string; // label of group for display, title will be used if label not found
  required: boolean; // is this group a required data point
  hideInView?: boolean = false; // should the fields belonging to this group be hidden on the view(s)
  handwritingFieldOptionGroup: string; // options for a handwriting field that can be detected   
  valid?: boolean;
  userInput?: boolean;
  userInputField?: string;
  userInputFormat?: string;
  dataFormat?: HWDataFormatTypes = 'text'; // See options from HandwritingFieldDatFormats, value is .name
  decimalPlaces?: number; // number of decimal places if data format is decimal
}

type HWDataFormatTypes = typeof HandwritingFieldDataFormats[number]['name'];

export class HWFieldDataFormat {
  name: string;
  description: string;
}

export const HandwritingFieldDataFormats: HWFieldDataFormat[] = [
  {
    name: 'text',
    description: 'Text (Convert to string, ignore blanks)'
  },
  {
    name: 'integer',
    description: 'Integer (Convert to number, ignore blanks)'
  },
  {
    name: 'decimal',
    description: 'Decimal (Convert to decimal, decimal places specified, ignore blanks)'
  },
  {
    name: 'date',
    description: 'Date (Format as date using input fields in order they are specified in the template)'
  },
  {
    name: 'phone',
    description: 'Phone (Format as phone number using input fields in order they are specified in the template)'
  }
]

/** Stores individual set of coordinates for an answer, the index is the name of the field. */
export class AnswerTemplate {
  serialNumber: number;
  fieldName: string;
  groupName?: string;
  x: number; // center x coord
  y: number; // center y coord
  csRatio?: number; // temporarily store center/surround ratio
}
export class Groups {
  groupTitle: string; // title of group for display
  groupLabel?: string; // optional label if different from title
  exclusive: boolean; // are the options in this group exclusive
  count: boolean; // is this a counting group, ie do we count the number of fields  circled to get the value eg stock
  required: boolean; // is this group a required data point
  hideInView?: boolean = false; // should the fields belonging to this group be hidden on the view(s)
  valid?: boolean;
}

export class OtherGroups extends Groups { }

export class OtherAnswerTemplates extends AnswerTemplate {
  type: string;
  display: boolean;
  displayFormat: string;
}

export class Routine {
  public static type = 'routine';
  public static fields = ['_id', '_rev', 'name', 'routineType', 'description', 'trigger', 'textParams'];
  _id: string;
  _rev: string;
  name: string;
  routineType: string;
  description: string;
  trigger: Trigger;
  textParams: TextParams[];
}

export class Trigger {
  condition: string;
  rules: Fields[];
}

export class Fields {
  type: string;
  groupName: string;
  fieldName: string;
  options: any[];
}

export class TextParams {
  paramName: string;
  paramValue: string;
  paramHint?: string;
}

export const TemplateTypes = [
  { shortName: 'genexpert', name: 'GeneXpert' },
  { shortName: 'referral', name: 'Referral' },
  { shortName: 'labTemplate', name: 'Lab Template' },
];

/** sample template object */
export const sampleTemplate: StampTemplate = {
  _id: 'UUIDFROMCOUCH',
  name: 'EORTI V1.1',
  description: 'Meta Data',
  version: '1.1',
  type: 'stamptemplate',
  requireEncounterDate: false,
  showEncounterDate: false,
  beforeFieldsPosition: 'above',
  requireEncounterTime: false,
  showEncounterTime: false,
  requireSpecimenId: false,
  showSpecimenId: false,
  showPlannedTransfusionDate: false,
  requirePlannedTransfusionDate: false,
  showPlannedTransfusionTime: false,
  requirePlannedTransfusionTime: false,
  showTransfusionEndDate: false,
  requireTransfusionEndDate: false,
  showTransfusionEndTime: false,
  requireTransfusionEndTime: false,
  customBeforeFormFields: { components: [] },
  customAfterFormFields: { components: [] },
  sharedTo: [],
  templateType: '',
  groups: [
    { groupTitle: 'gender', exclusive: true, required: true, count: true },
    { groupTitle: 'age', exclusive: true, required: false, count: true }
  ],
  circle_radius: 14,
  handwritingFieldWidth: 30,
  handwritingFieldHeight: 35,
  freeTextFields: [
    { serialNumber: 0, fieldName: 'summary_notes', x: 120, y: 130, width: 120, height: 20 }
  ],
  handwritingFields: [
    { serialNumber: 0, fieldName: 'phone_0', groupName: 'phone', x: 120, y: 130 },
    { serialNumber: 1, fieldName: 'phone_1', groupName: 'phone', x: 120, y: 330 }
  ],
  handwritingGroups: [
    {
      groupTitle: 'phone',
      required: true,
      handwritingFieldOptionGroup: HandwritingFieldOptionGroups[0].name,
      userInput: true,
      userInputField: 'encounterDate',
      userInputFormat: 'dd/mm/yy',
      dataFormat: 'phone'
    },
    {
      groupTitle: 'MCUL',
      required: false,
      handwritingFieldOptionGroup: HandwritingFieldOptionGroups[0].name,
      dataFormat: 'text'
    }
  ],
  answerTemplates: [
    { serialNumber: 0, fieldName: 'male', groupName: 'gender', x: 120, y: 130 },
    { serialNumber: 1, fieldName: 'female', groupName: 'gender', x: 120, y: 330 }
  ],
  otherGroups: [
    { groupTitle: 'genexpert', exclusive: false, required: false, count: true },
    { groupTitle: 'age', exclusive: true, required: false, count: true }
  ],
  otherAnswerTemplates: [
    {
      serialNumber: 0, fieldName: 'result', type: '', display: false, displayFormat: 'concatenate',
      x: 123.00, y: 32.00, groupName: 'genexpert'
    }
  ],
  routines: [
    {
      _id: '', _rev: 'some string', name: '', routineType: '', description: '', trigger: { condition: '', rules: [] },
      textParams: [{ paramName: '', paramValue: '' }]
    }
  ],
  _attachments: {
    'file': {
      'content_type': 'image/png',
      'revpos': 3,
      'digest': 'md5-UtYIOblclD9Z6iAX42ne6Q==',
      'length': 1082337,
      'stub': true
    }
  },
  templateWidth: 1074,
  templateHeight: 1545,
  _rev: '3-16a1ad0d23434d60b84aafd5bb6653db',
  _deleted: false,
  dateAdded: new Date('2017-10-18T07:23:20.619Z'),
  dateUpdated: new Date('2017-10-18T07:23:20.619Z'),
  updatedBy: 'kennie',
  createdBy: 'kennie',
  createFacility: 'IRC',
  updateFacility: 'IRC'
};
