import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from "moment";

import { environment } from "../../../../environments/environment";
import {
  AnswerSheet,
  SheetError,
  SheetErrorCodes,
} from "../../../models/answer-sheet";
import { Attachment } from "../../../models/attachment";
import { Facility } from "../../../models/facility";
import { Patient } from "../../../models/patient";
import { TemplateSeries } from "../../../models/series";
import { AppSettings } from "../../../models/settings";
import {
  Groups,
  HandwritingGroup,
  StampTemplate,
} from "../../../models/stamp-template";

import { AttachmentService } from "../../../services/attachment.service";
import { FacilityService } from "../../../services/facility.service";
import { PatientService } from "../../../services/patient.service";
import {
  AppTypes,
  RepositoryService,
} from "../../../services/repository.service";
import { TemplateSeriesService } from "../../../services/template-series.service";
import { TemplateService } from "../../../services/template.service";

import { fabric } from "fabric";
import { User, UserRoles } from "../../../models/user";
import { Router } from "@angular/router";

@Component({
  template: `
    <h3 mat-dialog-title style="margin: 10px 0; text-align: center;">Success</h3>
    <mat-dialog-content>
    <p class="uk-text-center" *ngIf="appSettings.uiViewSettings.cicWorkflow">
      Report successfully saved. Click OK to continue to add the Next Report.
    </p>

    <p
      class="uk-text-center"
      *ngIf="appSettings.uiViewSettings.safeBloodWorkflow"
    >
      Data from Template <strong>{{ template.name }}</strong> was saved. Click
      OK to continue and digitize the next template.
    </p>

    <p
      class="uk-text-center"
      *ngIf="
        appSettings.uiViewSettings.farmAlertWorkflow ||
        appSettings.uiViewSettings.polioWorkflow
      "
    >
      Record for <strong>{{ template.name }}</strong> was saved. Click OK to
      continue and digitize the next record.
    </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
    <button
      type="button"
      mat-raised-button
      class="width100 uk-text-contrast"
      style="background: #71C341;"
      (click)="dialogRef.close(true)"
    >
      OK
    </button>
    </mat-dialog-actions>
  `,
})
export class SuccessDialogComponent {
  appSettings: AppSettings;
  template: StampTemplate;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.appSettings = data.appSettings;
    this.template = data.template;
  }
}

@Component({
  template: `
    <h2 mat-dialog-title >Select Referring to Facility</h2>
    <mat-dialog-content>
    <mat-form-field class="width100">
      <mat-label>Refer to Facility</mat-label>
      <mat-select [(ngModel)]="referToFacility">
        <mat-option *ngFor="let facility of facilities" [value]="facility">
          {{ facility.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        type="button"
        mat-raised-button
        class="width45"
        color="primary"
        (click)="dialogRef.close(referToFacility)"
      >
        Yes (Refer)
      </button>
      <button
        type="button"
        mat-raised-button
        class="width45 uk-margin-small-left"
        color="warn"
        (click)="dialogRef.close()"
      >
        No (Cancel)
      </button>
    </mat-dialog-actions>
  `,
})
export class SelectReferringFacilityComponent {
  facilities: Facility[];
  facility: Facility;
  referToFacility: Facility;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private facilityService: FacilityService
  ) {
    this.facility = data.facility;
    console.log("Facility::", this.facility);

    this.facilityService
      .getFacilities()
      .then((allFacilities) => {
        this.facilities = allFacilities.filter(
          (f) => f._id !== this.facility._id
        );
      })
      .catch((error) => console.log(error));
  }
}

@Component({
  selector: "paper-sheet-view",
  templateUrl: "sheet-view.component.html",
  styleUrls: ["sheet-view.component.css"],
})
export class SheetViewComponent implements OnInit, OnDestroy {
  env = environment;
  debugMode: boolean = this.env.debugMode;
  @Output() sheetViewEditSheet: EventEmitter<AnswerSheet> =
    new EventEmitter<AnswerSheet>();
  @Output() sheetViewShowPatient: EventEmitter<Patient> =
    new EventEmitter<Patient>();
  @Output() newPatientEdit: EventEmitter<any> = new EventEmitter<any>();
  @Input() facilities: Facility[];
  @Input() set template(template: StampTemplate) {
    if (!this.nextClicked) {
      this._template = template;
    } else {
      return;
    }
  } get template(): StampTemplate {
    return this._template;
  }
  _template: StampTemplate;
  @Output() templateChange: EventEmitter<StampTemplate> = new EventEmitter<StampTemplate>();
  @Input() templates: StampTemplate[];
  @Input() facilityTemplates: StampTemplate[];
  facilityTemplateSeries: TemplateSeries;
  @Input() newRecord: boolean;
  @Input() loggedInUser: User;
  @Input() loadingPatientRecords: boolean;
  @Input() currentPatient: Patient;
  @Output() currentPatientChange: EventEmitter<Patient> =
    new EventEmitter<Patient>();
  @Output() nextTemplateScan: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextFarmRecordScan: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextRecordScan: EventEmitter<any> = new EventEmitter<any>();
  @Input() currentTemplateSeries: TemplateSeries;
  @Output() currentTemplateSeriesChange: EventEmitter<TemplateSeries> =
    new EventEmitter<TemplateSeries>();
  @Input() facility: Facility;
  showFarmNextRecordButton: boolean;
  showPen: boolean;
  disableShowNextFarmRecordBtn: boolean;
  @Input() penNumber: number;
  @Output() penNumberChange: EventEmitter<number> = new EventEmitter<number>();
  serialNumberLabel: string;

  currentSheetIndex: number;
  viewDirection: string;
  disableNextRecordInReport: boolean;
  disablePreviousRecordInReport: boolean;
  loadingRecordInReport: boolean;
  @Input() set reportSeriesRecords(sheets: AnswerSheet[]) {
    this._reportSeriesRecords = sheets;
    if (!this.newRecord) {
      // TO-DO??? : sort the report series by the id, template 
      // current sheet index
      this.currentSheetIndex = sheets.findIndex(sheet => sheet._id === this.sheet._id);
      // disable next record in report view button
      this.disableNextRecordInReport = sheets.length === 1 || this.currentSheetIndex + 1 === sheets.length;
      // disable previous (before) record in report view button
      this.disablePreviousRecordInReport = sheets.length === 1 || this.currentSheetIndex === 0;
    }
  } get reportSeriesRecords(): AnswerSheet[] {
    return this._reportSeriesRecords;
  }
  _reportSeriesRecords: AnswerSheet[];

  @Input() set sheet(sheet: AnswerSheet) {
    if (!this.nextClicked) {
      this._sheet = sheet;

      if (this.newRecord) {
        this.dialogRef = this.dialog.open(SuccessDialogComponent, { data: { appSettings: this.appSettings, template: this.template } });
        this.dialogRef.afterClosed().subscribe(result => {
          if (result) {
            console.log('Continue scanning');
          }
        });
      }

      // make sure pen number is loaded
      this.penNumber = sheet.penNumber;
      this.penNumberChange.emit(sheet.penNumber);

      // initialize list of answer groups
      // this.answerGroups = Object.keys(sheet.answers).sort();
      this.sheetErrors = sheet.sheetErrors ? sheet.sheetErrors : [];

      // make sure template is loaded
      if (!this.template || (this.template && this.template._id !== this._sheet.stampTemplateId)) {
        this.getTemplate(sheet.stampTemplateId);
      }

      // farmSettings to hide/show Pen
      if (this.appSettings.uiViewSettings.farmAlertWorkflow) {
        this.showPen = this.template._id === this.appSettings.farmSettings.incomeExpenseTemplate || this.template._id === this.appSettings.farmSettings.smartRegistrationTemplate;
        this.showFarmNextRecordButton = this.showPen && this.appSettings.farmSettings.showFarmNextRecordButton;
        this.disableShowNextFarmRecordBtn = sheet.encounterDate === moment(new Date()).format('MM/DD/YYYY');
      }

      // // load handwriting groups
      // this.handWritingGroups = this.template.handwritingGroups?.map((g, i) => {
      //   if (this.appSettings.uiViewSettings.farmAlertWorkflow && (g.groupTitle === 'Date' || g.groupTitle === 'Pen#')) {
      //     // remove groups (date & pen #)
      //     return;
      //   }
      //   return { groupTitle: g.groupTitle, hideInView: g.hideInView }
      // }).filter(g => g); // HW groups

      // this.answerGroups = this.template.groups?.map(g => { return { groupTitle: g.groupTitle, hideInView: g.hideInView } }); // OMR groups

      // set current patient non exist
      if ((!this.currentPatient || this.currentPatient._id !== sheet.patientId) && this.appSettings.patientSettings.enablePatientObjects) {
        this.getPatient(this.sheet.patientId);
      }

      this.getCurrentSeries(this.template.seriesId);

      // track when loading next/previous record in report
      this.loadingRecordInReport = false;

      // set dimensions
      this.setDimensions();

      // draw circles
      this.drawTemplateCirclesToCanvas();

      // update the date & time fields labels depending on the selected template
      const { dateLabel, timeLabel } = this.templateService.getDateTimeLabels(this.template.name, this.appSettings);
      this.dateFieldLabel = dateLabel;
      this.timeFieldLabel = timeLabel;
    } else {
      return;
    }
  }
  get sheet(): AnswerSheet {
    return this._sheet;
  }
  _sheet: AnswerSheet;

  @Input() set currentAttachment(currentAttachment: Attachment) {
    if (!this.nextClicked) {
      this._currentAttachment = currentAttachment;
      // get scanned image
      this.getAttachment();
    } else {
      return;
    }
  }
  get currentAttachment(): Attachment {
    return this._currentAttachment;
  }
  _currentAttachment: Attachment;
  @Input() set appSettings(appSettings: AppSettings) {
    this._appSettings = appSettings;
    if (this.appSettings.uiViewSettings.cicWorkflow) {
      this.isCHAUser = this.loggedInUser.userRoles
        ? this.loggedInUser.userRoles.includes(
          this.repository.enumSelector(UserRoles)[3].title
        )
        : false;
    }
    this.serialNumberLabel = this.appSettings.uiViewSettings.serialNumberLabelShort
      && this.appSettings.uiViewSettings.serialNumberLabelShort !== ''
      ? this.appSettings.uiViewSettings.serialNumberLabelShort :
      'Serial #';
  } get appSettings(): AppSettings { return this._appSettings; }
  _appSettings: AppSettings;

  userRoles = UserRoles;
  isCHAUser: boolean;
  scannedImageNotAvailable = "";
  scannedImageLayer: any; // layer to display scanned image on our canvas
  canvasViewCircles: any = null; // fabric canvas to hold scanned image and circles
  circles: any[] = []; // track array of canvas circles so we can delete them
  COLOR_CHECKED = "lightgreen"; // color for checked fields
  handWritingGroups: HandwritingGroup[]; // convenience list of all the answer groups we have data for on this answersheet
  answerGroups: any[]; // convenience list of all the answer groups we have data for on this answersheet
  sheetErrors: SheetError[]; // list of all the errors for this answers
  dateFieldLabel: string;
  timeFieldLabel: string;

  public SheetErrorCodes = SheetErrorCodes;
  templateInSeries: boolean;
  isLastInSeries: boolean;
  isFutureEncDate: boolean;

  // app types
  public appTypeEnum = AppTypes;

  // dialog reference
  dialogRef: MatDialogRef<any>;
  nextClicked: boolean = false;
  nextTemplateButton: boolean = true;

  // dimensions
  dimensions: {
    maxWidth: number;
    maxHeight: number;
    templateScale: number;
  } = {
      maxWidth: Math.min(500, window.innerWidth), // allow for padding
      maxHeight: Math.min(600),
      templateScale: null, // scale factor for fitting canvases to screen
    };

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private patientService: PatientService,
    private templateService: TemplateService,
    private attachmentService: AttachmentService,
    private seriesService: TemplateSeriesService,
    public repository: RepositoryService,
    private router: Router
  ) {
    fabric.Object.prototype.objectCaching = false;
  }

  ngOnInit() {
    this.disableNavigation();
    this.hideNextTemplateButton(this.router.url);
  }

  hideNextTemplateButton(url: string) {
    const pattern = /sheetId=(\d+)/;
    const match = url.match(pattern);
    const sheetId = match ? match[1] : null;
    this.nextTemplateButton = !sheetId;
  }

  /** Destroy unused elements to recover memory */
  ngOnDestroy(): void {
    this.canvasViewCircles.clear();
    this.canvasViewCircles = null;
    if (this.scannedImageLayer) {
      this.scannedImageLayer.dispose();
    }
    this.scannedImageLayer = null;
    this.circles.forEach((circle) => {
      circle = null;
    });
    this.circles = [];
  }

  // getGroupName(fg) {
  //   return this.answerGroups.filter(ag => ag.includes(fg));;
  // }

  setDimensions() {
    // init canvas
    this.canvasViewCircles = new fabric.Canvas("canvasViewCircles", {
      allowTouchScrolling: true,
    });

    // set scale factor based on max available width, height
    this.dimensions.templateScale = Math.min(
      this.dimensions.maxWidth / this.template.templateWidth,
      this.dimensions.maxHeight / this.template.templateHeight
    );

    // set size of canvas
    this.canvasViewCircles.setWidth(
      this.template.templateWidth * this.dimensions.templateScale
    );
    this.canvasViewCircles.setHeight(
      this.template.templateHeight * this.dimensions.templateScale
    );
  }

  /**
   * function to get template record
   * @param tempId template id
   */
  getTemplate(tempId) {
    this.template = this.templates.find((template) => template._id === tempId);
    setTimeout(() => {
      this.templateChange.emit(this.template);
    }, 0);
    console.log("VIEW: Template loaded");
  }

  /** load the patient from the database */
  getPatient(patientId: string) {
    this.patientService
      .getPatient(patientId)
      .then((patient) => {
        this.currentPatient = patient;
        this.currentPatientChange.emit(patient);
      })
      .catch((error) => {
        this.currentPatient = null;
        console.log("Error getting patient", error);
      });
  }

  /** get the current series for this template */
  getCurrentSeries(seriesId: string) {
    // check if template is of a series
    this.templateInSeries = this.seriesService.isTemplateInSeries(
      this.template
    );

    if (!seriesId && !this.templateInSeries) {
      return;
    }

    this.seriesService
      .getSeriesById(seriesId)
      .then((series) => {
        // check if template is last in series
        this.facilityTemplateSeries = new TemplateSeries(series);
        this.facilityTemplateSeries.templateIds =
          this.seriesService.filterTemplateIdsInSeries(
            this.facilityTemplates,
            series
          );
        this.isLastInSeries = this.seriesService.isLastInSeries(
          this.template,
          this.facilityTemplateSeries
        );
        this.currentTemplateSeriesChange.emit(series);
      })
      .catch((error) => console.log("Error loading template series", error));
  }

  disableNavigation(){
    this.disableNextRecordInReport = true;
    this.disablePreviousRecordInReport = true;
  }

  /**
   * function to navigate through the records in the report series
   */
  navigateRecordsInReport(viewDirection: string) {
    this.loadingRecordInReport = true;
    this.disableNavigation();
    this.viewDirection = viewDirection; // used in the progress bar used to track loading next/previous record in report
    let index = viewDirection === 'next' ? this.currentSheetIndex + 1 : this.currentSheetIndex - 1
    const newSheet = this.reportSeriesRecords[index];
    this.viewSheet(newSheet, index);
  }

  /**
   *  let the controller know an answer sheet has been selected for viewing
   */
  viewSheet(sheet: AnswerSheet, index: number) {
    this.currentSheetIndex = index;

    if (sheet.skipped) {
      if (this.debugMode) {
        console.log("Record Skipped");
      }
      return;
    }
    // navigate to view scanned record
    this.router.navigate([
      "/new_record",
      { viewMode: "view", sheetId: sheet._id },
    ]);
  }

  /** display circles for each template answer field */
  drawTemplateCirclesToCanvas(): void {
    console.log("VIEW: adding template circles to canvas");
    this.template.answerTemplates.forEach((answerTemplate) => {
      if (!this.sheet.answers[answerTemplate.groupName]) {
        // if group does not exist add it
        this.sheet.answers[answerTemplate.groupName] = {
          [answerTemplate.fieldName]: false,
        };
      } else if (
        !this.sheet.answers[answerTemplate.groupName][answerTemplate.fieldName]
      ) {
        // if property doesn't exist on model, add it
        this.sheet.answers[answerTemplate.groupName][answerTemplate.fieldName] =
          false;
      }
      if (
        !this.template.groups.find(
          (group) => group.groupTitle === answerTemplate.groupName
        ).hideInView
      ) {
        // add circle on canvas
        this.addCircle(
          answerTemplate.x * this.dimensions.templateScale,
          answerTemplate.y * this.dimensions.templateScale,
          this.sheet.answers[answerTemplate.groupName][
          answerTemplate.fieldName
          ],
          answerTemplate.fieldName,
          answerTemplate.groupName
        );
      }
    });
  }

  /**
   * Function to add circle to template image
   * @param x x coordinate (distance from left)
   * @param y y coordinate (distance from top)
   */
  addCircle(
    x: number,
    y: number,
    selected: boolean,
    fieldName: string,
    groupName: string
  ): void {
    if (selected) {
      const circle = new fabric.Circle({
        groupName: groupName,
        fieldName: fieldName,
        stroke: this.COLOR_CHECKED,
        fill: "rgba(0,0,0,0)",
        radius: this.template.circle_radius * this.dimensions.templateScale,
        strokeWidth: 4 * this.dimensions.templateScale,
        opacity: 1,
        hasControls: false,
        lockMovementX: true,
        lockMovementY: true,
        hasBorders: false,
        left: x,
        top: y,
        originX: "center",
        originY: "center",
      });
      this.canvasViewCircles.add(circle);
      this.circles.push(circle);
    }
  }

  // given the custom fields property get all of the field names
  getFields(data: any) {
    return !this.sheet.customBeforeFieldsData ? [] : Object.keys(data);
  }

  /** return display label of a formio field given its key */
  getFormioFieldLabel(fieldKey: string): string {
    return this.template?.customBeforeFormFields?.components?.find(
      (component) => component.key === fieldKey
    )?.label
      ? this.template.customBeforeFormFields.components.find(
        (component) => component.key === fieldKey
      ).label
      : fieldKey;
  }

  // filter out hidden HW field
  filterHiddenHWGroups(hwGroups: HandwritingGroup[]): HandwritingGroup[] {
    return hwGroups.filter((p) => p.hideInView !== true);
  }

  // filter out hidden OMW field
  filterHiddenOMRGroups(omrGroups: Groups[]): Groups[] {
    return omrGroups.filter((p) => p.hideInView !== true);
  }

  /** return whether we should display the value of a formio field in view mode given its key */
  displayFormioFieldInView(fieldKey: string): boolean {
    return this.template?.customBeforeFormFields?.components?.find(
      (component) => component.key === fieldKey
    )?.tableView;
  }

  /** Get OMR answers for a given group */
  getAnswers(answerGroup: string) {
    let answersString = ""; // keep track of answers that are true
    Object.keys(this._sheet.answers[answerGroup]).forEach((answerField) => {
      if (this._sheet.answers[answerGroup][answerField]) {
        answersString += answerField + "; ";
      }
    });
    return answersString;
  }

  /** Cancel and reset to default editing view */
  cancel(): void {
    this.sheetViewEditSheet.emit(null);
  }

  /** Edit current sheet */
  editSheet(): void {
    console.log("VIEW: Edit Record button has been pressed");
    this.sheetViewEditSheet.emit(this.sheet);
  }

  /** Add new medical record for a different patient */
  addNewPatient(): void {
    console.log("VIEW: Add New Patient button has been pressed");
    this.newPatientEdit.emit();
  }

  /** function to get attachment file from the database  */
  getAttachment() {
    // fetch all attachment files from the sheet object
    if (!this.currentAttachment || !this.currentAttachment._attachments) {
      console.log("VIEW: No attachments: unable to list attachments");
      this.scannedImageNotAvailable = "(Scanned Image Not Available)";
      this.getTemplateAttachment();
      return;
    }

    // check if record is new
    if (
      this.newRecord ||
      (!this.newRecord &&
        this.currentAttachment._attachments &&
        this.currentAttachment._attachments["file"].data)
    ) {
      console.log(
        "new record OR record with currentAttachmentData, adding image layer"
      );
      this.addImageLayer(this.currentAttachment._attachments["file"].data);
      return;
    }

    // get scanned image blob for display
    this.attachmentService
      .getSheetAttachment(this.currentAttachment._id, "file")
      .then((blob) => {
        this.addImageLayer(blob);
        this.scannedImageNotAvailable = "";
      })
      .catch((err) => {
        console.log("ERROR: getting scanned image", err);
        this.scannedImageNotAvailable = "(Scanned Image Not Available)";
        this.getTemplateAttachment();
      });
  }

  /** function to load the template image file */
  getTemplateAttachment() {
    this.templateService
      .getTemplateAttachment(this.sheet.stampTemplateId)
      .then((blob) => {
        this.addImageLayer(blob);
      })
      .catch((error) => {
        this.snackBar.open("Unable to load template image file", "Error");
        console.log("VIEW: Unable to load template image file", error);
      });
  }

  /**
   * function to create image url rom the blob
   * @param imageBlob scanned image blob
   */
  addImageLayer(imageBlob) {
    console.log("VIEW: adding scanned image layer");
    this.canvasViewCircles.remove(this.scannedImageLayer);

    const img = new Image();
    const url = URL.createObjectURL(imageBlob);

    img.onload = () => {
      // copy scanned image to fabric
      this.scannedImageLayer = new fabric.Image(img, {
        left: 0,
        top: 0,
        scaleX:
          (this.template.templateWidth * this.dimensions.templateScale) /
          img.width,
        scaleY:
          (this.template.templateHeight * this.dimensions.templateScale) /
          img.height,
        opacity: 0.85,
        selectable: false,
        evented: false,
      });
      console.log("VIEW: scanned image layer added");

      // add scanned image to the back of the canvas
      this.canvasViewCircles.add(this.scannedImageLayer);
      this.canvasViewCircles.sendToBack(this.scannedImageLayer);
    };
    img.src = url;
  }

  /** Function check if user role has been activated edit flow */
  userAllowedEditFlow(): boolean {
    return this.loggedInUser.userRoles.some((role) =>
      this.appSettings.recordSettings.enableEditRecordForUserRole.includes(role)
    );
  }
}
