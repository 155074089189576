import { Component, OnInit, Renderer2 } from '@angular/core';
import { RepositoryService, AppTypes } from '../app/services/repository.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'paper-app-root',
  templateUrl: './app-user.component.html',
  // providers: [AuthenticationService, RepositoryService, Globals]
})
export class AppComponent implements OnInit {
  env = environment;
  loadingOpenCV = false;

  constructor(private repository: RepositoryService, private renderer: Renderer2) {
    // load opencv
    // this.loadOpenCVScript('assets/opencv/opencv.js', () => {
    //   console.log('OpenCV Loaded');
    //   this.loadingOpenCV = false;
    // });

    // load web analytics script
    this.loadWebAnalyticScript(() => {
      console.log('Loaded web analytics script');
    })
  }

  ngOnInit() {
    this.repository.setApp(AppTypes.userApp);
  }

  /**
   * Load plausible script to the index head tag
   * @param src script src
   * @param callback 
   */
  loadWebAnalyticScript(callback: any) {
    const scriptElement = this.renderer.createElement('script');

    // Set script attributes
    this.renderer.setAttribute(scriptElement, 'defer', '');
    this.renderer.setAttribute(scriptElement, 'data-domain', `${this.env.appURL}`);
    this.renderer.setAttribute(scriptElement, 'src', `${this.env.webAnalyticURL}`);

    // Append the script element to the head tag
    this.renderer.appendChild(document.head, scriptElement)

    callback();
  }

  /** Load scripts to index head tag */
  loadScript(url, callback) {
    const script: any = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {  // IE
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' ||
          script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {  // Others
      script.onload = function () {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
