import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Facility } from '../../models/facility';
import { User, UserRoles } from '../../models/user';

import { RepositoryService } from '../../services/repository.service';
import { ValidationService } from '../../validation/validation.service';
import { AuthenticationService } from '../../services/authentication.service';

import { FormatPhone } from '../../pipes/formatPhone';

@Component({
  selector: 'paper-user-edit',
  templateUrl: 'user-edit.component.html'
})

export class UserEditComponent {
  @Input() user: User;
  @Input() facilities: Facility[];
  @Output() userUpdated: EventEmitter<User> = new EventEmitter<User>();
  @Output() editingCancel: EventEmitter<string> = new EventEmitter<string>();
  userForm: FormGroup;
  userRoleOptions = [];
  userRolesOptions = this.repositoryService.enumSelector(UserRoles);

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private repositoryService: RepositoryService
  ) {
    this.userForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', [Validators.required, ValidationService.phoneNumberValidation, Validators.pattern('[0-9]*')]],
      emailAddress: ['', [Validators.required, ValidationService.emailValidator]],
      facility: ['', Validators.required],
      userRole: ['', Validators.required],
      userRoles: ['', Validators.required],
    });

    this.userRoleOptions = this.authService.userRoleOptions;
  }

  updateUser(): void {
    this.userUpdated.emit(this.user);
  }

  cancel(): void {
    this.editingCancel.emit('');
  }

  /** Format the phone number when user has left the input field */
  formatNumber() {
    this.user.phone = FormatPhone.formatPhoneNumber(this.user.phone).shortPhone;
  }

}
