<!-- spinner indicator -->
<div class="width100"
  *ngIf="loadingAppSettings || loadingFacility || loadingPatients || loadingReportSeries || loadingAnswerSheets || loadingTemplates || loadingTemplateSeries">
  <div style="width:200px" class="uk-container-center uk-margin-large-top uk-text-center">
    <mat-progress-spinner color="primary" mode="indeterminate" class="uk-margin uk-align-center">
    </mat-progress-spinner>
    <span *ngIf="loadingAppSettings">Loading app settings. <br /></span>
    <span *ngIf="loadingFacility">Loading facility. <br /></span>
    <span *ngIf="loadingPatients">Loading patients. <br /></span>
    <span *ngIf="loadingReportSeries">Loading report series. <br /></span>
    <span *ngIf="loadingAnswerSheets">Loading patients records. <br /></span>
    <span *ngIf="loadingTemplates">Loading stamp templates. <br /></span>
    <span *ngIf="loadingTemplateSeries">Loading templates series. <br /></span>
    Please wait.
  </div>
</div>

<div
  *ngIf="!loadingAppSettings && !loadingFacility && !loadingPatients && !loadingAnswerSheets && !loadingTemplates && !loadingTemplateSeries">
  <!-- Top Nav -->
  <paper-top-nav [appSettings]="appSettings"></paper-top-nav>

  <!-- outlet for patients components -->
  <router-outlet></router-outlet>
</div>